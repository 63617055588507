import { Pipe, PipeTransform } from '@angular/core';
import {isArray} from 'util';
import {MessagingService} from '../messaging.service';

@Pipe({
  name: 'groupMessages'
})
export class GroupMessagesPipe implements PipeTransform {

    constructor(private messagingService: MessagingService) {

    }

    transform(messages: any[], args?: any): any {
        if (isArray(messages)) {
            messages.forEach((msg, index) => {
                const previous = messages[index - 1];
                const next = messages[index + 1];
                if (!this.sameGroup(msg, previous) && this.sameGroup(msg, next)) {
                    msg['group_role'] = 'first-of-group';
                } else if (this.sameGroup(msg, previous) && !this.sameGroup(msg, next)) {
                    msg['group_role'] = 'last-of-group';
                } else if (this.sameGroup(msg, previous) && this.sameGroup(msg, next)) {
                    msg['group_role'] = 'middle-of-group';
                } else {
                    msg['group_role'] = 'none';
                }
            });
            return messages;
        } else {
            return [];
        }
    }

    private sameGroup(msg1, msg2): boolean {
        if (msg1 && !msg1.author || msg2 && !msg2.author) {
            return false;
        }
        return (!msg1 || !msg2) ? false : String(msg1.author.id) === String(msg2.author.id);
    }

}
