export const groupsManagement = {
    accessGroupsManager: ['manager', 'administrator'],
    accessGroupsTrainer: ['trainer', 'administrator'],
    authorizedRoles: ['learner', 'trainer', 'manager', 'administrator'],
    canActiveMetacognition: {
        group: true
    },
    canBeJoined: {
        group: true
    },
    canBeDelete: {
        workgroup: false,
        institution: false,
        learner: true,
        learners: true,
    },
    group: {
        columns: { // TODO verifier setting pour etablissement
            trainer: ['checkbox', 'color', 'groupname', 'learners', 'level', 'code', 'metacognitionState', 'buttons'],
            administrator: ['checkbox', 'color', 'groupname', 'learners', 'level', 'code', 'metacognitionState', 'buttons'],
            manager: ['checkbox', 'color', 'groupname', 'learners', 'level', 'code', 'metacognitionState', 'buttons'],
            default: ['color', 'groupname', 'level', 'code', 'metacognitionState']
        }
    },
    learner: {
        columns: {default: ['checkbox', 'avatar', 'username', 'groups', 'workgroups', 'buttons']},
        createFields: { default: ['avatar', 'nickname', 'password', 'groups', 'workgroups']},
        editFields: { default: ['id', 'avatar', 'nickname', 'password', 'groups', 'workgroups']},
        joinFields: { default: ['avatar', 'nickname', 'password', 'groups', 'workgroups']},
    },
    groupType: 2,
    institutionGroupType: 52,
    levelsWidget: 'select',
    primaryColors: ['#C5629D', '#FF9EA1', '#EEDA78', '#6FF2C5', '#6DE6E2', '#305792'],
    workgroupType: 3,
    requiredFields: ['nickname', 'level', 'groupname', 'workgroupname']
};
