import {takeUntil} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import 'videojs-youtube';
import 'videojs-markers';
import 'videojs-overlay';
import {CommunicationCenterService} from '@modules/communication-center';
import {Subject} from 'rxjs/index';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {EventService} from '../../../../../shared/event.service';

declare var videojs: any;

@Component({
    selector: 'app-video-with-markers',
    templateUrl: './video-with-markers.component.html'
})
export class VideoWithMarkersComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('video', { static: true }) videoElement: ElementRef;
    @ViewChild('toMarker', { static: true }) toMarker: ElementRef;
    @ViewChild('tooManyMarker', { static: true }) tooManyMarker: ElementRef;

    @Input('videoConfig') videoConfig: any;
    @Input('questionObject') questionObject: any;

    public showVideoWithMarkersLabel: boolean;
    private removeMarker: any;
    private video: any;
    private videoSetup: any;
    private selectedMarker: any;
    private markers: any;
    private overlayClicked: any;
    private startTime: 0;
    private unsubscribeInTakeUntil = new Subject();
    private isMarkerOpened: boolean;
    private overlayActive: boolean;
    private readonly tooManyMarkerOverlayTimeout = 1500;
    public settings: {[key: string]: any};
    private tracked: boolean = false;
    private firstPlayPassed: boolean = false;
    private selectedMarkerIndex: number;


    constructor(
        private communicationCenter: CommunicationCenterService,
        private activitiesService: ActivitiesService,
        private lessonsService: LessonsService,
        private eventService: EventService,
    ) {
        this.settings = this.activitiesService.settings;
    }

    ngOnInit(): any {
        this.communicationCenter
            .getRoom('video')
            .getSubject('markers').pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((markers) => {
                this.markers = markers;
                if (this.video) {
                    if (this.markers.length > 0) {
                        this.video.markers.reset(this.markers);
                    } else {
                        this.video.markers.removeAll();
                    }
                }
            });

        this.communicationCenter
            .getRoom('video')
            .getSubject('action').pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((action) => {

                switch (action) {
                    case 'play' :
                        if (this.video) {
                            this.video.play();
                        }
                        break;
                    case 'pause' :
                        if (this.video) {
                            this.video.pause();
                        }
                        break;
                    case 'new_marker' :
                        if (this.video) {
                            this.video.pause();
                            this.overlayClicked({time: this.video.currentTime()}, true);
                        }
                        break;
                    case 'marker_limit_reached':
                        if (this.video) {
                            this.video.trigger('showTooManyMarkerOverlay');
                        }
                        break;
                    default:
                        break;

                }
            });

        this.showVideoWithMarkersLabel = this.settings.showVideoWithMarkersLabel;
    }

    ngAfterViewInit(): any {
        this.videoSetup = {
            'preload': 'none',
            'controls': true,
            'muted': false,
            'autoplay': true,
            'fluid': true,
            'playbackRates': [0.5, 0.75, 1, 1.25, 1.5, 2],
            'techOrder': ['html5', 'youtube'],
            'sources': [{
                'type': 'video/youtube',
                'youtube': {
                    'ytControls': 2,
                    'iv_load_policy': 3,
                    'color': 'red',
                    'modestbranding': 1,
                    'rel': 0,
                    'fs': 1,
                    'customVars': {
                        'wmode': 'transparent',
                        'enablejsapi': 1,
                        'origin': 'window.location.origin'
                    }
                }
            }],
        };

        if (this.videoConfig.config.type === 'video/mp4') {
            this.videoSetup['sources'] = [{
                'type': 'video/mp4'
            }];
        }
        this.videoSetup['sources'][0]['src'] = this.videoConfig.config.src;
        if (this.videoConfig.config.controls === false) { // '... === false' because if the field doesnt exist by default, only if we dont want controls, will set controls to false
            this.videoSetup['controls'] = this.videoConfig.config.controls;
        }
        this.isMarkerOpened = this.videoConfig.isMarkerOpened;
        this.markers = this.videoConfig.markers ? this.videoConfig.markers : [];
        this.overlayClicked = this.videoConfig.overlayClicked ? this.videoConfig.overlayClicked : null;
        this.startTime = this.videoConfig.startTime ? this.videoConfig.startTime : 0;
        this.removeMarker = this.videoConfig.removeMarker ? this.videoConfig.removeMarker : null;

        this.video = new videojs(this.videoElement.nativeElement, this.videoSetup, () => {
            this.video.one('play', () => {
                this.video.pause();
                this.video.currentTime(this.startTime);
                this.firstPlayPassed = true;
            });
        });

        this.video.on('playing', (event) => {
            this.video.trigger('hideOverlay');
            this.overlayActive = false;
        });

        this.video.on('play', (event) => {
            try {
                // track play event only one time. The first play need to be passed because it is play auto and paused without user action
                if (!this.tracked && this.firstPlayPassed && this.questionObject && this.lessonsService.currentLesson) { // check if already tracked to send only one play event
                    this.eventService.trackEvent(
                        'Play ' + this.questionObject.activity_content[0].granule[0].format.label,
                        this.questionObject.activity_content[0].granule[0].format.label === 'videoUrl' ? this.questionObject.activity_content[0].granule[0].reference.url + ' ' + this.questionObject.activity_content[0].granule[0].metadatas.title : this.questionObject.activity_content[0].granule[0].file.filename + ' ' + this.questionObject.activity_content[0].granule[0].metadatas.title,
                        this.lessonsService.currentLesson.id + ' ' + this.lessonsService.currentLesson.get('metadatas').title
                    );
                    this.tracked = true;
                }
            } catch (e) {
                console.error(e);
            }
        });

        /**
         *  TODO: needed to click in the exact time in progress bar to open overlay,
         *  problem with css markerStyle who take more place and take additionnal or less seconds
         *  this.selectedMarker.time !== Math.floor(this.video.currentTime())
         *
         *  edited markerStyle with tinner less for fix
         */
        this.video.on('timeupdate', (event) => {
            if (this.selectedMarker && +this.selectedMarker.time !== Math.floor(this.video.currentTime())) {
                delete this.selectedMarker;
                this.video.trigger('hideOverlay');
            }
        });

        this.video.on('showTooManyMarkerOverlay', (event) => {
            setTimeout(() => {
                this.video.trigger('hideTooManyMarkerOverlay');
            }, this.tooManyMarkerOverlayTimeout);
        });

        this.setVideoConfig();
    }

    ngOnDestroy(): any {
        if (this.video) {
            this.video.dispose();
        }
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public remove(): void {
        this.video.trigger('hideOverlay');
        if (this.selectedMarker) {
            this.removeMarker(this.selectedMarker);
        }
    }

    private clickMarker(marker): void {
        this.video.pause();
        if (marker) {
            this.selectedMarker = this.markers.find((mark) => +mark.id === +marker.id);
            this.selectedMarkerIndex = this.markers.findIndex((mark) => +mark.id === +marker.id);

            if (this.videoConfig && this.videoConfig.openMarkerAutomatic) {
                this.clickOverlay();
            } else {
                this.video.trigger('showOverlay');
            }
            this.overlayActive = true;
        }
    }

    private reachedMarker(marker): void {
        this.video.pause();
        if (!this.overlayActive && !this.isMarkerOpened && marker && +marker.time === Math.floor(this.video.currentTime())) {
            this.selectedMarker = this.markers.find((mark) => +mark.id === +marker.id);
            if (this.videoConfig && this.videoConfig.openMarkerAutomatic) {
                this.clickOverlay();
            } else {
                this.video.trigger('showOverlay');
            }
        }
    }

    public clickOverlay(): void {
        this.video.trigger('hideOverlay');
        if (this.overlayClicked) {
            this.video.exitFullscreen();
            this.overlayClicked(this.selectedMarker, false);
            this.selectedMarkerIndex =  +this.selectedMarkerIndex + 1;
            this.eventService.trackEvent(
                'Decrypt',
                'Decrypt ' + this.selectedMarkerIndex + '/' + this.markers.length + ' : ' + this.selectedMarker.marker_type.label,
                this.lessonsService.currentLesson.id + ' ' + this.lessonsService.currentLesson.get('metadatas').title
            );
        }
    }

    private setVideoConfig(): void {
        this.video.markers({
            markers: this.markers,
            markerStyle: {
                'top': '-7px',
                'transform': 'translateX(-50%)',
                'width': '18px',
                'height': '18px',
                'border-radius': '100%',
                'background-color': '#EC184F'
            },
            markerTip: {
                display: false,
                text: (marker) => {
                    return 'Break: ' + marker.text;
                },
                time: (marker) => {
                    return marker.time;
                }
            },
            breakOverlay: {
                display: false,
                displayTime: 1,
            },

            onMarkerClick: (marker) => this.clickMarker(marker),
            onMarkerReached: (marker) => this.reachedMarker(marker),
        });

        /******************  OVERLAY   *********************/
        this.video.overlay({
            overlays: [{
                class: 'videoJsOverlayCustom',
                content: this.toMarker.nativeElement,
                align: 'top-left',
                start: 'showOverlay',
                end: 'hideOverlay'
            }, {
                class: 'videoJsOverlayCustom',
                content: this.tooManyMarker.nativeElement,
                align: 'top-left',
                start: 'showTooManyMarkerOverlay',
                end: 'hideTooManyMarkerOverlay'
            }]
        });
    }

}
