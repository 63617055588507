
import {take, combineLatest, mergeMap, map, filter} from 'rxjs/operators';
import { Injectable  } from '@angular/core';
import { Observable ,  BehaviorSubject ,  ReplaySubject ,  Subscription } from 'rxjs';
import { DataCollection, DataEntity, OctopusConnectService } from 'octopus-connect';
import { Project } from '@modules/projects-management/core/project.model';
import { AccountManagementProviderService } from '@modules/account-management';
import { CommunicationCenterService } from '@modules/communication-center';
import { modulesSettings } from '../../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import { DynamicNavigationService } from '../../../navigation/dynamic-navigation.service';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { localizedDate } from '../../../shared/utils';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Group} from '@modules/groups-management/core/definitions';

const settingsStructure: ModelSchema = new ModelSchema({
    gettingStarted: Structures.string(''),
    accessProject: Structures.boolean()
});

@Injectable()
export class ProjectsService {
    private userData: DataEntity;
    private projectsCollection: {[key: number]: DataEntity} = {};
    private projectsList: Project[] = [];
    private groupsList: any[] = [];
    private workgroupsList: any[] = [];
    private trainersList: any[] = [];
    private corpusSets: any[] = [];
    private selectedProject: Project = null;
    private onProjectSelectionChange: ReplaySubject<Project>;
    private projectsSubscription: Subscription = null;
    private projectToLoad: boolean = false;
    private listArchived = false;

    public settings: {[key: string]: any};
    public onProjectsChanged: BehaviorSubject<Project[]> = new BehaviorSubject([]);


    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    constructor(
        private octopusConnect: OctopusConnectService,
        private accountService: AccountManagementProviderService,
        private communicationCenter: CommunicationCenterService,
        private dynamicNavigation: DynamicNavigationService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        public dialog: MatDialog,
    ) {
        router.events.pipe(filter(event => event instanceof NavigationEnd),
            map(() => this.route),
            map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter(route => route.outlet === 'primary'),
            mergeMap(route => {
                if (route.params) {
                    return route.params;
                } else {
                    return null;
                }
            }),)
            .subscribe((params: Params) => {
                if (params['projectId']) {
                   this.setProjectId = parseInt(params['projectId']);
                }
            });

        this.onProjectSelectionChange = new ReplaySubject<Project>();
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.userData = data;
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.settings = settingsStructure.filterModel(modulesSettings.projectsManagement);
    }

    private postLogout(): void{
        if (this.projectsSubscription) {
            this.projectsSubscription.unsubscribe();
            this.projectsSubscription = null;
            this.communicationCenter
                .getRoom('project-management')
                .getSubject('selectedProject')
                .next(null);
        }
    }

    private postAuthentication(): void {
        if (!this.projectsSubscription && this.settings.accessProject) {
            const subjectTrainersList = this.communicationCenter
                .getRoom('groups-management')
                .getSubject('trainersList');
            const subjectGroupsList = this.communicationCenter
                .getRoom('groups-management')
                .getSubject('groupsList');
            const subjectWorkgroupsList = this.communicationCenter
                .getRoom('groups-management')
                .getSubject('workgroupsList');
            const subjectCorpusSets = this.communicationCenter
                .getRoom('corpus')
                .getSubject('corpusSets');

            this.projectsSubscription = this.loadProjects().pipe(
                combineLatest(subjectGroupsList, subjectTrainersList, subjectCorpusSets, subjectWorkgroupsList))
                .subscribe((data: [DataCollection, any[], any[], any[], any[]]) => {
                    this.groupsList = data[1].filter((group) => !group.archived);
                    this.trainersList = data[2];
                    this.corpusSets = data[3];
                    this.projectsList = [];
                    this.workgroupsList = data[4].filter((group) => !group.archived);

                    for (const entity of data[0].entities) {
                        const currentId: number = parseInt(entity.id.toString());
                        const groups: string[] = this.groupsList
                            .filter((group) => group.projects.indexOf(entity.id.toString()) > -1)
                            .map((group) => group.groupname);
                        const groupsIds: number[] = this.groupsList
                            .filter((group) => group.projects.indexOf(entity.id.toString()) > -1)
                            .map((group) => +group.id);
                        const workgroups: string[] = this.workgroupsList
                            .filter((workgroup) => workgroup.projects.indexOf(entity.id.toString()) > -1)
                            .map((workgroup) => workgroup.workgroupname);
                        const trainers: string[] = this.trainersList
                            .filter((trainer) => entity.get('trainers').indexOf(trainer.id.toString()) > -1)
                            .map((trainer) => trainer.username);
                        const corpus: any[] = this.corpusSets
                            .filter((corpusSet) => corpusSet.project === entity.id.toString())
                            .map((corpusSet) => corpusSet.corpusArray)
                            .reduce((accumulator, corpusArray) => {accumulator.push(...corpusArray);return accumulator;}, []);

                        this.projectsList.push({
                            id: currentId,
                            name: entity.get('label'),
                            theme: entity.get('theme'),
                            groups: groups,
                            groupsIds: groupsIds,
                            workgroups: workgroups,
                            corpus: corpus,
                            trainers: trainers,
                            archived: entity.get('archived'),
                            originalEntity: entity
                        });

                        if (this.projectToLoad && this.selectedProject && this.selectedProject.id === currentId) {
                            this.selectedProject = this.projectsList[this.projectsList.length - 1];
                            this.onProjectSelectionChange.next(this.selectedProject);
                            this.projectToLoad = false;
                            this.communicationCenter
                                .getRoom('project-management')
                                .getSubject('selectedProject')
                                .next(this.selectedProject);
                        }

                        this.projectsCollection[entity.id] = entity;

                      const menuId = `project${entity.id}`;
                      this.dynamicNavigation.clearMenu(menuId);
                      this.dynamicNavigation.registerModuleMenu(menuId, [
                          {
                              'id'   : 'projectsManagement',
                              'title': entity.get('label'),
                              'translate': entity.get('label'),
                              'type' : 'item',
                              'icon' : 'back',
                              'url'  : '/projects/list'
                          },
                          {
                              'id'   : 'projectsDashboard',
                              'title': 'Tableau de bord',
                              'translate': 'navigation.dashboard',
                              'type' : 'item',
                              'icon' : 'dashboard',
                              'url'  : `/projects/${entity.id}/dashboard`
                          },
                          {
                              'id'   : 'monitoring',
                              'title': 'Suivi',
                              'translate': 'navigation.monitoring',
                              'type' : 'item',
                              'icon' : 'followed',
                              'url': `/projects/${entity.id}/tools/followed`
                          },
                          {
                              'id'   : 'researchMonitoring',
                              'title': 'Suivi de recherche',
                              'translate': 'navigation.research_monitoring',
                              'type' : 'collapse',
                              'icon' : 'search',
                              'children': [
                                  {
                                      'id'   : 'researchSheetDraft',
                                      'title': 'Brouillons fiche recherche',
                                      'translate': 'navigation.research_sheet_draft',
                                      'type' : 'item',
                                      'url'  : `/projects/${entity.id}/tools/section-draft/list`
                                  },
                                  {
                                      'id'   : 'researchSheet',
                                      'title': 'Fiche recherche',
                                      'translate': 'navigation.research_sheet',
                                      'type' : 'item',
                                      'url'  : `/projects/${entity.id}/tools/research-sheet`
                                  },
                              ]
                          },
                          {
                              'id'   : 'corpus',
                              'title': 'Corpus',
                              'translate': 'generic.resources',
                              'type' : 'collapse',
                              'icon' : 'file',
                              'children': []
                          },
                          {
                              'id'   : 'ideasWalls',
                              'title': 'Générateur d\'idées',
                              'translate': 'navigation.ideas-wall',
                              'type' : 'item',
                              'icon' : 'generateur',
                              'url'  : `/projects/${entity.id}/tools/ideas-wall/list`
                          },
                      {
                                'id'   : 'help',
                                'title': 'Help',
                                'translate': 'navigation.general_help',
                                'type' : 'item',
                                'icon' : 'help',
                                'url'  : `/projects/${entity.id}/page/aide-aux-ressources`
                            }
                        ]);

                        this.dynamicNavigation.setChildren(menuId, 'corpus', corpus.map((element) => {
                            return {
                                'id'   : element.name,
                                'title': element.name,
                                'translate': element.name,
                                'type' : 'item',
                                'url'  : `/projects/${entity.id}/tools/corpus/${element.id}`
                            };
                        }));
                    }

                    this.communicationCenter
                        .getRoom('projects-management')
                        .next('projectsList', this.projectsList);
                    this.onProjectsChanged.next(this.projectsList.filter((project: Project) => project.archived === this.listArchived));
                });
        }
    }

    getGroups(): string[] {
        return this.groupsList.filter((grp) => !grp.archived).map((group: Group) => group.groupname);
    }

    getWorkgroups(): string[] {
        return this.workgroupsList.map((workgroup) => workgroup.workgroupname);
    }

    getTrainers(): string[] {
        return this.trainersList
            .filter((trainer) => trainer.type === 'mentor')
            .map((trainer) => trainer.username);
    }

    saveGroupsForProject(projectId: number, groups: string[]): void {
        const groupsId: number[] = this.groupsList
            .filter((group) => groups.indexOf(group.groupname) > -1)
            .map((group) => group.id);

        this.communicationCenter
            .getRoom('projects-management')
            .next('setProjectGroups', {groups: groupsId, project: projectId});
    }
    saveWorkgroupsForProject(projectId: number, workgroups: string[]): void {
        const workgroupsId: number[] = this.workgroupsList
            .filter((workgroup) => workgroups.indexOf(workgroup.workgroupname) > -1)
            .map((workgroup) => workgroup.id);

        this.communicationCenter
            .getRoom('projects-management')
            .next('setProjectWorkgroups', {workgroups: workgroupsId, project: projectId});
    }

    trainersListToId(project: Project): string[] {
        return this.trainersList
            .filter((trainer) => project.trainers.indexOf(trainer.username) > -1)
            .map((trainer) => trainer.id.toString());
    }

    loadProjects(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('projects');
    }

    public get projects(): Project[] {
        return this.projectsList.slice();
    }

    public get currentProject(): Project {
        return this.selectedProject;
    }

    public get currentProjectObs(): Observable<Project> {
        return this.onProjectSelectionChange;
    }

    public set setProjectId(id: number) {
        if (this.projectsCollection[id]) {
            this.selectedProject = this.projectsList.filter((project: Project) => project.id === id)[0];
            if (this.selectedProject) {
                this.onProjectSelectionChange.next(this.selectedProject);
                this.communicationCenter
                    .getRoom('project-management')
                    .getSubject('selectedProject')
                    .next(this.selectedProject);
                return;
            }
        }

        this.selectedProject = new Project();
        this.selectedProject.id = id;
        this.onProjectSelectionChange.next(this.selectedProject);
        this.projectToLoad = true;
        this.communicationCenter
            .getRoom('project-management')
            .getSubject('selectedProject')
            .next(this.selectedProject);
    }

    public filterArchived(unlistArchived: boolean): void {
        this.listArchived = unlistArchived;
        this.onProjectsChanged.next(this.projectsList.filter((project: Project) => project.archived === this.listArchived));
    }

    addProject(project): void {
        this.octopusConnect
            .createEntity('projects', {
                label: project.name,
                trainers: this.trainersListToId(project)
            }).pipe(
            take(1))
            .subscribe((data: DataEntity) => {
                this.saveGroupsForProject(parseInt(data.id.toString()), project.groups);
                this.saveWorkgroupsForProject(parseInt(data.id.toString()), project.workgroups);
                this.communicationCenter
                    .getRoom('projects-management')
                    .dispatchEvent('projectCreated', data.id.toString());
            });
    }

    selectProject(project: Project): void {
        this.selectedProject = project;
        this.onProjectSelectionChange.next(this.selectedProject);
        this.communicationCenter
            .getRoom('project-management')
            .getSubject('selectedProject')
            .next(this.selectedProject);
    }

    saveProject(project): void {
        const projectEntity = this.projectsCollection[project.id];

        if (projectEntity) {
            projectEntity.set('label', project.name);
            projectEntity.set('archived', !!project.archived);
            projectEntity.set('trainers', this.trainersListToId(project));
            projectEntity.save().pipe(take(1)).subscribe(() => {
                this.saveGroupsForProject(parseInt(projectEntity.id.toString()), project.groups);
                this.saveWorkgroupsForProject(parseInt(projectEntity.id.toString()), project.workgroups);
            }, (error) => {

                const errorField = {
                    titleDialog: '',
                    bodyDialog: 'projects-management.error_project_unauthorized',
                    labelTrueDialog: '',
                    labelFalseDialog: 'generic.close',
                };
                this.showErrorMessage(errorField);
            });


        }
    }

    deleteProject(project): void {
        const projectEntity = this.projectsCollection[project.id];

        if (projectEntity) {
            projectEntity.remove();

            this.saveGroupsForProject(parseInt(projectEntity.id.toString()), []);
            this.communicationCenter
                .getRoom('projects-management')
                .dispatchEvent('projectDeleted', projectEntity.id.toString());
        }
    }

    showErrorMessage(fields): void {
        for (const myField in fields){
            if (fields[myField] !== ''){
                this.translate.get(fields[myField]).subscribe((translation: string) => fields[myField] = translation);
            }
        }

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data      : fields
        });

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
            this.confirmDialogRef = null;
        });
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    localeDate(date: number): string {
        return localizedDate(date);
    }
}
