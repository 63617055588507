
import {mergeMap, take, takeUntil} from 'rxjs/operators';
import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {Subject, combineLatest} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {VideoMarkersComponent} from '@modules/activities/core/editor-components/video-editor/video-markers/video-markers.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs/index';
import * as _ from 'lodash';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html'
})
export class VideoComponent implements OnInit, OnDestroy {

    @Input() public activityId: any;
    @Input() public contextId: string;
    @Input() questionTypeName: string;

    public instruction: string;
    public isInitializing: boolean;
    public isSaveLoading: boolean;
    public isSaving: boolean;
    public markers: any[];
    public questionObject: any;
    public selectedMarker: any;
    public userSave: DataEntity;
    public videoConfig: any;
    public videoUrl: any;
    public wording: string;

    private activityStepIndex: number;
    private dialogRef: MatDialogRef<VideoMarkersComponent>;
    private unsubscribeInTakeUntil = new Subject();
    private videoCorpus: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        private changeDetector: ChangeDetectorRef,
        private dialog: MatDialog,
        private lessonsService: LessonsService,
        private octopusConnect: OctopusConnectService,
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.activityId) {
                this.activityId = {};
            }

            if (params) {
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activityId[key] = params[key];
                    }
                }
            }
        });
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(() => {
            this.initialize();
        });
    }

    /**
     * Initializes the current component
     */
    private initialize(): void {
        this.reset();

        if (this.activityId && this.activityId['type'] && this.activityId['type'] === 'granule') {
            /*
                todo need to refacto to have only one format of data : DataEntity
            */
            const activity: DataEntity = <DataEntity>this.activityId;
            this.setContentData(activity.get('reference'));
        } else {
            this.activityService.launchActivity((this.activityId))
                .pipe(take(1))
                .subscribe(data => {
                    this.setContentData(data.reference);
                });
        }
    }

    private setContentData (data): void {
        this.questionObject = data;
        this.instruction = this.questionObject.instruction;
        this.wording = this.questionObject.wording;
        this.videoUrl = this.questionObject.activity_content[0].media;
        this.videoCorpus = this.getGranule(this.questionObject.activity_content[0]);
        this.markers =  this.questionObject.activity_content[0].marker;
        if (this.videoCorpus) {
            if (this.markers && this.markers.length) {
                this.loadUserSave();
                this.activityStepIndex = this.activityService.presentArrayElementIndex;
            }
            this.videoConfig = {
                config: {
                    src: this.videoCorpus.get('reference').url,
                },
                canEditMarker: false,
                markers: this.markers,
                overlayClicked: (marker) => this.openSelectedMarker(marker)
            };
            if (this.activityService.settings.urlVideoException.some((urlException) => this.videoCorpus.get('reference').url.includes(urlException))) {
                this.videoConfig.config['type'] = 'video/mp4';
            }
            if (this.videoCorpus.get('format').label === 'video' && this.videoCorpus.get('reference').filemime === 'video/mp4') {
                this.videoConfig['config'] = {
                    src: this.videoCorpus.get('reference').uri,
                    type: this.videoCorpus.get('reference').filemime,
                };
            }
        }

        this.isInitializing = false;
    }

        /**
     * Extract the granule's DataEntity from the given activityContent data
     * @param activityContent - field from activity
     * @returns granule from the content as a DataEntity
     */
    private getGranule(activityContent: any): DataEntity {
        if (activityContent && activityContent.granule && activityContent.granule.length) {
            const granule = activityContent.granule[0];
            return new DataEntity('granule', granule, this.octopusConnect, granule.id);
        }

        return null;
    }

    /**
     * Resets the current component
     */
    private reset(): void {
        this.isInitializing = true;
        delete this.questionObject;
        delete this.instruction;
        delete this.wording;
        delete this.videoCorpus;
        delete this.videoConfig;

        this.changeDetector.detectChanges();
    }

    private openSelectedMarker(selectedMarker): void {
        const index = this.markers.findIndex((marker) => marker.id === selectedMarker.id);
        const answer = this.userSave ? this.userSave.get('userActivity').entitySave.answers[index] : '';
        this.selectedMarker = selectedMarker;
        this.dialogRef = this.dialog.open(VideoMarkersComponent, {
            panelClass: 'player-video-dialog',
            data: {
                lesson: this.lessonsService.currentLesson.get('metadatas').title,
                markerType: selectedMarker.marker_type ? selectedMarker.marker_type : {label: 'activities.personalize'},
                marker: selectedMarker,
                isEditor: false,
                videoConfig: _.cloneDeep(this.videoConfig),
                answer: answer,
                callback: (marker, data) => this.saveUserNotes(marker, data),
            }
        });

        this.dialogRef.afterClosed().subscribe((result) => {

            if (result) {
                if (this.isSaving && result) {
                    this.userSave = result;
                    this.isSaving = false;
                }
            }

        });
    }

    private loadUserSave(): void {
        this.isSaveLoading = true;
        this.activityService.getUserSave(this.activityId.id, this.contextId).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(userSave => {
                if (userSave) {
                    this.isSaveLoading = false;
                    this.userSave = userSave;
                    this.setAnswer();
                } else if (this.lessonsService.isMyAssignment()) {
                    this.isSaveLoading = false;
                    this.saveDefault();
                } else {
                    this.isSaveLoading = false;
                }
            });
    }


    private saveDefault(): void {
        if (!this.isSaving && !this.userSave) {
            this.isSaving = true;
            const answers = this.markers.map((marker) => '');
            this.activityService.saveUserSave(this.activityId.id.toString(), this.contextId, answers, null, 'qcm-save')
                .subscribe((userSave: DataEntity) => {
                    this.userSave = userSave;
                    this.isSaving = false;
                    this.isSaveLoading = false;
                });
        }
    }

    private setAnswer(): void {
        if (this.userSave && this.userSave.get('state') !== 'incomplete') {
            this.activityService.userAnswer.next(this.userSave.get('userActivity').entitySave.answers);
        }
    }

    public saveUserNotes(selectedMarker: any, data: any): Observable<DataEntity> {
        const index = this.markers.findIndex((marker) => marker.id === selectedMarker.id);
        const answers = this.userSave && this.userSave.get('userActivity').entitySave.answers.length > 0 ? this.userSave.get('userActivity').entitySave.answers : this.markers.map((marker) => {
            return ' ';
        });

        let answersEntity;
        let obs;

        if (answers && answers[index] && !answers[index].id) {
            answers[index] = data['survey-notes'];
            obs = this.saveAnswerMarkers(answers).pipe(take(1));
        } else {
            answersEntity = answers.map((answer, key) => {
                const entity = new  DataEntity(
                    'answer',
                    answer,
                    this.octopusConnect,
                    answer.id
                );
                if (+key === +index) {
                    entity.set('answer',  data['survey-notes']);
                }
                return entity;
            });

            obs = this.saveAnswerMarkers(answersEntity, true).pipe(take(1));
        }

        return obs;
    }


    public saveAnswerMarkers(answers, isEntities = null): Observable<DataEntity> {
        const obsAnswer = answers.flatMap((answer) => {
            if (isEntities) {
                if (this.lessonsService.isLessonTest()) {
                    return this.activityService.saveAnswer({answer: answer.get('answer')}, 'answer');
                } else {
                    return answer.save();
                }
            } else {
                return this.activityService.saveAnswer({answer: answer}, 'answer');
            }
        });
        this.isSaving = true;
        return combineLatest(...obsAnswer).pipe(
            take(1),
            mergeMap((entities: DataEntity[]) => {
                const obs = this.activityService
                    .saveUserSave(this.activityId.id.toString(),
                        this.activityService.currentAssignment ? this.activityService.currentAssignment.id.toString() : null,
                        entities ? entities.map((entity) => entity.id.toString()) : [],
                        null, 'qcm-save',
                        this.userSave,
                        this.activityStepIndex);
                obs.subscribe((data) => {
                    this.userSave = data;
                    this.isSaving = false;
                });
                return obs;
            }),);
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

}
