import {Injectable} from '@angular/core';
import {AuthorizationService} from '@modules/authorization';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../settings';

const settingsStructure: ModelSchema = new ModelSchema({
    selfSignup: Structures.boolean(true),
    signup: Structures.boolean(true),
    passwordPolicy: Structures.object(null),
    validateEmailStrategyActivated: Structures.boolean(false),
    fields: Structures.object({
        default: Structures.array([
            'label|require',
            'firstName|require',
            'email|require',
            'password|require',
            'passwordConfirm|require',
            'you_are|require',
            'find_us|require',
            'newsletter'])
    }),
    displayDialogMinorAdult: Structures.boolean(true),
    displaySubTitle: Structures.boolean(false),
    emailIncludeText: Structures.string(''),
    userIsAdultOrMinorOption: Structures.boolean(),
    concatUsernameWithFirstName: Structures.boolean(),
});

const settingsAuthStructure: ModelSchema = new ModelSchema({
    displayLoginLogo: Structures.boolean(false),
    urlSSO: Structures.boolean(false),
});

@Injectable({
    providedIn: 'root'
})

export class AccountManagementAuthorizationService {
    public settings: {[key: string]: any};
    public authenticationSettings: {[key: string]: any};
    constructor(private authorizationService: AuthorizationService) {
        this.settings = settingsStructure.filterModel(modulesSettings.accountManagement);
        this.authenticationSettings = settingsAuthStructure.filterModel(modulesSettings.authentication);
    }

    activeRulesOnStartup(): void {
        // example of rules to put here for trainer in this case
        // this.authorizationService.addRoleRule(SyncRules.blabla, ['trainer']);
    }
}
