import {MatDialog} from '@angular/material/dialog';

declare var tarteaucitron: any;
declare global {
    interface Window {
        tarteaucitronForceLanguage: string;
    }
}

import {take, takeUntil} from 'rxjs/operators';
import {Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {LangChangeEvent, TranslateService, TranslationChangeEvent} from '@ngx-translate/core';
import {Subject, Subscription} from 'rxjs';
import {FuseConfigService} from '@fuse/services/config.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {navigation} from 'app/navigation/navigation';
import {ServiceInstantiator} from './service-instantiator.class';
import {TralaTranslationLoaderService} from './trala-translation-loader.service';
import {BrowserTestService} from '@modules/browser-test';
import {DynamicNavigationService} from './navigation/dynamic-navigation.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {
    brand,
    brandTitle,
    defaultLanguage,
    langs,
    GATracking,
    trackingIDFromSettings,
    matomoTrackingIDFromSettings,
    matomoTracking,
    tarteaucitronConf,
    mobileUrlRedirection,
    youTubeTracking,
    twitterTracking
} from './settings';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {AccountManagementProviderService} from '@modules/account-management';
import {Angulartics2GoogleGlobalSiteTag} from 'angulartics2/gst';
import {Angulartics2Piwik} from 'angulartics2/piwik';
import {MobileRedirectionModalComponent} from '../@fuse/components/mobile-redirection-modal/mobile-redirection-modal.component';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    lang: string;
    browserLang: string;
    themeColor = 'red';
    route: string;
    fuseConfig: any;
    navigation: any;
    tarteaucitronConf: object = tarteaucitronConf;
    brand: string = brand;
    brandTitle: string = brandTitle;
    trackingID: string = trackingIDFromSettings;
    matomoTrackingID: string = matomoTrackingIDFromSettings;
    GATracking: boolean = GATracking;
    matomoTracking: boolean = matomoTracking;
    youTubeTracking: boolean = youTubeTracking;
    twitterTracking: boolean = twitterTracking;

    // Private
    private _unsubscribeAll: Subject<any>;


    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private dialog: MatDialog,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private translationLoader: TralaTranslationLoaderService,
        private serviceInstantiator: ServiceInstantiator,
        private browserTestService: BrowserTestService,
        private dynamicNavigation: DynamicNavigationService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private location: Location,
        private connector: OctopusConnectService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private usersProvider: AccountManagementProviderService,
        private titleService: Title,
        angularticsGA: Angulartics2GoogleGlobalSiteTag,
        angularticsMatomo: Angulartics2Piwik,
        private renderer: Renderer2
    ) {
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', []);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        router.events.subscribe((val) => {
            if (location.path() !== '') {
                this.route = location.path();
            } else {
                this.route = 'Home';
            }
        });

        const defaultLang: string[] = langs.map((lang) => lang.id);
        this.browserLang = defaultLang.indexOf(navigator.language.substring(0, 2)) !== -1 ? navigator.language.substring(0, 2) : defaultLanguage;

        // Add languages
        this._translateService.addLangs(defaultLang);

        // Set the default language
        this._translateService.setDefaultLang(this.browserLang);

        // Use a language
        this.lang = localStorage.getItem('lang') || this.browserLang;
        this.connector.setLanguage(this.lang);
        this._translateService.use(this.lang);
        // Set the navigation translations
        this.translationLoader.loadTranslations(...defaultLang);


        this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.connector.setLanguage(event.lang);
            this._translateService.currentLang = event.lang;
            // init tarteaucitron and update tarteaucitron language
            if (this.youTubeTracking || this.twitterTracking || this.GATracking){
                this.tarteaucitronInit();
            }
        });

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this._fuseConfigService.setConfig({
            layout: {
                navigation: 'true',
                toolbar: 'below',
                footer: 'none'
            }
        });

        browserTestService.checkCompatibility();

        // cookies managment with tarteaucitron
        if (this.youTubeTracking || this.twitterTracking || this.GATracking){
            this.loadTartaucitron().onload = () => {
                this.tarteaucitronInit();
            };
        }

        // Matomo tracking
        if (this.matomoTracking) {
            this.loadMatomo(this.matomoTrackingID);
            angularticsMatomo.startTracking();
        }

        // Google Analytics tracking
        if (this.GATracking) {
            angularticsGA.startTracking();
        }

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (this.isMobile() && mobileUrlRedirection) {
            const dialogRef = this.dialog.open(MobileRedirectionModalComponent, {});
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    window.location.href = mobileUrlRedirection;
                }
            });
        }
        this.document.body.classList.add(this.brand);
        this.document.querySelector('link[ rel *= "icon"]').href = 'assets/' + this.brand + '/favicon.png?v=4';
        this.setTitle(this.brandTitle);
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;

                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }
            });

        // Check (prepare data) if user is Logged or Anonymous
        this.connector.authenticated('http').pipe(take(1)).subscribe((userData: DataEntity) => {
            this.authService.loggedUser = userData;
            this.authService.isAuthenticated = true;
            this.authService.overrideDefaultRouteInRegardOfRole();
            this.communicationCenter.getRoom('authentication').next('userData', userData);
        }, (err) => {
            if (err === 401) {
                this.connector.logout('http').subscribe(() => {
                    this.communicationCenter.getRoom('authentication').next('doLogout', null);
                });
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    public setTitle(newTitle: string): void {
        this.titleService.setTitle(newTitle);
    }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    loadGoogleAnalytics(trackingID: string): void {

        const gaScript = document.createElement('script');
        gaScript.setAttribute('async', 'true');
        gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingID}`);

        const gaScript2 = document.createElement('script');
        gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\', {'anonymize_ip': true, 'cookie_expires': 33696000});`;

        document.documentElement.firstChild.appendChild(gaScript);
        document.documentElement.firstChild.appendChild(gaScript2);
    }

    loadMatomo(trackingID: string): void {
        const matomoScript = document.createElement('script');
        matomoScript.setAttribute('type', `text/javascript`);
        matomoScript.innerText = `var _paq = window._paq || [];_paq.push([\'enableLinkTracking\']);(function() {var u=\'//matomo.tralalere.com/\';_paq.push([\'setTrackerUrl\', u+\'matomo.php\']);_paq.push([\'setSiteId\', \'${trackingID}\']);var d=document, g=d.createElement(\'script\'), s=d.getElementsByTagName(\'script\')[0];g.type=\'text/javascript\'; g.async=true; g.defer=true; g.src=u+\'matomo.js\'; s.parentNode.insertBefore(g,s);})();`;
        document.documentElement.firstChild.appendChild(matomoScript);
    }

    loadTartaucitron(): HTMLScriptElement  {
        const tarteaucitronScript = document.createElement('script');
        tarteaucitronScript.setAttribute('src', `https://preprod.tralalere.com/tarteaucitron/tarteaucitron.js`);
        this.renderer.appendChild(document.body, tarteaucitronScript);
        return tarteaucitronScript;
    }

    tarteaucitronInit(): void{
        window.tarteaucitronForceLanguage = this._translateService.currentLang;
        tarteaucitron.init(this.tarteaucitronConf);
        // Google analytics
        if (this.GATracking) {
            tarteaucitron.user.gtagUa = this.trackingID;
            // tarteaucitron.user.gtagCrossdomain = ['example.com', 'example2.com'];
            tarteaucitron.user.gtagMore = function (): void { /* add here your optionnal gtag() */
            };
            (tarteaucitron.job = tarteaucitron.job || []).push('gtag');
        }

        // matomo
        /* // remove from tarteaucitron to force matomo tracking
        if (this.matomoTracking) {
            tarteaucitron.user.matomoId = this.matomoTrackingID;
            tarteaucitron.user.matomoHost = 'https://matomo.tralalere.com/';
            (tarteaucitron.job = tarteaucitron.job || []).push('matomo');
        } */

        // youtube
        if (this.youTubeTracking) {
            (tarteaucitron.job = tarteaucitron.job || []).push('youtube');
        }

        // twitter
        if (this.twitterTracking) {
            (tarteaucitron.job = tarteaucitron.job || []).push('twitterembed');
        }
    }

    /**
     * know if device is mobile..
     * @private
     */
    private isMobile(): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
}
