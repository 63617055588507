import {Component, Input, OnInit} from '@angular/core';

/**
 * Simplest genereic progress bar take two input :
 * one is the number of step to show 0 = no step optionnal field each step separate equaly the zone
 * other is the pourcent of progression
 * the eventual link beetween step and pourcent must be manage by parent using compornent
 * example of use :
 * <app-progress-bar-generic *ngIf="activitiesService.settings.showProgressBar" [pourcent]="progression"  [numberOfSteps]="5"> </app-progress-bar-generic>
 */

@Component({
    selector: 'app-progress-bar-generic',
    templateUrl: './progress-bar-generic.component.html',
})
export class ProgressBarGenericComponent implements OnInit {

    @Input('pourcent') pourcent: number = 0; // pourcent of bar to colorize
    @Input('numberOfSteps') numberOfSteps: number = 0; // zero equal no steps to show
    public steps: number[] = [];

    constructor() {
    }

    ngOnInit(): void {
        this.generateSteps();
    }

    /**
     * generate the array of step in regard of the number of step need
     */
    private generateSteps(): void {
        const increment = 100 / this.numberOfSteps;
        let sumOfIncrements: number = 0;

        for (let i = 0; i <= this.numberOfSteps; i++) {
            sumOfIncrements = sumOfIncrements + increment;
            this.steps.push(sumOfIncrements);
        }
    }
}
