import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-graph-view',
  templateUrl: './single-graph-view.component.html',
})
export class SingleGraphViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
