export const assignation = {
    assignableLesson: true,
    columns: {
        default: ['assigned_node_title', 'start_date', 'assigned_user', 'state', 'buttons']
    },
    columnsDashboardWidget: {
        default: ['assigned_node_title', 'start_date', 'assigned_user']
    },
    completionStartDateOnly: ['*'],
    displayNews: {
        default : [],
        trainer: ['createNoAssignmentForLongTimeAgo']
    },
    filters: {
        default: ['title', 'group', 'workgroup', 'learner', 'type', 'state'],
        learner: []
    },
    formFields : {
        default : ['group', 'workgroup', 'learner', 'start_date', 'assignment_type'],
    },
    followedLogBookFields: {
        default: ['group', 'type', 'exoName', 'learner', 'beginDate', 'endDate'],
        learner: []
    },
    hasCompletionDate: false,
    hasCompletionTime: false,
    hasType: true,
    checkDefaultFiltersInUrl: true,
    stateWithIcon: false,
    noFilterforAssignmentsWidget: true,
    showHours: false
};
