import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '@modules/authentication';
import {defaultRoute} from '../settings/mathia';

@Injectable({
    providedIn: 'root'
})
export class IsLearnerGuard implements CanActivate {
    constructor(private authService: AuthenticationService, private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const canActivate = this.authService.isLearner();
        if (canActivate === false) {
            this.router.navigate([defaultRoute]);
        }
        return canActivate;
    }

}
