import {Injectable} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable, ReplaySubject} from 'rxjs';
import {TypedDataCollectionInterface} from '../../../../shared/models/octopus-connect/typed-data-collection.interface';
import {TypedDataEntityInterface} from '../../../../shared/models/octopus-connect/typed-data-entity.interface';
import {CommunicationCenterService} from '@modules/communication-center';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {mergeMap, take, tap} from 'rxjs/operators';
import {Moment} from 'moment';

const LICENSE_ENDPOINT = 'licenses';

export enum LicenseType {
    class= '4045',
    institution= '4044',
}

export interface LicenseTypeInterface {
    'id': string | number;
    'label': string;
}

export interface LicenseDataInterface {
    'id': string | number;
    'code': string;
    // TODO si utilisé
    'access': unknown;
    'date': Date;
    'expirationDate': Date;
    // User id
    'uid': string | number;
    'expired': boolean;
    'type'?: LicenseTypeInterface;
}

export type LicenseDataEntity = TypedDataEntityInterface<LicenseDataInterface>;
export type LicenseDataCollection = TypedDataCollectionInterface<LicenseDataInterface>;

@Injectable({
    providedIn: 'root'
})
export class LicensesService {

    public currentUserLicenses: LicenseDataEntity[] = [];
    public currentUserLicenses$ = new ReplaySubject<LicenseDataEntity[]>(1);

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: UserDataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    public getLicensesOfCurrentUser$(): Observable<LicenseDataCollection> {
        // Doublon avec le licensing module, mais il n'a pas le meme intérêt
        return this.octopusConnect.loadCollection(LICENSE_ENDPOINT) as Observable<LicenseDataCollection>;
    }

    private postAuthentication(): void {
        this.loadUserLicenses$().subscribe();
    }

    private postLogout(): void {
        this.currentUserLicenses = [];
        this.currentUserLicenses$.next([]);
    }

    private loadUserLicenses$(): Observable<LicenseDataCollection> {
        return this.getLicensesOfCurrentUser$()
            .pipe(
                tap(licenseCollection => {
                    this.currentUserLicenses = licenseCollection.entities.slice();
                    this.currentUserLicenses$.next(this.currentUserLicenses.slice());
                })
            );
    }

    public patchLicense(license: { id: string; startDate: number; endDate: number; type: string }, change: { type: string; endDate: number }): Observable<DataEntity> {
        const license$ = this.octopusConnect.loadEntity(LICENSE_ENDPOINT, license.id);
        return license$.pipe(
            take(1),
            mergeMap((licenseEntity: DataEntity) => {
                licenseEntity.set('type', LicenseType[change.type]);
                licenseEntity.set('expirationDate', change.endDate.toString());
                return licenseEntity.save(true);
            })
        );
    }

    public createLicense(param: { endDate: number; type: string, userId: string }): Observable<DataEntity> {
        return this.octopusConnect.createEntity(LICENSE_ENDPOINT, {
            uid: param.userId,
            type: LicenseType[param.type],
            expirationDate: param.endDate,
            date: Math.round(new Date().getTime() / 1000),
        });
    }
}
