import {take, takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {DataCollection, DataEntity, OrderCriteria, OrderDirection} from 'octopus-connect';
import {CollectionOptionsInterface} from 'octopus-connect';
import {SearchFiltersService} from 'fuse-core/components/search-filters/search-filters.service';
import {TranslateService} from '@ngx-translate/core';
import {filterByCurrentYearByDefault} from '../../../app/settings';
import {Subject, Subscription} from 'rxjs';
import * as _ from 'lodash';

export const FORM_CONTROL_MAPPING = [
    {endpointFilter: 'title', formControlField: 'titleFilter'},
    {endpointFilter: 'metadatas_title', formControlField: 'metadatas_title'},
    {endpointFilter: 'skills', formControlField: 'searchSkillsFilter'},
    {endpointFilter: 'difficulty_id', formControlField: 'difficultyFilter'},
    {endpointFilter: 'licenseContent', formControlField: 'licenseFilter'},
    {endpointFilter: 'level', formControlField: 'educationnalLevelFilter'},
    {endpointFilter: 'theme', formControlField: 'searchThemeFilter'},
    {endpointFilter: 'bookmarks', formControlField: 'searchBookmarkFilter'},
    {endpointFilter: 'format', formControlField: 'typeFilter'},
    {endpointFilter: 'author:name', formControlField: 'authorFilter'},
    {endpointFilter: 'group:name', formControlField: 'groupFilter'},
    {endpointFilter: 'chapters', formControlField: 'chaptersFilter'},
    {endpointFilter: 'assignation_type', formControlField: 'assignation_typeFilter'},
    {endpointFilter: 'schoolyear', formControlField: 'schoolyearsFilter'},
    {endpointFilter: 'lessons', formControlField: 'lessonsFilter'}
];

export interface CustomList {
    [key: string]: { id: string | number, label: string }[];
}

export type SearchFiltersOptions = Partial<{
    launchSearchOnChange: boolean
}>;

/**
 * generic component to apply filter on data
 * /!\this component must be inside <mat-toolbar>/!\
 *
 * @exemple
 *  <mat-toolbar class="filters">
 *      <app-search-filters
 *
 *      [fields]="fieldsToDisplay"
 *      list of fields ['title', 'label']
 *
 *      [countEntities]="countEntities" [orderFields]="false"
 *      use the order of fields to order the html fields
 *      need to put 'bookmarks', 'launchsearch' and all html element in settings
 *
 *      [overrideEndpointFilterName]="[{originalFilterName: 'bookmarks', targetFilterName: 'bookmarks_theme'}]"
 *      in some case the name of the filter could be différent of the normalize name set corresonping fields here
 *
 *      [customLists]="[{methods:methods}]" methods is an array of :
 *      { id: string,
 *        label: string
 *       }
 *       use to get list with complexe logic in parent get data for mutliple endpoint etc...
 *
 *      (launchSearch)="loadData($event)"></app-search-filters>
 *      emit the filter created to the parent button
 * </mat-toolbar>
 */
@Component({
    selector: 'app-search-filters',
    templateUrl: './search-filters.component.html',
    styleUrls: ['./search-filters.component.scss']
})
export class SearchFiltersComponent implements OnInit, OnDestroy {
    @Output('launchSearch') filter: EventEmitter<CollectionOptionsInterface> = new EventEmitter<CollectionOptionsInterface>();

    // TODO change the comportment to not list the specific fields but type of field. They will be defined by interfaces (like text, list, autocomplete, etc.) and
    //  let each module define the field. A same field like ("title") is not used in the same way between modules, that why an interface define the kind of field and
    //  a module define, by implementing the type interface, each field's configuration (label, filterName, localisation key, list of values)
    @Input() fields: string[] = []; // list of allowed fields ['title', 'skills' etc.]
    @Input() initialsValues: { [control: string]: any } = {};
    @Input() orderFields = false; // pass true for order field like in setting array using appMoveElement directive
    @Input() countEntities = 0;
    // use this customLists to get list with complex logic in parent component
    @Input() customLists?: CustomList;
    @Input() displayedFiltersIcons?: boolean;
    // in some part of program name of filter could be different of normalize case so we use it to override the default value
    @Input() overrideEndpointFilterName: { originalFilterName: string, targetFilterName: string }[] = [];
    // change the default name of field in translate
    @Input() customFieldName: { field: string, value: string }[] = [];
    @Input() options: SearchFiltersOptions;

    @ViewChild('titleInput') titleInput: MatInput;
    // data of the lists fields
    public dataLists: { [key: string]: DataEntity[] } = {};
    // list of form control with endpoint Filter key( value to put in CollectionOptionsInterface filter value)
    public controls: { [key: string]: { endpointFilter: string, formControl: FormControl } } = {};
    public defaultFieldTitle = '';
    public filterByCurrentYearByDefault: boolean = filterByCurrentYearByDefault;
    // name of endpointFilter (filter name in back) and corresponding formControl in html
    private listOfFormControl: { endpointFilter: string, formControlField: string }[] = FORM_CONTROL_MAPPING;
    private optionsInterface: CollectionOptionsInterface = {
        filter: {},
        page: 1,
        range: 12,
        orderOptions: []
    };
    private langSubscription: Subscription;
    private unsubscribeInTakeUntil = new Subject();
    private defaultOptions: SearchFiltersOptions = {
        launchSearchOnChange: false
    };

    constructor(private searchFiltersService: SearchFiltersService,
                private translateService: TranslateService) {
    }

    public get currentOptions(): SearchFiltersOptions {
        return _.merge({}, this.defaultOptions, this.options);
    }

    /**TODO get the list only if fields list are in form */
    ngOnInit(): void {
        this.overrideSpecificEndpointNameFilter();
        this.initFormsControl();
        this.setAllLists();
        this.translateTitleField();
        this.initInitialsValues();
        if (!this.langSubscription) {
            this.langSubscription = this.translateService.onLangChange.pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(() => {
                this.translateTitleField();
            });
        }
        if (this.currentOptions.launchSearchOnChange) {
            this.initOnChanges();
        }
    }

    /**
     * launch search with filter if add
     */
    public launchSearch(): void {
        this.updateFilter();
        this.filter.emit(this.optionsInterface);
    }

    /**
     * display or not field regard to allowedFields
     * @param field
     */
    public displayField(filterField: string): boolean {
        return this.fields.indexOf(filterField) > -1;
    }

    /**
     * some field can have multiple name in regard of where they're call
     * change the defaultField by those pass in Input
     * @param field: string name of the field
     */
    public getFieldTerms(field): string {
        if (this.customFieldName.filter(customField => customField.field === field).length > 0) {
            return this.customFieldName.filter(customField => customField.field === field)[0].value;
        } else {
            return '';
        }
    }

    ngOnDestroy(): void {
    }

    /**
     * update schoolyear display
     */
    public displaySchoolYear(schoolyear: string): string {
        const nextSchoolyear = +schoolyear + 1;
        return schoolyear + '-' + nextSchoolyear;
    }

    public localizedType(type: string): string {
        return `assignment.type.${type}`;
    }

    /**
     * in some part of code lesson and theme for example the name for filter a field could be different of other part
     * of code exemple bookmarks except for theme bookmarks_theme
     * this override with the good value .
     * TODO In back : Better way will be to add spécific endpoint with normalize fields name
     */
    private overrideSpecificEndpointNameFilter(): void {
        if (this.overrideEndpointFilterName && this.overrideEndpointFilterName.length > 0) {
            this.overrideEndpointFilterName.forEach(matchingFields => {
                const fieldControl = this.listOfFormControl.find((field) => field.endpointFilter === matchingFields.originalFilterName);
                if (!!fieldControl) {
                    fieldControl.endpointFilter = matchingFields.targetFilterName;
                }
            });
        }
    }

    /**
     * init form control use a list of string to init all formcontrol
     * with value to '' for filter
     */
    private initFormsControl(): void {
        this.listOfFormControl.forEach(formControl => {
            this.controls[formControl.formControlField] = {endpointFilter: formControl.endpointFilter, formControl: new FormControl('')};
        });
    }

    /**
     * set all the list take the list of fields and search wich one is a list
     * when find one set the list calling the endpoint corresponding
     */
    private setAllLists(): void {
        for (let endpointFieldNameKey in endpointFieldName) {
            if (this.fields.filter(field => field === endpointFieldNameKey).length > 0) {
                this.setList(endpointFieldName[endpointFieldNameKey]);
            }
        }
    }

    /**
     * init list data by type
     * @param endpoint : type of the list = endpoint name
     */
    private setList(endpoint: string): void {
        this.searchFiltersService.getList(endpoint).pipe(take(1))
            .subscribe((data: DataCollection) => {
                this.dataLists[endpoint] = data.entities;
                // TODO move default value into parent component
                if (endpoint === 'schoolyears' && this.filterByCurrentYearByDefault && this.displayField('schoolyears')) {
                    // filter by default with current year
                    let schoolyears = this.dataLists['schoolyears'];
                    schoolyears = schoolyears.filter(schoolyear => schoolyear.get('name') === this.currentSchoolYearBegin());
                    this.controls['schoolyearsFilter'].formControl.setValue(schoolyears[0].id);
                    this.launchSearch();
                }
            }, error => {
                console.log(error);
            });
    }

    /**
     * update filter to apply use list of controls to create filter
     */
    private updateFilter(): void {
        // iterate on formControl name key titleFilter
        for (let control in this.controls) {
            // if on list of formControl present in view there is value for the current key add filter else remove it
            if (this.controls[control].formControl && this.controls[control].formControl.value !== ''
                && this.controls[control].formControl.value !== undefined && this.controls[control].formControl.value !== false
                && this.controls[control].formControl.value !== 'all'
                && this.controls[control].formControl.value !== 'allf') {
                this.optionsInterface.filter[this.controls[control].endpointFilter] = this.controls[control].formControl.value;
            } else {
                delete this.optionsInterface.filter[this.controls[control].endpointFilter];
            }
        }

        // for find on title or tag like before common search filter
        //  use urlExtension: '' // use it to pass data directly in url like /?filtredirect
        if (this.optionsInterface.filter.title && this.optionsInterface.filter.title !== '') {
            this.optionsInterface.urlExtension = this.optionsInterface.filter.title;
            // we don't delete data whith this.optionsInterface.filter.title because some component need title in filter and other in url
            // TODO refacto : see with back dev if we can suppress urlExtenssion method to replace all by field method
        } else {
            this.optionsInterface.urlExtension = '';
        }
    }

    /**
     * by defalut title value is a complex field title with tree field concat
     */
    private translateTitleField(): void {

        const data = {
            titleOne: 'generic.title',
            titleTwo: 'generic.or',
            titleThree: 'generic.tags'
        };

        this.defaultFieldTitle = '';

        // tslint:disable-next-line:forin
        for (const term in data) {
            this.translateService.get(data[term]).subscribe((translation: string) => {
                this.defaultFieldTitle = this.defaultFieldTitle + ' ' + translation;
            });
        }
    }

    /**
     * return the year where begin the current school year in regard of the current date
     */
    private currentSchoolYearBegin(): string {
        // year begin 1er août and finish 31 juillet
        const month = (new Date()).getMonth();
        const year = (new Date()).getFullYear();
        // 1 aout => 31 december
        if (month > 6 && month < 12) {
            return year.toString();
        }
        // december to aout exclude
        if (month < 7) {
            return (year - 1).toString();
        }
    }

    private initInitialsValues(): void {
        Object.keys(this.initialsValues).map(field => {
            const value = this.initialsValues[field];
            if (this.controls.hasOwnProperty(field) === false) {
                console.error(`cannot set value "${value}" to field "${field}". This field does not exist`);
            }
            const control = this.controls[field];
            control.formControl.setValue(value);
        });
    }

    private initOnChanges(): void {
        Object.keys(this.controls).forEach(key => {
            const control = this.controls[key];
            control.formControl.valueChanges.subscribe(() => this.launchSearch());
        });
    }
}

/**
 * enum of fieldtypeand endpoint matching 'field' = 'endpoint name' to get the list except
 * spécific list pass by input
 */
export enum endpointFieldName {
    'difficulty' = 'difficulty',
    'schoolyears' = 'schoolyears',
    'skills' = 'skills',
    'theme' = 'themes',
    'educationnalLevel' = 'educational_level',
    'assignation_type' = 'assignation_type',
    'chapters' = 'chapters',
}

