import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import * as _ from 'lodash';
import {Observable, Subject} from 'rxjs';
import {GraphDysappService} from '@modules/graph-dysapp/core/services/graph-dysapp.service';
import {filter, map, startWith, takeUntil} from 'rxjs/operators';
import {LearnerInterface} from '@modules/graph-dysapp/core/model/learner-interface';
import {GraphFilterCustomRule, GraphFilterInterface} from '@modules/graph-dysapp/core/model/graph-filter-interface';
import {GraphFiltersValuesInterface} from '@modules/graph-dysapp/core/model/graph-filters-values-interface';
import {RawGraphFiltersValuesInterface} from '@modules/graph-dysapp/core/model/raw-graph-filters-values-interface';
import * as moment from 'moment';
import {ExerciseType} from '@modules/graph-dysapp/core/model/exercise-type';
import {AuthenticationService} from '@modules/authentication';

@Component({
    selector: ' app-filters',
    templateUrl: './filters.component.html',
})
export class FiltersComponent implements OnInit, OnDestroy {
    public controls = {
        endDate: new FormControl(),
        exerciseType: new FormControl(),
        learner: new FormControl(),
        startDate: new FormControl(),
    };
    public filteredLearners: Observable<string[]>;
    public isReady = false;
    public exerciseTypes: ExerciseType[] = [];
    private filters: GraphFilterInterface[] = [];
    private unsubscribeInTakeUntil = new Subject();
    private learnerNicknames: string[] = [];
    private filtersValues: Partial<RawGraphFiltersValuesInterface> = {};
    private learners: LearnerInterface[] = [];

    constructor(private graphDysappService: GraphDysappService, private authService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.isReady = false;
        this.graphDysappService.isReady
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil),
            ).subscribe((isReady) => {
            this.isReady = false;
            if (isReady) {
                this.graphDysappService.forceFiltersValues.pipe(
                    takeUntil(this.unsubscribeInTakeUntil),
                    takeUntil(this.graphDysappService.isReady.pipe(filter((shadowedIsReady) => shadowedIsReady === false)))
                ).subscribe(() => this.redoAtEachForcedFilters());

                this.learners = this.graphDysappService.learners;
                this.learnerNicknames = this.graphDysappService.getLearnersAlphabetically().map(l => l.nickname);
                this.exerciseTypes = this.graphDysappService.exerciseTypes.slice();
                this.initLists();
                this.initAutoSubmitOnChanges();
                this.initAutoFills();
                this.initDefaultValues();
                this.isReady = true;
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public isEmptyAllowed(field: string): boolean {
        return this.isFieldHasRule(field, 'allowEmpty');
    }

    public isDisplayed(filterName: string): boolean {
        try {
            // Fix rapide pour cacher le champ élève si on est élève
            if (this.authService.isLearner() && filterName === 'learner') {
                return false;
            }

            return this.filters.map(f => f.label).includes(filterName);
        } catch (e) {
            console.warn(e);
            return false;
        }
    }

    private initLists(): void {
        this.filteredLearners = this.controls.learner.valueChanges
            .pipe(
                startWith(''),
                map(value => this.filterLearners(value))
            );
    }

    private initAutoSubmitOnChanges(): void {
        for (const control of Object.keys(this.controls)) {
            this.controls[control].valueChanges.subscribe((v) => {
                this.updateFiltersValues(control, v);
                this.emitFiltersValues();
            });
        }
    }

    private filterLearners(value: string): string[] {
        const filterValue = !!value && value.toLowerCase();
        return this.learnerNicknames.filter(option => option.toLowerCase().includes(filterValue));
    }

    private updateFiltersValues(control: string, v: any): void {
        if (v === undefined || v === null || v === '' || v === []) {
            delete this.filtersValues[control];
        } else {
            this.filtersValues[control] = v;
        }
    }

    private emitFiltersValues(): void {
        const raw = _.cloneDeep(this.filtersValues);
        // On duplique this.filtersValues mais il a pas le meme format que notre variable values
        // alors on va ajouter/retirer des données a values pour respecter le format
        const optimised: Partial<GraphFiltersValuesInterface & RawGraphFiltersValuesInterface> = _.cloneDeep(raw);

        Object.keys(optimised).forEach((field) => {
            if (this.isIgnored(field)) {
                delete optimised[field];
                return;
            }

            if (field === 'learner') {
                if (!this.authService.isLearner()) {
                    const learner = this.learners.find(l => l.nickname === this.filtersValues.learner);
                    if (!!learner) {
                        optimised.learner = learner.id;
                    } else {
                        delete optimised.learner;
                    }
                } else {
                    delete optimised.learner;
                }
                return;
            }

            if (field === 'exerciseType') {
                const exerciseType = this.exerciseTypes.find(e => e.attributes.label === this.filtersValues.exerciseType);
                if (!!exerciseType) {
                    optimised.exerciseType = exerciseType.get('label').toString();
                } else {
                    delete optimised.exerciseType;
                }
                return;
            }

            if (field === 'startDate') {
                optimised.startDate = moment(optimised.startDate).toDate();
                return;
            }

            if (field === 'endDate') {
                optimised.endDate = moment(optimised.endDate).endOf('day').toDate();
                return;
            }
        });

        this.graphDysappService.filtersChanges.next({
            raw,
            optimised
        });
    }

    private initDefaultValues(): void {
        this.graphDysappService.setLearnerDynamicFilterWithCacheFilter();
        this.filters = _.clone(this.graphDysappService.dynamicFilters);

        Object.keys(this.controls).forEach(control => this.controls[control].setValue(null));
        this.filters.forEach(f => {
            this.controls[f.label].setValue(f.value);
        });
    }

    private isIgnored(field: string): boolean {
        return this.isFieldHasRule(field, 'ignore');
    }

    private fieldsToAutofill(field: string): string[] {
        const fieldConfig = this.filters.find(f => f.label === field);

        if (!!fieldConfig && !!fieldConfig.custom && !!fieldConfig.custom['rules']) {
            const rules = fieldConfig.custom['rules'].filter(rule => rule.startsWith('autofill:'));

            if (rules.length > 0) {
                return rules.map(r => r.replace('autofill:', ''));
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    private isFieldHasRule(field: string, rule: GraphFilterCustomRule): boolean {
        try {
            const fieldConfig = this.filters.find(f => f.label === field);

            if (!!fieldConfig) {
                return fieldConfig.custom['rules'].includes(rule);
            }
        } catch (e) {
            // on avale volontairement l'erreur pour pas avoir a tester
        }

        return false;
    }

    private initAutoFills(): void {
        this.filters.forEach(field => {
            const autoFills = this.fieldsToAutofill(field.label);
            if (autoFills.length > 0) {
                this.controls[field.label].valueChanges.subscribe(() => {
                    this.emitFiltersValues();
                });
            }
        });
    }

    private redoAtEachForcedFilters(): void {
        this.initDefaultValues();
        this.initAutoFills();
    }
}
