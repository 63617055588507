import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Router} from '@angular/router';

import {FlagService} from '../../../../../shared/flag.service';
import {localizedDate} from '../../../../../shared/utils';
import {ActivitiesService} from '../../activities.service';
import {LessonsService} from '../services/lessons.service';
import {ArchiveService} from '../../../../../shared/archive.service';
import {Subject} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {displayHeaderTitle, modulesSettings} from 'app/settings';

@Component({
    selector: 'fuse-app-models-tab',
    templateUrl: './forms-list.component.html',
    animations : fuseAnimations
})


export class FormsListComponent implements OnInit, OnDestroy {

    ressources: DataEntity[];
    ressourcesFiltered: DataEntity[];
    public archiveFilter = 'false';
    public displayHeaderTitle: boolean;
    public displayArchiveOptionToolbar: boolean;

    private unsubscribeInTakeUntil = new Subject();
    private activitiesSettings: { [key: string]: any };

    constructor(
        public lessonsService: LessonsService,
        private activitiesService: ActivitiesService,
        private communicationCenter: CommunicationCenterService,
        private flagService: FlagService,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        public archiveService: ArchiveService
    ) {
        this.displayHeaderTitle = displayHeaderTitle;
        this.displayArchiveOptionToolbar = this.activitiesService.settings.displayArchiveOptionToolbar;
    }

    ngOnInit(): void {
        this.ressources = this.lessonsService.forms;
        this.ressourcesFiltered = this.getRessources();
        this.applyFilters({value : this.archiveFilter}, 'archived');

        this.lessonsService.loadForms().subscribe((resources: DataEntity[]) => {

            this.ressources = resources;
            this.ressourcesFiltered = this.getRessources();
            this.applyFilters({value : this.archiveFilter}, 'archived');

            if (!resources){
                return;
            }
        });
    }

    ngOnDestroy(): void{
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    applyFilters(event, field): void {
        if (event.value !== 'all') {
            event.value = event.value === 'true';
            this.ressourcesFiltered = this.getRessources().filter((entity) =>
                entity.get(field) === event.value
            );
        } else {
            this.ressourcesFiltered = this.getRessources();
        }
    }

    getRessources(): DataEntity[]{
        return this.ressources.slice();
    }

    openAssign(form): void {
        this.communicationCenter
            .getRoom('project-management')
            .getSubject('selectedProject')
            .next(null);
        if (this.activitiesService.assignmentView) {
            this.dialog.open(this.activitiesService.assignmentView, {
                data: {
                    nodeId: form.id
                }
            });
        }
    }

    navigateToLesson(item: any): void {
        this.router.navigate(['forms', item.id, 'player']);
    }

    localeDate(date): string {
        return localizedDate(date);
    }
}
