import {
    AfterContentChecked,
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IActivity, IActivityContentPoint, IFullActivityContent} from '@modules/activities/core/player-components/interactive-image/models/interactive-image.models';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {Subject} from 'rxjs';
import {startWith, takeUntil, tap} from 'rxjs/operators';
import {DataEntity} from 'octopus-connect';
import {EntityDataSet} from 'octopus-connect';

@Component({
    selector: 'app-interactive-image',
    templateUrl: './interactive-image.component.html'
})
export class InteractiveImageComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('imgViewport', { static: true }) imgViewport: ElementRef;
    @ViewChild('interactiveImage') interactiveImage: ElementRef;
    @ViewChild('targetDetailsImage') targetDetailsImage: ElementRef;
    // optionnal field use only when we can't get activity id by url navigtion ex : dialog.component.html
    @Input() public activityId?: IActivity | DataEntity;
    private _activity: IActivity;
    private imgWidth = 200;
    private imgHeight = 100;
    private unsubscribeInTakeUntil = new Subject();

    constructor(private activatedRoute: ActivatedRoute,
                private activityService: ActivitiesService) {
    }

    private _isDotDocOpen = false;

    /***
     * is media open by click on the interactiv picture
     */
    get isDotDocOpen(): boolean {
        return this._isDotDocOpen;
    }

    private _data: IFullActivityContent;

    /***
     * all the data we need to manage the interactive picture
     */
    get data(): IFullActivityContent {
        return this._data;
    }

    private _currentDot: IActivityContentPoint;

    /***
     * current dot click who was click on the interactive picture( content data to show)
     */
    get currentDot(): IActivityContentPoint {
        return this._currentDot;
    }

    public currentMediaType(media): string {

        if (media.uri.includes('.png') || media.uri.includes('.jpg')) {
            return 'wrapper-image';
        }
        if (media.uri.includes('.mp3')) {
            return 'wrapper-audio';
        }
        if (media.uri.includes('.mp4')) {
            return 'wrapper-video';
        }
            return null;
    }

    private _isShowDot = false;

    /***
     * is dot show on interactive picture
     */
    get isShowDot(): boolean {
        return this._isShowDot;
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.imageSizeByRatio();
    }

    ngOnInit(): void {
        if (!this.activityId) {
            this.getDataByRouteQueryParams();
        } else {
            this.getDataByInput();
        }
    }

    ngAfterViewInit(): void {
    }

    initialize(): void {
        this.reset();
        this.getActivityData();
    }

    ngOnDestroy(): void {
        this._data = {} as IFullActivityContent;
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    /***
     * open the media after a click on a dot on interactive picture
     * @param p : nfo of current dot
     */
    public openDocument(point: IActivityContentPoint): void {
        this._currentDot = point;
        this._isDotDocOpen = true;
        // add info about media was already open to change color
        this._data.activity_content.points.filter(res => res.id === point.id)[0].alreadyOpen = true;
    }

    /***
     * close the media open
     */
    public closeDocument(): void {
        this._isDotDocOpen = false;
        this._currentDot = null;
    }

    /**
     * show dot on interactive picture
     */
    public showhideDot(): void {
        this._isShowDot = !this._isShowDot;
    }

    /***
     * open pdf
     */
    public openPdf(path: string): void {
        window.open(path, '_blank');
    }

    /**
     * data need to initialise come from @input get it and initialise component
     */
    private getDataByInput(): void {
        this._activity = {
            id: this.activityId.id.toString(),
            isLoadBeforeLaunch: this.activityId && this.activityId['isLoadBeforeLaunch'] // use [] because activityId can be Interface type and do error
        };
        this.initialize();
    }

    /**
     * data need to initialise come from route by queryParams get it and initialise component
     */
    private getDataByRouteQueryParams(): void {
        this.activatedRoute.queryParams.subscribe((params: IActivity) => {
            this._activity = params;
            this.initialize();
        });
    }

    private getActivityData(): void {
        if (this.activityId && this.activityId['type'] && this.activityId['type'] === 'granule') {
            /*
                todo need to refacto to have only one format of data : DataEntity
            */
            const activity: DataEntity = <DataEntity>this.activityId;
            this.setContentData(activity.get('reference'));
        } else {
            this.activityService.launchActivity(this._activity).pipe(
                takeUntil(this.unsubscribeInTakeUntil),
                tap((data: EntityDataSet) => {
                    if (data) {
                        this.setContentData(data.reference);
                    }
                })
            ).subscribe();
        }
    }

    private setContentData(data): void {
        this._data = data as IFullActivityContent;
        this.initLocalValueDotOpenToFalse();
    }

    private initLocalValueDotOpenToFalse(): void {
        // local value not exist in object from back
        // => init value dot not already selected when open interactive picture
        this._data.activity_content.points.forEach(res => {
            res.alreadyOpen = false;
        });
    }

    private reset(): void {
        this._isShowDot = false;
        this._isDotDocOpen = false;
        this.activityService.displayActions.next(false);
    }

    /**
     * resize the image according to its frame/viewport
     */
    private imageSizeByRatio(): void {

        const imgViewport = this.imgViewport.nativeElement;
        const img = this.interactiveImage.nativeElement;

        const ratioImgWidth = img.naturalWidth / img.naturalHeight;
        const ratioImgHeight = img.naturalHeight / img.naturalWidth;

        this.imgWidth = imgViewport.offsetHeight * ratioImgWidth;
        this.imgHeight = imgViewport.offsetWidth * ratioImgHeight;

        if (this.imgHeight > imgViewport.offsetHeight) {
            this.imgHeight = imgViewport.offsetHeight;
            this.imgWidth = imgViewport.offsetHeight * ratioImgWidth;
        } else {
            this.imgWidth = imgViewport.offsetWidth;
            this.imgHeight = imgViewport.offsetWidth * ratioImgHeight;
        }
    }

    public imageRatioType(): void {
        const img = this.targetDetailsImage.nativeElement;

        if (img.naturalHeight > img.naturalWidth) {
            img.classList.add('img-portrait');
        } else {
            img.classList.add('img-paysage');
        }
    }
}
