export const activities = {
    actionButtonsInMultiMatrix: {
        default: ['previousActivityInMulti', 'nextActivityInMulti']
    },
    activitiesDisplayedColumns: ['title', 'educationalLevel', 'chapters', 'action'],
    activityOptionsEditable: ['content'], // options for new lesson editor when user want to edit activities exp content, titleAndInstruction, all
    activitiesWithDefinedTypology: ['EXT'], // type of activities used for filter in editor-activities-list
    allowErrorReporting: false,
    activateNewEditor: true,
    cardFieldsForPreview: [ 'chapters', 'description'],
    cardLayout: 'card-split-media-info',
    displayFilters: false,
    showAddLessonButtonCard: true,
    hideAddButtonLessonList: true,
    hideUserActionButtons: true,
    hiddenActivityPreview: false,
    isArrowNavigationEnabled: {  // allow arrow for navigation in lesson (player)
        learner: false,
        default: true
    },
    isOnlyModelLesson: true, // should be called by example : enableSelectionByTab for lesson list component
    latexKeyboard: false,
    lessonStep: null,
    loadLessonWithSublesson: {
        typology: null,
        multi_step: 0
    },
    openDialogInfoAfterDuplicateLesson: {
        manager: false,
        default: true
    },
    routeOnExitLesson: ['lessons', 'list', 'lessons'],
    searchFields: ['title', 'educationnalLevel', 'assignation_type', 'launchSearch', 'countEntities'],
    showLessonTitle: false,
    stepper: null,
    allowedRolesForModelsAssignation: ['manager', 'trainer'],
    allowedActivityTypes: [],
    // required params name for partner through iframe url
    iframeParams: ['uid', 'prenom', 'codeclasse', 'token', 'assignationId'],
    dynamicContent: false,
    menu: {
        models: false
    },
    filtersEditorActivitiesList: ['title', 'educationnalLevel', 'chapters'],
    lessonDialogFields: {
        default: ['title', 'description', 'educationalLevel', 'assignation_type'],
    },
    lessonDialogRequiredFields:  {
        default: ['title', 'educationalLevel', 'assignation_type'],
    },
    saveLessonContentOptions: {
        activityTypesCanBeDuplicate: [], // type d'activitiés que l'on peut dupliquer (certaines activitiés n'ont pas besoin d'être dupliqué)
        saveContent: false // si chaque activités ou souslesson du parcours doit être sauvegardé
    },
    typeActivitiesToSkip: ['EXT'], // (lesson player) skip activity if activity's type is included,
    displayLearnerInfo: false,
    recommendationDisplayedColumns: ['checkbox', 'pseudo', 'lesson', 'level', 'type', 'buttons'], // 'confidence', retrait en attendant que ça soit opérationnel
    shouldSetDefaultOptionsOnActivityList: true,
    keepFiltersEditorActivitiesList: ['level', 'chapters']
};
