import {Injectable} from '@angular/core';
import {DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {filter, mergeMap, map, take, tap} from 'rxjs/operators';
import {CollectionOptionsInterface} from 'octopus-connect';
import * as _ from 'lodash';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NotepadDataEditorModalComponent} from '@modules/notepad/core/notepad-data-editor-modal/notepad-data-editor-modal.component';
import {CommunicationCenterService} from '@modules/communication-center';
import {NavigationExtras, Router} from '@angular/router';
import {NotepadGenericAlertModalComponent} from '@modules/notepad/core/notepad-generic-alert-modal/notepad-generic-alert-modal.component';
import {INotepadFormData, NotepadRepositoryService} from '@modules/notepad/core/notepad-repository.service';
import {NotepadContentViewerModalComponent} from '@modules/notepad/core/notepad-content-viewer-modal/notepad-content-viewer-modal.component';
import {v4 as uuidv4} from 'uuid';

// Should be a copy of LessonToolDataCommunicationCenterInterface interface in GenericPluginService
export interface NotepadDataCommunicationCenterInterface {
    lesson: DataEntity;
    onComplete: ReplaySubject<DataEntity>;
}

/**
 * List field options to apply on notepad creation or edition
 *
 * @remarks For now, only the `associatedLessonId` field is used
 */
export interface INotepadFormOptions {
    [fieldName: string]: { disable: boolean };
}

@Injectable({
    providedIn: 'root'
})
/**
 * Define the notepad business rules of the application
 */
export class NotepadService {
    /**
     * Obtain the current user or null if not authenticated
     */
    public currentUser$ = new ReplaySubject<DataEntity>(1);
    /**
     * List of unique {@link ReplaySubject} used to identity when an notepad edition is done.
     * There should never have more than one subject at times, but a subject in this array can be an old one and we need a way to identify if it's the good one.
     * For resolve this problem, we use an object has an hashMap/Key->Value array. The `k` is the unique identifier to a subject
     */
    public onNotepadDataEditionCompleteSubject: { [k: string]: ReplaySubject<DataEntity> } = {};
    /**
     * Obtain the function to load lessons. This method is the container and undefined, it's filled in the constructor by calling activities-module
     */
    private loadPaginatedLessons: (type?: string, roles?: number[], searchValue?, filterOptions?: {}) => Observable<DataEntity[]>;
    private getAllowedRoleIdsForModelsCreation;
    private shareableModel;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private notepadRepoSvc: NotepadRepositoryService,
        private octopusConnect: OctopusConnectService,
        private router: Router,
    ) {
        this.communicationCenter
            .getRoom('activities')
            .getSubject('loadPaginatedLessonsCallback')
            .subscribe((callback: (type?: string, roles?: number[], searchValue?, filterOptions?: {}) => Observable<DataEntity[]>) => {
                this.loadPaginatedLessons = callback;
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('getAllowedRoleIdsForModelsCreationCallback')
            .subscribe((callback) => {
                this.getAllowedRoleIdsForModelsCreation = callback;
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('shareableModelCallback')
            .subscribe((callback) => {
                this.shareableModel = callback;
            });

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .pipe(
                filter(currentUser => !!currentUser),
                tap(currentUser => {
                    this.currentUser$.next(currentUser);
                })
            )
            .subscribe();

        /**
         * Used to create or edit a notepad from everywhere out of the current module
         */
        this.communicationCenter
            .getRoom('notepad')
            .getSubject('execute')
            .pipe(
                mergeMap((args: NotepadDataCommunicationCenterInterface) =>
                    this.createOrEditNotepadIfAlreadyExistForLesson(args.lesson.id).pipe(
                        map(notepad => args.onComplete.next(notepad))
                    )
                )
            )
            .subscribe();
    }

    /**
     * Obtains the paginated list of current user's notes
     * @param filterOptions
     * @return The {@link DataEntity} are `granules` and the are not of `notepads` but `BasicSearch` endpoint
     */
    public getCurrentUserPaginatedNotepads(filterOptions: CollectionOptionsInterface = {}): Observable<PaginatedCollection> {
        return this.currentUser$.pipe(
            filter(currentUser => !!currentUser),
            map(currentUser => _.merge({
                filter: {
                    author: currentUser.id
                }
            }, filterOptions)),
            mergeMap(options => this.notepadRepoSvc.getPaginatedNotepads(options)),
            take(1)
        );
    }

    /**
     * Open and return the notepad data editor modal.
     *
     * - It's the same way to create a note ({@link goToNotepadDataCreation}) but the save method passed to the modal is used to patch notepad
     *
     * @param notepad Used to defined which notepad to edit and the default form values.
     * Should be a entity given by the `BasicSearch` endpoint or similar because the default values are not in the same path for other endpoints
     */
    public goToNotepadDataEdition(notepad: DataEntity): MatDialogRef<NotepadDataEditorModalComponent, DataEntity | null> {
        return this.goToNotepadDataAndContentEditors({
            saveNotepad: (data) => this.notepadRepoSvc.updateNotepad(notepad.id, data),
            defaultValues: {
                title: _.get(notepad.get('metadatas'), 'title'),
                associatedLessonId: _.get(notepad.get('reference'), 'activity_content[0].associated_nodes[0].id')
            }
        });
    }

    /**
     * Open and return the notepad data editor modal.
     *
     * - It's the same way to edit a note ({@link goToNotepadDataCreation}) but the save method passed to the modal is used to create notepad*
     */
    public goToNotepadDataCreation(defaultValues?: INotepadFormData, options?: INotepadFormOptions): MatDialogRef<NotepadDataEditorModalComponent, DataEntity | null> {
        return this.goToNotepadDataAndContentEditors({
            saveNotepad: (data) => this.notepadRepoSvc.createNotepad(data),
            defaultValues: defaultValues,
            options: options
        });
    }

    /**
     * Redirect to the list of current user's notepad
     */
    public goToNotepadList(): Promise<boolean> {
        return this.router.navigate(['notepad', 'list']);
    }

    /**
     * Obtain the lesson available to be associated to a notepad
     * An lesson is available because
     * - User have access to it
     * - No current user's notepad are already associated to it
     * - it not accessible because shared by community
     *
     * @remarks: the backend limit the result to 50 item, if we need more, we need to make it paginated
     */
    public getAssociableLessons(): Observable<DataEntity[]> {
        return this.currentUser$.pipe(
            filter(currentUser => !!currentUser),
            mergeMap(currentUser => {
                const allModelsWithoutNotepad$ = this.getLessons({
                    filter: {
                        'removeNotepadAttachedNode': true,
                        'role': this.getAllowedRoleIdsForModelsCreation(), // get role used for models creation
                        'model': this.shareableModel()
                    }
                });

                const allMyLessonsWithoutNotepad$ = this.getLessons({
                    filter: {
                        'author': currentUser.id,
                        'removeNotepadAttachedNode': true,
                    }
                });

                return combineLatest([
                    allModelsWithoutNotepad$,
                    allMyLessonsWithoutNotepad$
                ]);
            }),
            map(([notSharedLessons, sharedLessons])  => [...notSharedLessons, ...sharedLessons])
        );
    }

    /**
     * Obtain the lesson already associated to a notepad o current user
     *
     * @remarks: the backend limit the result to 50 item, if we need more, we need to make it paginated
     */
    public getAssociatedLessons(): Observable<DataEntity[]> {
        return this.getLessons({
            filter: {
                'removeNotepadAttachedNode': false
            }
        });
    }

    /**
     * Obtain the all lessons
     * @param filterOptions
     */
    public getLessons(filterOptions: {}): Observable<DataEntity[]> {
        const mergedOptions = _.merge({
            filter: {
                'multi_step': 0
            }
        }, filterOptions);

        return this.loadPaginatedLessons('all', null, '', mergedOptions);
    }

    /**
     * Ask user if he confirm the notepad deletion and, if it's ok, delete it
     * @param id of the notepad granule
     */
    public deleteNotepad(id: number | string): Observable<boolean> {
        const modalData = {data: {contentKey: 'notepad.ask_before_delete_alert'}};
        return this.dialog.open(NotepadGenericAlertModalComponent, modalData).afterClosed().pipe(
            filter(isConfirm => !!isConfirm),
            mergeMap(() => this.notepadRepoSvc.destroyNotepad(id))
        );
    }

    /**
     * Display the notepad content as a read-only modal
     * @param id of the notepad Granule
     */
    displayNotepad(id: number | string): void {
        this.dialog.open(NotepadContentViewerModalComponent, {data: {notepad$: this.notepadRepoSvc.getNotepad(id)}});
    }

    /**
     * Open the notepad editor modal and if there are a notepad given on closed go to notepad content editor
     * @param data The saveNotepad method receive the form data TODO give an interface of form data here
     */
    private goToNotepadDataAndContentEditors(
        data: { saveNotepad: (data) => Observable<DataEntity>; defaultValues: INotepadFormData; options?: INotepadFormOptions }
    ): MatDialogRef<NotepadDataEditorModalComponent, DataEntity | null> {
        const modalData = _.merge({
            availableLessons$: this.getAssociableLessons(),
            getLessonById: (id: string | number) => this.getLessonById(id)
        }, data);

        const dialogRef = this.dialog.open(NotepadDataEditorModalComponent, {
            data: modalData
        });

        dialogRef.afterClosed().pipe(
            // If is notepadGranule empty is because the modal is closed without want to create/edit the notepad
            filter(notepadGranule => !!notepadGranule),
            tap(notepadGranule => {
                this.goToNotepadContentEditor(notepadGranule);
            })).subscribe();

        return dialogRef;
    }

    /**
     * Redirect the user to the notepad content editor page.
     *
     * @param notepadGranule
     * @param navigationExtras some data to pass by the url, show {@link onNotepadDataEditionCompleteSubject} for example
     */
    private goToNotepadContentEditor(notepadGranule: DataEntity, navigationExtras?: NavigationExtras): void {
        this.router.navigate(['notepad', notepadGranule.id, 'edit'], navigationExtras);
    }

    /**
     * Obtain a lesson from the activities module
     * @param id unique identifier of the lesson DataEntity
     */
    private getLessonById(id: string | number): Observable<DataEntity> {
        const lesson$ = new ReplaySubject<Observable<DataEntity>>(1);

        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId: id,
                callbackSubject: lesson$
            });

        return lesson$.pipe(
            mergeMap(obs => obs)
        );
    }

    /**
     * Generic way to open a note hypothetically already created
     * @param lessonId unique identifier of lesson granule used to identify if a notepad is alrealdy associated to this lesson.
     *  Open the notepad editor if true, the notepad creator if false but with the lesson associated by default
     */
    private createOrEditNotepadIfAlreadyExistForLesson(lessonId: number | string): Observable<DataEntity> {
        const onCompleteSubject = new ReplaySubject<DataEntity>(1);
        const onCompleteSubjectId: string = uuidv4();
        this.onNotepadDataEditionCompleteSubject[onCompleteSubjectId] = onCompleteSubject;

        return this.getCurrentUserPaginatedNotepads({filter: {associated_node: lessonId}}).pipe(
            // We load the notepad from the lesson id associated
            mergeMap(paginatedCollection => paginatedCollection.collectionObservable.pipe(
                take(1)
            )),
            map(collection => collection.entities),
            mergeMap(entities => {
                if (entities.length > 1) {
                    throw new Error('a user must have only one note by lesson');
                } else if (entities.length === 0) {
                    // If no notepad, we creating it
                    return this.goToNotepadDataCreation({associatedLessonId: lessonId}, {associatedLessonId: {disable: true}}).afterClosed();
                } else {
                    // Else we reload it but from another endpoint to get all datas
                    return this.notepadRepoSvc.getNotepad(entities[0].id);
                }
            }),
            // If the user cancel the notepad creation there are no notepad
            filter(notepad => !!notepad),
            // whether it existed or not, we are modifying the content now
            tap((notepad: DataEntity) => this.goToNotepadContentEditor(notepad, {queryParams: {onComplete: onCompleteSubjectId}, skipLocationChange: false})),
            mergeMap(() => onCompleteSubject)
        );
    }

    public goToLesson(associatedLessonId: string | number): void {
        this.communicationCenter.getRoom('lessons').next('playLesson', {id: associatedLessonId});
    }
}
