export const mainMenu = {
    displayMenu: {
        default: {
            'level0':
                [
                    'dashboard',
                    {
                        'name': 'parameter',
                        'children': ['trainers']
                    },
                    'themes',
                    'lessonsDeployable',
                    'Activities',
                    'corpus',
                    'tools',
                    {
                        'name': 'help',
                        'translate': {
                            'parent': {
                                default: 'generic.help'
                            }
                        }
                    }
                ]
        }
    }
};
