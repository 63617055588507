import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-consignes',
    templateUrl: './consignes.component.html'
})
export class ConsignesComponent {

    @Input('questionTypeName') questionTypeName?: string;
    @Input('instruction') instruction?: string;
    @Input('wording') wording?: any;

    constructor() {}
}
