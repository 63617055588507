import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-draft-questions-modal',
  templateUrl: './draft-questions-modal.component.html',
  styleUrls: ['./draft-questions-modal.component.scss']
})
export class DraftQuestionsModalComponent implements OnInit {

  questionIndex = 0;
  currentQuestion: Object;
  answers: string[] = [];
  feedbacks: string[] = [];

  constructor(
      @Inject(MAT_DIALOG_DATA) public questions: Object[],
      private ref: MatDialogRef<DraftQuestionsModalComponent>
  ) { }

  ngOnInit() {
    this.displayQuestionAtCurrentIndex();
  }

  displayQuestionAtCurrentIndex() {
    this.currentQuestion = this.questions[this.questionIndex];
  }

  nextQuestion() {
    if (this.questionIndex < this.questions.length - 1) {
      this.questionIndex++;
      this.displayQuestionAtCurrentIndex();
    } else {
      this.ref.close(this.feedbacks);
    }
  }

  replyYes() {
    this.answers[this.questionIndex] = 'yes';
    this.feedbacks[this.questionIndex] = this.questions[this.questionIndex]['feedbackYes'];
    this.nextQuestion();
  }

  replyNo() {
    this.answers[this.questionIndex] = 'no';
      this.feedbacks[this.questionIndex] = this.questions[this.questionIndex]['feedbackNo'];
    this.nextQuestion();
  }


}
