import {DataEntity} from 'octopus-connect';

export class MultimediaMedia {
    public get type(): string {
        if (this.isDataEntity()) {
            try {
                return (<DataEntity> this._media).get('format').label;
            } catch (error) {
                throw new Error('not implemented');
            }
        } else {
            return 'text';
        }
    }

    constructor(private _media: DataEntity | string) {

    }

    private isDataEntity(): boolean {
        return typeof this._media !== 'string';
    }

    /**
     * get  the media in activity multimedia
     * @returns {DataEntity | string}
     */
    public get media(): DataEntity | string {
        return this._media;
    }
}