import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ResearchSheetService} from '../../research-sheet.service';
import {ResearchSheet, ResearchTemplate} from '../../definitions';
import {ActivatedRoute, Router} from '@angular/router';
import {ResearchSheetFormComponent} from '@modules/research-sheet/core/research-sheet/research-sheet-form/research-sheet-form.component';
import { MatDialog } from '@angular/material/dialog';
import {FormGroup} from '@angular/forms';
import {fuseAnimations} from 'fuse-core/animations';
import {ResearchTemplateService} from '../research-template.service';
import {brand, displayHeaderTitle} from 'app/settings';

@Component({
  selector: 'app-research-template-list',
  templateUrl: './research-template-list.component.html',
  styleUrls: ['./research-template-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ResearchTemplateListComponent implements OnInit {
  private dialogRef: any;

  public displayHeaderTitle: boolean;
  public templatesList: ResearchTemplate[] = [];
  brand: string = brand;

  constructor(
    private sheetService: ResearchSheetService,
    private templatesService: ResearchTemplateService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
      this.displayHeaderTitle = displayHeaderTitle;
  }

  ngOnInit() {
    this.sheetService.onTemplatesChanged.subscribe((templates: ResearchTemplate[]) => {
      this.templatesList = templates;
    });
  }



  public goToTemplate(template: ResearchTemplate) {
    this.router.navigate([`../edit/${template.id}`], {relativeTo: this.route});
  }

    clickAction(template: ResearchTemplate): void {
        if (this.isMine(template)) {
            this.editTemplate(template);
        } else {
            this.viewTemplate(template);
        }
    }

    isMine(template: ResearchTemplate): boolean {
        // return this.sheetService.isMine(+template.get('owner')['uid']);
        return true;
    }

    editTemplate(template: ResearchTemplate): void {
        this.router.navigate([this.templatesService.getTemplateEditLink(+template.id)]);
    }

    viewTemplate(template: ResearchTemplate): void {
        this.router.navigate([this.sheetService.getDraftViewLink(+template.id)]);
    }

  public newSheet() {
    this.dialogRef = this.dialog.open(ResearchSheetFormComponent, {
      panelClass: 'research-sheet-form-dialog',
      data: {
        action: 'new'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe((response: FormGroup) => {
        if (!response) {
          return;
        }

        this.sheetService.addResearchSheet(response.getRawValue());
      })
  }

  public editSheet(sheet: ResearchSheet) {
    this.dialogRef = this.dialog.open(ResearchSheetFormComponent, {
      panelClass: 'research-sheet-form-dialog',
      data: {
        action: 'edit',
        sheet: sheet
      }
    });

    this.dialogRef.afterClosed()
      .subscribe((response: FormGroup) => {
        if (!response) {
          return;
        }

        this.sheetService.saveResearchSheet(response.getRawValue());
      })
  }

  public deleteSheet(sheet: ResearchSheet) {
    this.sheetService.deleteResearchSheet(sheet);
  }

}
