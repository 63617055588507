import {MultimediaMedia} from '@modules/activities/core/lessons/editor/models/multimedia-media.class';

export class MultimediaPage {

    constructor(private _first: MultimediaMedia, private _second?: MultimediaMedia) {}

    public get first(): MultimediaMedia {
        return this._first;
    }

    public get second(): MultimediaMedia {
        return this._second;
    }

    public set first(multimediaMediaElement: MultimediaMedia) {
        this._first = multimediaMediaElement;
    }

    public set second(multimediaMediaElement: MultimediaMedia| undefined) {
        this._second = multimediaMediaElement;
    }
}