import {of as observableOf, combineLatest, Observable, EMPTY, ReplaySubject} from 'rxjs';
import {delay, filter, mergeMap, map, take, tap} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {LessonEditionService} from '@modules/activities/core/lessons/editor/services/lesson-edition.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EditableLesson} from '@modules/activities/core/lessons/editor/models/editable-lesson.class';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {EditableActivity, EditableActivityDefaultValues} from '@modules/activities/core/lessons/editor/models/editable-activity.class';
import {DataEntity} from 'octopus-connect';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDeleteActivityDialogComponent} from '@modules/activities/core/lessons/editor/components/confirm-delete-activity-dialog/confirm-delete-activity-dialog.component';
import {EditStepComponent} from '@modules/activities/core/shared-components/edit-step/edit-step.component';
import * as _ from 'lodash';
import {MultimediaPageEditorComponent} from '@modules/activities/core/lessons/editor/components/multimedia-page-editor/multimedia-page-editor.component';
import {MultimediaMedia} from '@modules/activities/core/lessons/editor/models/multimedia-media.class';
import {MultimediaPage} from '@modules/activities/core/lessons/editor/models/multimedia-page.class';
import {EditableMultimediaActivity} from '@modules/activities/core/lessons/editor/models/editable-multimedia-activity.class';
import {EditableToolActivity} from '@modules/activities/core/lessons/editor/models/editable-tool-activity.class';
import {Subject} from 'rxjs/index';

@Component({
    selector: 'app-lesson-editor',
    templateUrl: './lesson-editor.component.html'
})
export class LessonEditorComponent implements OnInit {
    /**
     * Show loading when lesson is not ready
     */
    public lessonIsReady = false;

    /**
     * Current lesson to edit as a instance of {@link EditableLesson}
     * @private
     */
    public editableLesson: EditableLesson = null;
    public lastToggleActivity = new ReplaySubject<EditableActivity>(1);
    public isSaving = false;
    public exitUrl: string[];
    private onActivitySelectionLastFilter = new Subject<{ [key: string]: any }>();
    private activitySelectionLastFilter: { [key: string]: any } = undefined;

    constructor(
        private activitiesService: ActivitiesService,
        private dialog: MatDialog,
        private lessonsService: LessonsService,
        private lessonEditionService: LessonEditionService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        if (this.router.getCurrentNavigation() &&
            this.router.getCurrentNavigation().extras.state &&
            this.router.getCurrentNavigation().extras.state.url) {
            this.exitUrl = this.router.getCurrentNavigation().extras.state.url.split('/');
        }
    }

    public get activities(): EditableActivity[] {
        return this.editableLesson.activities;
    }

    public get tips(): string {
        return 'activities.lesson_editor_tips_no_activity';
    }

    ngOnInit(): void {
        this.getLessonIdFromUrl().pipe(
            mergeMap((lessonId: string) => this.initializeLesson(lessonId)),
            take(1)
        ).subscribe();

        this.onActivitySelectionLastFilter.subscribe(data => this.activitySelectionLastFilter = data);

    }

    /**
     * add the selected type step to the current lesson edited
     * @param typologyLabel: type of step (activity) the
     */
    public selectStepTypeToAddInLesson(typologyLabel: string): void {
        let addActivityObservable: Observable<EditableActivity> = EMPTY;

        if (typologyLabel === 'Tool') {
            addActivityObservable = this.addToolActivity();
        } else if (typologyLabel === 'MULTI') {
            addActivityObservable = this.addMultimediaActivity();
        } else {
            addActivityObservable = this.showModalEditActivity('generic.wording_' + typologyLabel.toLocaleLowerCase())
                .pipe(
                    mergeMap((values: { title: string, instruction: string }) => {
                        return this.lessonEditionService.showActivities(this.lessonsService.getTypologies(typologyLabel))
                            .pipe(
                                map((activity: DataEntity) => {
                                    if (activity) {
                                        const editableActivity = new EditableActivity(activity);
                                        editableActivity.title = values.title;
                                        editableActivity.instruction = values.instruction;

                                        this.editableLesson.addActivity(editableActivity);
                                        return editableActivity;
                                    }
                                    return null;
                                })
                            );
                    })
                );
        }

        addActivityObservable.pipe(
            delay(1),
            tap<EditableActivity>((editableActivity) => this.lastToggleActivity.next(editableActivity))
        ).subscribe();
    }

    /**
     * open model to edit the activity title and instruction
     * @returns {Observable<{[p: string]: string}>}
     */
    showModalEditActivity(instructionKey: string, defaultValues?: { title: string, instruction: string }): Observable<{ title: string, instruction: string }> {
        return this.dialog.open(EditStepComponent, {
            data: _.merge({}, defaultValues, {instructionKey})
        }).beforeClosed();
    }


    public deleteActivity(activity: EditableActivity): void {
        this.openConfirmDeleteDialog().pipe(
            filter((isConfirm) => !!isConfirm),
            map(() => this.editableLesson.deleteActivity(activity))
        ).subscribe();
    }

    public addChildActivity(editableMultimediaActivity: EditableMultimediaActivity): void {
        if (editableMultimediaActivity.type === 'MULTI') {
            this.dialog.open(MultimediaPageEditorComponent)
                .afterClosed()
                .pipe(
                    filter((medias: (string | DataEntity)[]) => !!medias && medias.length > 0),
                    map((medias) => medias.map(m => new MultimediaMedia(m))),
                    map((multimediaMedias: [MultimediaMedia, MultimediaMedia?]) => new MultimediaPage(...multimediaMedias)),
                    tap(multimediaPage => editableMultimediaActivity.addPage(multimediaPage))
                )
                .subscribe();
        } else {
            throw new Error('Not implemented');
        }
    }

    private getLessonIdFromUrl(): Observable<string> {
        return this.route.params.pipe(
            filter((urlParams) => urlParams.hasOwnProperty('lessonId')),
            map(urlParams => urlParams['lessonId'])
        );
    }

    private initializeLesson(idLesson: string): Observable<EditableLesson> {
        this.lessonIsReady = false;
        return this.lessonsService.getLessonObs(idLesson, true).pipe(
            mergeMap((lessonGranule) => {
                let obsActivities: Observable<DataEntity[]> = observableOf([]);
                if (lessonGranule.get('reference') && lessonGranule.get('reference').length) {
                    obsActivities = combineLatest<DataEntity[]>(
                        lessonGranule.get('reference').map(({id}) => this.activitiesService.loadActivitiesFromId(id))
                    );
                }
                return this.initializeEditableLesson(obsActivities, lessonGranule);
            }),
            tap(() => this.lessonIsReady = true)
        );
    }

    private initializeEditableLesson(obsActivities: Observable<DataEntity[]>, lessonGranule: DataEntity): Observable<EditableLesson> {
        return obsActivities.pipe(
            map((activities: DataEntity[]) => {
                const editableActivities: EditableActivity[] = activities.map(activity => this.toEditableActivity(activity));
                this.setEditableActivities(editableActivities);
                return this.editableLesson = new EditableLesson(lessonGranule, editableActivities);
            })
        );
    }

    /**
     * if a type of activity requires treatment before being added to the lesson
     * @param {EditableActivity[]} editableActivities
     */
    private setEditableActivities(editableActivities: EditableActivity[]): void {
        editableActivities.forEach((editableActivity: EditableActivity) => {
            if (editableActivity.type === 'MULTI' || editableActivity.type === 'MULTIAC') {
                const actvitiesObs: Observable<DataEntity>[] = editableActivity.granuleActivityEntity.get('reference')
                    .map((act: { [key: string]: string }) => this.activitiesService
                        .loadActivitiesFromId(act.id).pipe(take(1)));

                combineLatest(actvitiesObs).pipe().subscribe((activitiesInSublesson: DataEntity[]) => {
                    const field = editableActivity.type === 'MULTIAC' ? 'wording' : 'instruction';
                    if (!!activitiesInSublesson.length) {
                        editableActivity.instruction = activitiesInSublesson[0].get('reference')[field];
                    }
                    if (editableActivity.type === 'MULTI') {
                        (<EditableMultimediaActivity>editableActivity).pages = activitiesInSublesson.map((entityActivity: DataEntity) => {
                            const multimediasMedias: [MultimediaMedia, MultimediaMedia?] = entityActivity.get('reference').activity_content[0]
                                .granule.filter((media) => !!media).map((media) => {
                                    return new MultimediaMedia(this.activitiesService.generateDataEntity(media, 'granule', media.id));
                                });
                            return new MultimediaPage(...multimediasMedias);
                        });
                    }
                });
            }
        });
    }

    private openConfirmDeleteDialog(): Observable<boolean> {
        return this.dialog.open<any, any, boolean>(ConfirmDeleteActivityDialogComponent).afterClosed();
    }

    /**
     * Ask service to have a new Tool activity, transform it to {@link EditableActivity} and fill the {@link EditableLesson} with it.
     * @private
     */
    private addToolActivity(): Observable<EditableToolActivity> {
        return this.lessonEditionService.getNewToolActivity()
            .pipe(
                tap<EditableToolActivity>((editableActivity) => this.editableLesson.addActivity(editableActivity))
            );
    }

    private addMultimediaActivity(): Observable<EditableMultimediaActivity> {
        return this.showModalEditActivity('generic.wording_multi')
            .pipe(
                map((values: { title: string, instruction: string }) => {
                    const defaultValues: EditableActivityDefaultValues =
                        <EditableActivityDefaultValues>_.merge({type: 'DefaultValues', granuleType: 'MULTI'}, values);
                    const tempActivity = new EditableMultimediaActivity(defaultValues);
                    this.editableLesson.addActivity(tempActivity);
                    return tempActivity;
                })
            );
    }

    public jumpBelowActivity(activity: EditableActivity): void {
        this.editableLesson.jumpBelow(activity);
    }

    public save(): void {
        this.isSaving = true;
        this.lessonEditionService.saveEditableLesson(this.editableLesson)
            .pipe(map(((data) => {
                    const editableActivities = data.activities.map((activity) => this.toEditableActivity(activity));
                    this.setEditableActivities(editableActivities);
                    return this.editableLesson = new EditableLesson(data.lesson, editableActivities);
                })
            ),
            mergeMap(() => {
                this.editableLesson.granuleLessonEntity.set('changed', Math.round(new Date().getTime() / 1000));
                return this.editableLesson.granuleLessonEntity.save(true);
            }),
            tap(() => this.isSaving = false)).subscribe();
    }

    public exit(): void {
        this.router.navigate(this.exitUrl ? this.exitUrl : this.lessonsService.settings.routeOnExitLesson);
    }

    public editActivity(activity: EditableActivity): void {
        if (this.activityOptionsEditable('titleAndInstruction')) {
            if (activity.type === 'Tool') {
                this.lessonEditionService.editToolActivity(<EditableToolActivity>activity).subscribe();
            } else {
                this.showModalEditActivity('generic.wording_' + activity.type.toLocaleLowerCase(), {title: activity.title, instruction: activity.instruction})
                    .pipe(
                        filter(defaultValues => !!defaultValues),
                        tap(({title, instruction}: { title: string, instruction: string }) => {
                            activity.title = title;
                            activity.instruction = instruction;
                        })
                    ).subscribe();
            }
        }
        if (this.activityOptionsEditable('content')) {
            this.lessonEditionService.showActivities(this.lessonsService.getTypologies(), true, {
                editableLesson: this.editableLesson,
                initialValues: this.getActivitySelectionInitialsValues(),
                keepLastFilters: this.onActivitySelectionLastFilter
            }).pipe(
                tap((entity: DataEntity) => {
                    const editableActivities = this.editableLesson.activities.map((oldActivity) => {
                        if (+oldActivity.granuleActivityEntity.id === +activity.granuleActivityEntity.id) {
                            return this.toEditableActivity(entity);
                        }
                        return oldActivity;
                    });
                    this.setEditableActivities(editableActivities);
                    return this.editableLesson = new EditableLesson(this.editableLesson.granuleLessonEntity, editableActivities);
                }),
            ).subscribe();
        }

    }

    private getActivitySelectionInitialsValues(): { [key: string]: any } {
        let initialValues: { [key: string]: any } = {};

        if (this.lessonsService.shouldSetDefaultOptionsOnActivityList()) {
            if (this.activitySelectionLastFilter === undefined) {
                try {
                    const educationalLevelRaw = this.editableLesson.granuleLessonEntity.get('metadatas').educationalLevel;
                    initialValues.level = _.isArray(educationalLevelRaw) ? educationalLevelRaw[0].id : educationalLevelRaw.id;
                } catch (e) {
                    console.error(e);
                }
            } else {
                initialValues = this.activitySelectionLastFilter;
            }
        }

        return initialValues;
    }

    /**
     * play for preview activity
     * @param {EditableActivity} activity
     */
    public playActivity(activity: EditableActivity): void {
        if (activity.type === 'MULTIAC' || activity.type === 'IMGI' || activity.type === 'EXT') {
            if (activity.granuleActivityEntity) {
                this.activitiesService.internalLaunchPreview(activity.granuleActivityEntity.id, null, {title: activity.title, instruction: activity.instruction});
            }
        }
        if (activity.type === 'MULTI') {
            this.lessonEditionService.playMultimediaSublesson((<EditableMultimediaActivity>activity));
        }
        if (activity.type === 'Tool') {
            // TODO: add preview for tool activity
        }
    }

    /**
     * play for preview child activity (multimedia)
     * @param {{page: MultimediaPage}} data
     */
    public playChildActivity(data: { activity: EditableActivity, page: MultimediaPage }): void {
        this.lessonEditionService.playMultimediaActivity(data.activity.title, data.activity.instruction, data.page);
    }

    private toEditableActivity(activity: DataEntity): EditableActivity {
        if (this.activitiesService.isMultimediaActivity(activity)) {
            return new EditableMultimediaActivity(activity);
        } else if (this.activitiesService.isToolActivity(activity)) {
            return new EditableToolActivity(activity);
        } else {
            return new EditableActivity(activity);
        }
    }

    public editChildActivity({activity, child}: { activity: EditableActivity; child: MultimediaPage }): void {
        if (activity.type === 'MULTI') {
            const defaultMedias = [child.first, child.second].filter(m => !!m);
            this.dialog.open(MultimediaPageEditorComponent, {data: defaultMedias})
                .afterClosed()
                .pipe(
                    filter((medias: (string | DataEntity)[]) => !!medias && medias.length > 0),
                    map((medias) => medias.map(m => new MultimediaMedia(m))),
                    map((multimediaMedias: [MultimediaMedia, MultimediaMedia?]) => {
                        child.first = multimediaMedias[0];
                        child.second = !!multimediaMedias[1] ? multimediaMedias[1] : undefined;
                    }),
                )
                .subscribe();
        } else {
            throw new Error('Not implemented');
        }
    }

    public addActivityWithDefinedTypology(): void {
        this.lessonEditionService.showActivities(this.lessonsService.getTypologies(), true, {
            editableLesson: this.editableLesson,
            initialValues: this.getActivitySelectionInitialsValues(),
            keepLastFilters: this.onActivitySelectionLastFilter
        }).pipe(
            tap((activity: DataEntity) => {
                const editableActivity = new EditableActivity(activity);
                this.setEditableActivities([editableActivity]);
                this.editableLesson.addActivity(editableActivity);
            })
        ).subscribe();
    }

    public activityOptionsEditable(option: string): boolean {
        return this.lessonsService.settings.activityOptionsEditable.includes(option);
    }
}
