import {Component, Input, OnInit} from '@angular/core';
import { fuseAnimations } from 'fuse-core/animations';
import {AuthenticationService} from '@modules/authentication';
import { MatTableDataSource } from '@angular/material/table';
import {ReplaySubject} from 'rxjs';
import {Router} from '@angular/router';


@Component({
  selector: 'app-dashwidget-group',
  templateUrl: './widget-group.component.html',
  styleUrls: ['./widget-group.component.scss'],
  animations   : fuseAnimations
})
export class WidgetGroupComponent implements OnInit {
    @Input('groupsSubscription') groupsSubscription: ReplaySubject<any>;

    public displayedColumns: string[] = ['color', 'groupname', 'learners'];
    public dataSource: MatTableDataSource<any> = new MatTableDataSource();

    entities: any;
    user: any;
    resource: boolean;



    constructor(
        public authService: AuthenticationService,
        private router: Router,
    ) {

    }

    ngOnInit(): any {
        if (this.authService.hasLevel(['learner'])){
            this.displayedColumns = ['groupname', 'learners'];
        }
        this.groupsSubscription.subscribe((resources) => {
            this.dataSource.data = resources.slice(0, 3);
            this.resource = resources && resources.length;
        });
    }

    getLink(): void{
        this.router.navigate(['/groups', 'list', 'groups']);
    }

    getTerms(data): string {
        return +data > 1 ? 'generic.learners' : 'generic.learner';
    }

    public get align(): string {
        if (!this.empty) {
            return 'start center';
        }
        return 'center center';
    }

    get empty(): boolean {
        return !this.resource;
    }

    public get getTermsByRole(): string {
        return  this.authService.isAtLeastTrainer() ? 'dashboard.group_empty_granule_trainer' : 'dashboard.group_empty_granule_learner';
    }

}


