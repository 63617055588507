import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {InstitutionManagementRoutes} from '@modules/groups-management/core/institution-management/institution-management.routes';

@Component({
  selector: 'app-tab-selector',
  templateUrl: './tab-selector.component.html'
})
export class TabSelectorComponent implements OnInit {

  public institutionLinks: { url: string, label: string }[] = [
    {url: '/institution/multi/' + InstitutionManagementRoutes.Users, label: 'institution_management.users'},
    {url: '/institution/multi/' + InstitutionManagementRoutes.Information, label: 'institution_management.information'},
  ];

  constructor(
      private router: Router,
  ) { }

  ngOnInit(): void {
  }

  public isRouteActive(url: string): boolean {
    return this.router.isActive(url, true);
  }

}
