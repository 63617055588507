import {Component, Inject, OnInit, Optional} from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CorpusDisplayWrapperComponent} from '@modules/activities/core/shared-components/corpus-display-wrapper/corpus-display-wrapper.component';
import { MatDialog } from '@angular/material/dialog';
import {DataEntity} from 'octopus-connect';
import {filter, tap} from 'rxjs/operators';
import {MultimediaMedia} from '@modules/activities/core/lessons/editor/models/multimedia-media.class';
import {SelectCorpusUploadComponent} from "@modules/activities/core/shared-components/select-corpus-upload/select-corpus-upload.component";

type availableMediaType = 'video' | 'audio' | 'image';

@Component({
    selector: 'app-multimedia-page-editor',
    templateUrl: './multimedia-page-editor.component.html'
})
export class MultimediaPageEditorComponent implements OnInit {
    public text = '';
    public editor = ClassicEditor;
    public editorConfig = {
        toolbar: [
            'bold', 'italic', 'underline',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'undo',
            'redo'
        ]
    };
    public availableMedias: (availableMediaType)[] = ['video', 'audio', 'image'];
    public selectedMedia: null | { type: availableMediaType, granule: DataEntity } = null;
    public selectedMediaType: availableMediaType = null;
    public showCorpusSelection: boolean;

    constructor(
        private dialog: MatDialog,
        public selfDialogRef: MatDialogRef<MultimediaPageEditorComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public modalData: MultimediaMedia[]
    ) {
    }

    public save(): void {
        const media = [];
        if (!!this.selectedMedia) {
            media.push(this.selectedMedia.granule);
        }
        if (this.text !== '') {
            media.push(this.text);
        }
        this.selfDialogRef.close(media);
    }

    selectTypeOfMedia(type: availableMediaType): void {
        this.showCorpusSelection = true;
        this.selectedMediaType = type;
    }

    public addMedia(selectCorpus: {userCorpus: boolean, upload: boolean}): void {
        this.showCorpusSelection = false;
        this.selectedMedia = null;
        const data = {
            isMyCorpus: selectCorpus.userCorpus,
            corpusFormatsAllowed: [this.selectedMediaType],
            forceMode: 'readonly'
        };

        const dialogRef = this.dialog.open(CorpusDisplayWrapperComponent, {
            width: '90%',
            height: '100%',
            data
        });

        dialogRef.afterClosed().pipe(
            filter(granule => !!granule),
            tap(granule => this.selectedMedia = {type: this.selectedMediaType, granule})
        ).subscribe();
    }

    public isSelectedMedia(media: availableMediaType): boolean {
        return this.selectedMedia !== null && this.selectedMedia.type === media;
    }

    public ngOnInit(): void {
        if (!!this.modalData) {
            this.modalData.forEach(multimediaMedia => {
                if (multimediaMedia.type === 'text') {
                    if (this.text !== '') {
                        throw new Error('A media is already set as text, but it can be only one text in one page');
                    }

                    if (typeof multimediaMedia.media === 'string') {
                        this.text = multimediaMedia.media as string;
                    } else {
                        this.text = multimediaMedia.media.get('reference').text as string;
                    }
                } else {
                    if (this.selectedMedia !== null) {
                        throw new Error('A media is already set as DataEntity, but it can be only one DataEntity in one page');
                    }
                    this.selectedMedia = {granule: <DataEntity>multimediaMedia.media, type: this.toAvailableMediaType(multimediaMedia.type)};
                }
            });
        }
    }

    private toAvailableMediaType(type: string): availableMediaType {
        if (type === 'videoUrl') {
            return 'video';
        }

        return <availableMediaType>type;
    }
}
