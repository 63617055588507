import {Observable} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {map} from 'rxjs/operators';
import {IConvert} from '@fuse/adapters/iconvert';

/**
 * adapter class take in entry a Observable<DataEntity[]> and a class
 * it ll prepare final data in regard of the class model pass using his convert method
 */
export class AdapterDataEntity {
    private data$: Observable<Object[]>;  // observable of data formated
    private adaptee: IConvert; // any class that contain a convert method will be accepted

    // adaptee class pass must contain a convert method
    constructor(dataEnities: Observable<DataEntity[]>, adaptee: IConvert) {
        this.adaptee = adaptee;
        this.data$ = this.converter(dataEnities);
    }

    /**
     * convert data entity to final object
     * converter is here to not having to rewrite it in each parent class wo will need it
     * and for having children class like BasicListDto the most simple possible
     */
    private converter(origine: Observable<DataEntity[]>): Observable<Object[]> {
        return origine.pipe(
            // observable of data entities
            map(dataEntities => {
                // array of data entities to convert with parent class method convert
                return this.adaptee.convert(dataEntities);
            }));
    }

    /**
     * return formated data not typed parent class will type them
     */
    getDataFormated(): Observable<Object[]> {
        return this.data$;
    }
}
