import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as _ from 'lodash';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subject} from 'rxjs/index';

export interface FakeStepperOptions {
    states: string[];
    interval: (stateIndex: number) => number;
    loop: boolean;
    onEnd: Subject<any>;
}

const defaultFakeStepperOptions: Partial<FakeStepperOptions> = {
    states: ['fake_stepper.initializing'],
    interval: (i) => 3000,
    loop: false
};

@Component({
    selector: 'app-fake-stepper',
    templateUrl: './fake-stepper.component.html',
    animations: [
        trigger('outIn', [
            state('out-right', style({
                left: '150%',
                right: '0',
                'text-align': 'none'
            })),
            state('in', style({
                left: '0',
                right: '0',
                'text-align': 'center'
            })),
            state('out-left', style({
                left: '-50%',
                right: '150%',
                'text-align': 'none'
            })),
            transition('out-right => in', [
                animate('1s ease-in-out')
            ]),
            transition('in => out-left', [
                animate('1s ease-in-out')
            ]),
            transition('out-left => in', [
                style({// On triche pour que le premier de la pile arrive tout de meme par la droite
                    left: '150%',
                    right: '0',
                    'text-align': 'none'
                }),
                animate('1s ease-in-out')
            ]),
            transition('in => out-right', [
                animate('1s ease-in-out',
                    style({ // On triche pour que le denier de la pile sorte tout de meme par la gauche
                        left: '-50%',
                        right: '150%',
                        'text-align': 'none'
                    }))
            ]),
        ]),
    ],
})
export class FakeStepperComponent implements OnInit, OnChanges {
    private currentStateIndex: number;
    private options: Partial<FakeStepperOptions> = _.clone(defaultFakeStepperOptions);
    private nextTick: NodeJS.Timeout;

    constructor() {
    }

    @Input('options')
    set optionsInput(opts: Partial<FakeStepperOptions>) {
        this.options = _.merge({}, defaultFakeStepperOptions, opts);
    }

    public getStates(): string[] {
        return this.options.states;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.reset();
    }

    ngOnInit(): void {
        this.reset();
    }

    /**
     * Retourne l'etat d'un stepper en fonction de son index dans la liste des steps
     * @param index
     */
    getAnimationState(index): string {
        if (index === this.currentStateIndex % this.options.states.length) {
            return 'in';
        } else if (index < this.currentStateIndex % this.options.states.length) {
            return 'out-left';
        } else if (index > this.currentStateIndex % this.options.states.length) {
            return 'out-right';
        }
    }

    private reset(): void {
        if (!!this.nextTick) {
            clearTimeout(this.nextTick);
        }
        this.currentStateIndex = 0;
        this.tick(this.options.interval(this.currentStateIndex + 1));
    }

    private tick(time: number): void {
        this.nextTick = setTimeout(() => {
            if (this.currentStateIndex >= this.options.states.length - 1 && this.options.loop === false) {
                this.triggerEnd();
                return;
            }
            this.currentStateIndex++;
            this.tick(this.options.interval(this.currentStateIndex + 1));
        }, time);
    }

    private triggerEnd(): void {
        if (!!this.options.onEnd) {
            this.options.onEnd.next();
        }
    }
}
