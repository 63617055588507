import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StarComponent} from './star/star.component';
import {MedalComponent} from './medal/medal.component';
import {WinsSummaryComponent} from './wins-summary/wins-summary.component';
import {TranslateModule} from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatIconModule
    ],
    declarations: [
        StarComponent,
        MedalComponent,
        WinsSummaryComponent],
    exports: [
        StarComponent,
        MedalComponent,
        WinsSummaryComponent
    ],
    entryComponents: []
})
export class WinsSummaryModule {
}
