export const activities = {
    actionButtonsInMultiMatrix: {
        default: [],
        QCM: ['testAnswer'],
        QCMU: ['testAnswer', 'resetAnswer']
    },
    activateNewEditor: true,
    activitiesNoClone: true, // not show clone in exercice bank
    activitiesDisplayedColumns: ['type', 'title', 'action'],
    activitiesTypesUserCanUse: ['MULTIAC', 'Tool', 'IMGI', 'MULTI'],
    addFromActivities: false,
    allowedThumbnailExtensions: ['image/jpg', 'image/jpeg', 'image/png'],
    allowedRolesForModelsCreation: ['administrator'],
    availableAddButtons: {
        default: ['activity', 'multimedia', 'tools']
    },
    canOverrideStepMetadatasInLesson: true,
    cardLayout: 'card-split-media-info',
    columns: {
        default: ['type', 'title', 'actions']
    },
    displayHeaderTitle: false,
    displayArchiveOptionToolbar: true,
    displayedFiltersIcons: true,
    displayFullscreenButton: true,
    displayHelpModalPage: ['byRole', 'currentUser'],
    filters: {
        default: ['title', 'type']
    },
    filtersTheme: {
        default: ['title', 'skills', 'difficulty', 'bookmarks', 'countEntities', 'launchSearch'],
    },
    allowedActivityTypes: [
        'IMGI',
        'MULTIAC'
    ],
    filterThemeUsePosition: true,
    filterTitleToTranslate: 'generic.title',
    grade: false,
    gradeCalculation: {},
    isOnlyModelLesson: true,
    hideUserActionButtonsWhenDisabled: true,
    hideAddButtonLessonForModel: true,
    hideAddButtonLessonForCommunity: true,
    hiddenFieldActivityPreview: [],
    hiddenActivityPreview: true,
    hideHeader: true,
    hideUserActionButtons: true,
    lessonDialogFields: {
        manager: ['title', 'thumbnail', 'target-age', 'difficulty', 'skill', 'theme', 'files'],
        default: ['title', 'thumbnail', 'target-age', 'difficulty', 'skill', 'files'],
    },
    lessonDialogRequiredFields:  {
        default: ['title'],
    },
    lessonStep: null,
    loadLessonWithSublesson: {
        typology: null,
        multi_step: 0
    },
    maxSizeThumbnail: '600000',
    multiSelectionForActivitiesList: false,
    openDialogInfoAfterDuplicateLesson: {
        default: true
    },
    plugins: {
        notepad: {
            label: 'notepad.title',
            octopusConnectRoom: 'notepad',
            pluginType: 'lessonTool',
            iconIdentifier: 'notepad.png'
        },
        mindmap: {
            label: 'mindmap.title',
            octopusConnectRoom: 'mindmap',
            pluginType: 'lessonTool',
            iconIdentifier: 'mindmap.png'
        },
        timeline: {
            label: 'timeline.title',
            octopusConnectRoom: 'timeline',
            pluginType: 'lessonTool',
            iconIdentifier: 'timeline.png'
        }
    },
    progress: false,
    searchFields: ['title', 'skills', 'theme', 'difficulty', 'countEntities', 'bookmarks', 'launchSearch'], // this fields are auto order like the list order
    shareableModel: 1,
    showActivityTitle: true, // show title instead of lesson name // TODO que dans lamorim ?
    showLessonTitle: false,
    stepper: {
        align: 'start',
        showRecap: false,
        stepValidationNeedSave: false,
        position: 'left'
    },
    stepTitlePrefixPosition: true, // show prefix in stepper in title lesson 1-title or not // TODO que dans lamorim ?
    urlVideoException: ['https://mp4.ina.fr/'], // url exception for play video url who is not know by js-video-url-parser
    routeOnExitLesson: ['lessons', 'list', 'lessons'],
    saveLessonContentOptions: {
        activityTypesCanBeDuplicate: ['MULTIAC', 'IMGI', 'QCMU', 'summary'], // type d'activitiés que l'on peut dupliquer (certaines activitiés n'ont pas besoin d'être dupliqué)
        saveContent: true // si chaque activités ou souslesson du parcours doit être sauvegardé
    }
};

