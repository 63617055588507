
import {of as observableOf, Observable, ReplaySubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {Graph} from '@modules/graph-assignation/core/progression/progression-display/graph';
import {modulesSettings} from '../../../../settings/mindmath';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {delay, map, mergeMap, take, tap} from 'rxjs/operators';
import {AuthenticationService} from '@modules/authentication';

const progressionSettingsStructure: ModelSchema = new ModelSchema(({
    progression: Structures.object({
        charts: [],
        chartOptions: {}
    }),
    metadataDialogFieldsInfoToShow: Structures.object({default: []}),
}));

@Injectable({
    providedIn: 'root'
})
export class ProgressionService {

    public progressionSettings: { charts: Graph[], chartOptions: any };
    private dataSelectedgraphProgression: DataEntity[];

    constructor(private communicationCenter: CommunicationCenterService,
                private octopusConnect: OctopusConnectService,
                private authenticationService: AuthenticationService) {
        this.progressionSettings = progressionSettingsStructure.filterModel(modulesSettings.graphAssignation).progression;
    }

    public getAssignments$(lessonId: string | number): Observable<DataEntity[]> {
        const resultSubject = new ReplaySubject<DataEntity[]>(1);

        this.communicationCenter.getRoom('assignment')
            .next('getAssignmentsByLesson$', {
                lessonId: lessonId,
                onComplete: resultSubject
            });

        return resultSubject;
    }

    public getDataForGraph(graphIdenfifier: string, idLesson: number, idGroup: string): Observable<{ data: number, label: string }[]> {
        // FAKE DATA
        let labels = this.progressionSettings.charts.find(c => c.label === graphIdenfifier).chartData.labels;

        if ([[], undefined, null].includes(labels)) {
            labels = [];
            const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const max = Math.random() * 5 + 1;
            for (let i = 0; i < max; i++) {
                labels[i] = 'Back data : ';
                for (let j = 0; j < Math.random() * 5; j++) {
                    labels[i] = labels[i].concat(chars[Math.floor(Math.random() * chars.length)]);
                }
            }
        }
        // real data from assignement progress
        if (graphIdenfifier === 'graph.advancement_title') {
            return this.getAssignationsByGroup(idGroup, idLesson).pipe(
                tap(assignations => this.storeDataforGetDetailsOnCurrentGraph(assignations)),
                map(assignations => this.formatAssignmentToAdvancementGraphData(assignations)),
            );
        }

        if (graphIdenfifier === 'graph.progression_title') {
            return this.getAssignationsByGroup(idGroup, idLesson).pipe(
                tap(assignations => this.storeDataforGetDetailsOnCurrentGraph(assignations)),
                map(assignations => this.formatAssignmentToProgressGraphData(assignations)),
            );
        }
        // data write in hard for test
        return observableOf(labels.map(l => ({data: l.length, label: l}))).pipe(delay(100));
    }

    /**
     * use idLesson and list of iduser exisiting in the slected group
     * to return the assignements
     * @param idLesson : id of the lesson
     * @param idGroup : group in string format
     */
    public getAssignationsByGroup(idGroup: string, idLesson: number): Observable<DataCollection> {
        return <Observable<DataCollection>> this.getLearners()
            .pipe(
                take(1),
                map((learners: ILearner[]) => learners.filter(learner => (learner.groups.includes(idGroup)))),
                mergeMap((learners: ILearner[]) =>
                    this.communicationCenter.getRoom('assignment').getSubject('loadPaginatedAssignmentsCallback')
                        .pipe(
                            mergeMap(callBack => callBack({
                                'assignated_lesson_id': +idLesson,
                                'assignated_user': learners.map(l => l.id)
                            }))
                        )),
            );
    }

    /**
     * make count by type of assignation pending, assigned , closed and format data to use in graph
     * @param assignations : DataCollection
     */
    private formatAssignmentToAdvancementGraphData(assignations: DataCollection): { data: number, label: string }[] {
        const assigned = assignations.entities.filter(entity => entity.attributes.state_term.label === 'assigned');
        const pending = assignations.entities.filter(entity => entity.attributes.state_term.label === 'pending');
        const closed = assignations.entities.filter(entity => entity.attributes.state_term.label === 'closed');

        const data: { data: number, label: string }[] = [];
        // warning : respect order assigned, pending, closed when push data
        data.push({data: assigned.length, label: 'graph.advancement_assigned'});
        data.push({data: pending.length, label: 'graph.advancement_pending'});
        data.push({data: closed.length, label: 'graph.advancement_closed'});
        return data;
    }

    /**
     * make count by grade on assignations and format data to use in graph
     * @param assignations : DataCollection
     */
    private formatAssignmentToProgressGraphData(assignations: DataCollection): { data: number, label: string }[] {
        const progression_not_acquired = this.filterBySuccess(0, 35, assignations.entities);
        const progression_to_do = this.filterBySuccess(35, 60, assignations.entities);
        const progression_so_close = this.filterBySuccess(60, 80, assignations.entities);
        const progression_acquired = this.filterBySuccess(80, 101, assignations.entities);

        const data: { data: number, label: string }[] = [];
        // warning : respect order assigned, pending, closed when push data
        data.push({data: progression_not_acquired.length, label: 'graph.progression_not_acquired'});
        data.push({data: progression_to_do.length, label: 'graph.progression_to_do'});
        data.push({data: progression_so_close.length, label: 'graph.progression_so_close'});
        data.push({data: progression_acquired.length, label: 'graph.progression_acquired'});
        return data;
    }

    private filterBySuccess(bound1: number, bound2: number, collection: DataEntity[]): DataEntity[] {
        return collection.filter(entity => +entity.attributes.grade < bound2 && +entity.attributes.grade >= bound1);
    }

    /**
     * store entitites with data on assignement for use later if user select a graph element
     * @param assignations : list of assignation for the lesson and group currently selected
     */
    private storeDataforGetDetailsOnCurrentGraph(assignations: DataCollection): void {
        this.dataSelectedgraphProgression = assignations.entities;
    }

    /**
     * get all the learners from communication center
     */
    private getLearners(): Observable<any> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList');
    }

    /**
     * format data to show details with name of student and progression in pourcent
     * @param graphIdentifier : type of graph
     * @param indexGraph: colomn of graph
     */
    getDetails(graphIdentifier: string, indexGraph: number): {}[] {
        if (graphIdentifier === 'graph.advancement_title') {
            // get current label by index of graph
            const labelIdentifier = ['assigned', 'pending', 'closed'][indexGraph];
            let assignment: DataEntity[] = [];
            assignment = this.dataSelectedgraphProgression.filter(entity => entity.attributes.state_term.label === labelIdentifier);
            const dataStudentProgression: { learner: string, success: string }[] = [];
            assignment.forEach((ass: DataEntity) => {
                dataStudentProgression.push({learner: ass.get('assignated_user').name, success: +ass.get('progress') + '%'});
            });
            return dataStudentProgression;
        }

        if (graphIdentifier === 'graph.progression_title') {
            // get current label by index of graph
            let labelIdentifier: any[];
            labelIdentifier = [[0, 35], [35, 60], [60, 80], [80, 101]][indexGraph];
            labelIdentifier.push(this.dataSelectedgraphProgression);
            let assignment: DataEntity[] = [];
            assignment = this.filterBySuccess(labelIdentifier[0], labelIdentifier[1], labelIdentifier[2]);
            const dataStudentProgression: { learner: string, progression: string }[] = [];
            assignment.forEach((ass: DataEntity) => {
                dataStudentProgression.push({learner: ass.get('assignated_user').name, progression: +ass.get('grade') + '%'});
            });
            return dataStudentProgression;
        }
    }

    /**
     * get fields allowed in pop up info
     */
    public getMetadataDialogFieldsInfoForCurrentRole(): string[] {
        const availableRole = !!progressionSettingsStructure.filterModel(modulesSettings.graphAssignation).metadataDialogFieldsInfoToShow[this.authenticationService.accessLevel]
            ? this.authenticationService.accessLevel
            : 'default';
        return progressionSettingsStructure.filterModel(modulesSettings.graphAssignation).metadataDialogFieldsInfoToShow[availableRole];
    }

}

export interface ILearner {
    id: number;
    avatar: string;
    groups: string[];
    password: string;
    schoolyear: string;
    sso: boolean;
    username: string;
    workgroups: string[];
}


