import {Component, Injectable} from '@angular/core';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {CreateGroupNewsComponent} from '../create-group-news/create-group-news.component';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {Subject} from 'rxjs';
import {v4 as uuidv4} from 'uuid';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../settings';
import {AuthenticationService} from '@modules/authentication';
import {CreateLeanerNewsComponent} from '@modules/groups-management/core/create-leaner-news/create-leaner-news.component';
import {AlertLearnersComeFromSsoNewsComponent} from '@modules/groups-management/core/alert-learners-come-from-sso-news/alert-learners-come-from-sso-news.component';
import {AlertTeachersComeFromSsoNewsComponent} from '@modules/groups-management/core/alert-teachers-come-from-sso-news/alert-teachers-come-from-sso-news.component';

const settingsStructure: ModelSchema = new ModelSchema({
    displayNews: Structures.object({
        default: []
    }),
});

interface NewsSettingsInterface {
    displayNews: {
        [role: string]: string[]
    };
}

@Injectable({
    providedIn: 'root'
})
export class GroupsManagementNewsService {

    /**
     * A news about the fact to create a group if it's not already done.
     */
    static createGroupNews = {
        id: uuidv4(),
        component: <Component>CreateGroupNewsComponent,
        weight: 100
    };

    /**
     * A news about the fact to create a learner if it's not already done.
     */
    static createLearnerNews = {
        id: uuidv4(),
        component: <Component>CreateLeanerNewsComponent,
        weight: 90
    };

    /**
     * A news about the fact to advise a teacher that he should ask the learners to connect through sso
     */
    static alertLearnerComeFromSso = {
        id: uuidv4(),
        component: <Component>AlertLearnersComeFromSsoNewsComponent,
        weight: 110
    };

    /**
     * A news about the fact to advise a teacher that he should ask the learners to connect through sso
     */
    static alertTeacherComeFromSso = {
        id: uuidv4(),
        component: <Component>AlertTeachersComeFromSsoNewsComponent,
        weight: 110
    };

    private onLogout = new Subject();
    private currentUser: DataEntity;
    private settings: NewsSettingsInterface;
    private shouldDisplayAlertLearnerFromSso = true;
    private shouldDisplayAlertTeacherFromSso = true;

    constructor(
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService
    ) {

        this.settings = <NewsSettingsInterface>settingsStructure.filterModel(modulesSettings.groupsManagement);

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((currentUser: DataEntity) => {
                this.currentUser = currentUser;
                if (!!currentUser) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    /**
     * Emit list news to the communication center according to the app state and the settings
     *
     * For now there are only one rule :
     * If the user is teacher not at his first connect and don't have groups emit {@link createGroupNews}
     *
     * @remarks this.currentUser must be the authenticated current user
     */
    public emitNews(): void {

        if (this.currentUser.get('sso') === false) {

            if (this.isCreateGroupNewsAllowed()) {
                this.emitCreateGroupNews();
            }

            if (this.isCreateLearnerNewsAllowed()) {
                this.emitCreateLearnerNews();
            }

        } else {

            if (this.isTeacherComeFromSsoNewsAllowed()) {
                this.emitTeacherComeFromSsoNews();
            }

            if (this.isLearnerComeFromSsoNewsAllowed()) {
                this.emitLearnerComeFromSsoNews();
            }

        }
    }

    /**
     * If the business rule accept it, emit the CreateLearnerNews
     * The rule : If it's not the first connection and there are no learners
     */
    public emitCreateLearnerNews(): void {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList').pipe(
            takeUntil(this.onLogout),
            tap(() => this.removeNews([GroupsManagementNewsService.createLearnerNews])),
            filter(() => this.authService.isFirstConnexion() !== true),
            filter((learners) => learners && learners.length === 0),
            tap(() => this.communicationCenter.getRoom('news').next('add', [GroupsManagementNewsService.createLearnerNews]))
        ).subscribe();
    }

    /**
     * If the business rule accept it, emit the alertLearnerComeFromSso
     * The rule : If this news has never been deactivated and it's not the first connection and there are no learners
     */
    public emitTeacherComeFromSsoNews(): void {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList').pipe(
            takeUntil(this.onLogout),
            tap(() => this.removeNews([GroupsManagementNewsService.alertLearnerComeFromSso])),
            filter(() => this.shouldDisplayAlertLearnerFromSso === true),
            filter((learners) => learners && learners.length === 0),
            tap(() => this.communicationCenter.getRoom('news').next('add', [GroupsManagementNewsService.alertLearnerComeFromSso]))
        ).subscribe();
    }

    /**
     * If the business rule accept it, emit the alertTeacherComeFromSso
     * The rule : If this news has never been deactivated and it's not the first connection and there are no learners
     */
    public emitLearnerComeFromSsoNews(): void {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList').pipe(
            takeUntil(this.onLogout),
            tap(() => this.removeNews([GroupsManagementNewsService.alertTeacherComeFromSso])),
            filter(() => this.shouldDisplayAlertTeacherFromSso === true),
            filter((learners) => learners && learners.length === 0),
            tap(() => this.communicationCenter.getRoom('news').next('add', [GroupsManagementNewsService.alertTeacherComeFromSso]))
        ).subscribe();
    }


    /**
     * If the business rule accept it, emit the CreateGroupNews
     * The rule : If it's not the first connection and there are no groups
     */
    public emitCreateGroupNews(): void {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList')
            .pipe(
                takeUntil(this.onLogout), // Si l'utilisateur se déconnecte, on ne veut pas mettre a jour cette news
                tap(() => this.removeNews([GroupsManagementNewsService.createGroupNews])),
                filter(() => this.authService.isFirstConnexion() !== true),
                filter((groups) => groups.length === 0), // Si on a pas encore de classe
                tap(() => this.communicationCenter.getRoom('news').next('add', [GroupsManagementNewsService.createGroupNews])),
            ).subscribe();
    }

    /**
     * Remove all news managed by this service.
     * @remarks If the news are not displayed it's not a problem.
     */
    public removeNews(news = []): void {
        if (news.length === 0) {
            news = [
                GroupsManagementNewsService.createGroupNews,
                GroupsManagementNewsService.createLearnerNews,
                GroupsManagementNewsService.alertLearnerComeFromSso,
                GroupsManagementNewsService.alertTeacherComeFromSso
            ];
        }
        this.communicationCenter.getRoom('news').next('delete', news);
    }

    /**
     * Return true if current user is allowed to have the createGroupNews define by the role in the settings
     */
    public isCreateGroupNewsAllowed(): boolean {
        return this.settings.displayNews.hasOwnProperty(this.authService.accessLevel) ?
            this.settings.displayNews[this.authService.accessLevel].includes('createGroup') :
            this.settings.displayNews['default'].includes('createGroup');
    }

    /**
     * Return true if current user is allowed to have the createLearnerNews define by the role in the settings
     */
    public isCreateLearnerNewsAllowed(): boolean {
        return this.settings.displayNews.hasOwnProperty(this.authService.accessLevel) ?
            this.settings.displayNews[this.authService.accessLevel].includes('createLearner') :
            this.settings.displayNews['default'].includes('createLearner');
    }

    /**
     * Return true if current user is allowed to have the createLearnerNews define by the role in the settings
     */
    public isTeacherComeFromSsoNewsAllowed(): boolean {
        return this.settings.displayNews.hasOwnProperty(this.authService.accessLevel) ?
            this.settings.displayNews[this.authService.accessLevel].includes('alertLearnersSso') :
            this.settings.displayNews['default'].includes('createLearner');
    }

    /**
     * Return true if current user is allowed to have the createTeacher define by the role in the settings
     */
    public isLearnerComeFromSsoNewsAllowed(): boolean {
        return this.settings.displayNews.hasOwnProperty(this.authService.accessLevel) ?
            this.settings.displayNews[this.authService.accessLevel].includes('alertTeacherSso') :
            this.settings.displayNews['default'].includes('alertTeacherSso');
    }

    /**
     * execute all needs to prepare the app for the current user
     * @private
     */
    private postAuthentication(): void {
        this.communicationCenter
            .getRoom('GroupsManagementNews')
            .getSubject('alertLearnersComeFromSsoUnderstand')
            .subscribe((userHasUnderstand: boolean) => {
                if (userHasUnderstand) {
                    this.shouldDisplayAlertLearnerFromSso = false;
                    this.removeNews([GroupsManagementNewsService.alertLearnerComeFromSso]);
                }
            });

        this.communicationCenter
            .getRoom('GroupsManagementNews')
            .getSubject('alertTeachersComeFromSsoUnderstand')
            .subscribe((userHasUnderstand: boolean) => {
                if (userHasUnderstand) {
                    this.shouldDisplayAlertTeacherFromSso = false;
                    this.removeNews([GroupsManagementNewsService.alertTeacherComeFromSso]);
                }
            });

        this.emitNews();
    }

    /**
     * execute all needs to clean the app on logout
     * @private
     */
    private postLogout(): void {
        this.onLogout.next();
        this.onLogout.complete();
        this.shouldDisplayAlertLearnerFromSso = true;
        this.shouldDisplayAlertTeacherFromSso = true;
        this.removeNews();
    }

}
