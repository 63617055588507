import {Component, Inject, Input, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DataEntity} from 'octopus-connect';
import * as _ from 'lodash';

@Component({
    selector: 'app-corpus-display-wrapper',
    templateUrl: './corpus-display-wrapper.component.html'
})
export class CorpusDisplayWrapperComponent implements OnInit {
    @Input('room') room = 'corpus';
    @Input('subject') subject = 'corpusDisplayComponent';
    @Input('params') params = {
        data: {
            displayHeader: false,
            selectionMode: true,
            isMyCorpus: false,
            corpusFormatsAllowed: ['image'],
            showCheckBox: false,
            callBack: (response) => this.selectedThumbnail(response)
        }
    };
    @Input('title') title = 'Mon corpus';

    constructor(private dialogRef: MatDialogRef<CorpusDisplayWrapperComponent>,
                @Inject(MAT_DIALOG_DATA) private data) {

    }

    ngOnInit(): void {
        if (!!this.data) {
            this.params.data = _.merge({}, this.params.data, this.data);
        }
    }

    /**
     * call back return of data input object from cor^pus display dialog component
     * launch by corpus file list component when image selected to return it here.
     * @param selected: Stored File as a DataEntity
     */
    private selectedThumbnail(selected: DataEntity): void {
        this.close(selected);
    }

    /**
     * close the corpus with or without image selected to store
     * @param corpusImage
     */
    public close(corpusImage?: DataEntity): void {
        if (corpusImage) {
            this.dialogRef.close(corpusImage);
        } else {
            this.dialogRef.close();
        }
    }
}
