import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {GraphConfig} from 'fuse-core/components/graph/graph-mixed/graph.config';
import {GraphMathiaService} from '../../services/graph-mathia.service';
import {delay, filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {AttendanceData} from '../../model/attendance-data';
import {Label} from 'ng2-charts/lib/base-chart.directive';
import {CustomTooltipModalContentSettings} from 'fuse-core/components/graph/graph-details-modal/custom-tooltip.setting';
import {ChartDataSets} from 'chart.js';
import {rgbOpacity} from '../../../../../shared/utils';
import * as moment from 'moment';
import 'moment-duration-format';
import {GraphMathiaTranslateService} from '@modules/graph-mathia/core/services/graph-mathia-translate.service';
import {FakeStepperOptions} from '@modules/graph-mathia/core/component/fake-stepper/fake-stepper.component';

const ExerciseTypeColors = [
    'rgb(0,56,75)',
    'rgb(0,95,128)',
    'rgb(0,143,194)',
    'rgb(0,175,236)',
    'rgb(53,203,255)',
    'rgb(138,225,255)',
    'rgb(202,245,255)',
];

const ModalityColors = [
    'rgb(0,121,107)',
    'rgb(0,194,161)',
    'rgb(148,240,224)',
];

@Component({
    selector: 'app-attendance-graph',
    templateUrl: './attendance-graph.component.html',
})
export class AttendanceGraphComponent implements OnInit, OnDestroy {

    public graphConfigObs: ReplaySubject<GraphConfig> = new ReplaySubject<GraphConfig>(1);
    public isReady = false;
    /**
     * Cache le loader si le service n'est pas encore pret
     */
    public showLoader = false;
    private unsubscribeInTakeUntil = new Subject();
    /**
     * Aide a déterminer le temps entre les steps du loader
     * @private
     */
    private loaderShouldEnd = false;
    /**
     * Défini les steps du loader, le temps entre les stepsn, etc.
     */
    public loaderOptions: Partial<FakeStepperOptions> = {
        loop: false,
        interval: stateIndex => (this.loaderShouldEnd === false || stateIndex <= 2 ? 4000 : 1000),
        states: ['fake_stepper.state_1', 'fake_stepper.state_2', 'fake_stepper.state_3'],
        onEnd: new ReplaySubject(1)
    };

    constructor(
        private graphMathiaService: GraphMathiaService,
        private graphMathiaTranslateService: GraphMathiaTranslateService,
    ) {
    }

    ngOnInit(): void {
        this.graphMathiaService.isReady
            .pipe(
                filter(isReady => !!isReady),
                tap(() => this.showLoader = true),
                tap(() => this.graphMathiaService.graphDataArePending.subscribe(() => this.isReady = false)),
                mergeMap(() => this.graphMathiaService.getAttendanceGraphData()),
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data) => {
            this.loaderShouldEnd = true;
            if (data.graphFilters.attendanceView === 'global') {
                this.generateGlobalGraph(data);
            } else {
                this.generateModalityGraph(data);
            }
            // Ne retirez pas de delay, sinon le timing foire et ça ne marche pas (je pense que le graphDataArePending est trigger trop tot)
            this.loaderOptions.onEnd.pipe(take(1), delay(750)).subscribe(() => this.isReady = true);
        });

    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private generateModalityGraph(graphData: AttendanceData): void {
        const rawData = graphData.entitiesGroupedByLearnerAndModality;
        const learners = this.graphMathiaService.learners
            .filter(l => graphData.graphFilters.learnerList.includes(+l.id))
            .sort((a, b) => a.nickname < b.nickname ? -1 : 1);
        const modalities = this.graphMathiaService.modalities.slice();

        const chartLabels: Label[] = learners.map(l => l.nickname);
        const chartDataSets: ChartDataSets[] = [];
        const learnerActivityCounter: {
            learnerId: number,
            modality: string,
            duration: number,
            count: number
        }[] = [];

        modalities.forEach((modality, indexModality) => {
            const modalityDataSet: ChartDataSets = {
                label: modality,
                backgroundColor: rgbOpacity(ModalityColors[indexModality], '.9'),
                hoverBackgroundColor: ModalityColors[indexModality],
                data: [],
                barThickness: 'flex',
            };
            learners.forEach((learner, indexLearner) => {
                const learnerGraphData = rawData[+learner.id + ''];
                const exerciceGraphData = !!learnerGraphData ? learnerGraphData[modality.toLocaleLowerCase()] : null;
                const learnerCountForThisModality: number = !!exerciceGraphData ? exerciceGraphData.length : 0;
                const duration: number = exerciceGraphData ? exerciceGraphData.map(d => (0 + d.duration)).reduce((acc, dd) => acc + dd, 0) : 0;
                learnerActivityCounter.push({
                    learnerId: +learner.id,
                    modality: modality,
                    duration: duration,
                    count: learnerCountForThisModality
                });
                modalityDataSet.data[indexLearner] = learnerCountForThisModality;
            });

            chartDataSets.push(modalityDataSet);
        });

        // On reboucle parce qu'on avait besoin d'avoir les totaux avant c'est pas la plus optimisé des façon de faire, mais la plus lisible
        const dotsDetails: CustomTooltipModalContentSettings[][] = modalities.map(() => {
            return learners.map((learner): CustomTooltipModalContentSettings => {
                const activitiesCounter = learnerActivityCounter.filter(c => +c.learnerId === +learner.id);
                const count = activitiesCounter.reduce((acc, ac) => acc + ac.count, 0);
                const durationCount = activitiesCounter.reduce((acc, ac) => acc + ac.duration, 0);
                const userFriendlyDuration = moment.duration(durationCount, 'seconds').format('mm[m]ss[s]');
                const lis = activitiesCounter.map(ac => `<li>${ac.modality} : ${ac.count} ${this.graphMathiaTranslateService.get('graph_mathia.exercises')}</li>`);
                return ({
                    content: `<ul><li>${this.graphMathiaTranslateService.get('graph_mathia.total_time')} : ${userFriendlyDuration}</li>${lis.join('')}</ul>`,
                    header: `<div>${learner.nickname}</div><div>${count} ${this.graphMathiaTranslateService.get('graph_mathia.exercises')}</div>`
                });
            });
        });

        this.graphConfigObs.next({
            chartLabels,
            chartColors: [],
            chartConfig: {
                scales: {
                    xAxes: [{
                        stacked: true,
                        gridLines: {
                            display: false,
                        }
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            // @ts-ignore
                            precision: 0
                        },
                        type: 'linear',
                    }]
                },
                legend: {
                    align: 'start',
                    position: 'bottom',
                    onClick: (event: MouseEvent) => event.stopPropagation()
                },
                aspectRatio: 2.5
            },
            chartData: chartDataSets,
            modalContent: dotsDetails,
        });
    }

    private generateGlobalGraph(graphData: AttendanceData): void {
        const rawData = graphData.entitiesGroupedByLearnerAndExerciseType;
        const learners = this.graphMathiaService.learners
            .filter(l => (graphData.graphFilters.learnerList || []).includes(+l.id))
            .sort((a, b) => a.nickname < b.nickname ? -1 : 1);
        const exerciseTypes = this.graphMathiaService.exerciseTypes.sort((a, b) => a.attributes.name < b.attributes.name ? -1 : 1);

        const chartLabels: Label[] = learners.map(l => l.nickname);
        const chartDataSets: ChartDataSets[] = [];
        const learnerActivityCounter: {
            learnerId: number,
            exerciseName: string,
            duration: number,
            count: number
        }[] = [];

        exerciseTypes.forEach((exerciseType, indexExerciseType) => {

            const exerciseTypeColor = this.getExerciseTypeColors(indexExerciseType);

            const exerciseTypeDataSet: ChartDataSets = {
                label: exerciseType.attributes.name,
                backgroundColor: rgbOpacity(exerciseTypeColor, '.9'),
                hoverBackgroundColor: exerciseTypeColor,
                data: [],
                barThickness: 'flex',
            };
            learners.forEach((learner, indexLearner) => {
                const learnerGraphData = rawData[+learner.id + ''];
                const exerciceGraphData = learnerGraphData[+exerciseType.id + ''];
                const learnerCountForThisExerciseType: number = !!exerciceGraphData ? exerciceGraphData.map(d => +d.detail.length).reduce((a, b) => a + (0 + b), 0) : 0;
                const duration: number = exerciceGraphData ? exerciceGraphData.map(d => d.detail.map((dd) => (0 + dd.duration))).flat().reduce((acc, dd) => acc + dd, 0) : 0;
                learnerActivityCounter.push({
                    learnerId: +learner.id,
                    exerciseName: exerciseType.attributes.name,
                    duration: duration,
                    count: learnerCountForThisExerciseType
                });
                exerciseTypeDataSet.data[indexLearner] = learnerCountForThisExerciseType;
            });

            chartDataSets.push(exerciseTypeDataSet);
        });

        // On reboucle parce qu'on avait besoin d'avoir les totaux avant c'est pas la plus optimisé des façon de faire, mais la plus lisible
        const dotsDetails: CustomTooltipModalContentSettings[][] = exerciseTypes.map((exerciseType) => {
            return learners.map((learner): CustomTooltipModalContentSettings => {
                const activitiesCounter = learnerActivityCounter.filter(c => +c.learnerId === +learner.id);
                const count = activitiesCounter.reduce((acc, ac) => acc + ac.count, 0);
                const durationCount = activitiesCounter.reduce((acc, ac) => acc + ac.duration, 0);
                const userFriendlyDuration = moment.duration(durationCount, 'seconds').format('mm[m]ss[s]');
                const lis = activitiesCounter.map(ac => `<li>${ac.exerciseName} : ${ac.count} ${this.graphMathiaTranslateService.get('graph_mathia.exercises')}</li>`);
                return ({
                    content: `<ul><li>${this.graphMathiaTranslateService.get('graph_mathia.total_time')} : ${userFriendlyDuration}</li>${lis.join('')}</ul>`,
                    header: `<div>${learner.nickname}</div><div>${count} ${this.graphMathiaTranslateService.get('graph_mathia.exercises')}</div>`
                });
            });
        });

        this.graphConfigObs.next({
            chartLabels,
            chartColors: [],
            chartConfig: {
                scales: {
                    xAxes: [{
                        stacked: true,
                        gridLines: {
                            display: false,
                        }
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            // @ts-ignore
                            precision: 0
                        },
                        type: 'linear',
                    }]
                },
                legend: {
                    align: 'start',
                    position: 'bottom',
                    onClick: (event: MouseEvent) => event.stopPropagation()
                },
                aspectRatio: 2.5
            },
            chartData: chartDataSets,
            modalContent: dotsDetails,
        });
    }

    private getExerciseTypeColors(indexExerciseType: number): string {
        if (indexExerciseType >= 0 && indexExerciseType < ExerciseTypeColors.length) {
            return ExerciseTypeColors[indexExerciseType];
        }
        const defaultColor = 'rgb(0,0,0)';
        console.warn(`Color indexed at number ${indexExerciseType} but there are only ${ExerciseTypeColors.length} colors. Color "${defaultColor}" by default`);
        return defaultColor;
    }
}
