
import {takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DataEntity} from 'octopus-connect';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DragulaService} from 'ng2-dragula';
import {ActivitiesService} from '../../activities.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-qcu-editor',
  templateUrl: './qcu-editor.component.html',
  providers: [DragulaService]
})
export class QcuEditorComponent implements OnInit, OnDestroy {
    private activity: DataEntity;
    private metadatas: DataEntity;
    private answersToRemove: number[] = [];
    public entity: any;
    public entityForm: FormGroup;
    public myFocusTriggeringEventEmitter = new EventEmitter<string>();
    private unsubscribeInTakeUntil = new Subject();

    constructor(
        private lessonsService: LessonsService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private dragulaService: DragulaService,
        private activitiesService: ActivitiesService,
    ) {
        const granule = this.lessonsService.getCurrentActivity();

        this.entity = {
            wording: '',
            answers: []
        };

        if (granule) {
            this.activity = <DataEntity>granule.getEmbed('reference');
            this.metadatas = <DataEntity>granule.getEmbed('metadatas');

            this.entity.wording = this.activity.get('instruction');
            this.activity.get('activity_content')
                .answers.forEach((answer) => {
                this.entity.answers.push(answer.answer);
            });
        }

        this.entityForm = this.createEntityForm();

        this.dragulaService.drop().pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(({source, target}) => {
                this.onDragChange();
            });
        this.dragulaService.createGroup('itemsBAG', {
                /* moves: (el, container, handle) => {
                    return handle.className === 'handle';
                },*/
            }
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
        this.lessonsService.clearCurrentActivity();
    }

    onDragChange() {
    }

    createEntityForm(): FormGroup {
        const config: any = {};

        config.wording = [this.entity.wording];

        this.entity.answers.forEach((answer, index) => {
            config[`answers${index}`] = [answer];
        });

        return this.formBuilder.group(config);
    }

    addAnswer() {
        const index = this.entity.answers.length;
        this.entity.answers.push('');

        const formControl = new FormControl();
        formControl.setValue(this.entity.answers[index]);

        this.entityForm.addControl(`answers${index}`, formControl);

        setTimeout(() => {
            this.myFocusTriggeringEventEmitter.emit(`answers${index}`);
        }, 100);
    }

    removeAnswer(index) {
        this.entity.answers.splice(index, 1);
        this.answersToRemove.push(index);
        const values = {};

        while (this.entityForm.controls[`answers${index + 1}`]) {
            // this.entityForm.controls[`answers${index}`].setValue(this.entityForm.controls[`answers${index + 1}`].value);
            values[`answers${index}`] = this.entityForm.controls[`answers${index + 1}`].value;
            index += 1;
        }

        this.entityForm.removeControl(`answers${index}`);

        setTimeout(() => {
            // HACK - TODO find another way to update the formGroup
            this.entityForm.addControl(`random`, new FormControl());
            this.entityForm.removeControl(`random`);
            this.entityForm.patchValue(values);
        }, 50);
    }

    saveActivity() {
        const answers = [];
        this.entity.answers.forEach((answer, index) => {
            answers.push(this.entityForm.value[`answers${index}`]);
        });

        if (this.activity && this.metadatas) {
            this.lessonsService.saveActivityQcm({
                wording: this.entityForm.value.wording,
                answers: answers,
                removeAnswers: this.answersToRemove
            }).pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((entities) => {
                    this.exit();
                });
        } else {
            this.lessonsService.createActivityQcm('QCMU', {
                wording: this.entityForm.value.wording,
                answers: answers
            }).pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((entity: DataEntity) => {
                    this.lessonsService.addActivityToForm(entity);
                    this.exit();
                });
        }
    }

    exit() {
        this.router.navigate(['../..'], {relativeTo: this.route});
    }

}
