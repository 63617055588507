import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import {TralalereBarComponent} from './tralalere-bar.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
        MatIconModule,
        MatToolbarModule,
        SharedModule
    ],
    exports: [
        TralalereBarComponent
    ],
    declarations: [
        TralalereBarComponent
    ]
})

export class TralalereBarModule { }
