import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CollectionPaginator, DataEntity, OctopusConnectService} from 'octopus-connect';
import {InstitutionUsersService} from '@modules/groups-management/core/services/institution-users-service/institution-users.service';
import {filter, map, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {CollectionOptionsInterface} from 'octopus-connect/lib/models/collection-options.interface';
import {fuseAnimations} from 'fuse-core/animations';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EditUserComponent} from '@modules/groups-management/core/institution-management/institution-users/edit-user/edit-user.component';
import {Observable} from 'rxjs/index';
import * as _ from 'lodash';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '@modules/groups-management/core/models/rules';
import {Subject} from 'rxjs';
import {User} from '@modules/groups-management/core/models/user-search-data-entity';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-institution-users',
    templateUrl: './institution-users.component.html',
    animations: fuseAnimations
})
export class InstitutionUsersComponent implements OnInit, OnDestroy {
    public displayedColumns: string[] = [];
    public countEntities = 0;
    public pageIndex = 1;
    public pageRange = 10;
    public pageRangeOptions: number[] = [10];
    public pending: boolean;
    public usersEntities: DataEntity[] = [];
    public selectAll: false;
    public checkboxes: { [key: string]: boolean } = {};
    public usersInTable: User[];

    private paginator: CollectionPaginator;
    private unsubscribeTakeUntil: Subject<any>;
    private confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    constructor(private institutionUsersService: InstitutionUsersService,
                private dialog: MatDialog,
                private authorizationService: AuthorizationService,
                private translate: TranslateService,
                @Inject(MAT_DIALOG_DATA) private data: any) {

    }

    ngOnInit(): void {
        this.unsubscribeTakeUntil = new Subject();
        this.initialise();
    }

    ngOnDestroy(): void {
        this.institutionUsersService.reloadClasses(false);
        this.unsubscribeTakeUntil.next();
        this.unsubscribeTakeUntil.complete();
    }

    public displayButton(buttonType: string): boolean {
        return true;
    }

    /**
     * !! decomment code when story will ask all is done but for moment sabri say to keep it locked !!
     * suppress user after modal confirm
     * @param userToDelete : user to delete contain id of user
     */
    public delete(userToDelete: any): void {
        /*const data = {
            titleDialog: 'groups-management.insitution.delete.confirm.title',
            bodyDialog: 'groups-management.institution.delete.confirm.body',
            labelTrueDialog: 'groups-management.institution.delete.confirm.yes',
            labelFalseDialog: 'groups-management.institution.delete.confirm.no'
        };

        this.translate.get(data.titleDialog).subscribe((translation: string) => data.titleDialog = translation);
        this.translate.get(data.bodyDialog).subscribe((translation: string) => data.bodyDialog = translation);
        this.translate.get(data.labelTrueDialog).subscribe((translation: string) => data.labelTrueDialog = translation);
        this.translate.get(data.labelFalseDialog).subscribe((translation: string) => data.labelFalseDialog = translation);

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {data});

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.institutionUsersService.getUserById(userToDelete.id)
                    .pipe(take(1))
                    .subscribe((user: DataEntity[]) => {
                        if (user) {
                            const index = this.usersInTable.findIndex(u => u.id === userToDelete.id);
                            user[0].remove();
                            const temp = [...this.usersInTable];
                            temp.splice(index, 1);
                            this.usersInTable = [];
                            this.usersInTable = temp;
                        }
                    });
            }
            this.confirmDialogRef = null;
        });*/
    }

    public edit(user: { [key: string]: any }, assignOnly = false): void {
        const refDialog: MatDialogRef<EditUserComponent> = this.dialog.open(EditUserComponent, {
            panelClass: 'edit-user-Component-form-dialog',
            width: '60vw',
            data: {
                user,
                classes: this.institutionUsersService.classes,
                workgroups: this.institutionUsersService.workgroups,
                assignOnly
            }
        });
        refDialog.afterClosed().pipe(
            filter((response) => !!response),
            mergeMap((response) => this.saveUserData(response))
        ).subscribe();
    }

    private initialise(): void {
        this.displayedColumns = ['name', 'email', 'classes', 'workgroups', 'types', 'buttons'];
        this.loadUsers();
    }

    private loadUsers(filterOptions?: CollectionOptionsInterface): void {
        this.pending = true;
        this.institutionUsersService.loadInstitutionAndGroup().pipe(
            mergeMap(() => {
                return this.institutionUsersService.getUsersAndPaginator(filterOptions)
                    .pipe(
                        takeUntil(this.unsubscribeTakeUntil),
                        tap((data: { entities: DataEntity[], paginator: CollectionPaginator }) => this.paginator = data.paginator),
                        tap((data: { entities: DataEntity[], paginator: CollectionPaginator }) => this.usersEntities = data.entities.slice()),
                        tap((data: { entities: DataEntity[], paginator: CollectionPaginator }) =>
                            this.usersInTable = this.institutionUsersService.associateUserWithRole(data.entities)),
                        tap(() => this.setPaginator()),
                        tap(() => this.pending = false)
                    );
            })
        ).subscribe();
    }

    private setPaginator(): void {
        if (this.paginator) {
            this.countEntities = this.paginator.count;
            this.pageIndex = this.paginator.page - 1;
            this.pageRange = this.paginator.range;
        }
    }

    public onPaginateChange(event): void {
        this.paginator.page = event.pageIndex + 1;
    }

    public filterBy(type: string, row: {
        id: number,
        name: string,
        mail: string,
        og_user_node: { [key: string]: any }[],
        roles: { [key: string]: string },
        rolesInInstitution: { [key: string]: string }
    }): string[] {
        return row.og_user_node
            .filter((group) => group.type.toLowerCase() === type)
            .map((group) => group.title);
    }

    private saveUserData(data: { [key: string]: any }): Observable<DataEntity> {
        const user = _.cloneDeep(this.usersEntities.find((userEntity: DataEntity) => +userEntity.id === +data.id));
        return this.institutionUsersService.saveUser(data, user).pipe(
            mergeMap((userEntity: DataEntity) => this.institutionUsersService.saveUserRoleInInstitution(data, userEntity))
        );
    }

    public isUserHasRole(roles: { [key: string]: any }, role): boolean {
        let isUserHasRole = false;
        if (roles && roles.rolesInInstitution) {
            for (const field in roles.rolesInInstitution) {
                if (roles.rolesInInstitution[field] === role) {
                    isUserHasRole = true;
                }
            }
        }
        return isUserHasRole;
    }

    public updateCheck(event): void {
        this.selectAll = event.checked;
        for (const user of this.usersInTable) {
            this.checkboxes[user.id] = event.checked;
        }
    }

    public createUser(): void {
        const refDialog: MatDialogRef<EditUserComponent> = this.dialog.open(EditUserComponent, {
            panelClass: 'add-user-Component-form-dialog',
            width: '60vw',
            data: {
                user: null,
                classes: this.institutionUsersService.classes,
                workgroups: this.institutionUsersService.workgroups,
                assignOnly: false,
            }
        });
        refDialog.afterClosed().pipe(
            filter((response) => !!response),
            mergeMap((response) => this.saveUserData(response))
        ).subscribe();
    }

    public canAssignUser(user: User): boolean {
        return this.authorizationService.currentUserCan(SyncRules.EditGroup, user);
    }

    public canEditUser(user: User): boolean {
        return this.authorizationService.currentUserCan(SyncRules.EditTrainer, user);
    }

    public canDeleteUser(user: User): boolean {
        return this.authorizationService.currentUserCan(SyncRules.DeleteTrainer, user);
    }
}
