import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {brand} from '../../../../../../../settings';
import {take, tap} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';
import {ReplaySubject} from 'rxjs';
import {LessonEditionService} from '@modules/activities/core/lessons/editor/services/lesson-edition.service';

@Component({
    selector: 'editor-add-step',
    templateUrl: './editor-add-step.component.html'
})
export class EditorAddStepComponent implements OnInit {
    @Output() selectStepTypeToAddInLesson: EventEmitter<string> = new EventEmitter<string>();
    public brand: string = brand;
    public visible: boolean = false;
    public types: string[];
    public selectedType: string;
    public disableSelectType: boolean;
    constructor(private communicationCenter: CommunicationCenterService,
                private lessonSvc: LessonEditionService) {
    }

    ngOnInit(): void {
        this.disableSelectType = false;
        this.types = this.lessonSvc.getTypesOfActivities();
    }

    public showStepPanel(): void {
        this.visible = !this.visible;
        if (!this.visible) {
            this.selectedType = null;
        }
    }

    public selectType(type): void {
        this.selectedType = type;
        this.selectStepTypeToAddInLesson.emit(type);
        this.showStepPanel();
    }

    public getContentTypeIcon(type: string): object {
        return this.lessonSvc ? this.lessonSvc.getContentTypeIcon(type) : null;
    }
}
