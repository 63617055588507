import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {MessagingService} from '../../messaging.service';
import {DatePipe} from '@angular/common';
import {isArray} from 'util';
import { MatDialog } from '@angular/material/dialog';
import {MessagingConfirmComponent} from '../messaging-confirm/messaging-confirm.component';
import {DOMAIN_MESSAGING, EVENT_CORE_LABELS, EVENT_THREAD_UPDATE,
    EVENT_MESSAGE_NEW, EVENT_THREAD_EDIT, EVENT_THREAD_ACTIVATED,
    EVENT_MESSAGE_LIST, EVENT_MESSAGE_LIST_NEXT, EVENT_MESSAGE_LIST_NEXT_HREF,
    EVENT_THREAD_EDIT_OPEN_MODAL,
    EVENT_MESSAGE_DELETE,
    EVENT_THREAD_LIST,
    EVENT_THREAD_LIST_NEXT} from '../../messaging.events';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-messaging-thread',
  templateUrl: './messaging-thread.component.html',
  styleUrls: ['./messaging-thread.component.scss'],
})
export class MessagingThreadComponent implements OnInit {

    dialogRef: any;
    public learnerDeletedTerm = '';
    messageZoneLinesNumber = 1;

    newMessage = '';
    activThread: any;
    messages: any[];
    nextPageUrl = undefined;

    messaging_me = '';
    messaging_the = '';
    messaging_at = '';
    confirm_delete_text = '';

    @ViewChild('zonemessage') zonemessage: ElementRef;
    @ViewChild('messagesListContainer') messagesListContainer: ElementRef;

    constructor(
        public messagingService: MessagingService,
        private datePipe: DatePipe,
        public dialog: MatDialog,
        private translate: TranslateService
    ) {
        this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_CORE_LABELS, (data: any) => {
            this.messaging_me = data['messaging.me'];
            this.messaging_the = data['messaging.the'];
            this.messaging_at = data['messaging.at'];
            this.confirm_delete_text = data['messaging.confirm_delete_text'];
            if (isArray(this.messages)) {
                this.messages.forEach(element => {
                    element['dateAuthorLabel'] = this.computeDateAuthorLabel(element);
                });
            }
        });
    }
    /**
     * 
     */
    ngOnInit(): void  {
        this.translate.get('generic.learner_deleted').subscribe((term) => this.learnerDeletedTerm = term);
        this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_LIST, (response) => {
            const data = response.data;
            if (this.activThread && isArray(data)) {
                const filtredThread = data.find(thr => String(thr.id) === String(this.activThread.id));
                if (filtredThread) {
                    this.activThread = filtredThread;
                }
            }
        });

        this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_LIST_NEXT, (response) => {
            const data = response.data;
            if (this.activThread && isArray(data)) {
                const filtredThread = data.find(thr => String(thr.id) === String(this.activThread.id));
                if (filtredThread) {
                    this.activThread = filtredThread;
                }
            }
        });

        this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_UPDATE, (data) => {
            if (String(data.id) === String(this.activThread.id)) {
                this.activThread = data;
                // TODO check if need to update viewed message
                // this.messagingService.setLastMsgViewedInStorage(this.activThread);
            }
        });

        this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_MESSAGE_NEW, (message) => {
            if (this.activThread && String(message.thread) === String(this.activThread.id)) {
                message['dateAuthorLabel'] = this.computeDateAuthorLabel(message);
                this.messages.push(message);
                this.updateGroupTag();
                this.messagingService.updateLastViewedMessageByUser(String(this.activThread.id), String(message.created));
            }
        });

        this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_MESSAGE_DELETE, (messageId) => {
            if (isArray(this.messages)) {
                this.messages = this.messages.filter(msg => String(msg.id) !== String(messageId));
            }
        });

        this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_EDIT, (thread) => {
            if (this.activThread && thread.id === this.activThread.id) {
                this.activThread = Object.assign({}, thread);
            }
        });

        this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_ACTIVATED, (thread) => {
            this.activThread = Object.assign({}, thread);
            this.nextPageUrl = undefined;
            // TODO check whether we need to update last viewed
            // this.messagingService.setLastMsgViewedInStorage(this.activThread);
        });

        this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_MESSAGE_LIST, (messages) => {
            this.messages = messages;
            this.messages.forEach(msg => {
                msg['dateAuthorLabel'] = this.computeDateAuthorLabel(msg);
            });
            this.scrollDown();
            if (isArray(this.messages) && this.messages.length > 0) {
                const lastMessageTimestamp = this.messages.map(msg => parseInt(msg.created, 10)).sort((a, b) => b - a)[0];
                this.messagingService.updateLastViewedMessageByUser(String(this.activThread.id), String(lastMessageTimestamp));
            }
        });

        this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_MESSAGE_LIST_NEXT, (_messages) => {
            const lastDisplayedMessageId = document.querySelector('.msg-thread-message-container').getAttribute('data-message-id');
            _messages.forEach(msg => {
                msg['dateAuthorLabel'] = this.computeDateAuthorLabel(msg);
            });
            this.messages = this.messages.concat(_messages);
            setTimeout(() => {
                document.querySelector('[data-message-id="' + lastDisplayedMessageId + '"]').scrollIntoView();
            }, 20);
        });

        this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_MESSAGE_LIST_NEXT_HREF, (_nextPageUrl) => {
            this.nextPageUrl = _nextPageUrl;
        });
    }
    /**
     * 
     * @param message 
     */
    isFirstOfGroup(message: any): boolean {
        return message['group_role'] === 'first-of-group';
    }
    /**
     * 
     * @param message 
     */
    isLastOfGroup(message: any): boolean {
        return message['group_role'] === 'last-of-group';
    }
    /**
     * 
     * @param message 
     */
    isMiddleOfGroup(message: any): boolean {
        return message['group_role'] === 'middle-of-group';
    }
    /**
     * 
     * @param message 
     */
    isInAnyGroup(message: any): boolean {
        return message['group_role'] === 'none';
    }
    /**
     * 
     */
    get isThreadActivated(): boolean {
        return this.activThread !== undefined;
    }
    /**
     * 
     */
    sendMessage(): void {
        if (typeof this.newMessage === 'string' && this.newMessage.length > 0) {
            this.messagingService.newMessage(this.newMessage, this.activThread.id)
                .subscribe((msg) => {
                    this.newMessage = '';
                    this.strechMessageZoneDown({target: this.zonemessage});
                    this.scrollDown();
                });
        }
    }
    /**
     * 
     */
    loadMoreMessages(): void {
        this.messagingService.loadMessages(undefined, this.nextPageUrl).subscribe((data) => {});
    }
    /**
     * 
     */
    nextPageExists(): boolean {
        return typeof this.nextPageUrl === 'string' && this.nextPageUrl.length > 0;
    }
    /**
     * 
     */
    strechMessageZoneUp($event: any): void {
        if ($event.target.scrollHeight > $event.target.clientHeight && this.messageZoneLinesNumber < 10) {
            this.messageZoneLinesNumber += 1;
            setTimeout(() => this.strechMessageZoneUp($event), 10);
        }
    }
    /**
     * 
     */
    strechMessageZoneDown($event: any): void {
        if ($event.target.scrollHeight === $event.target.clientHeight && this.messageZoneLinesNumber > 1) {
            this.messageZoneLinesNumber -= 1;
            setTimeout(() => this.strechMessageZoneDown($event), 10);
        } else {
            this.strechMessageZoneUp($event);
        }
    }
    /**
     * 
     */
    myMessage(message: any): boolean {
        return this.messagingService.isUserMessageAuthor(message);
    }
    /**
     * 
     */
    private computeDateAuthorLabel(message: any): string {
        const dateString = this.datePipe.transform(message.created * 1000, 'dd/MM/yyyy');
        const timeString = this.datePipe.transform(message.created * 1000, 'HH:mm');
        return (this.myMessage(message) ?
                          this.messaging_me : message.author ? message.author.label + ', ' : this.learnerDeletedTerm + ' ')   +
                          this.messaging_the + ' ' + dateString + ' ' + this.messaging_at + ' ' + timeString;
    }
    /**
     * 
     */
    modifyThread(): void {
        this.messagingService.publishEvent(DOMAIN_MESSAGING, EVENT_THREAD_EDIT_OPEN_MODAL, this.activThread.id);
    }
    /**
     * 
     */
    canDelete(): boolean {
        if (
            this.activThread.archived === false &&
            (this.messagingService.isTeacher || 
            this.messagingService.isAuthor(this.activThread))
        ) {
            return true;
        } else {
            return false;
        }
    }
    /**
     * 
     */
    canDeleteMessage(): boolean {
        return this.messagingService.isTeacher && this.activThread.archived === false;
    }
    /**
     * 
     */
    deleteThread(): void {
        if (this.canDelete()) {
            this.dialogRef = this.dialog.open(MessagingConfirmComponent);
            this.dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                    this.messagingService.deleteThread(this.activThread.id)
                    .subscribe(
                        s => {
                            this.activThread = undefined;
                            this.messages = [];
                        },
                        e => {}
                    );
                }
            });
        }
    }
    /**
     * 
     */
    deleteMessage(message: any): void {
        this.messagingService.deleteMessage(message.id);
    }
    /**
     * 
     */
    archiveThread(): void {
        this.messagingService.archiveUnarchiveThread(this.activThread.id, true);
    }
    /**
     * 
     */
    unarchiveThread(): void {
        this.messagingService.archiveUnarchiveThread(this.activThread.id, false); 
    }
    /**
     * 
     */
    scrollDown(): void {
        setTimeout(() => {
            if (this.messagesListContainer) {
                this.messagesListContainer.nativeElement.scrollTop = this.messagesListContainer.nativeElement.scrollHeight;
            }
        }, 10);
    }
    /**
     * 
     */
    canAddNewMessage(): boolean {
        return !this.messagingService.isArchived(this.activThread);
    }
    /**
     * 
     */
    canEditThread(): boolean {
        return this.activThread.archived === false &&
            (this.messagingService.isTeacher || this.messagingService.isAuthor(this.activThread));
    }
    /**
     * 
     */
    canArchiveThread(): boolean {
        return this.messagingService.isTeacher && this.activThread.archived === false;
    }
    /**
     * 
     */
    canUnarchiveThread(): boolean {
        return this.messagingService.isTeacher && this.activThread.archived === true;
    }
    /**
     * 
     */
    updateGroupTag(): void {
        this.messages.forEach((msg, index) => {
            const previous = this.messages[index - 1];
            const next = this.messages[index + 1];
            if (!this.sameGroup(msg, previous) && this.sameGroup(msg, next)) {
                msg['group_role'] = 'first-of-group';
            } else if (this.sameGroup(msg, previous) && !this.sameGroup(msg, next)) {
                msg['group_role'] = 'last-of-group';
            } else if (this.sameGroup(msg, previous) && this.sameGroup(msg, next)) {
                msg['group_role'] = 'middle-of-group';
            } else {
                msg['group_role'] = 'none';
            }
        });
    }
    /**
     * 
     */
    sameGroup(msg1, msg2): boolean {
        return (!msg1 || !msg2) ? false : String(msg1.author.id) === String(msg2.author.id);
    }
}
