export const cards = {
    cardDisplay: {
        showButtonThemeLesson: false,
        showButtonBookmarks: {
            themes: false,
            lesson: false,
            model: false,
            community: false,
            image: false,
            video: false,
            videoUrl: false,
            audio: false,
            document: false,
            url: false,
        },
        isTextButton: false,
    },
    cardDefaultHeader: '/assets/mindmath/images/backgrounds/header.png',
    cardFields: {
        default: {
            lesson: ['titleIcon', 'title', 'menu', 'description', 'educationalLevel', 'keywords', 'difficulty', 'playMetadata'],
            model: ['titleIcon', 'title', 'menu', 'description', 'educationalLevel', 'keywords', 'difficulty', 'playMetadata'],
            community: ['titleIcon', 'title', 'menu', 'description', 'educationalLevel', 'keywords', 'difficulty', 'playMetadata'],
            image: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            video: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            videoUrl: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            audio: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            document: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            url: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
        }
    },
    cardPlayOpenMenu: false,
    menuLinks: {
        lesson: {
            administrator: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'assign', 'duplicate'],
            default: ['assign']
        },
        model: {
            administrator: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'duplicate'],
            default: ['assign']
        }
    },
    difficultyDisplaySteps: 3
};
