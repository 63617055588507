
import {tap, take} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {Subject} from 'rxjs';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {DataEntity} from 'octopus-connect';
import {NavigateService} from '@modules/activities/core/navigate.service';

@Component({
    selector: 'app-multi',
    templateUrl: './multi.component.html'
})
export class MultiComponent implements OnInit, OnDestroy {

    private subLessonId: string;
    private unsubscribeInTakeUntil = new Subject();

    constructor(
        private activatedRoute: ActivatedRoute,
        private activitiesService: ActivitiesService,
        private lessonsService: LessonsService,
        private navigateService: NavigateService
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params) => {
            this.subLessonId = params['subLessonId'];
            this.initialize();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private initialize(): void {
        this.reset();
        const activities = [];
        this.lessonsService.getLessonObs(this.subLessonId).pipe(
            take(1),
            tap(subLesson => {
                subLesson.attributes.reference.map(activityReference =>
                    this.activitiesService.activityEntities.filter(activity =>
                        activity.id === activityReference.id
                    ).map((activity: DataEntity) => {
                            if (this.activitiesService.typeActivitiesToSkip(activity)) {
                                activity.attributes.metadatas.title = subLesson.get('metadatas').title;
                                activities.push(activity);
                            }
                        }
                    )
                );
            })).subscribe(() => {
                this.lessonsService.editSubLessonContentEdited = activities;
                this.playFirstActivity();
            });
    }

    private reset(): void {
        this.lessonsService.editSubLessonContentEdited = [];
        this.lessonsService.editCurrentActivityInSubLesson = null;
    }

    public canlaunchPrevious(): boolean {
        return this.indexInSublesson > 0;
    }

    public canlaunchNext(): boolean {
        return this.indexInSublesson < this.lessonsService.subLessonContentEdited.length - 1;
    }

    public launchPreviousActivity(): void {
        if (this.canlaunchPrevious() === false) {
            return;
        }
        let currentActivityIndex = +this.indexInSublesson;
        currentActivityIndex--;
        this.lessonsService.editCurrentActivityInSubLesson = this.lessonsService.subLessonContentEdited[currentActivityIndex];
        this.navigateService.navigateHere(this.lessonsService.subLessonContentEdited[this.indexInSublesson], false, '../..');
    }

    public launchNextActivity(): void {
        if (this.canlaunchNext() === false) {
            return;
        }
        let currentActivityIndex = +this.indexInSublesson;
        currentActivityIndex++;
        this.lessonsService.editCurrentActivityInSubLesson = this.lessonsService.subLessonContentEdited[currentActivityIndex];
        this.navigateService.navigateHere(this.lessonsService.subLessonContentEdited[currentActivityIndex], false, '../..');
    }

    public displayUserActionButton(UserActionButtonName: string): boolean {
        if (this.lessonsService.settings['actionButtonsInMultiMatrix'][this.getCurrentActivityType()]) {
            return this.lessonsService.settings['actionButtonsInMultiMatrix'][this.getCurrentActivityType()].includes(UserActionButtonName);
        }
        return this.lessonsService.settings['actionButtonsInMultiMatrix']['default'].includes(UserActionButtonName);
    }

    public get indexInSublesson():  number {
        if (this.lessonsService.currentActivityInSubLesson) {
            return this.lessonsService.subLessonContentEdited
                .findIndex((activity) => +activity.id === +this.lessonsService.currentActivityInSubLesson.id);
        }
        return null;
    }

    public getCurrentActivityType(): string{
        return this.indexInSublesson !== null && this.lessonsService.subLessonContentEdited[this.indexInSublesson] ?
            this.lessonsService.subLessonContentEdited[this.indexInSublesson].attributes.metadatas.typology.label : '';
    }

    private playFirstActivity(): void {
        const currentActivityIndex = +this.indexInSublesson;
        this.lessonsService.editCurrentActivityInSubLesson = this.lessonsService.subLessonContentEdited[currentActivityIndex];
        this.navigateService.navigateHere(this.lessonsService.subLessonContentEdited[currentActivityIndex], false, '.');
    }
}
