import {Component, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {brand} from '../../../../../settings';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {ActivitiesService} from '@modules/activities/core/activities.service';

@Component({
    selector: 'fuse-app-lessons-list',
    templateUrl: './lessons-list.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class LessonsListComponent {

    public brand: string = brand;

    public creatorsRolesFilter: number[] = this.lessonsService.getAllowedRoleIdsForModelsCreation();

    constructor(public lessonsService: LessonsService,
                private activitiesService: ActivitiesService) {
        /**
         * if we change the current lesson, need to init the array of title and wording
         * @type {any[]}
         */
        this.activitiesService.metadatasUsedForOverride = [];
        // when come back after leave component set the active tab to the default value
        this.lessonsService.selectedTabIndex = this.lessonsService.settings.selectedTabIndex;
    }

}
