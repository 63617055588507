import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ChaptersSelectionComponent} from 'fuse-core/components/chapters-selection/chapters-selection.component';
import {FormControl, FormGroup} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Group} from '@modules/dashboard/core/definitions';
import {MatChipInputEvent} from '@angular/material/chips';
import {Workgroup} from '@modules/groups-management/core/definitions';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '../../../models/rules';

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss']
})

export class EditUserComponent implements OnInit {
    @ViewChild('classesChipInput') classesChipInput: ElementRef;
    @ViewChild('workgroupsChipInput') workgroupsChipInput: ElementRef;

    public form: FormGroup;
    public controls: {
        name: FormControl,
        mail: FormControl,
        classes: FormControl,
        workgroups: FormControl,
        password: FormControl,
        'teacher-rights': FormControl,
        'admin-rights': FormControl
    };
    public inputAddOnBlur = true;

    public classesChips = [];
    public inputSelectable = true;
    public inputRemovable = true;
    public classesFilteredChips;

    public workgroupsChips = [];
    public workgroupsFilteredChips;
    public separatorKeysCodes = [ENTER, COMMA];


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { user: { [key: string]: any }, classes: Group[], workgroups: Workgroup[], assignOnly: boolean },
        public dialogRef: MatDialogRef<ChaptersSelectionComponent>,
        private authorization: AuthorizationService
    ) {
    }

    ngOnInit(): void {
        this.initialise();
    }

    private initialise(): void {
        this.initFormControls();
        this.initFormControlSubscribes();
        this.classesChips = this.data.classes.slice()
            .filter((classItem) => this.data.user && this.data.user.og_user_node
                .some((classAssociated) => classAssociated.type === 'Class' && +classAssociated.id === +classItem.id));
        this.workgroupsChips = this.data.workgroups.slice()
            .filter((workgroupItem) => this.data.user && this.data.user.og_user_node
                .some((workgroupAssociated) => workgroupAssociated.type === 'Group' && +workgroupAssociated.id === +workgroupItem.id));
        this.classesFilteredChips = this.controls.classes.valueChanges.pipe(
            startWith(null),
            map((chip: any | null) => chip && typeof chip === 'string' ?
                this.distinctFilterForClasses(this.filterClasses(chip)) :
                this.distinctFilterForClasses(this.data.classes.slice())
            )
        );
        this.workgroupsFilteredChips = this.controls.workgroups.valueChanges.pipe(
            startWith(null),
            map((chip: any | null) => chip && typeof chip === 'string' ?
                this.distinctFilterForWorkgroups(this.filterWorkgroups(chip)) :
                this.distinctFilterForWorkgroups(this.data.workgroups.slice())
            )
        );
    }

    private filterClasses(classNameEntered: string): any[] {
        return this.data.classes.filter((classItem) =>
            classItem.groupname.toLowerCase().includes(classNameEntered.toLowerCase()));
    }

    private filterWorkgroups(workgroupNameEntered: string): any[] {
        return this.data.workgroups.filter((workgroupItem) =>
            workgroupItem.workgroupname.toLowerCase().includes(workgroupNameEntered.toLowerCase()));
    }

    private distinctFilterForClasses(classes): Group[] {
        return classes.filter(item => !this.classesChips.find((classItem) => classItem.id === item.id));
    }

    private distinctFilterForWorkgroups(workgroups): Group[] {
        return workgroups.filter(item => !this.workgroupsChips.find((workgroup) => workgroup.id === item.id));
    }

    public close(): void {
        this.dialogRef.close();
    }

    public save(): void {
        const values: { id?: number, name: string, mail: string, groups: any[], roles: any, password?: string } =
            {
                name: this.controls.name.value,
                mail: this.controls.mail.value,
                groups: [...this.classesChips, ...this.workgroupsChips],
                roles: {}
            };
        if (this.formControlValueByField('teacher-rights')) {
            values.roles['4'] = 'educator';
        }
        if (this.formControlValueByField('admin-rights')) {
            values.roles['5'] = 'manager';
        }
        if (!this.isNewUser) {
            values.id = this.data.user.id;
        } else {
            values.password = this.controls.password.value;
        }
        this.dialogRef.close(values);
    }

    private initFormControlSubscribes(): void {
        // tslint:disable-next-line:forin
        for (const field in this.controls) {
            this.controls[field].valueChanges.pipe(
                // wip ici verifier si la valeur corresponds au format que l'on attends (cf formErrors)
            ).subscribe();
        }
    }

    public add(event: MatChipInputEvent, type): void {
        if (event && event.value) {
            if (type === 'classes') {
                const classSelected = this.data.classes.slice()
                        .find((classItem) => classItem.groupname.toLowerCase() === event.value.toLowerCase())
                    || this.distinctFilterForClasses(this.filterClasses(event.value))[0];

                if (classSelected && !this.classesChips.some((classItem) => classItem.id === classSelected.id)) {
                    this.classesChips.push(classSelected);
                    // Clear the input value
                    event.input.value = '';
                }
                this.controls.classes.setValue(null);
                this.classesChipInput.nativeElement.blur();
            } else {
                const workgroupSelected = this.data.workgroups.slice()
                        .find((workgroupItem) => workgroupItem.workgroupname.toLowerCase() === event.value.toLowerCase())
                    || this.distinctFilterForWorkgroups(this.filterWorkgroups(event.value))[0];

                if (workgroupSelected && !this.workgroupsChips.some((workgroupItem) => workgroupItem.id === workgroupSelected.id)) {
                    this.workgroupsChips.push(workgroupSelected);
                    event.input.value = '';
                }
                this.controls.workgroups.setValue(null);
                this.workgroupsChipInput.nativeElement.blur();
            }

        }
    }

    public classSelected(event: MatAutocompleteSelectedEvent): void {
        if (!this.classesChips.some((classItem) => classItem.id === event.option.value.id)) {
            this.classesChips.push(event.option.value);
        }
        this.controls.classes.setValue(null);
        this.classesChipInput.nativeElement.value = '';
        this.classesChipInput.nativeElement.blur();
    }

    public workgroupSelected(event: MatAutocompleteSelectedEvent): void {
        if (!this.workgroupsChips.some((workgroupItem) => workgroupItem.id === event.option.value.id)) {
            this.workgroupsChips.push(event.option.value);
        }
        this.controls.workgroups.setValue(null);
        this.workgroupsChipInput.nativeElement.value = '';
    }

    public removeClassChip(classChip: any): void {
        const index = this.classesChips.findIndex((classItem) => classItem.id === classChip.id);
        if (index >= 0) {
            this.classesChips.splice(index, 1);
            this.controls.classes.setValue(null);
        }
    }

    public removeWorkgroupChip(workgroupChip: any): void {
        const index = this.workgroupsChips.findIndex((workgroupItem) => workgroupItem.id === workgroupChip.id);
        if (index >= 0) {
            this.workgroupsChips.splice(index, 1);
            this.controls.workgroups.setValue(null);
        }
    }

    private initFormControls(): void {
        this.controls = {
            name: new FormControl({value: this.data.user && this.data.user.name || '', disabled: !this.isNewUser}),
            mail: new FormControl({value: this.data.user && this.data.user.mail || '', disabled: !this.isNewUser}),
            classes: new FormControl([]),
            workgroups: new FormControl([]),
            password: new FormControl(''),
            'teacher-rights': new FormControl(this.data.user && this.getRoleInInstitution(this.data.user.rolesInInstitution, 'educator') || null),
            'admin-rights': new FormControl(this.data.user && this.getRoleInInstitution(this.data.user.rolesInInstitution, 'manager') || null),
        };
    }

    private getRoleInInstitution(roles: { [key: string]: string }, role): boolean {
        let isUserHasRole = false;
        if (roles) {
            for (const field in roles) {
                if (roles[field] === role) {
                    isUserHasRole = true;
                }
            }
        }
        return isUserHasRole;
    }

    public toggle(event: MatSlideToggleChange, role: string): void {
        if (role === 'educator') {
            this.controls['teacher-rights'].setValue(event.checked);
        }
        if (role === 'admin') {
            this.controls['admin-rights'].setValue(event.checked);
        }
    }

    public formControlValueByField(field: string): any {
        return this.controls && this.controls[field] && this.controls[field].value || null;
    }

    public get assignOnly(): boolean {
        return this.data.assignOnly;
    }

    public get isNewUser(): boolean {
        return !this.data.user;
    }

    public canEditEducatorRights(): boolean {
        return this.authorization.currentUserCan(SyncRules.EditTrainer);
    }

    public canEditInstitutionManagerRights(): boolean {
        return this.authorization.currentUserCan(SyncRules.EditTrainerInstitutionManagerTrainerRights);
    }

    public isAllowedToAssignToWorkGroup(): boolean {
        return this.authorization.currentUserCan(SyncRules.BeAttachedToWorkgroup);

    }

    public isAllowedToAssignToGroup(): boolean {
        return this.authorization.currentUserCan(SyncRules.BeAttachedToGroup);

    }
}
