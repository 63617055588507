import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {GraphConfig} from 'fuse-core/components/graph/graph-mixed/graph.config';
import {GraphUbolinoService} from '../../services/graph-ubolino.service';
import {delay, filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ProgressData, ProgressDotDetail} from '@modules/graph-ubolino/core/model/progress-data';
import {Label} from 'ng2-charts/lib/base-chart.directive';
import {CustomTooltipModalContentSettings} from 'fuse-core/components/graph/graph-details-modal/custom-tooltip.setting';
import * as moment from 'moment';
import {rgbOpacity} from '../../../../../shared/utils';
import * as _ from 'lodash';
import {GraphUbolinoTranslateService} from '@modules/graph-ubolino/core/services/graph-ubolino-translate.service';
import {FakeStepperOptions} from '@modules/graph-ubolino/core/component/fake-stepper/fake-stepper.component';

const simpleColor = 'rgb(78,117,255)';
// const firstLayerColor = 'rgb(144,144,144)';
// const secondLayerColor = 'rgb(78,117,255)';
// const thirdLayerColor = 'rgb(252,183,49)';
// const fourthLayerColor = 'rgb(104,33,122)';

@Component({
    selector: 'app-progress-graph',
    templateUrl: './progress-graph.component.html',
})
export class ProgressGraphComponent implements OnInit, OnDestroy {

    public graphConfigObs: ReplaySubject<GraphConfig> = new ReplaySubject<GraphConfig>(1);
    public isReady = false;
    public challenge: {
        averageScore: number;
        count: number;
        lastScore: number;
        lastDate: string;
    } = null;
    /**
     * Cache le loader si le service n'est pas encore pret
     */
    public showLoader = false;
    private unsubscribeInTakeUntil = new Subject();
    /**
     * Aide a déterminer le temps entre les steps du loader
     * @private
     */
    private loaderShouldEnd = false;
    /**
     * Défini les steps du loader, le temps entre les stepsn, etc.
     */
    public loaderOptions: Partial<FakeStepperOptions> = {
        loop: false,
        interval: stateIndex => (this.loaderShouldEnd === false || stateIndex <= 2 ? 4000 : 1000),
        states: ['fake_stepper.state_1', 'fake_stepper.state_2', 'fake_stepper.state_3'],
        onEnd: new ReplaySubject(1)
    };
    private fakeData: {
        questionSet: string,
        date: Date,
        goodOnes: number,
        goodOnesPercent: number,
        badOnes: number,
        total: number,
        duration: number,
        variation: string
    } [];

    constructor (private graphUbolinoService: GraphUbolinoService, public graphUbolinoTranslateService: GraphUbolinoTranslateService) {
    }

    ngOnInit (): void {

        this.graphUbolinoService.isReady
            .pipe(
                filter(isReady => !!isReady),
                tap(() => this.showLoader = true),
                tap(() => this.graphUbolinoService.graphDataArePending.subscribe(() => this.isReady = false)),
                mergeMap(() => this.graphUbolinoService.getProgressGraphData()),
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data) => {
            this.loaderShouldEnd = true;

            this.generateSimpleGraph(data);

            // Ne retirez pas de delay, sinon le timing foire et ça ne marche pas (je pense que le graphDataArePending est trigger trop tot)
            this.loaderOptions.onEnd.pipe(take(1), delay(750)).subscribe(() => this.isReady = true);
        });

    }

    ngOnDestroy (): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private generateSimpleGraph (progressData: ProgressData): void {
        const chartLabels: Label[] = [];
        const dots: number[] = [];
        const dotsDetails: CustomTooltipModalContentSettings[] = [];
        this.fakeData = [];

        progressData.entities[0].get('qs').forEach((dot, index) => {
            dot.date = +dot.date * 1000;

            chartLabels.push(dot.label);
            dots.push(dot.answers.goodAnswersPercent);
            dotsDetails.push({
                header: `<div>${dot.label/*exerciseName*/}\n</div><div>${moment(dot.date/*, 'X'*/).format('DD/MM/YYYY')}</div>`,
                content: this.generateModalContentHtml(dot, index)
            });
        });

        this.graphConfigObs.next({
            chartLabels,
            chartColors: [],
            chartConfig: {
                scales: {
                    yAxes: [{
                        ticks: {beginAtZero: true, max: 100, min: 0},
                        offset: false
                    }],
                    xAxes: [{
                        offset: false
                    }]
                },
                legend: {
                    align: 'start',
                    position: 'bottom',
                    onClick: (event: MouseEvent) => event.stopPropagation()
                }
            },
            chartData: [{
                data: dots,
                type: 'line',
                fill: true,
                label: this.graphUbolinoTranslateService.get('generic.pourcent_success'),
                backgroundColor: rgbOpacity(simpleColor, '0.3'),
                borderColor: simpleColor,
            }],
            modalContent: [dotsDetails],
        });
    }

    private generateModalContentHtml (currentDot/*: ProgressDotDetail*/, index: number): string {
        return `<ul>
    <li>${this.graphUbolinoTranslateService.get('graph_ubolino.progress_duration')} ${moment.duration(currentDot.duration, 'seconds').format('mm[m]ss[s]')}</li>
    <li>${this.graphUbolinoTranslateService.get('graph_ubolino.progress_good_ones')} ${currentDot.answers.goodAnswers}</li>
    <li>${this.graphUbolinoTranslateService.get('graph_ubolino.progress_bad_ones')} ${currentDot.answers.badAnswers}</li>
    <!--<li>${this.graphUbolinoTranslateService.get('graph_ubolino.progress_variation')} ${'20'}</li>-->
</ul>`;
    }
}
