
import {map, takeUntil} from 'rxjs/operators';
import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {NotificationsService} from '@modules/notification/core/notifications.service';
import {fuseAnimations} from '@fuse/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {NotificationInterface} from '@modules/notification/core/notification.interface';
import {NotificationDefinition} from '@modules/notification';
import {Subject} from "rxjs";

@Component({
    selector: 'app-notif-list',
    templateUrl: './notif-list.component.html',
    styleUrls: ['./notif-list.component.scss'],
    animations : fuseAnimations
})
export class NotifListComponent implements OnInit, OnDestroy {

    private notifs: any;
    public dataSource = new MatTableDataSource<NotificationInterface>();
    public displayedColumns: string[];
    public selected: NotificationInterface;

    // https://github.com/angular/material2/issues/10205
    private paginator: MatPaginator;
    private sort: MatSort;
    private unsubscribeInTakeUntil = new Subject();

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.setDataSourceAttributes();
    }

    @ViewChild(MatPaginator, { static: true }) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    constructor(
        public notificationsService: NotificationsService,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.notificationsService.onFilesChanged.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(notifs => {
                this.notifs = notifs;
            });
        this.notificationsService.onFileSelected.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(selected => {
                this.selected = selected;
            });

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('myNotifications').pipe(
            map((entities: DataEntity[]) => {
                return entities.filter(entity => {
                    return this.notificationsService.isNotificationTypeRegistered(entity);
                }).map(entity => {

                    const definition: NotificationDefinition = this.notificationsService.getDefinition(entity.get('type'));

                    const tData: Object = (typeof entity.get('data') === 'object') ? entity.get('data') : JSON.parse(entity.get('data'));

                    if (definition) {
                        return <NotificationInterface>{
                            notificationType: entity.get('type'),
                            type: definition.icon,
                            read: entity.get('read'),
                            label: this.notificationsService.getTranslatedText(entity.get('type'), tData),
                            data: tData,
                            date: entity.get('created'),
                            id: entity.id,
                            originalEntity: entity,
                            deletable: definition.deletable !== false
                        };
                    }
                });
            }),
            takeUntil(this.unsubscribeInTakeUntil),)
            .subscribe((notifs: NotificationInterface[]) => {
                this.displayedColumns = ['type', 'read', 'label', 'date', 'delete'];
                this.dataSource.data = notifs;
            });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private setDataSourceAttributes(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    public onSelect(row: NotificationInterface): void {
        this.notificationsService.onFileSelected.next(row);

        row.originalEntity.set('read', true);
        row.originalEntity.save();

        this.notificationsService.doRouting(row.originalEntity);
    }

    public deleteNotification(row: NotificationInterface, evt: MouseEvent): void {
        row.originalEntity.remove();
        evt.stopPropagation();
        evt.preventDefault();
    }
}
