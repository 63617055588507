import { Injectable } from '@angular/core';
import {CollectionOptionsInterface} from 'octopus-connect/lib/models/collection-options.interface';
import {observable, Observable, of} from 'rxjs';
import {CollectionPaginator, DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {map, mapTo, mergeMap, take, tap} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';
import {User, UserSearchDataEntity} from '@modules/groups-management/core/models/user-search-data-entity';
import {InstitutionGroupService} from '@modules/groups-management/core/services/institution-group.service';
import {
    InstitutionDataCollection,
    InstitutionDataEntity,
} from '@modules/groups-management/core/definitions';
import {Moment} from 'moment';
import * as _ from 'lodash';
import {LicensesService} from '@modules/groups-management/core/services/licenses.service';

export enum LicenseTypes {
    class = 'Class',
    institution = 'Institution',
    free = 'Free'
}
export type LicenseTypeKey = keyof typeof LicenseTypes;

export const LICENSE_TYPES_KEYS = new Map<LicenseTypes, LicenseTypeKey>(
    Object.entries(LicenseTypes).map(([key, value]: [LicenseTypeKey, LicenseTypes]) => [value, key])
);

@Injectable({
    providedIn: 'root'
})
export class LicenseManagementService {
    public institutions: InstitutionDataEntity[] = [];
    public usersEntities: UserSearchDataEntity[];
    public licenseTypes = [LICENSE_TYPES_KEYS.get(LicenseTypes.class), LICENSE_TYPES_KEYS.get(LicenseTypes.institution), LICENSE_TYPES_KEYS.get(LicenseTypes.free)];

    public roles: {[key: string]: number};
    constructor(private communicationCenter: CommunicationCenterService,
                private octopusConnect: OctopusConnectService,
                private institutionsService: InstitutionGroupService,
                private licenseService: LicensesService) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('roles')
            .pipe(tap((roles:  {[key: string]: number}) => this.roles = roles))
            .subscribe();
    }

    /**
     * récupère tous les utilisateurs
     * @param filterOptions
     */
    public getUsersAndPaginator(filterOptions: CollectionOptionsInterface): Observable<{entities: UserSearchDataEntity[], paginator: CollectionPaginator}> {
        if (!filterOptions.filter.roles) {
            filterOptions.filter.roles = [this.roles.trainer, this.roles.director];
        }
        const activitiesPaginated = this.octopusConnect.paginatedLoadCollection('user_search', filterOptions);
        const activitiesPaginatedObs = activitiesPaginated.collectionObservable;
        return activitiesPaginatedObs.pipe(
            take(1),
            map(collection => collection.entities as UserSearchDataEntity[]),
            tap(entities => this.usersEntities = entities),
            map((entities: UserSearchDataEntity[]) => ({entities, paginator: activitiesPaginated.paginator}))
        );
    }

    /**
     * recupère toutes les institutions
     * @param userEntities
     */
    public loadInstitutions(userEntities: UserSearchDataEntity[]): Observable<DataCollection> {
        const institutionsId = [];
        userEntities.forEach((user) => {
            const institutions = user.get('og_user_node').filter((group) => group.type === 'Institution');
            if (institutions.length) {
                institutionsId.push(...institutions.map((institution) => institution.id)
                    .filter((institutionId) => !institutionsId.includes(institutionId)));
            }
        });

        return this.institutionsService.getInstitutionGroupInUserFromServer(institutionsId.length ? {id: institutionsId} : null)
            .pipe(
                tap((institutionsCollection: InstitutionDataCollection) => {
                    if (institutionsCollection && institutionsCollection.entities) {
                        this.institutions = institutionsCollection.entities;
                    }
                })
            );
    }

    /**
     * retourne les entites d'utilisateurs sous forme de interface User
     * @param userEntities
     */
    public associateUserEntitiesWithUserInterface(userEntities: UserSearchDataEntity[]): User[] {
        return userEntities.map((userEntity: DataEntity) => {
            return  {
                id: +userEntity.id,
                name: userEntity.get('name'),
                mail: userEntity.get('mail'),
                og_user_node: userEntity.get('og_user_node')
                    .filter((group) => group.type === 'Institution'),
                roles: userEntity.get('roles'),
                rolesInInstitution: null,
                status: userEntity.get('status')
            };
        });
    }

    /**
     * chargement de toutes les institutions
     */
    loadAllInstitutions(): Observable<InstitutionDataEntity[]> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionEntitiesList');
    }

    /**
     * edition d'une license
     * @param license
     * @param change
     */
    public patchLicense(license: { id: string; startDate: number; endDate: number; type: string }, change: {type: string, endDate: number}): Observable<DataEntity> {
        return this.licenseService.patchLicense(license, change);
    }

    /**
     * creation d'une nouvelle institution
     * @param user
     * @param institution
     */
    public createInstitution(user: User, institution: {label: string}): Observable<InstitutionDataEntity> {
        const data = {
            uid: user.id.toString(),
            type: '52',
            label: institution.label
        };
        return this.institutionsService.createInstitution(data);
    }

    /**
     * creation d'une nouvelle license
     * @param param
     */
    public createLicense(param: { endDate: number; type: string, userId: string }): Observable<DataEntity> {
        return this.licenseService.createLicense(param);
    }

    /**
     * sauvegarde del'edition d'un utilisateur
     * @param user
     * @param params
     */
    public editUser(user: User, params: {institution?: InstitutionDataEntity, role?: number }): Observable<DataEntity> {
        const userEntityForInstitution: DataEntity = _.cloneDeep(this.usersEntities.find((u) => +u.id === +user.id));
        const userEntityForRole: DataEntity = _.cloneDeep(this.usersEntities.find((u) => +u.id === +user.id));
        // 2 endpoint distinct donc deux sauvegardes à faire possiblement role et/ou institution
        if (params.institution) {
            userEntityForInstitution.type = 'users';
            userEntityForInstitution.set('groups', [params.institution, ...userEntityForInstitution.get('og_user_node')
                .filter((group) => group.type !== 'Institution' && +group.id !== +params.institution.id)]
                .map((group) => group.id));
        }
        if (params.role) {
            userEntityForRole.type = 'user-role-update';
            userEntityForRole.set('roles', ['2', this.roles.director.toString()]);
        }

        if (params.institution && params.role) {
            return userEntityForInstitution.save(true).pipe(
                mergeMap(() => userEntityForRole.save(true))
            );
        }
        if (params.institution) {
            return userEntityForInstitution.save(true);
        }
        if (params.role) {
            return userEntityForRole.save(true);
        }
        return of(null);
    }
    /**
     * sauvegarde de la creation d'un utilisateur
     * @param user
     * @param params
     */
    public createUser(userData: {
        user: User,
        name: string,
        mail: string,
        password?: string,
        institution?: InstitutionDataEntity | {label: string},
        license: string,
        endDate: number}): Observable<DataEntity> {
        const data: {label: string, email: string, groups?: number[], role?: number, password: string} = {
          label: userData.name,
          email: userData.mail,
          password: userData.password,
          role: this.roles.trainer,
        };
        if (userData.license === 'institution' && userData.institution && !userData.institution['id']) {
            data.role = this.roles.director;
        }
        return this.octopusConnect.createEntity('user-registration', data).pipe(
            mergeMap((userEntity: DataEntity) => {
                const user: User = { // for create institution, need data with "User" Interface
                    id: +userEntity.id,
                    name: userData.name,
                    mail: userData.mail,
                };
                if (userData.institution && !userData.institution['id']) {
                    return this.createInstitution(user, userData.institution as {label: string}).pipe(
                        mergeMap((institutionEntity) => {
                            userEntity.set('groups', [institutionEntity.id]);
                            userEntity.type = 'users';
                            return userEntity.save(true);
                        }),
                        mergeMap((userEntity: DataEntity) => {
                            if (userData.license === 'institution') {
                                userEntity.type = 'user-role-update';
                                userEntity.set('roles', ['2', this.roles.director.toString()]);
                                return userEntity.save(true);
                            }
                            return of(userEntity);
                        }),
                        mergeMap((userEntity: DataEntity) => this.createLicense({
                            endDate: userData.endDate,
                            type: userData.license,
                            userId: userEntity.id.toString()
                        })),
                        mapTo(userEntity)
                    );
                } else {
                    userEntity.type = 'users';
                    userEntity.set('groups', [userData.institution['id']]);
                    return userEntity.save(true);
                }
            })
        );

        return of(null);
    }

    /**
     * return an array with that contain the user corresponding to the id pass
     * @param idUser
     */
    public getUserById(idUser: string): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('users', {id: idUser})
            .pipe(map(collection => collection.entities));
    }
}
