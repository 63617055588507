import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {map, tap} from 'rxjs/operators';

@Component({
    selector: 'app-notepad-content-viewer-modal',
    templateUrl: './notepad-content-viewer-modal.component.html',
})
export class NotepadContentViewerModalComponent implements OnInit {
    public isLoading = true;
    public text: string;
    public notepadTitle: string;
    private notepadGranule: DataEntity;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public modalData: { notepad$: Observable<DataEntity> },
        public selfDialogRef: MatDialogRef<NotepadContentViewerModalComponent>) {
    }

    ngOnInit(): void {
        this.modalData.notepad$.pipe(
            tap(notepadGranule => {
                this.notepadGranule = notepadGranule;
                this.text = this.notepadGranule.get('reference').activity_content[0].text;
                this.notepadTitle = this.notepadGranule.get('metadatas').title;
                this.isLoading = false;
            })
        ).subscribe();
    }

    public close(): void {
        this.selfDialogRef.close();
    }
}
