import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ReplaySubject} from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import {DataEntity} from 'octopus-connect';
import {fuseAnimations} from 'fuse-core/animations';

@Component({
  selector: 'app-widget-research-sheet-models',
  templateUrl: './widget-research-sheet-models.component.html',
  styleUrls: ['./widget-research-sheet-models.component.scss'],
  animations: fuseAnimations

})
export class WidgetResearchSheetModelsComponent implements OnInit {
    @Input('sheetTemplateSubscription') sheetTemplateSubscription: ReplaySubject<any>;

    displayedColumns = ['nameResearchSheetTemplate', 'author'];
    dataSource = new MatTableDataSource();
    resource: boolean;
    templateCollection: {[key: number]: DataEntity} = {};

    constructor(
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.sheetTemplateSubscription.subscribe((data) => {
            if (data && data.templates) {
                this.templateCollection = data.templateCollection;
                this.dataSource.data = data.templates.slice(0, 3);
            }
            this.resource = data && data.templates && data.templates.length;
        });

    }


    getLinkTemlplate(data): any{
        this.router.navigate(['research-template', 'edit', data.id]);
    }

    getNameAuthor(data): string {
      return this.templateCollection[data.id].get('username');
    }

    public get empty(): boolean {
        return !this.resource;
    }

    public get align(): string {
        if (!this.empty) {
            return 'start ';
        }
        return 'center ';
    }

}
