import {Component, Injectable} from '@angular/core';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {Subject} from 'rxjs';
import {v4 as uuidv4} from 'uuid';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../settings';
import {AuthenticationService} from '@modules/authentication';
import {CollectionOptionsInterface} from 'octopus-connect';
import {OctopusConnectService} from 'octopus-connect';
import {currentTimestamp} from '../../../../shared/utils';
import {NoAssignmentForLongTimeAgoNewsComponent} from '@modules/assignation/core/components/no-assignment-for-long-time-ago-news/no-assignment-for-long-time-ago-news.component';
import {Subscription} from 'rxjs';

const SEVEN_DAYS_IN_SECONDS = 604800;
const NOT_MODELS_URL_REGEX = /^(?!\/lessons\/list\/models).*$/;

const settingsStructure: ModelSchema = new ModelSchema({
    displayNews: Structures.object({
        default: []
    }),
});

interface NewsSettingsInterface {
    displayNews: {
        [role: string]: string[]
    };
}

@Injectable({
    providedIn: 'root'
})
export class AssignmentNewsService {
    private subscriptionLearnerList: Subscription;
    /**
     * A news about the fact to create a learner if it's not already done.
     */
    static createNoAssignmentForLongTimeAgoNews = {
        id: uuidv4(),
        component: <Component>NoAssignmentForLongTimeAgoNewsComponent, // component MUST be in entryComponents of module to work fine
        weight: 100,
        channel: {
            snackbar: {
                acceptedUrlRegex: NOT_MODELS_URL_REGEX
            }
        }
    };

    private onLogout = new Subject();
    private almostOneLearnerExist = new Subject();
    private currentUser: DataEntity;
    private settings: NewsSettingsInterface;

    constructor(
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private octopusConnect: OctopusConnectService
    ) {

        this.settings = <NewsSettingsInterface>settingsStructure.filterModel(modulesSettings.assignation);

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((currentUser: DataEntity) => {
                this.currentUser = currentUser;
                if (!!currentUser) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('sendNotification')
            .subscribe(notif => {
                if (notif.type === 'NEW_ASSIGNATION') {
                    // an assignment is just created if there is a new i remove it
                    this.removeNews();
                }
            });
    }

    /**
     * send alert news if all the assignment are closed and more recent is more than timeToCheck time
     * @param timeToCheck time to compare from now to last assignemnt ended
     */
    private sendNewsIfLastAssignmentIsTooOld(timeToCheck: number): void {
        const filterOptions: CollectionOptionsInterface = {
            filter: {'assignator': this.currentUser.id},
        };

        this.octopusConnect.paginatedLoadCollection('assignation_search', filterOptions)
            .collectionObservable
            .pipe(
                take(1),
                map(collection => collection.entities
                    .filter(data =>
                        // assigment currently opened
                        data.get('state') !== 'closed' && data.get('assignated_user').uid
                        ||
                        // assignment closed but less than seven days ago
                        (data.get('state') === 'closed' && currentTimestamp() - data.get('dates').value2 < timeToCheck)))
            ).subscribe(assignements => {
            if (assignements.length > 0) {
                // no message to shown
            } else {
                // user is not a learner and there's learner created and no assignment from more than seven days message to send create assignment
                if (this.isCreateAssigmentNewsAllowed()) {
                    this.communicationCenter.getRoom('news').next('add', [AssignmentNewsService.createNoAssignmentForLongTimeAgoNews]);
                }
            }
        });
    }

    /**
     * Emit list news to the communication center according to the app state and the settings
     * If the user is a teacher have a learner and no assignment for more than seven day emit alert
     */
    public emitNews(): void {
        if (this.isCreateAssigmentNewsAllowed()) {
            this.emitNoAssignmentForLongTimeAgoNewsIfAlmostOneLearner();
        }
    }

    /**
     * If the business rule accept it, emit news
     * user have almost a learner and no active assignment for more than seven day emit alert
     */
    public emitNoAssignmentForLongTimeAgoNewsIfAlmostOneLearner(): void {
        this.subscriptionLearnerList = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList').pipe(
                takeUntil(this.almostOneLearnerExist), // when appli is loading we have multiple emit first are without any learner
                tap(() => this.removeNews([AssignmentNewsService.createNoAssignmentForLongTimeAgoNews])),
                filter((learners) => !!learners && learners.length !== 0),
            ).subscribe(learners => {
                    if (learners.length > 0) {
                        this.almostOneLearnerExistCloseListener();
                        // is there no assignment from more than seven days if it is send news
                        this.sendNewsIfLastAssignmentIsTooOld(604800);
                    }
                }
            );
    }

    /**
     * Remove all news managed by this service.
     * @remarks If the news are not displayed it's not a problem.
     */
    public removeNews(news = []): void {
        if (news.length === 0) {
            news = [
                AssignmentNewsService.createNoAssignmentForLongTimeAgoNews
            ];
        }
        this.communicationCenter.getRoom('news').next('delete', news);
    }

    /**
     * execute all needs to prepare the app for the current user
     * @private
     */
    private postAuthentication(): void {
        this.emitNews();
    }

    /**
     * execute all needs to clean the app on logout
     * @private
     */
    private postLogout(): void {
        this.almostOneLearnerExistCloseListener();
        this.onLogout.next();
        this.onLogout.complete();
        if (!!this.subscriptionLearnerList) {
            this.subscriptionLearnerList.unsubscribe();
        }
        this.removeNews();

    }

    /**
     * if a learner is return the emit news will be done if needed
     * no need to wait until other learner list change
     */
    private almostOneLearnerExistCloseListener(): void {
        this.almostOneLearnerExist.next();
        this.almostOneLearnerExist.complete();
    }

    /**
     * Return true if current user is allowed to have the createLearnerNews define by the role in the settings
     */
    public isCreateAssigmentNewsAllowed(): boolean {
        return this.settings.displayNews.hasOwnProperty(this.authService.accessLevel) ?
            this.settings.displayNews[this.authService.accessLevel].includes('createNoAssignmentForLongTimeAgo') :
            this.settings.displayNews['default'].includes('createNoAssignmentForLongTimeAgo');
    }
}

