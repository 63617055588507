import {Injectable} from '@angular/core';
import { AuthorizationService } from '@modules/authorization';
import {AsyncRules} from '@modules/graph-ubolino/core/model/rules';
import {Observable, of} from 'rxjs';
import {AuthenticationService} from '@modules/authentication';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {mergeMap, take} from 'rxjs/operators';
import { CommunicationCenterService } from '@modules/communication-center';

@Injectable({
    providedIn: 'root'
})
export class GraphUbolinoAuthorizationService {

    constructor(
        private authentication: AuthenticationService,
        private authorization: AuthorizationService,
        private communicationCenter: CommunicationCenterService,
    ) {
    }

    public activeRulesOnStartup(): void {
        this.authorization.addRule(AsyncRules.seeGraphs, (user) => {
            if (this.authentication.isLearner() || this.authentication.isAtLeastDirector()) {
                return of(true);
            } else if (this.authentication.isTrainer()) {
                return this.isUserHasEducatorRights(user);
            }

            return of(false);
        });
    }

    private isUserHasEducatorRights(user: UserDataEntity): Observable<boolean> {
        return this.communicationCenter
            .getRoom('groupsManagement')
            .getSubject('isUserHasEducatorRightsInHisInstitutionsCallback')
            .pipe(
                take(1),
                mergeMap((callBack: (UserDataEntity) => Observable<boolean>) => callBack(user))
            );
    }
}
