import {Observable, ReplaySubject, Subject, BehaviorSubject, Subscription, combineLatest, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {DataCollection, DataEntity, InterfaceError, PaginatedCollection, OctopusConnectService} from 'octopus-connect';
import {
    ActivatedRoute,
    NavigationEnd,
    Params,
    Router
} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {
    ActivitiesActivityInterface,
    ActivitiesAnswerAppInterface,
    ActivitiesAnswerInterface,
    ActivitiesAppInterface,
    ActivitiesQcmInterface,
    ActivitiesRbInterface, GranuleInterface,
    LessonInterface, MarkerInterface, MediaInterface, MetadataInterface,
} from '../lessons-interfaces.interface';
import {EditFormDialogComponent} from '@modules/activities/core/lessons/forms-list/edit-form-dialog/edit-form-dialog.component';
import {ActivitiesService, GenericActivityOptionsInterface} from '../../activities.service';
import {AccountManagementProviderService} from '../../../../account-management';
import {EditLessonDialogComponent} from '../lessons-list/edit-lesson-dialog/edit-lesson-dialog.component';
import {localizedDate, localizedTime, isEmpty} from '../../../../../shared/utils';
import {AuthenticationService} from '@modules/authentication';
import * as _ from 'lodash';
import {filterByCurrentYearByDefault} from '../../../../../settings';
import {FlagService} from '../../../../../shared/flag.service';
import {GenericPluginsService, LessonToolDataCommunicationCenterInterface} from '../../services/generic-plugins.service';
import {filter, map, mapTo, mergeMap, take, tap} from 'rxjs/operators';
import {PluginSetting} from '../../models/plugin.setting';
import {LessonMetadataDialogComponent} from '@modules/activities/core/lessons/lessons-list/lesson-metadata-dialog/lesson-metadata-dialog.component';
import {NavigateToLessonOptions} from '@modules/activities/core/models/lessonsActivityRoutes';
import {CollectionPaginator} from 'octopus-connect';
import {CollectionOptionsInterface} from 'octopus-connect';
import {NavigateService} from '@modules/activities/core/navigate.service';
import {EventService} from '../../../../../shared/event.service';
import {LessonsConfigurationService} from './lessons-configuration.service';

/** Rappel :
 * get user lesson : filter: {author: user.id}
 * get lesson models : filter: {role: this.getAllowedRoleIdsForModelsCreation(), typology; null}
 * no sub lessons : filter: { multi_step: 0}
 */

@Injectable()
export class LessonsService {
    activities: DataEntity[];
    lessonsObservable: Observable<DataEntity[]>;
    userLessonsObservable: Observable<DataEntity[]>;
    formsSubscription: Subscription;
    formsObservable: Observable<DataEntity[]>;
    formats: DataEntity[];
    sequencesSubscription: Subscription;
    lessons: DataEntity[] = [];
    userLessons: DataEntity[] = [];
    byRoleLessons: DataEntity[] = [];
    forms: DataEntity[] = [];
    formsObs: { [key: string]: ReplaySubject<DataEntity> } = {};
    lessonsObs: { [key: string]: ReplaySubject<DataEntity> } = {};
    private activitiesToClean: any[] = [];
    private activitiesToDelete: any[] = [];
    sequences: DataEntity[];
    onFilesChanged: BehaviorSubject<any> = new BehaviorSubject({});
    onFileSelected: BehaviorSubject<any> = new BehaviorSubject({});
    onSelectedResourcesChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    public onLessonUpdate = new Subject<DataEntity>();
    selectedLessonId: string;
    selectedActivity: DataEntity;
    private selectedActivityInSubLesson: DataEntity;
    currentLessonContentEdited: DataEntity[];
    private currentSubLessonContentEdited: DataEntity[];
    i: number;
    dialogYes: string;
    dialogCancel: string;
    dialogTitle: string;
    dialogDeleteMessage: string;
    userData: DataEntity;
    currentUser: DataEntity;
    public currentAssignment: DataEntity;
    public lessonButtonClicked = new Subject<boolean>();
    public userAnswerTempSave: any[];
    public activityAnswerResult: any[];
    public userLessonsPaginated: PaginatedCollection;
    public lessonsPaginated: PaginatedCollection;
    public lessonRunTraining = false;
    public tagModified = false;
    public selectedTabIndex = 0;
    public currentLesson: DataEntity;
    public exitLessonUrl = '';
    public btnSave = false;
    public savingAssignment = false;
    public activityIdChange = new Subject<boolean>();
    public showSpinner: boolean;
    public isShareableCommunity: boolean;
    public isShareableModel: boolean;
    public showOnlySharedCommunityLesson: boolean;
    grade: any;
    activityGrade: any;
    activityOldGrade: any;
    activityOldGradePercent: any;
    activityGradePercent: any;

    /**
     * @deprecated must use LessonsConfigurationService
     */
    public settings: { [key: string]: any };
    /**
     * Contains all typologies.
     * Filled by the backend.
     */
    private allTypes: any[];

    private currentActivitiesEdited: any;
    private lessonEditorWithStepConfig: any;

    private obsEntitiesForDuplicate: Observable<DataEntity[]>;
    private roleNameToIdMapping: { string: number }[];

    constructor(
        private octopusConnect: OctopusConnectService,
        private activitiesService: ActivitiesService,
        private communicationCenter: CommunicationCenterService,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private translate: TranslateService,
        private http: HttpClient,
        private accountManagementProvider: AccountManagementProviderService,
        private authenticationService: AuthenticationService,
        public flagService: FlagService,
        private genericPluginsService: GenericPluginsService,
        private navigateService: NavigateService,
        private eventService: EventService,
        private config: LessonsConfigurationService
    ) {
        // TODO ne plus avoir de settings dans ce service mais utiliser le LessonsConfigurationService ou le AuthorizationService
        this.settings = this.config.settings;

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.route),
            map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter(route => route.outlet === 'primary'),
            mergeMap(route => {
                if (route.params) {
                    return route.params;
                } else {
                    return null;
                }
            }))
            .subscribe((params: Params) => {
                if (params['formId']) {
                    if (!this.selectedLessonId) {
                        this.selectedLessonId = params['formId'];
                    }
                } else if (params['lessonId']) {
                    if (!this.selectedLessonId) {
                        this.selectedLessonId = params['lessonId'];
                    }
                } else {
                    this.selectedLessonId = null;
                }

                if (params['activityId']) {
                    this.activityIdChange.next(true);
                }

            });

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.currentUser = data;
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('loadLessons')
            .subscribe(() => {
                this.loadLessons('currentUser');
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('current')
            .subscribe((data) => {
                this.currentAssignment = data;
                /*userId, expires, state*/
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('saving')
            .subscribe((saving: boolean) => {
                this.savingAssignment = saving;
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('unassign')
            .subscribe((node) => {
                switch (node.type) {
                    case 'lesson':
                        this.unlockLesson(node.id);
                        break;
                    case 'form':
                    default:
                        break;
                }
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('addResources')
            .subscribe((resources: DataEntity[]) => {
                this.setActivitiesOfSelectedLesson(resources);
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('selectionLessonEditorWithStepConfig')
            .subscribe((val: any) => {
                this.currentActivitiesEdited = val.activities;
                this.lessonEditorWithStepConfig = val;
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('addResourceToActivity')
            .subscribe((resource: any) => {
                this.showSpinner = true;
                this.editActivityVideo(resource)
                    .subscribe((entities) => {
                        this.showSpinner = false;
                        if (entities && entities.length) {
                            this.setCurrentActivity(entities[0]);
                        }
                        this.lessonEditorWithStepConfig.callback();
                    });

            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('updateCurrentLesson')
            .subscribe((selectedLesson: DataEntity) => {
                this.updateCurrentLesson(selectedLesson);
            });

        this.communicationCenter
            .getRoom('activities')
            .next('loadPaginatedLessonsCallback', (type, userRoles, searchValue, optionsInterface) => {
                return this.loadPaginatedLessons(type, userRoles, searchValue, optionsInterface);
            });

        this.communicationCenter
            .getRoom('activities')
            .next('loadLessonGranuleCollection', (options: CollectionOptionsInterface) => {
                return this.loadPaginatedLessonGranuleCollection(options);
            });

        this.communicationCenter
            .getRoom('activities')
            .next('getAllowedRoleIdsForModelsCreationCallback', () => {
                return this.getAllowedRoleIdsForModelsCreation().join(); // get the role id for models creation
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('saveFromModalToLesson')
            .subscribe((lessonContent: DataEntity[]) => {
                this.saveFromModalToLesson(lessonContent);
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('saveProgress')
            .subscribe((progressValue: number) => {
                this.saveProgress(progressValue);
            });

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('roles')
            .subscribe((roles) => {
                this.roleNameToIdMapping = roles;
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('createActivities')
            .subscribe((data: { types: string[], callbackSubject: Subject<Observable<DataEntity>[]> }) => {
                data.callbackSubject.next(this.createActivitiesByTypes(data.types));
            });

        this.selectedTabIndex = this.settings.selectedTabIndex;

        this.communicationCenter
            .getRoom('lessons')
            .next('allowedRolesForModelsAssignation', this.settings.allowedRolesForModelsAssignation);

        this.communicationCenter
            .getRoom('lessons')
            .next('allowedRolesForModelsCreation', this.settings.allowedRolesForModelsCreation);

        this.communicationCenter
            .getRoom('lessons')
            .getSubject('getLesson')
            .subscribe(({callbackSubject, lessonId}: { lessonId: string | number, callbackSubject: Subject<Observable<DataEntity>> }) =>
                callbackSubject.next(this.loadLessonById(lessonId))
            );

        this.communicationCenter
            .getRoom('lessons')
            .getSubject('playLesson')
            .subscribe(({id}: { id: string | number }) => {
                this.loadAndNavigateToLesson(id.toString());
            });

        this.initSchoolYearFilterValue().subscribe();

        this.communicationCenter
            .getRoom('lessons')
            .getSubject('openLessonMetadataDialog')
            .subscribe(({lessonId}: { lessonId: string | number }) => {
                this.openLessonMetadataDialog(lessonId).subscribe();
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('setAssignmentProgress')
            .pipe(
                tap((data: { [key: string]: any }) => this.saveProgressInAssignmentWithMetacognition(data.value, data.callBack))
            ).subscribe();
    }

    private initSchoolYearFilterValue(): Observable<void> {
        const getCurrentSchoolYear = () => {
            const usedSchoolYear = (new Date()).getFullYear();
            const month = (new Date()).getMonth();
            return (month < 7) ? usedSchoolYear - 1 : usedSchoolYear;
        };

        let schoolYears$: Observable<DataEntity[]> = of([]);
        if (!!filterByCurrentYearByDefault) {
            schoolYears$ = this.octopusConnect.loadCollection('schoolyears').pipe(
                map(dataCollection => dataCollection.entities),
                take(1)
            );
        }
        return schoolYears$.pipe(
            map(entities => entities.find((schoolYear) => getCurrentSchoolYear().toString() === schoolYear.get('name'))),
            map(schoolYearEntity => !!schoolYearEntity ? schoolYearEntity.id : null),
            map((schoolYearFilterValue: string) => {
                    this.communicationCenter
                        .getRoom('lessons')
                        .next('schoolYearFilterValue', schoolYearFilterValue);
                }
            )
        );

    }

    private loadTypes(): void {
        this.activitiesService.loadActivityTypes().subscribe(collection => {
            if (collection.entities[0]) {
                this.allTypes = collection.entities[0].get('activityTypes');
            }
        });
    }

    public loadMarkerTypes(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('marker_type').pipe(map((collection) => collection.entities));
    }

    public loadMarker(id): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('video_marker', id);
    }

    public createMarker(data: MarkerInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('video_marker', data);
    }

    public saveMarker(selectedMarker, data, markerIds): any {
        // if edit marker
        if (selectedMarker) {
            const marker: DataEntity = new DataEntity(
                'video_marker',
                selectedMarker,
                this.octopusConnect,
                selectedMarker.id);

            marker.set('title', data.title);
            marker.set('description', data.description);
            marker.set('time', data.time);

            return marker.save();
        } else {
            // if create marker
            return this.createMarker(data).pipe(
                mergeMap((entity) => {
                    const allMarkers = [];
                    const media = this.getCurrentActivity().get('reference').activity_content[0];
                    allMarkers.push(...markerIds, entity.id);
                    const newMedia: DataEntity = new DataEntity(
                        'media',
                        media,
                        this.octopusConnect,
                        media.id);
                    newMedia.set('marker', allMarkers);
                    return newMedia.save();
                }));
        }

    }

    public removeMarker(marker, markerIds): any {
        const media = this.getCurrentActivity().get('reference').activity_content[0];
        const allMarkers = markerIds.filter((mark) => +mark !== +marker.id);
        const newMedia: DataEntity = new DataEntity(
            'media',
            media,
            this.octopusConnect,
            media.id);
        newMedia.set('marker', allMarkers);

        const currentMarker: DataEntity = new DataEntity(
            'video_marker',
            marker,
            this.octopusConnect,
            marker.id);

        return newMedia.save().pipe(
            mergeMap(() => {
                return currentMarker.remove();
            }));

    }

    private postLogout(): void {
        this.resetActivitiesArray();
        if (this.sequencesSubscription) {
            this.sequencesSubscription.unsubscribe();
            this.sequencesSubscription = null;
        }
        this.userLessonsObservable = null;
    }

    private postAuthentication(): void {
        this.loadTypes();
        // load lessons after user authentification for use it by communication center in other module like idea-wall
        // !!! be carefull when use it load only 50 lessons not all because of back restriction!!!
        const filterOpions = {
            filter: {
                multi_step: 0
            }
        };
        this.settings.initLoadLessonFilter.forEach((field: string) => {
            switch (field) {
                case 'author':
                    filterOpions['filter']['author'] = this.currentUser.id;
                    break;
                case 'role':
                    filterOpions['filter']['role'] = this.getAllowedRoleIdsForModelsCreation();
                    break;
            }
        });
        this.loadPaginatedLessons('currentUser', null, '', filterOpions, true);
        this.translate.onLangChange
            .subscribe((event: LangChangeEvent) => {
                this.loadTypes();
            });

        /* this.loadForms(1, 6); */ // first load page 1 with 6 elements
        this.loadForms();
    }

    /**
     * use by communication center (lesson-activities.resolver)
     * @param type : model = 'byRole' or lesson = currentUser'
     * @param role:
     */
    public loadLessons(type?: string, role?: string): Observable<DataEntity[]> {
        let currentObservable: Observable<DataEntity[]>;
        let needSubscribe = false;
        switch (type) {
            case 'currentUser':
                if (!this.userLessonsObservable) {
                    needSubscribe = true;
                    this.userLessonsObservable = this.octopusConnect
                        .loadCollection('granule-lesson', {'owner': this.currentUser.id}).pipe(
                            map(collection => collection.entities));
                }

                currentObservable = this.userLessonsObservable;
                break;
            case 'byRole':
                if (!this.lessonsObservable) {
                    needSubscribe = true;
                    this.lessonsObservable = this.octopusConnect.loadCollection('granule-lesson').pipe(
                        map(collection => collection.entities.filter(item => item.attributes['owner-role'].indexOf(role) > -1)));
                }

                currentObservable = this.lessonsObservable;
                break;
        }

        if (needSubscribe) {
            currentObservable.subscribe((entities: DataEntity[]) => {
                switch (type) {
                    case 'currentUser':
                        this.userLessons = entities;
                        break;
                    case 'byRole':
                        this.byRoleLessons = entities;
                        break;
                }

                this.lessons = this.userLessons.concat(this.byRoleLessons);

                entities.forEach((entity) => {
                    if (this.lessonsObs[entity.id.toString()]) {
                        this.lessonsObs[entity.id.toString()].next(entity);
                    }
                });

                this.communicationCenter
                    .getRoom('activities')
                    .next('lessonsList', this.lessons);
            });
        }

        return currentObservable;
    }

    /**
     * Get an Observable about a list of activities ready to use (already loaded in {@see ActivitiesService}).
     * Only real activities are loaded, if an activity is a lesson for multi activity support.
     * This activity (lesson) will be ignored but the children activities will be loaded.
     * It's not a recursive way to load deeply activities of other Activites, the multi support work for a deep limit of 1 children.
     * @param lesson Lesson to load
     * @return Observable<DataEntity[]> List of activities
     */
    public loadLessonActivities(lesson: DataEntity): Observable<DataEntity[]> {
        const activitiesToLoad: number[] = [];
        const activityObsList: Observable<DataEntity>[] = [];
        const lessonObsList: Observable<void>[] = [];
        let loadedActivities: DataEntity[] = [];
        const observable = new ReplaySubject<DataEntity[]>(1);

        const getLoadActivityObservables = (activity) => {
            if (activitiesToLoad.indexOf(+activity.id) === -1) {
                activitiesToLoad.push(+activity.id);
                const loadedActivity = this.activitiesService.getActivityEntity(+activity.id);

                if (loadedActivity) {
                    loadedActivities.push(loadedActivity);
                } else {
                    activityObsList.push(this.activitiesService.loadActivitiesFromId(activity.id));
                }
            }
        };

        for (const activity of lesson.get('reference')) {
            if (activity['type'] !== 'lesson') {
                getLoadActivityObservables(activity);
            } else {
                const lessonObs = this.loadLessonById(activity.id).pipe(map(subLesson => {
                    loadedActivities.push(subLesson);
                    const subActivities = subLesson.get('reference');
                    for (const subActivity of subActivities) {
                        getLoadActivityObservables(subActivity);
                    }
                }));

                lessonObsList.push(lessonObs);
            }
        }

        (lessonObsList.length ? combineLatest(...lessonObsList) : of([]))
            .subscribe(() => {
                (activityObsList.length ? combineLatest(...activityObsList) : of([]))
                    .subscribe((activitiesEntity: DataEntity[]) => {
                        loadedActivities = activitiesEntity.concat(loadedActivities);
                        this.activitiesService.setCurrentActivities(loadedActivities);
                        observable.next(loadedActivities);
                    });
            });

        return observable;
    }

    /**
     * load paginated lesson
     * @param options
     */
    public loadPaginatedLessonGranuleCollection(options: CollectionOptionsInterface): Observable<{ entities: DataEntity[], paginator: CollectionPaginator }> {
        const paginatedCollection: PaginatedCollection = this.octopusConnect.paginatedLoadCollection('lesson_granule_search', options);
        const collection$ = paginatedCollection.collectionObservable.pipe(map(collection => collection.entities));

        return collection$.pipe(
            mergeMap((entities: DataEntity[]) => of({entities, paginator: paginatedCollection.paginator}))
        );
    }

    public loadPaginatedLessons(type?: string, roles?: number[], searchValue?, filterOptions = {}, init?): Observable<DataEntity[]> {
        let currentObservable: Observable<DataEntity[]>;
        let needSubscribe = false;

        const defaultFilters = {
            'urlExtension': '',
            filter: {
                format: 'lesson'
            }
        };

        filterOptions = _.merge(defaultFilters, filterOptions);

        switch (type) {
            case 'currentUser':
                needSubscribe = true;
                if (this.showOnlySharedCommunityLesson) {
                    filterOptions['filter']['shared'] = 1;
                } else {
                    if (!init) {
                        filterOptions['filter']['author'] = this.currentUser.id; // current user lessons
                    }
                }
                // WIP : waiting for connector sort
                // filterOptions['orderOptions']['field'] = 'changed';
                // filterOptions['orderOptions']['direction'] = 'DESC';

                this.userLessonsPaginated = this.octopusConnect.paginatedLoadCollection('lesson_granule_search', filterOptions);
                this.userLessonsObservable = this.userLessonsPaginated.collectionObservable.pipe(map(collection => collection.entities));

                currentObservable = this.userLessonsObservable;
                break;
            case 'byRole': // On devrait ajouter ici "typology = null" dans les filtres
                needSubscribe = true;
                filterOptions['filter']['role'] = roles ? roles.join(',') : null; // gestionnaire role id
                delete filterOptions['filter']['schoolyear']; // for manager : force school year to "all"
                this.lessonsPaginated = this.octopusConnect.paginatedLoadCollection('lesson_granule_search', filterOptions);
                this.lessonsObservable = this.lessonsPaginated.collectionObservable.pipe(map(collection => collection.entities));

                currentObservable = this.lessonsObservable;
                break;
            case 'all':
                currentObservable = this.octopusConnect
                    .paginatedLoadCollection('lesson_granule_search', filterOptions)
                    .collectionObservable.pipe(
                        map(collection => collection.entities));
        }

        if (needSubscribe) {
            currentObservable.subscribe((entities: DataEntity[]) => {
                switch (type) {
                    case 'currentUser':
                        this.userLessons = entities;
                        break;
                    case 'byRole':
                        this.byRoleLessons = entities;
                        break;
                }

                this.lessons = this.userLessons.concat(this.byRoleLessons);

                entities.forEach((entity) => {
                    if (this.lessonsObs[entity.id.toString()]) {
                        this.lessonsObs[entity.id.toString()].next(entity);
                    }
                });

                this.communicationCenter
                    .getRoom('activities')
                    .next('lessonsList', this.lessons);
            });
        }

        return currentObservable;
    }

    /**
     * Loads the 10 last consulted lessons by the user
     * @returns Array of DataEntity lessons ordered by last consulted
     */
    public loadConsultedLessons(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('user-dashboard').pipe(
            take(1),
            map((collection: DataCollection) => {
                return collection.entities.flatMap((entity: DataEntity) => {
                    return entity.get('lessonsWidget').map((lesson) => {
                        return new DataEntity('granule', lesson, this.octopusConnect, lesson.id);
                    });
                });
            }));
    }


    public loadUserSaves(contextId: string): Observable<DataEntity[]> {
        const filters = {context: contextId};

        return this.octopusConnect.loadCollection('user-save', filters).pipe(
            take(1),
            map((collection: DataCollection) => collection.entities));
    }

    public loadSequences(): Observable<DataEntity[]> {
        if (this.sequencesSubscription) {
            this.sequencesSubscription.unsubscribe();
        }
        const obs: Observable<DataEntity[]> = this.octopusConnect.loadCollection('granule-sequence').pipe(map(collection => collection.entities));
        this.sequencesSubscription = obs.subscribe(entities => this.activities = entities);
        return obs;
    }

    public loadForms(): Observable<DataEntity[]> {
        if (!this.formsObservable) {
            this.formsObservable = this.octopusConnect.loadCollection('granule-form').pipe(map(collection => collection.entities));
            this.formsSubscription = this.formsObservable.subscribe((entities: DataEntity[]) => {
                this.forms = entities;

                entities.forEach((entity) => {
                    if (this.formsObs[entity.id.toString()]) {
                        this.formsObs[entity.id.toString()].next(entity);
                    }
                });

                this.communicationCenter
                    .getRoom('activities')
                    .getSubject('form-list')
                    .next(entities);
            });
        }

        return this.formsObservable;
    }

    // TODO - commenter utilité fonctionnement loadEntity
    public reloadForm(id: number | string): Observable<DataEntity> {
        const loadObs = this.octopusConnect.loadEntity('granule-form', id);

        loadObs.pipe(take(1))
            .subscribe((form) => {
                // Force reload of granule-form collection
            });

        return loadObs;
    }

    public reloadLesson(id: number | string): Observable<DataEntity> {
        const loadObsLesson = this.octopusConnect.loadEntity('granule-lesson', id);

        loadObsLesson.pipe(take(1))
            .subscribe((form) => {
                // Force reload of granule-lesson collection
            });

        return loadObsLesson;
    }

    public getForm(id: string): DataEntity {
        let form = null;

        this.forms.some((entity) => {
            if (entity.id.toString() === id) {
                form = entity;
                return true;
            }
        });

        return form;
    }

    public getLesson(id: string): DataEntity {
        return this.lessons.find((entity: DataEntity) => +entity.id === +id);
    }

    public getFormObs(id: string): Observable<DataEntity> {
        if (!this.formsObs[id]) {
            this.formsObs[id] = new ReplaySubject<DataEntity>(1);

            const form = this.getForm(id);
            if (form) {
                this.formsObs[id].next(form);
            }
        }

        return this.formsObs[id];
    }

    public getLessonObs(id: string, forceReload: boolean = false): Observable<DataEntity> {
        if (!this.lessonsObs[id]) {
            this.lessonsObs[id] = new ReplaySubject<DataEntity>(1);

            if (forceReload) {
                this.loadLessonById(id).subscribe((lesson) => this.lessonsObs[id].next(lesson));
            } else {
                const lesson = this.getLesson(id);
                if (lesson) {
                    this.lessonsObs[id].next(lesson);
                } else {
                    this.loadLessonById(id).subscribe((rlesson) => this.lessonsObs[id].next(rlesson));
                }
            }
        } else if (forceReload) {
            this.lessonsObs[id].complete();
            this.lessonsObs[id] = new ReplaySubject<DataEntity>(1);
            this.loadLessonById(id).subscribe((lesson) => this.lessonsObs[id].next(lesson));
        }

        return this.lessonsObs[id];
    }

    public set setCurrentLessonId(id) {
        this.selectedLessonId = id;
    }

    public setCurrentActivity(activity: DataEntity): void {
        this.selectedActivity = activity;
    }

    public getCurrentActivity(): DataEntity {
        return this.selectedActivity;
    }

    public set editCurrentActivityInSubLesson(activity: DataEntity) {
        this.selectedActivityInSubLesson = activity;
    }

    public get currentActivityInSubLesson(): DataEntity {
        return this.selectedActivityInSubLesson;
    }

    public setCurrentLessonContentEdited(activities: DataEntity[]): void {
        this.currentLessonContentEdited = activities;
    }

    public getCurrentLessonContentEdited(): DataEntity[] {
        return this.currentLessonContentEdited;
    }

    public set editSubLessonContentEdited(activities: DataEntity[]) {
        this.currentSubLessonContentEdited = activities;
    }

    public get subLessonContentEdited(): DataEntity[] {
        return this.currentSubLessonContentEdited;
    }

    public clearCurrentActivity(): void {
        delete this.selectedActivity;
    }

    public launchActivity(id: string, getFromHttp?: boolean): any {
        if (getFromHttp) {

        }
        const attribute = this.activities.filter((item) => item.id === id);
        return attribute[0].attributes;
    }

    /**
     * common method to launch editing of lessson creating a new one etc.
     * @param id : lesson id
     * @param action : lesson form etc.
     * @param type : edit, new etc.
     * @param autoSubscribe : optionnal not launch the subscribe inside the method if false is passed
     * use because of compatibility with legacy code and we need now to subscribe in componenent in some case
     */
    public launchEditor(id: string, action: string, type: string, autoSubscribe = true, exitUrl?: string): Observable<DataEntity> {
        let obs: Observable<any>;
        switch (type) {
            case ('form'):
                obs = this.launchFormEditor(action, id, type);
                break;
            case ('lesson'):
                obs = this.launchLessonEditor(action, id, type, exitUrl);
                break;
            default:
                console.log('warning : type is missing');
        }
        if (autoSubscribe) {
            obs.subscribe(); // needed for other component than lesson-tab component who call it
        }

        return obs;
    }

    private launchNewLessonEditor(action: string, id: string, type: string): Observable<DataEntity> {
        const data = {
            action,
            item: id ? this.getLesson(id) : null,
        };

        const dialogRef = this.dialog.open(EditLessonDialogComponent, {data});

        return dialogRef.afterClosed().pipe(
            filter(result => !!result),
            tap(() => this.showSpinner = true),
            mergeMap(result => this.createLesson(result.form.getRawValue(), type))
        );
    }

    private launchEditLessonEditor(action: string, id: string, type: string): Observable<DataEntity> {
        const data = {
            action,
            item: id ? this.getLesson(id) : null,
        };

        const dialogRef = this.dialog.open(EditLessonDialogComponent, {data});

        return dialogRef.afterClosed().pipe(
            mergeMap((result) => {
                if (!result) {
                    return of(null);
                }
                return this.saveFormOrLessonMetadata(id, result.form.getRawValue(), type)
                    .pipe(
                        mergeMap(() => {
                            // if theme is change by user save new theme in granule
                            const newThemeId = result.form.getRawValue().theme;
                            const oldThemeId = data.item.get('theme').id;
                            if (newThemeId !== oldThemeId) {
                                const entityToSave: DataEntity = new DataEntity('granule', <any>data.item, this.octopusConnect, data.item.id);
                                entityToSave.set('theme', newThemeId);
                                return entityToSave.save();
                            }
                            return of({});
                        }),
                        map(() => data.item)
                    );
            })
        );
    }

    launchCopyLessonEditor(action: string, id: string, type: string): Observable<DataEntity> {
        const data = {
            action,
            item: this.getLesson(id)
        };

        if (!data.item) {
            return this.loadLessonById(id).pipe(
                tap((entityLesson: DataEntity) => data.item = entityLesson),
                mergeMap(() => this.openMetadataEditor(data, id, type))
            );
        } else {
            return this.openMetadataEditor(data, id, type);
        }
    }

    private openMetadataEditor(data: { [key: string]: any }, id: string, type: string): Observable<DataEntity> {
        const dialogRef = this.dialog.open(EditLessonDialogComponent, {data});

        return dialogRef.afterClosed().pipe(
            mergeMap((result) => {
                if (!result) {
                    return of(null);
                }
                return this.lessonDuplication(id, type).pipe(
                    take(1),
                    mergeMap((entity: DataEntity) => this.getLessonObs(entity.get('duplicateGranuleLesson').nid, true)),
                    take(1),
                    tap((lesson: DataEntity) => this.lessons.push(lesson)),
                    mergeMap((lesson: DataEntity) => this.saveFormOrLessonMetadata(lesson.id, result.form.getRawValue(), type).pipe(
                        take(1),
                        mapTo(lesson)
                    ))
                );
            })
        );
    }

    private launchLessonEditor(action: string, id: string, type: string, exitUrl?: string): Observable<DataEntity> {
        switch (action) {
            case 'new': {
                return this.launchNewLessonEditor(action, id, type).pipe(
                    tap(() => this.showSpinner = false),
                    tap((entity: DataEntity) => {
                        this.lessons.push(entity);
                        this.communicationCenter.getRoom('lessons').next('openEditor', {id: entity.id.toString(), exitUrl});
                    })
                );
            }
            case 'edit': {
                return this.launchEditLessonEditor(action, id, type).pipe(
                    tap((entity) => {
                            if (!!entity) {
                                this.communicationCenter
                                    .getRoom('lessons')
                                    .next('openEditor', {id, exitUrl});
                            }
                        }
                    )
                );
            }
            case 'copy': {
                return this.launchCopyLessonEditor(action, id, type).pipe(
                    tap(() => this.userLessonsPaginated.paginator.reload()),
                    filter(() => {
                        const roles: string[] = Object.keys(this.settings.openDialogInfoAfterDuplicateLesson);
                        return roles.length && roles.includes(this.authenticationService.accessLevel) ?
                            this.settings.openDialogInfoAfterDuplicateLesson[this.authenticationService.accessLevel] :
                            this.settings.openDialogInfoAfterDuplicateLesson.default;
                    }),
                    tap((res) => {
                        if (!!res) {
                            this.showInfoLocationDuplicateLesson();
                        }
                    })
                );
            }
        }
    }

    private launchFormEditor(action: string, id: string, type: string): Observable<DataEntity> {
        const data = {
            action,
            item: id ? this.getForm(id) : null,
        };

        // @ts-ignore
        const dialogRef = this.dialog.open(EditFormDialogComponent, {data});

        return dialogRef.afterClosed().pipe(
            filter(result => !!result),
            mergeMap(result => {
                if (action === 'new') {
                    return this.createLesson(result.form.getRawValue(), type).pipe(
                        filter(() => result.redirect),
                        mergeMap((entity) =>
                            this.reloadForm(entity.id).pipe(take(1)).pipe(
                                tap(() => this.router.navigate(['forms', entity.id, 'edit']))
                            ))
                    );
                } else if (action === 'edit') {
                    this.saveFormOrLessonMetadata(id, result.form.getRawValue(), type);
                    this.router.navigate(['forms', id, 'edit']);
                }
            })
        );
    }

    /**
     * show info on the place where the duplicate lesson is create in the application
     */
    private showInfoLocationDuplicateLesson(): void {
        const dialogConfig = new MatDialogConfig();
        this.translate.get('activities.information').subscribe((translation: string) => {
            dialogConfig.data = {
                titleDialog: translation,
            };
        });

        this.translate.get('activities.duplicate.lesson.location').subscribe((translation: string) => {
            dialogConfig.data.bodyDialog = translation;
        });

        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                panelClass: 'lesson-form-dialog',
                data: dialogConfig.data
            }
        );

        dialogRef.afterClosed().subscribe(() => {
            // redirect to "my lessons" tab or to 'my lessons' url
            if (this.settings.isOnlyModelLesson) { // no tabs
                this.router.navigate(['lessons/list/lessons']);
            } else {
                this.selectedIndexChange(0);
            }
        });
    }

    /**
     * open the dialog modal for deleting lesson not use in erasme who use assignment
     * @param ressource : DataEntity
     * @param autoSubscribe : optionnal not launch the subscribe inside the method if false is passed
     * use because of compatibility with legacy code and we need now to subscribe in componenent in some case
     */
    public openDeleteDialog(ressource: DataEntity, autoSubscribe = true): Observable<any> {
        // get translation
        this.translate.get('generic.yes').subscribe((translation: string) => this.dialogYes = translation);
        this.translate.get('generic.cancel').subscribe((translation: string) => this.dialogCancel = translation);
        this.translate.get('generic.delete').subscribe((translation: string) => this.dialogTitle = translation);

        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
            titleDialog: this.dialogTitle,
        };


        this.translate.get('generic.confim_delete_single_file').subscribe((translation: string) => this.dialogDeleteMessage = translation);
        dialogConfig.data.bodyDialog = this.dialogDeleteMessage;
        dialogConfig.data.labelTrueDialog = this.dialogYes;
        dialogConfig.data.labelFalseDialog = this.dialogCancel;

        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                panelClass: 'lesson-form-dialog',
                data: dialogConfig.data
            }
        );

        const dialogCloseResult = dialogRef.afterClosed().pipe(
            mergeMap(result => {
                if (result === true) {
                    const entity = ressource.type !== 'granule-form' ? new DataEntity('granule', ressource.attributes, this.octopusConnect, ressource.id) : ressource;
                    return entity.remove().pipe(
                        map(success => {
                            if (success) {
                                if (this.userLessonsPaginated) {
                                    this.userLessonsPaginated.paginator.reload(); // refresh lessons ab
                                }

                                if (this.lessonsPaginated) {
                                    this.lessonsPaginated.paginator.reload(); // refresh models tab
                                }
                            }
                            return false;
                        }));
                } else {
                    return of(false);
                }
            }));

        if (autoSubscribe) {
            dialogCloseResult.subscribe();
        }

        return dialogCloseResult;
    }

    private unlockLesson(id: string): void {
        this.getLessonObs(id, true).pipe(
            take(1))
            .subscribe((lesson: DataEntity) => {
                if (lesson.get('assignatedCount') === 0) {
                    lesson.set('locked', false);
                    lesson.save();
                }
            });
    }

    /**
     * Create metadata values of one granule
     * @param metadata : metadata values
     * TODO not really good to remove tagModified & theme here
     */
    private createGranuleMetadata(metadata: MetadataInterface): Observable<DataEntity> {
        delete metadata['tagModified']; // remove tagModified before metadata save
        const metadataTemp = {...metadata};
        delete metadataTemp['theme']; // remove theme before metadata save
        return this.octopusConnect.createEntity('metadatas', metadataTemp).pipe(take(1));
    }

    private createActivitiesLesson(LessonStep: LessonInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('lesson', LessonStep).pipe(take(1));
    }

    public loadActivitiesLesson(id): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('lesson', id).pipe(take(1));
    }

    private createActivitiesAnswerApp(AnswerApp: ActivitiesAnswerAppInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('answer_app', AnswerApp).pipe(take(1));
    }

    private createActivitiesApp(App: ActivitiesAppInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('app', App).pipe(take(1));
    }

    private createActivitiesAnswer(Answer: ActivitiesAnswerInterface): Observable<DataEntity> {
        const obs = this.octopusConnect.createEntity('answer', Answer).pipe(take(1));
        obs.subscribe((entity) => {
            console.log('answer', entity);
        }, error1 => console.log('error answer', error1));
        return obs;
    }

    private createActivitiesRb(Rb: ActivitiesRbInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('rb', Rb).pipe(take(1));
    }

    private createActivitiesQcm(Qcm: ActivitiesQcmInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('qcm', Qcm).pipe(take(1));
    }

    private createActivitiesActivityInterface(Activity: ActivitiesActivityInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('activity', Activity).pipe(take(1));
    }

    private createGranuleInterface(Granule: GranuleInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('granule', Granule).pipe(take(1));
    }

    private createMedia(Granule: MediaInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('media', Granule).pipe(take(1));
    }

    public createSummary(Granule: MediaInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('summary', Granule).pipe(take(1));
    }

    private loadMedia(id: number): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('media', id).pipe(take(1));
    }

    private createTags(tags: any[], forCopy: boolean = false): Observable<DataEntity[]> {
        if (tags && tags.length > 0) { // if indexation keywords
            const tagObsArray: Observable<DataEntity>[] = [];
            let tagObs;

            tags.forEach((chip) => {
                if (!chip.id || forCopy) { // if tag not in DB
                    tagObs = this.octopusConnect.createEntity('tags', chip).pipe(take(1));
                    tagObs.subscribe((data: DataEntity) => { // create tag
                        chip.id = data.id.toString(); // add id to object
                    });
                    tagObsArray.push(tagObs);
                }
            });

            if (tagObsArray.length !== 0) {
                return combineLatest(tagObsArray);
            } else {
                const placeholder = new ReplaySubject<DataEntity[]>(1);
                placeholder.next([]);
                return placeholder;
            }
        } else {
            const placeholder = new ReplaySubject<DataEntity[]>(1);
            placeholder.next([]);
            return placeholder;
        }
    }

    public createLesson(metadata, type, forCopy = false, subLesson = null): Observable<DataEntity> {

        if (this.settings.lessonStep && !subLesson) {

            const obsContainer = this.createActivitiesByTypes(this.settings.lessonStep.typeSteps);

            return combineLatest(obsContainer).pipe(
                take(1),
                mergeMap(entities => {
                    const obsSummary: Observable<DataEntity>[] = [];
                    const entitySummary: DataEntity[] = [];
                    if (this.settings.lessonStep && this.settings.lessonStep.typeSteps) {
                        obsSummary.push(
                            ...this.settings.lessonStep.typeSteps.map((summary, index) => {
                                if (summary.type === 'SUMMARY') {
                                    entitySummary.push(entities[index]);
                                    if (entities[index].get('format') && entities[index].get('format').label === 'activity') {
                                        const summaryData: MediaInterface = {
                                            granule: [entities[summary.dataFromStep.stepIndex].id]
                                        };
                                        return this.createSummary(summaryData);
                                    }
                                }
                            }).filter((val) => val)
                        );
                    }
                    if (obsSummary.length > 0) {
                        const obsAllActivityRef = entitySummary.map((entity) => {
                            return this.activitiesService.loadActivityInterface(entity.get('reference'));
                        });

                        return combineLatest(obsSummary).pipe(
                            mergeMap((entitiesSummary: DataEntity[]) => {
                                return combineLatest(obsAllActivityRef).pipe(
                                    mergeMap((refs: DataEntity[]) => {
                                        const obsActivityContent = entitiesSummary.map((summary, index) => {
                                            refs[index].set('activity_content_patch', summary.id);
                                            return refs[index].save();
                                        });

                                        return combineLatest(obsActivityContent).pipe(
                                            mergeMap(() => this.createLessonAndGranule(metadata, forCopy, entities, type))
                                        );
                                    })
                                );
                            })
                        );
                    } else {
                        return this.createLessonAndGranule(metadata, forCopy, entities, type);
                    }
                }));

        } else {
            const lessonObs = this.createActivitiesLesson({lesson_step: subLesson ? subLesson.map((entity) => +entity.id) : []});
            return this.createBundleGranule(metadata, forCopy, lessonObs, type);
        }
    }

    private createLessonAndGranule(metadata, forCopy, entities, type): Observable<DataEntity> {
        const lessonObs = this.createActivitiesLesson({lesson_step: entities.map((entity) => +entity.id)});
        return this.createBundleGranule(metadata, forCopy, lessonObs, type);
    }

    createBundleGranule(metadata, forCopy, lessonObs, type): Observable<DataEntity> {
        return this.createTags(metadata.indexation, forCopy).pipe(mergeMap((tags: DataEntity[]) => {
            const originalTags = metadata.indexation;
            metadata.indexation = tags.map((tag) => +tag.id);
            metadata.chapters = [metadata.chapters];
            const metadataObs = this.activitiesService.createGranuleMetadata(metadata);
            metadata.indexation = originalTags;

            const combined: Observable<any[]> = combineLatest([lessonObs, metadataObs]).pipe(take(1));

            return combined.pipe(mergeMap((entities: DataEntity[]) => {
                return this.activitiesService.createGranuleInterface({
                    format: this.activitiesService.getFormatId(type),
                    reference: +entities[0].id,
                    metadatas: +entities[1].id,
                    theme: metadata.theme
                });
            }));
        }));
    }

    public createActivitiesByTypes(types: any[]): Observable<DataEntity>[] {
        const obsContainer: Observable<DataEntity>[] = [];
        for (const type of types) {
            switch (type.type) {
                case 'LESSON':
                    const obsActivities: Observable<DataEntity>[] = [];
                    if (type.dataFromStep) {
                        if (type.summary) {
                            const obsActivity = this.createActivitySummary({type: 'SUMMARY'}, type.config);
                            obsContainer.push(combineLatest([this.obsEntitiesForDuplicate, obsActivity]).pipe(
                                mergeMap((entities: [DataEntity[], DataEntity]) => {
                                    const allActivities = [];
                                    allActivities.push(...entities[0], entities[1]);
                                    return this.createLesson({}, 'lesson', false, allActivities);
                                })));
                        } else {
                            obsContainer.push(obsContainer[type.dataFromStep.stepIndex]);
                        }
                    } else {
                        for (const subLessonType of type.subLessonType) {
                            switch (subLessonType) {
                                case 'POLL':
                                    obsActivities.push(this.createActivityQcm('CRT', {
                                        wording: '',
                                        answers: ['']
                                    }, type.config));
                                    break;
                                case 'SUMMARY':
                                    obsActivities.push(this.createActivitySummary({type: 'SUMMARY'}, type.config));
                                    break;
                                default:
                                    break;
                            }
                        }
                        const combine = combineLatest(obsActivities);
                        this.obsEntitiesForDuplicate = combine;
                        obsContainer.push(combine.pipe(mergeMap((entities: DataEntity[]) => {
                            return this.createLesson({}, 'lesson', false, entities);
                        })));
                    }
                    break;
                case 'VIDEO':
                    obsContainer.push(this.createActivityVideo(type, type.config));
                    break;
                case 'SUMMARY':
                    obsContainer.push(this.createActivitySummary(type, type.config));
                    break;
                case 'MULTI':
                    obsContainer.push(this.activitiesService.createMultimediaActivity({activity: {config: type.config}}));
                    break;
                default:
                    obsContainer.push(this.activitiesService.createGenericActivity(type, {activity: {config: type.config, activity_content: type.activity_content}}));
                    break;
            }
        }

        return obsContainer;
    }

    public createAnswers(answers: string[]): Observable<DataEntity>[] {
        return answers.map((answer) => {
            return this.createActivitiesAnswer({answer});
        });
    }

    public createActivityQcm(typology, data, config = null): Observable<DataEntity> {
        return combineLatest(this.createAnswers(data.answers)).pipe(
            mergeMap((answerEntities: DataEntity[]) => this.createActivitiesQcm({answers: answerEntities.map((entity) => +entity.id)})),
            mergeMap((qcmEntity: DataEntity) => {
                const metadataObs = this.activitiesService.createGranuleMetadata({
                    typology: this.activitiesService.getTypologyId(typology)
                });
                const dataObs = this.activitiesService.createActivitiesActivityInterface({
                    instruction: data.wording,
                    activity_content: +qcmEntity.id,
                    config: config
                });

                const combined: Observable<DataEntity[]> = combineLatest(metadataObs, dataObs).pipe(take(1));

                return combined.pipe(mergeMap((entities: DataEntity[]) => {
                    return this.activitiesService.createGranuleInterface({
                        format: this.activitiesService.getFormatId('activity'),
                        metadatas: +entities[0].id,
                        reference: +entities[1].id
                    });
                }));
            }),
            take(1)
        );
    }

    public createActivityVideo(typology, config = null): Observable<DataEntity> {
        const metadataObs = this.activitiesService.createGranuleMetadata({
            typology: this.activitiesService.getTypologyId(typology.type),
            title: ''
        });

        const dataObs = this.activitiesService.createActivitiesActivityInterface({
            instruction: '',
            activity_content: null, // set the media's 'id' fetch from created entity 'media'
            config: config
        });

        return combineLatest(metadataObs, dataObs).pipe(mergeMap((entities: DataEntity[]) => {
            return this.activitiesService.createGranuleInterface({
                format: this.activitiesService.getFormatId('activity'),
                metadatas: +entities[0].id,
                reference: +entities[1].id
            });
        }));
    }

    public createActivitySummary(typology, config = null): Observable<DataEntity> {
        const metadataObs = this.activitiesService.createGranuleMetadata({
            typology: this.activitiesService.getTypologyId(typology.type),
            title: ''
        });

        const dataObs = this.activitiesService.createActivitiesActivityInterface({
            instruction: '',
            activity_content: null, // set the media's 'id' fetch from created entity 'media'
            config: config
        });

        return combineLatest(metadataObs, dataObs).pipe(mergeMap((entities: DataEntity[]) => {
            return this.activitiesService.createGranuleInterface({
                format: this.activitiesService.getFormatId('activity'),
                metadatas: +entities[0].id,
                reference: +entities[1].id
            });
        }));
    }

    /**
     * Create an activity with the good typology but with potentially no activity_content
     * @param typology should be the typology object or an object with this format {label: string}
     * @param options List of defaults values
     *
     * @remarks Be aware that no activityContent will not work, if you not give an activity content id, you have to add it after.
     */
    public createGenericActivity(typology: { label: string }, options: GenericActivityOptionsInterface = {}): Observable<DataEntity> {
        const requiredMetadatasValues = {
            typology: this.activitiesService.getTypologyId(typology.label),
            title: '',
            language: this.translate.currentLang
        };

        const requiredActivityInterfaceValues = {
            instruction: '',
            activity_content: null,
            config: null
        };

        const metadataObs = this.createGranuleMetadata(_.merge(requiredMetadatasValues, _.get(options, 'metadatas')));
        const activityInterfaceObs = this.createActivitiesActivityInterface(_.merge(requiredActivityInterfaceValues, _.get(options, 'activity')));

        return combineLatest([metadataObs, activityInterfaceObs]).pipe(mergeMap(([metadatas, activityInterface]) => {
            return this.createGranuleInterface({
                format: this.activitiesService.getFormatId('activity'),
                metadatas: +metadatas.id,
                reference: +activityInterface.id
            });
        }));
    }

    /**
     * Create an activity granule. Activity granule should reference an Activity entity.
     * But in some case, we do not know what kind of data should be set in the activities.
     * For this, we allow the creation of empty activity granule.
     * (A metadata granule are created by default with empty title)
     * @param typologyLabel string to reference the typology in {@link allTypes}
     * @todo Not used for now but it's a seed for a refact of Granule creation
     */
    public createEmptyActivityGranule(typologyLabel: string): Observable<DataEntity> {
        return this.activitiesService.createGranuleMetadata({
            typology: this.activitiesService.getTypologyId(typologyLabel),
            title: ''
        }).pipe(mergeMap(metadataGranule => {
            return this.activitiesService.createGranuleInterface({
                format: this.activitiesService.getFormatId('activity'),
                metadatas: +metadataGranule.id
            });
        }));
    }

    // TODO : Rendre generique cette fonction pour l'uitlisier dans le video-editor
    public saveActivityQcm(data): Observable<(DataEntity | DataEntity[] | boolean[])[]> {
        const combinationObs: Observable<DataEntity | DataEntity[] | boolean[]>[] = [];
        const newOrderObs: Observable<(DataEntity | boolean)[]>[] = [];
        const granule = this.getCurrentActivity();
        const activity = <DataEntity>granule.getEmbed('reference');
        const metadatas = <DataEntity>granule.getEmbed('metadatas');

        activity.set('instruction', data.wording);

        const answerObs: Observable<DataEntity>[] = [];
        const qcmEntity = new DataEntity('qcm', activity.get('activity_content'), this.octopusConnect);
        const answers = qcmEntity.get('answers');

        const removalObs: Observable<boolean>[] = [];
        const removal = answers.splice(data.answers.length);
        if (removal.length) {
            removal.forEach((answer) => {
                const answerEntity = new DataEntity('answer', answer, this.octopusConnect);
                removalObs.push(answerEntity.remove());
            });
        }

        answers.forEach((answer, index) => {
            if (answer.answer !== data.answers[index]) {
                const answerEntity = new DataEntity('answer', answer, this.octopusConnect);
                answerEntity.set('answer', data.answers[index]);
                answerObs.push(answerEntity.save());

                answer.answer = data.answers[index];
            }
        });

        const newAnswers = this.createAnswers(data.answers.slice(answers.length));
        answerObs.push(...newAnswers);

        if (newAnswers.length) {
            newOrderObs.push(combineLatest(newAnswers));
        }

        if (!isEmpty(metadatas.getDiff())) {
            combinationObs.push(metadatas.save());
        }

        if (!isEmpty(activity.getDiff())) {
            combinationObs.push(activity.save());
            granule.get('reference').instruction = data.wording;
        }

        if (answerObs.length) {
            combinationObs.push(combineLatest(answerObs));
        }

        if (removalObs.length) {
            combinationObs.push(combineLatest(removalObs));
            newOrderObs.push(combineLatest(removalObs));
        }

        if (newOrderObs.length) {
            combinationObs.push(combineLatest(newOrderObs).pipe(
                take(1),
                mergeMap((data) => {
                    const answerList = answers.map(answer => answer.id);

                    if (newAnswers.length) {
                        answerList.push(...data[0].map((answer: DataEntity) => answer.id));
                        answers.push(...data[0].map((answer: DataEntity) => {
                            const answerData = _.cloneDeep(answer.attributes);
                            answerData.id = answer.id;
                            return answerData;
                        }));
                    }

                    qcmEntity.set('answers', answerList);

                    return qcmEntity.save();
                })
            ));
        }

        if (combinationObs.length) {
            return combineLatest(combinationObs).pipe(take(1));
        } else {
            const noObs = new ReplaySubject<(DataEntity | DataEntity[] | boolean[])[]>(1);
            noObs.next([]);
            return noObs;
        }
    }


    public addActivityToForm(activity: DataEntity): void {
        this.getFormObs(this.selectedLessonId.toString()).pipe(
            take(1))
            .subscribe((formEntity: DataEntity) => {
                const activityList = formEntity.get('reference');

                const activityData = activity.attributes;
                activityData.id = activity.id.toString();
                activityList.push(activityData);
                this.activitiesToClean.push(activityData);
            });
    }

    /**
     * TODO It's look like, the logic of editing a lesson are too much included in this service. But it's have to be separate between :
     * - Lesson service, able to do the exchange between front & back and the controls before it or able to do the generic actions about lessons
     * - A component, able to do the exchange between the service and the user, the controls before it and use the service generic actions for specific need
     * The service is shared between all the application as a Singleton. He don't have to be able to remember the temporary activities to delete, the current lesson, etc.
     * example :
     * - It's the role of the component to know which activities are to delete, which lesson are currently used, and the role of service to delete activity by example
     * - The button save is a 'View' context intelligence 0and the ability of be active or not it's a 'controller' context, the both has to be in a component
     * @param activities
     */
    public setActivitiesOfSelectedLesson(activities: DataEntity[]): void {
        this.getLessonObs(this.selectedLessonId.toString()).pipe(
            take(1))
            .subscribe((lessonEntity: DataEntity) => {
                const activityListAlreadyInLesson = lessonEntity.get('reference');

                activities.forEach((activity: DataEntity) => {
                    const activityData = activity.attributes;
                    activityData.id = activity.id.toString();
                    let activityIndex = activityListAlreadyInLesson.map(element => +element.id).indexOf(+activity.id);

                    if (activityIndex === -1) { // true if activity is new in lesson
                        activityListAlreadyInLesson.push(activityData);
                        this.activitiesToClean.push(activityData);
                    } else { // Don't know why we need to override
                        activityListAlreadyInLesson[activityIndex] = activityData;
                    }

                    activityIndex = this.activitiesToDelete.findIndex(element => +element.id === +activity.id);

                    if (activityIndex > -1) {
                        this.activitiesToDelete.splice(activityIndex, 1);
                    }
                });

                this.onLessonUpdate.next(lessonEntity);

                this.btnSave = true;
            });
    }

    /**
     * edit activity video : if activity-interface doesnt exist : this.currentActivitiesEdited[0].get('reference'), we create media
     * then we patch the reference 'activity_content_patch' with the id of the media (granule resource associated)
     * then we do the same for the others activities video that need to have the same reference attributes.
     * @param resource
     * @returns {any}
     */
    public editActivityVideo(resource): any {
        const granule = {
            granule: resource.ressourceEntity.id
        };

        if (this.currentActivitiesEdited[0].get('reference') && typeof this.currentActivitiesEdited[0].get('reference') === 'string') {
            const obsAllActivityRef = this.currentActivitiesEdited.map((entity) => {
                return this.activitiesService.loadActivityInterface(entity.get('reference'));
            });
            const obsAllActivitymedia = this.currentActivitiesEdited.map(() => {
                return this.activitiesService.createMedia(granule);
            });

            const entitiesMedia = [];

            return combineLatest(...obsAllActivitymedia).pipe(
                mergeMap((medias: DataEntity[]) => {
                    entitiesMedia.push(...medias);
                    return combineLatest(...obsAllActivityRef).pipe(
                        mergeMap((refs: DataEntity[]) => {
                            const obsActivityContent = entitiesMedia.map((media, index) => {
                                refs[index].set('activity_content_patch', media.id);
                                return refs[index].save();
                            });

                            return combineLatest(...obsActivityContent).pipe(
                                mergeMap((refEntities: DataEntity[]) => {
                                    const obsActivitiesSaved = this.currentActivitiesEdited
                                        .map((activity, index) => {
                                            activity.set('reference', refEntities[index].id);
                                            activity.set('format', activity.get('format').id);
                                            return activity.save();
                                        });
                                    return combineLatest(...obsActivitiesSaved);

                                }));
                        }));
                }));
        } else {

            const obsActivityMedia = this.currentActivitiesEdited
                .map((media, index) => {
                    const entity = new DataEntity(
                        'media',
                        this.currentActivitiesEdited[index].get('reference').activity_content[index],
                        this.octopusConnect,
                        this.currentActivitiesEdited[index].get('reference').activity_content[index].id
                    );

                    entity.set('granule', resource.ressourceEntity.id);

                    return entity.save();
                });

            return combineLatest(...obsActivityMedia);
        }
    }

    public removeActivityFromForm(activity: DataEntity): void {
        this.getFormObs(this.selectedLessonId.toString()).pipe(
            take(1))
            .subscribe((formEntity) => {
                const activityList = formEntity.get('reference');

                const activityIndex = activityList.findIndex((element) => +element.id === +activity.id);
                if (activityIndex > -1) {
                    activityList.splice(activityIndex, 1);
                }
            });
    }

    public removeActivityFromLesson(activity: DataEntity): void {
        this.getLessonObs(this.selectedLessonId.toString()).pipe(
            take(1))
            .subscribe((lessonEntity) => {
                const activityList = lessonEntity.get('reference');

                const activityIndex = activityList.findIndex((element) => +element.id === +activity.id);
                if (activityIndex > -1) {
                    activityList.splice(activityIndex, 1);
                    this.activitiesToDelete.push(activity);
                }

                this.btnSave = true;

            });
    }

    public saveFormOrLessonMetadata(id, metadata, type): Observable<DataEntity> {
        const updatedMetadataObs = new ReplaySubject<DataEntity>(1);
        const initializeObs = new Subject<DataEntity>();

        initializeObs.subscribe(entity => {
            if (entity) {
                const metadatas = <DataEntity>entity.getEmbed('metadatas');
                metadatas.set('title', metadata.title);
                metadatas.set('licenseContent', metadata.licenseContent);
                metadatas.set('description', metadata.description);
                metadatas.set('educationalLevel', metadata.educationalLevel);
                metadatas.set('chapters', metadata.chapters);
                metadatas.set('assignation_type', metadata.assignation_type);
                metadatas.set('difficulty', metadata.difficulty);
                metadatas.set('skills', metadata.skills);
                metadatas.set('thumbnail', metadata.thumbnail);
                metadatas.set('files', metadata.files);
                metadatas.set('source-author', metadata['source-author']);
                switch (type) {
                    case ('form'):
                        metadatas.save().subscribe(updatedMetadata => {
                            updatedMetadataObs.next(updatedMetadata);
                        });
                        break;
                    case ('lesson'):
                        if (metadata.tagModified === true) { // number of tag increased
                            this.createTags(metadata.indexation).pipe(
                                take(1))
                                .subscribe(data => {
                                    metadatas.set('indexation', metadata.indexation.map((tag) => +tag.id)); // keep only ids for PATCH
                                    delete metadata.tagModified; // remove value before save
                                    metadatas.save().subscribe(updatedMetadata => {
                                        updatedMetadataObs.next(updatedMetadata);
                                    });
                                    metadatas.set('indexation', metadata.indexation); // add complete objects with labels
                                });
                        } else {
                            metadatas.save().subscribe(updatedMetadata => {
                                updatedMetadataObs.next(updatedMetadata);
                            });
                        }
                        break;
                }
            }
        });

        switch (type) {
            case ('form'):
                initializeObs.next(this.getForm(id));
                break;
            case ('lesson'):
                this.getLessonObs(id).pipe(
                    take(1))
                    .subscribe(lesson => {
                        initializeObs.next(lesson);
                    });
                break;
        }

        return updatedMetadataObs;
    }

    public saveMetadatas(id, metadata, type): Observable<DataEntity> {
        let entity: any;


        switch (type) {
            case ('form'):
                entity = this.getForm(id);
                break;
            case ('lesson'):
                entity = this.getLesson(id);
                break;
            default:
        }

        if (entity) {
            const metadatas = <DataEntity>entity.getEmbed('metadatas');
            metadatas.set('changed', entity.attributes.changed); // force metadatas changed date from granule

            switch (type) {
                case ('form'):
                    return metadatas.save();
                // break;
                case ('lesson'):
                    return metadatas.save();
                // break;
                default:
            }
        }

        return null;
    }

    public saveFormActivities(id): Observable<DataEntity> {
        const formEntity = this.getForm(id.toString());
        const activityList = formEntity.get('reference').map((activity) => +activity.id);
        const lessonStepEntity = new DataEntity('lesson', {lesson_step: []}, this.octopusConnect, formEntity.get('lesson_id'));

        // TODO - Restore DataEntity cleanup once octopus-connect is fixed
        /* this.activitiesToClean.forEach((activity) => {
            this.removeActivityFromForm(activity);
        });
        this.clearTempActivities(); */

        lessonStepEntity.set('lesson_step', activityList);

        let loading = false;
        let timeout = null;
        const saveObs = lessonStepEntity.save();
        saveObs.pipe(take(2)).subscribe(lesson => {
            if (!loading) {
                loading = true;
                timeout = setTimeout(() => this.reloadForm(formEntity.id), 500);
            } else {
                if (timeout) {
                    clearTimeout(timeout);
                    this.reloadForm(formEntity.id);
                }
            }
        });

        return saveObs.pipe(take(1));
    }

    public saveLessonActivities(id): Observable<DataEntity> {
        const lessonEntity = this.getLesson(id.toString());
        const activityList = lessonEntity.get('reference').map((activity) => +activity.id);
        const lessonStepEntity = new DataEntity('lesson', {lesson_step: []}, this.octopusConnect, lessonEntity.get('lesson_id'));

        this.activitiesToDelete.forEach((activity: DataEntity) => {
            if (activity.attributes.format.label === 'divider') {
                activity.remove();
            }
        });

        this.activitiesToDelete = [];

        lessonStepEntity.set('lesson_step', activityList);

        return lessonStepEntity.save().pipe(take(1));
    }

    public saveFromModalToLesson(lessonContent): void {
        this.currentLesson.set('reference', lessonContent);
        this.saveLessonActivities(+this.selectedLessonId).pipe(
            take(1))
            .subscribe((entity) => {
                this.saveMetadatas(this.selectedLessonId, [], 'lesson');
                this.clearTempActivities();
            });
    }

    public updateCurrentLesson(selectedLesson): void {
        this.currentLesson = selectedLesson;
        this.selectedLessonId = this.currentLesson.id.toString();
    }

    public clearTempActivities(): void {
        const lessonEntity = this.getLesson(this.selectedLessonId.toString());
        const activityList = lessonEntity.get('reference');

        this.activitiesToDelete.forEach((activity: DataEntity) => {
            const activityData = activity.attributes;
            activityData.id = activity.id.toString();
            activityList.push(activityData);
        });
        this.activitiesToClean.forEach((activity) => {
            this.removeActivityFromForm(activity);
        });

        this.activitiesToClean = [];
        this.activitiesToDelete = [];

        this.btnSave = false;

    }

    public saveFeedbackUserSave(chooseActionForActivity: string, userSave: DataEntity): void {
        userSave.set('feedback', chooseActionForActivity);
        userSave.save();
    }

    public isLessonLaunched(): boolean {
        return this.isLessonTest() || this.isLessonTraining() || this.isLessonEvaluation() || this.isAssignmentWithMetacognition();
    }

    public isLessonTest(): boolean {
        return !this.currentAssignment && this.authenticationService.isAtLeastTrainer();
    }

    public isLessonTraining(): boolean {
        return (this.currentAssignment &&
            this.currentAssignment.get('type_term') &&
            this.currentAssignment.get('type_term').label === 'training') ||
            this.lessonRunTraining;
    }

    public isLessonEvaluation(): boolean {
        return this.currentAssignment &&
            this.currentAssignment.get('type_term') &&
            (
                this.currentAssignment.get('type_term').label === 'assessment' ||
                this.currentAssignment.get('type_term').label === 'homework'
            );
    }

    public isLessonValidated(): boolean {
        return this.currentAssignment &&
            this.currentAssignment.get('state_term') &&
            this.currentAssignment.get('state_term').label === 'closed';
    }

    public isLessonCorrected(): boolean {
        return this.currentAssignment &&
            this.currentAssignment.get('dates') &&
            +this.currentAssignment.get('dates').value2 &&
            +this.currentAssignment.get('dates').value2 < (Date.now() / 1000);
    }

    public isMyAssignment(): boolean {
        return this.currentAssignment &&
            this.authenticationService.isMe(this.currentAssignment.get('assignated_user').uid);
    }

    public isTrainerSeeCorrection(): boolean {
        return this.isLessonEvaluation() &&
            (this.isAtLeastTrainer() || this.isLessonCorrected());
    }

    public lessonDuplication(id, type): Observable<DataEntity> {
        const saveObs = this.octopusConnect.createEntity('granule-lesson', {
            duplicateId: id,
            language: this.translate.currentLang,
        });
        saveObs.pipe(take(1)).subscribe((entity: DataEntity) => {
            switch (type) {
                case 'currentUser':
                    break;
                case 'byRole':
                    // redirect from models tab to user lessons tab
                    this.selectedIndexChange(0);
                    break;
            }
            if (this.userLessonsPaginated) {
                this.userLessonsPaginated.paginator.reload(); // refresh lessons ab
            }

            if (this.lessonsPaginated) {
                this.lessonsPaginated.paginator.reload(); // refresh models tab
            }
            return entity;
        }, (error: InterfaceError) => {
            return error;
        });

        return saveObs.pipe(take(1));

    }

    selectedIndexChange(val): void {
        this.selectedTabIndex = val;
    }

    public checkAccess(user: string[]): boolean {
        if (user) {
            return this.authenticationService.hasLevel(user);
        }
        return false;
    }

    public isAtLeastTrainer(): boolean {
        return this.authenticationService.isAtLeastTrainer();
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    localeDate(date: number): string {
        return localizedDate(date);
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    localeTime(date: number): string {
        return localizedTime(date);
    }

    getDate(resource: any): string {
        if (resource) {
            let translateCreated = '';
            let translateChanged = '';
            let completeSentence = '';
            this.translate.get('generic.created_on').subscribe((translate) => {
                translateCreated = translate;
            });
            this.translate.get('generic.modify-date').subscribe((translate) => {
                translateChanged = translate;
            });

            completeSentence += ' ' + translateCreated;
            completeSentence += ' ' + resource.LocaleDateCreated;
            completeSentence += ' ' + '-';
            completeSentence += ' ' + translateChanged;
            completeSentence += ' ' + resource.LocaleDateChanged;
            return completeSentence;

        }
        return '';
    }

    public loadLessonById(id: string | number): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('granule-lesson', id);
    }

    setAssignGrade(rate: number, oldRate: number): void {
        if (this.isLessonEvaluation() && this.settings.grade) {
            let activitiesLength;
            if (!this.currentLesson) {
                const lessonIndex = this.lessons
                    .findIndex(lesson => +lesson.id === +this.currentAssignment.get('assignated_node').id);
                activitiesLength = this.lessons[lessonIndex].get('infos').activities;
            } else {
                activitiesLength = this.currentLesson.get('infos').activities;
            }

            const ratingBase = 20;
            const grade = +this.currentAssignment.get('grade');
            const sensitivity = 10000;

            const newNote = Math.floor(rate * (ratingBase / activitiesLength) * sensitivity) / sensitivity;
            const oldNote = Math.floor(oldRate * (ratingBase / activitiesLength) * sensitivity) / sensitivity;

            let newGrade;

            newGrade = Math.max((grade - oldNote) + newNote, 0);
            if (newGrade > 0) {
                newGrade = Math.min((grade - oldNote) + newNote, 20);
            }

            this.activityOldGradePercent = Math.round(oldRate * 100);
            this.activityGradePercent = Math.round(rate * 100);
            this.grade = newGrade;
            this.activityGrade = newNote;
            this.activityOldGrade = oldNote;

            console.log('ancienne note de l\'exo en % : ' + this.activityOldGradePercent + '%');
            console.log('nouvelle note de l\'exo en % : ' + this.activityGradePercent + '%');
            console.log('ancienne note sur la base : ' + this.activityOldGrade);
            console.log('nouvelle note sur la base : ' + this.activityGrade);
            console.log('note de l\'assignation : ' + this.grade);

            this.communicationCenter
                .getRoom('assignation')
                .getSubject('saveGrade')
                .next({grade: newGrade, idAssign: this.currentAssignment.id});
        }
    }

    public initDebugGrade(): void {
        this.activityOldGradePercent = 0;
        this.activityGradePercent = 0;
        this.grade = 0;
        this.activityGrade = 0;
        this.activityOldGrade = 0;
    }

    setProgress(usersave, status): void {
        if (this.currentAssignment && this.settings.progress) {
            let progress = 0;
            let defaultValueProgress;

            const state = usersave && usersave.get('state') ? usersave.get('state') : null;

            let activitiesLength;

            const oldProgress = +this.currentAssignment.get('progress');

            if (!this.currentLesson) {
                const lessonIndex = this.lessons
                    .findIndex(lesson => +lesson.id === +this.currentAssignment.get('assignated_node').id);
                activitiesLength = this.lessons[lessonIndex].get('infos').activities;
            } else {
                activitiesLength = this.currentLesson.get('infos').activities;
            }

            defaultValueProgress = (100 / activitiesLength);

            if ((!state || state === 'incomplete') && +status !== 2) {
                progress += (oldProgress + defaultValueProgress);


                if (this.activitiesService.activityAnswerResult.length === this.activitiesService.activitiesArray.length &&
                    this.activitiesService.activityAnswerResult.indexOf(2) === -1) {
                    progress = 100;
                }

                this.saveProgress(progress);
            }
        }
    }

    private saveProgress(progressValue: number): void {
        if (progressValue === null || progressValue === undefined) {
            throw new Error(`progressValue cannot be null or undefined`);
        } else if (progressValue < 0 || progressValue > 100) {
            throw new Error(`progressValue must be between 0 and 100. Current value : ${progressValue}`);
        }

        this.communicationCenter
            .getRoom('assignation')
            .getSubject('saveProgress')
            .next({progress: progressValue, idAssign: this.currentAssignment.id});
    }

    public loadRecapScreen(): void {
        let currentRoute = this.router.routerState.root;
        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
        }

        this.activitiesService.playScreenStatus = 3;
        this.activitiesService.endScreenSeen = true;
        this.router.navigate(['recap'], {relativeTo: currentRoute});
    }

    public get isAtLeastTrainerAndAssignmentExist(): boolean {
        return this.isAtLeastTrainer() && !!this.currentAssignment;
    }

    public goBackToForm(): void {
        this.communicationCenter.getRoom('lessons').next('openEditor', this.selectedLessonId.toString());
    }

    public loadAndNavigateToLesson(id: string, options?: NavigateToLessonOptions): void {
        this.loadLessonById(id).pipe(
            take(1))
            .subscribe((lesson) => {
                this.navigateToLesson(lesson, options);
            });
    }

    /**
     * Launch the lesson player from everywhere
     * @param lesson
     * @param options : `exitLessonUrl` Set the rollback url. If undefined, the rollback url will be the current url before navigation.
     *
     * @remarks TODO This methods do some things that should be move to 'after navigation' for allow the lesson to be load from the url.
     */
    public navigateToLesson(lesson: DataEntity, options?: NavigateToLessonOptions, preview?: boolean): void {
        let label = '';
        if (lesson.attributes.metadatas && lesson.attributes.metadatas.assignation_type && lesson.attributes.metadatas.assignation_type.label) {
            label = lesson.attributes.metadatas.assignation_type.label;
        }

        this.eventService.trackEvent(
            'Start lesson',
            (preview ? 'Start preview' : 'Start lesson') + ' ' + label,
            lesson.id + ' ' + lesson.get('metadatas').title
        );

        if (preview) {
            this.activitiesService.resetActivitiesInCache();
            this.resetActivitiesArray();
        }
        this.activitiesService.pushLessonFromAssignment.next(lesson);
        this.activitiesService.currentLesson = lesson;
        this.activitiesService.isLessonPlay = true;
        this.activitiesService.playScreenStatus = 1;
        this.activitiesService.presentArrayElementIndex = 0;

        // consulted flag
        let flaggingId;

        if (typeof (lesson.get('consulted')) === 'object') {
            flaggingId = lesson.get('consulted').flagging_id;
        }

        this.flagService.updateFlagEntity(lesson, 'node', 'consulted', flaggingId);
        this.exitLessonUrl = _.get(options, 'exitLessonUrl', this.router.url);
        this.navigateService.constructUrlAndNavigateToLesson(+lesson.id, false, false, options);
    }

    /**
     * Return settings value
     */
    public displayLearnerInfo(): boolean {
        return this.settings.displayLearnerInfo;
    }

    /**
     * Return list of available add buttons filtered by current user level
     */
    public getAvailableAddButtons(): string[] {
        let availableAddButtons = this.settings.availableAddButtons[this.authenticationService.accessLevel];
        if (availableAddButtons === undefined) {
            availableAddButtons = this.settings.availableAddButtons['default'];
        }
        return availableAddButtons;
    }

    /**
     * A multimedia activity is a child lesson with limited granule type for children.
     * This methods will :
     *  - create a new lesson with lesson type to 'multimedia'
     *  - affect the new lesson type to be a child of the current lesson
     * @param lessonGranule
     * @return The new lesson as multimedia Activity
     */
    public addChildMultimediaActivity(lessonGranule: DataEntity): Observable<DataEntity> {
        const metadata = {
            typology: this.activitiesService.getTypologyId('multi') // multimedia typology label
        };

        return this.createLessonAndGranule(metadata, false, [], 'lesson').pipe(
            tap(subLessonGranule => {
                this.setActivityAsChildOfLesson(lessonGranule, subLessonGranule);
            })
        );
    }

    /**
     * From granule and only granule, set an activity child of lesson.
     * After that you can retrieve the activity in the lessonGranule.reference array.
     * @param lessonGranule Parent lesson
     * @param activityGranule Could be another lesson for make a multiActivity
     * @return lessonGranule updated
     */
    public setActivityAsChildOfLesson(lessonGranule: DataEntity, activityGranule: DataEntity): Observable<DataEntity> {
        // INFO : There are a difference between granule of lesson and lesson.
        // A granule of lesson is another DataEntity with the values valued by lesson dataEntity
        // For add a child to a lesson we have to add the granule child id to the reference attribute of the parent lesson (and not of the parent granule)

        const lessonEntity = new DataEntity('lesson', {lesson_step: []}, this.octopusConnect, lessonGranule.get('lesson_id'));
        const activityList = lessonGranule.get('reference')
            .map((activity) => +activity.id);
        activityList.push(+activityGranule.id);
        lessonEntity.set('lesson_step', activityList);
        return lessonEntity.save().pipe(take(1));
    }

    /**
     *  From granule and only granule, set activities children of lesson.
     * @param lessonGranule Parent lesson
     * @param activitiesGranules Could includes another lesson for make a multiActivity
     * @param replace If true, current parent lesson references will be erased for activitiesGranules. If false, activitiesGranules will be add next to the already existing children activities
     */
    public setActivitiesAsChildrenOfLesson(lessonGranule: DataEntity, activitiesGranules: DataEntity[], replace = false): Observable<DataEntity> {
        const lessonEntity = new DataEntity('lesson', {lesson_step: []}, this.octopusConnect, lessonGranule.get('lesson_id'));
        const activityList = replace ? [] : lessonGranule.get('reference').map((activity) => +activity.id);

        activityList.push(...activitiesGranules.map(activityGranule => +activityGranule.id));
        lessonEntity.set('lesson_step', activityList);
        return lessonEntity.save().pipe(take(1));
    }

    /**
     * edit and save granule lesson
     * @param resource
     * @param values
     */
    public editGranuleLesson(resource, values): void {
        const lesson =
            new DataEntity(
                'granule',
                resource,
                this.octopusConnect,
                resource.id);
        for (const field in values) {
            lesson.set(field, values[field]);
        }
        lesson.save();
    }

    /**
     * we can know if the user role is manager
     * @returns {boolean}
     */
    public get isUserManager(): boolean {
        return this.authenticationService.isManager();
    }

    /**
     * Obtain Skills from the server. ( or history period )
     * @return List of {@link DataEntity}
     */
    getSkills(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('skills');
    }

    /**
     * Obtain themes from the server.
     * @return List of {@link DataEntity}
     */
    getThemes(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('themes');
    }

    /**
     * Return list of allowed combination of media.
     * Currently used by multimedia activities.
     */
    public getAllowedMediaTypeCombination(): string[][] {
        return this.settings.allowedMediaTypeCombination;
    }

    /**
     * Return array of Id. Each id represent the role ID defined by ther server
     *
     * @example `[4, 3]` for manager and administrator (if manager id is 4 and administrator id is 3)
     */
    public getAllowedRoleIdsForModelsCreation(): number[] {
        return this.getAllowedRolesForModelsCreation().map(role => this.roleNameToIdMapping[role]);
    }

    /**
     * Return array of role name.
     *
     * @example `['manager', 'administrator']`
     */
    public getAllowedRolesForModelsCreation(): string[] {
        return this.settings.allowedRolesForModelsCreation;
    }

    /**
     * Generically call a tool to be executed with the given lesson as reference.
     * @param tool The target tool setting to execute
     * @param currentLesson
     */
    public executeToolFromLesson(tool: { toolIdentifier: string; setting: PluginSetting }, currentLesson: { lesson: DataEntity, step?: number }): Observable<DataEntity> {
        const onComplete = new ReplaySubject<DataEntity>(1);
        const navigationOptions: NavigateToLessonOptions = {exitLessonUrl: this.exitLessonUrl};

        if (currentLesson.hasOwnProperty('step')) {
            navigationOptions.startOnStepIndex = currentLesson.step;
        }

        this.communicationCenter.getRoom(tool.setting.octopusConnectRoom)
            .next('execute', <LessonToolDataCommunicationCenterInterface>{
                lesson: currentLesson.lesson,
                onComplete: onComplete
            });

        return onComplete.pipe(
            tap(() => {
                this.loadAndNavigateToLesson(this.currentLesson.id.toString(), navigationOptions);
            })
        );
    }

    /**
     * load video's data for video help in lesson tab
     * @returns {Observable<DataEntity[]>}
     */
    public loadVideoHelpUrl(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('variables/helpVideos').pipe(
            map((collection) => collection.entities));
    }

    /**
     * Return the number of assignment created from a lesson.
     *
     * TODO For now, it's return the length of the entities (voluntary not paginated) because the 'count' property is not valued by octopusConnect (count = undefined)
     *
     * @param id unique identifier of the lesson
     */
    public getAssignmentsCountByLessonId(id: string | number): Observable<number> {
        const replaySubjectResults = this.getAssignmentsByLessonId(id);

        return replaySubjectResults.pipe(
            take(1),
            map(dataCollection => dataCollection.entities.length)
        );
    }

    /**
     * Return the assignments created from a lesson.
     *
     * TODO For now, it's return the the entities intentionally not paginated because there a not a lot of assignments for a lesson
     *
     * @param id unique identifier of the lesson
     */
    public getAssignmentsByLessonId(id: string | number): Observable<DataCollection> {
        const replaySubjectResults = new ReplaySubject<Observable<DataCollection>>(1);

        this.communicationCenter.getRoom('assignment').next('getAssignmentsByLesson$', {
            lessonId: id,
            onComplete: replaySubjectResults
        });

        return replaySubjectResults.pipe(
            take(1),
            mergeMap(dataCollection$ => dataCollection$)
        );
    }

    public deleteLessonAssignments(id: string | number): Observable<boolean[]> {
        const replaySubjectResults = new ReplaySubject<Observable<boolean[]>>(1);

        this.communicationCenter.getRoom('assignment').next('deleteLessonAssignments$', {
            lessonId: id,
            onComplete: replaySubjectResults
        });

        return replaySubjectResults.pipe(
            take(1),
            mergeMap(obs => obs)
        );
    }

    /**
     * save the lesson reference with the activities of the granule lesson
     * @param {number | string} lessonId
     * @param {DataEntity[]} activities
     * @returns {Observable<DataEntity>}
     */
    public saveLessonReference(lessonId: number | string, activities: DataEntity[]): Observable<DataEntity> {
        const lessonEntity = this.activitiesService.generateDataEntity({}, 'lesson', lessonId);
        lessonEntity.set('lesson_step', activities.map((activity) => +activity.id));

        return lessonEntity.save(true);
    }

    /**
     * save the lesson reference with the activities of the granule lesson
     * @param {number | string} lessonId
     * @param {DataEntity[]} activities
     * @returns {Observable<DataEntity>}
     */
    public saveLessonGranule(lessonGranule: DataEntity): Observable<DataEntity> {
        const granuleLesson = this.activitiesService.generateDataEntity(lessonGranule.attributes, 'granule', lessonGranule.id);
        granuleLesson.set('lesson_id', lessonGranule.get('lesson_id'));

        return granuleLesson.save(true);
    }

    public getLessonMetadataDialogFieldsForCurrentRole(): string[] {
        const availableRole = !!this.settings.lessonMetadataDialogFields[this.authenticationService.accessLevel] ? this.authenticationService.accessLevel : 'default';
        return this.settings.lessonMetadataDialogFields[availableRole];
    }

    public openLessonMetadataDialog(lessonId: string | number): Observable<MatDialogRef<LessonMetadataDialogComponent>> {
        return this.getLessonObs(lessonId.toString()).pipe(
            take(1),
            map(granuleLesson =>
                this.dialog.open(LessonMetadataDialogComponent, {
                    data: {
                        metadatas: granuleLesson.get('metadatas'),
                        settings: {lessonMetadataDialogFields: this.getLessonMetadataDialogFieldsForCurrentRole()}
                    }
                }))
        );
    }

    /**
     * Return true if dynamic reward must be displayed in the recap.
     * True it's defined if the dynamic milestones are in the settings,
     * False it's defined if the milestones are null, undefined or an empty array in the settings.
     */
    public dynamicRewardIsDisplayed(): boolean {
        return _.get(this, 'getDynamicRewardMilestones()', []).length !== 0;
    }

    /**
     * Return the array of milestones from settings and used for the dynamic reward in the recap.
     */
    public getDynamicRewardMilestones(): number[] {
        return this.settings.dynamicRewardMilestones;
    }

    public isAssignmentWithMetacognition(): boolean {
        return this.currentAssignment && this.currentAssignment.get('type_term') &&
            this.currentAssignment.get('type_term').label === 'init';
    }

    public resetActivitiesArray(): void {
        this.currentAssignment = null;
        this.editSubLessonContentEdited = [];
        this.setCurrentLessonContentEdited([]);
    }

    /**
     * get multiple typologies ids
     * @param typologyLabel
     */
    public getTypologies(typologyLabel?: string): number[] {
        if (typologyLabel) {
            return [this.activitiesService.getTypologyId(typologyLabel)];
        }
        return this.settings.activitiesWithDefinedTypology.map((typology) => this.activitiesService.getTypologyId(typology));
    }

    /**
     * Return true if settings allow the activity list to be filtered by lessons data by default
     */
    public shouldSetDefaultOptionsOnActivityList(): boolean {
        return this.settings.shouldSetDefaultOptionsOnActivityList;
    }

    /**
     * set and save progress for assignment with metacognition, save is in assignation service
     * @param lessonNexted used to know if the next lesson in the assignment will be loaded.
     * so if the current lesson is the last in the assignment, we know the user progress will be 100%
     */
    public saveProgressInAssignmentWithMetacognition(lessonNexted: boolean = false, callBack?): void {
        if (this.authenticationService.userData && +this.currentAssignment.get('assignated_user').uid === +this.authenticationService.userData.id) {
            const percentValues = [0, 33, 66];
            const indexOfCurrentLesson = this.currentAssignment.get('assignated_nodes')
                .findIndex((lesson) => +lesson.id === +this.currentLesson.id);
            const progressValue = lessonNexted && indexOfCurrentLesson === this.currentAssignment.get('assignated_nodes').length - 1 ? 100 : percentValues[indexOfCurrentLesson];
            if (!this.currentAssignment.get('progress') || this.currentAssignment.get('progress') && Math.round(+this.currentAssignment.get('progress')) !== progressValue) {
                this.communicationCenter
                    .getRoom('assignation')
                    .next('saveProgress', {progress: progressValue});
            }
        }
        if (callBack) {
            callBack();
        }
    }
}
