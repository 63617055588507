import { Injectable } from '@angular/core';
import {ResearchSection} from '@modules/research-sheet/core/definitions';
import {ResearchSheetService} from '@modules/research-sheet/core/research-sheet.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {localizedDate, localizedTime} from '../../../../../shared/utils';

@Injectable()
export class ResearchSheetDisplayService {
    private sectionsList: ResearchSection[] = [];

    constructor(
        private sheetService: ResearchSheetService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('research-sheet')
            .getSubject('sectionsList')
            .subscribe((sections: ResearchSection[]) => this.sectionsList = sections);
    }

    // TODO - get current sheet with observable

    public getSection(sectionId: string): ResearchSection {
        let sectionFound: ResearchSection = null;

        this.sectionsList.some((section: ResearchSection) => {
            if (section.id.toString() === sectionId) {
                sectionFound = section;
                return true;
            }
        });

        return sectionFound;
    }

    goToSection(idSection: number): string{
        return this.sheetService.getSectionLink(+idSection);
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    localeDate(date: number): string {
        return localizedDate(date);
    }

    public localizedTime(date: number): string {
        const time = localizedTime(date);
        // remove seconds..
        return time.substr(0, time.length - 3);
    }
}
