
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {DataEntity, PaginatedCollection, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {FlagService} from '../../../../shared/flag.service';
import {Router} from '@angular/router';
import {CollectionOptionsInterface} from 'octopus-connect';
import {IThemeDto, ThemeDto} from '@modules/activities/core/modelsDto/theme-dto';

@Injectable({
    providedIn: 'root'
})
export class ThemesService {
    public themePaginated: PaginatedCollection;
    public pageIndex = 0;
    public pageRangeOptions = [12];

    public settings: { [key: string]: any };

    constructor(private octopusConnect: OctopusConnectService,
                private activitiesService: ActivitiesService,
                public flagService: FlagService,
                private router: Router,
    ) {}

    /***
     * load themes
     */
    public getThemes(filterOptions: CollectionOptionsInterface = {filter: {}, page: 1, range: 12}): Observable<IThemeDto[]> {
        const theme = new ThemeDto(this.octopusConnect.paginatedLoadCollection('theme_search', filterOptions));
        this.themePaginated = theme.getPaginated();
        return theme.getObjectDto();

    }

    /**
     * change info on paginated page when click on arrow
     * @param event : event off change page
     */
    public onPaginateChange(event): void {
        this.themePaginated.paginator.page = event.pageIndex + 1;
    }
}

