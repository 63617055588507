import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IntToBooleanPipe} from '@modules/activities/core/pipes/intToBoolean/int-to-boolean.pipe';
import {StringToHtmlPipe} from '@modules/activities/core/pipes/stringToHtml/string-to-html.pipe';
import { AddInputPipe } from './addInput/add-input.pipe';


@NgModule({
    imports: [ CommonModule ],
    declarations: [ IntToBooleanPipe, StringToHtmlPipe , AddInputPipe],
    exports: [ IntToBooleanPipe, StringToHtmlPipe, AddInputPipe ],
    providers: []
})
export class ActivitiesPipesModule {}
