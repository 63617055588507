import * as _ from "lodash";

import { ModelSchema, Structures } from "octopus-model";

import { Injectable } from "@angular/core";
import { modulesSettings } from "../../../../settings";

const settingsStructure: ModelSchema = new ModelSchema({
  groupType: Structures.number(2),
  workgroupType: Structures.number(3),
  institutionGroupType: Structures.number(52),
  primaryColors: Structures.array([
    "#D4E157",
    "#FFD740",
    "#F4511E",
    "#EC407A",
    "#4A148C",
    "#26C6DA",
  ]),
  accessGroupsTrainer: Structures.array(),
  accessGroupsManager: Structures.array(),
  canActiveMetacognition: Structures.object(null),
  canBeDelete: Structures.object(),
  canBeJoined: Structures.object({}),
  graphRoomId: Structures.string(""),
  displayedColumns: Structures.array([
    "checkbox",
    "avatar",
    "trainer-name",
    "email",
    "type",
    "access",
    "buttons",
  ]),
  displayedFiltersLearners: Structures.object({
    trainer: ["school", "workgroup", "groups", "archived", "button-video-help"],
    administrator: [
      "school",
      "workgroup",
      "groups",
      "archived",
      "button-video-help",
    ],
    manager: ["school", "workgroup", "groups", "archived", "button-video-help"],
    director: [
      "school",
      "workgroup",
      "groups",
      "archived",
      "button-video-help",
    ],
    default: ["school", "workgroup", "groups", "archived", "button-video-help"],
  }),
  displayedFiltersTrainers: Structures.object({
    trainer: ["school", "workgroup", "groups", "archived", "button-video-help"],
    administrator: [
      "school",
      "workgroup",
      "groups",
      "archived",
      "button-video-help",
    ],
    manager: ["school", "workgroup", "groups", "archived", "button-video-help"],
    director: [
      "school",
      "workgroup",
      "groups",
      "archived",
      "button-video-help",
    ],
    default: ["school", "workgroup", "groups", "archived", "button-video-help"],
  }),
  displayedFiltersGroups: Structures.object({
    trainer: ["school", "workgroup", "groups", "archived", "button-video-help"],
    administrator: [
      "school",
      "workgroup",
      "groups",
      "archived",
      "button-video-help",
    ],
    manager: ["school", "workgroup", "groups", "archived", "button-video-help"],
    director: [
      "school",
      "workgroup",
      "groups",
      "archived",
      "button-video-help",
    ],
    default: ["school", "workgroup", "groups", "archived", "button-video-help"],
  }),
  displayedFiltersWorkGroups: Structures.object({
    trainer: ["school", "workgroup", "groups", "archived", "button-video-help"],
    administrator: [
      "school",
      "workgroup",
      "groups",
      "archived",
      "button-video-help",
    ],
    manager: ["school", "workgroup", "groups", "archived", "button-video-help"],
    director: [
      "school",
      "workgroup",
      "groups",
      "archived",
      "button-video-help",
    ],
    default: ["school", "workgroup", "groups", "archived", "button-video-help"],
  }),
  displayedFiltersInstitutions: Structures.object({
    trainer: ["school", "workgroup", "groups", "archived", "button-video-help"],
    administrator: [
      "school",
      "workgroup",
      "groups",
      "archived",
      "button-video-help",
    ],
    manager: ["school", "workgroup", "groups", "archived", "button-video-help"],
    director: [
      "school",
      "workgroup",
      "groups",
      "archived",
      "button-video-help",
    ],
    default: ["school", "workgroup", "groups", "archived", "button-video-help"],
  }),
  collapsibleFields: Structures.array([]),
  displayHeader: Structures.boolean(true),
  idDefiTaxonomy: Structures.string("5427"),
  idActivityToHideInProgressTab: Structures.array([]),
  // An admin teacher can always CRUD on any leaner, workgroup or group.
  doTrainersHaveRestrictiveRights: Structures.boolean(false),
  gettingStarted: Structures.object({}),
  group: Structures.object({ columns: [] }),
  showGlobalAddButton: Structures.boolean(true),
  trainer: Structures.object({
    columns: {
      default: [
        "checkbox",
        "avatar",
        "trainer-name",
        "email",
        "type",
        "access",
        "buttons",
      ],
    },
    fields: {
      default: ["avatar", "username", "password", "email"],
    },
  }),
  learner: Structures.object({
    columns: {
      default: [
        "checkbox",
        "avatar",
        "username",
        "groups",
        "workgroups",
        "buttons",
      ],
    },
    createFields: {
      default: [
        "avatar",
        "nickname",
        "password",
        "groups",
        "workgroups",
        "parentalConsent",
      ],
    },
    editFields: {
      default: [
        "id",
        "avatar",
        "nickname",
        "password",
        "groups",
        "workgroups",
        "sso",
        "parentalConsent",
      ],
    },
    joinFields: {
      default: ["avatar", "nickname", "password", "groups", "workgroups"],
    },
  }),
  filterGroupListingsByCurrentYearByDefault: Structures.boolean(false),
  helpGroupVideo: Structures.object(),
  importInClasses: Structures.boolean(false),
  // {@link IsUserAuthorisedByRoleGuard}
  forbiddenPaths: Structures.object({
    default: [],
  }),
  hideEditSSO: Structures.boolean(false),
  displayAssignButtonInGroupList: Structures.boolean(false),
  displayTrainerLink: Structures.array([
    "director",
    "manager",
    "administrator",
  ]),
  workgroupColumns: Structures.object({
    trainer: ["checkbox", "workgroupname", "learnerslist", "buttons"],
    administrator: ["checkbox", "workgroupname", "learnerslist", "buttons"],
    manager: ["checkbox", "workgroupname", "learnerslist", "buttons"],
    director: ["checkbox", "workgroupname", "learnerslist", "buttons"],
    default: ["checkbox", "workgroupname", "learnerslist", "buttons"],
  }),
  newGroupField: Structures.array(["color", "groupname", "level"]),
  editGroupField: Structures.array(["id", "color", "groupname", "level"]),
});

const settingsAccessFeature: ModelSchema = new ModelSchema({
  institutionsGroupAccess: Structures.boolean(),
});

@Injectable({
  providedIn: "root",
})
export class GroupManagementConfigurationService {
  /**
   * @deprecated don't call settings directly, it must be private and use a function in this service
   */
  public settings: { [key: string]: any };
  /**
   * @deprecated don't call settings directly, it must be private and use a function in this service
   */
  public settingsAccess: { [key: string]: any };

  private privateSettings: { [p: string]: any };
  private privateSettingsAccessFeature: { [p: string]: any };

  constructor() {
    this.settings = this.privateSettings = settingsStructure.filterModel(
      modulesSettings.groupsManagement
    );
    this.settingsAccess = this.privateSettingsAccessFeature =
      settingsAccessFeature.filterModel(modulesSettings.featureAccess);
  }

  /**
   * Defined if it's possible in this instance to delete a workgroup
   */
  public isDeleteWorkgroupAvailable(): boolean {
    return _.get(this.privateSettings, "canBeDelete.workgroup", false);
  }

  public isDeleteInstitutionAvailable(): boolean {
    return _.get(this.privateSettings, "canBeDelete.institution", false);
  }

  public isDeleteLearnerListAvailable(): boolean {
    return _.get(this.privateSettings, "canBeDelete.learners", false);
  }

  public isDeleteLearnerAvailable(): boolean {
    return _.get(this.privateSettings, "canBeDelete.learner", false);
  }

  public isMetacognitionIsAvailableForGroup(): boolean {
    return _.get(this.privateSettings, "canActiveMetacognition.group", false);
  }

  public isMetacognitionAvailable(): boolean {
    const metacognitionParams = _.get(
      this.privateSettings,
      "canActiveMetacognition",
      {}
    );
    if (!!metacognitionParams) {
      return Object.values(metacognitionParams).some((v) => v === true);
    }

    return false;
  }

  public getGraphRoomId(): string {
    return _.get(this.privateSettings, "graphRoomId", "");
  }

  /**
   * If true the trainers rights are different for each trainer in each institution and defined by Education or Admin rules.
   */
  public doTrainersHaveRestrictiveRights(): boolean {
    return _.get(
      this.privateSettings,
      "doTrainersHaveRestrictiveRights",
      false
    );
  }

  /**
   * Return true if learner's dataEntity has birthMonth & birthYear properties
   */
  public doLearnerHasBirthdate(): boolean {
    return _.get(this.privateSettings, "doLearnerHasBirthdate", false);
  }

  /**
   * affiche le bouton assigner.
   */
  public isAssignButtonInGroupListMustBeDisplayed(): boolean {
    return _.get(this.privateSettings, "displayAssignButtonInGroupList", false);
  }

  /**
   * affiche le lien trainer dans le menu.
   */
  public isLinkTrainerMustBeDisplayed(): string[] {
    return _.get(this.privateSettings, "displayTrainerLink", [
      "director",
      "manager",
      "administrator",
    ]);
  }

  /**
   * return the list of workgroups columns to show in regard of the role
   * @param role : can be learner trainer director administrator manager : see enum roleList
   */
  public workGroupColumns(role: roleList): string[] {
    const settings = _.get(this.privateSettings, "workgroupColumns", [
      "checkbox",
      "workgroupname",
      "learnerslist",
      "buttons",
    ]);
    if (settings[role]) {
      return settings[role];
    } else {
      return settings["default"];
    }
  }

  /**
   * take the rules in regard of role and who have calling it learnercomponent groupcomponent etc..
   * @param caller :witch type of list ask for filter fields: can be learners , trainers, groups, workGroups, Institutions
   * @param role : can be learner trainer director administrator manager : see enum roleList
   */
  public displayFilterByOriginAndRole(
    caller: filterType,
    role: roleList
  ): string[] {
    const settingByOrigin = _.get(this.privateSettings, caller);
    if (settingByOrigin[role]) {
      return settingByOrigin[role];
    } else {
      return settingByOrigin["default"];
    }
  }

  /**
   * list of fields to show when user add a new class
   */
  public newGroupField(): string[] {
    return _.get(this.privateSettings, "newGroupField", [
      "color",
      "groupname",
      "level",
      "parent",
    ]);
  }

  /**
   * list of fields to show when user edit a class
   */
  public editGroupField(): string[] {
    return _.get(this.privateSettings, "editGroupField", [
      "id",
      "color",
      "groupname",
      "level",
    ]);
  }
}

// list of type of filter existing in settings in workgroup group trainer learner manage list
export enum filterType {
  learners = "displayedFiltersLearners",
  trainers = "displayedFiltersTrainers",
  groups = "displayedFiltersGroups",
  workGroups = "displayedFiltersWorkGroups",
  institutions = "displayedFiltersInstitutions",
}

// list of existing role to use.
// use it when we use authService.accessLevel to choose a list of rules in regard of role
// use syntax : roleList[authService.accessLevel] to send the corresponding role using the enum
export enum roleList {
  learner = "learner",
  trainer = "trainer",
  director = "director",
  administrator = "administrator",
  manager = "manager",
}
