import { Injectable } from '@angular/core';
import {ResourceCreationSteps} from '@modules/corpus/core/resource-creation-steps.class';
import { MatDialog } from '@angular/material/dialog';
import {ResourceUploadModalComponent} from '@modules/corpus/core/components/resource-upload-modal/resource-upload-modal.component';
import {ResourceEditionModalComponent} from '@modules/corpus/core/components/resource-edition-modal/resource-edition-modal.component';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {DividerUploadModalComponent} from '@modules/corpus/core/components/divider-upload-modal/divider-upload-modal.component';
import {DataEntity} from 'octopus-connect';

@Injectable()
export class MediaUploadService {

    constructor(
        private dialog: MatDialog
    ) { }

    openResourceCreationModal() {
        this.dialog.open(ResourceUploadModalComponent, {
            panelClass: 'corpus-create-dialog',
            disableClose: true,
            // width: '600px',
        });
    }

    openDividerCreationModal(item?: DataEntity) {
        if (item) {
            this.dialog.open(DividerUploadModalComponent, {
                panelClass: 'corpus-edit-dialog',
                disableClose: true,
                width: '600px',
                data: {
                    mode: 'edition',
                    divider: item
                }
            });
        } else {
            this.dialog.open(DividerUploadModalComponent, {
                panelClass: 'corpus-create-dialog',
                disableClose: true,
                width: '600px',
                data: {
                    mode: 'creation',
                }
            });
        }
    }

    openResourceEditionModal(resource: CorpusRessource) {

        this.dialog.open(ResourceEditionModalComponent, {
            panelClass: 'corpus-edit-dialog',
            disableClose: true,
            data: {
                resource: resource
            },
            // width: '600px',
        });
    }

}
