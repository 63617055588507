
import {take, takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, ElementRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {ActivatedRoute} from '@angular/router';
import {DragulaService} from 'ng2-dragula';
import * as _ from 'lodash';
import {brand} from '../../../../../settings';
import {Observable, Subject} from 'rxjs';
import {ActivityInterface} from '@modules/activities/core/player-components/activity.interface';
import {DataEntity} from 'octopus-connect';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';

@Component({
    selector: 'app-ordon',
    templateUrl: './ordon.component.html',
    providers: [DragulaService]
})
export class OrdOnComponent implements OnDestroy, OnInit, ActivityInterface {
    @Input('activityId') public activityId: any;
    @Input('contextId') public contextId: string;
    @Input('questionTypeName') questionTypeName: string;

    public activityPercentil = 0;
    public answers = [];
    public answerStatus = 2; // 1 => correct, 2 => missing, 3 => wrong;
    public answerValidated = false;
    public brand = brand;
    public canMove = 1;
    public correctAnswer = [];
    public displayFeedback = false;
    public instruction: string;
    public isVertical = true;
    public options: any;
    public orderlist = [];
    public questionObject: any;
    public recordAnswer: any[] = [];
    public recordAnswerWrong: boolean;
    public showCorrection = false;
    public shuffledOptions: any[] = [];
    public userOrderList: any[] = [];
    public wording: string;

    private activityStepIndex: number;
    private isSaving = false;
    private showSolution = false;
    private unsubscribeInTakeUntil = new Subject();
    private userAccessCorrection = false;
    private userSave: DataEntity;

    constructor(
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        private dragulaService: DragulaService,
        private elementRef: ElementRef,
        private lessonsService: LessonsService,
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.activityId) {
                this.activityId = {};
            }

            if (params) {
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activityId[key] = params[key];
                    }
                }
            }
        });

        this.dragulaService.drop().subscribe(({source, target}) => {
            this.onDragChange();
        });

        this.dragulaService.createGroup('bag-1',
            {
                moves: (el: any, container: any, handle: any): any => {
                    return !el.classList.contains('cannotMove');
                }
            }
        );
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params) => {
            this.initialize();
        });
    }

    initialize(): void {
        this.lessonsService.initDebugGrade();
        this.reset(true);

        if (this.activityId && this.activityId['type'] && this.activityId['type'] === 'granule') {
            /*
                todo need to refacto to have only one format of data : DataEntity
            */
            const activity: DataEntity = <DataEntity>this.activityId;
            this.setContentData(activity.get('reference'));
        }  else {
            // get activity details
            this.activityService.launchActivity(this.activityId)
                .pipe(take(1))
                .subscribe(data => {
                    this.setContentData(data.reference);
                });
        }

        this.activityService.userActionWaiting.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((action) => {
                switch (action.actionLabel) {
                    case 'test':
                        this.showCorrection = true;
                    case 'save':
                        this.checkAnswer();
                        this.saveAnswer().pipe(
                            take(1))
                            .subscribe((userSave: DataEntity) => {
                                if (this.isSaving && userSave) {
                                    this.isSaving = false;
                                    this.userSave = userSave;
                                }
                                action.endSubject.next();
                            });
                        break;
                    case 'reset':
                        this.showCorrection = false;
                        this.reset(false, 'reset');
                        action.endSubject.next();
                        break;
                    case 'modify':
                        this.reset(false, 'modify');
                        action.endSubject.next();
                        break;
                    case 'see_solution':
                        this.seeAnswerSolution();
                        action.endSubject.next();
                        break;
                    case 'see_answer':
                        this.reviewAnswer();
                        action.endSubject.next();
                        break;
                }
            });
    }

    private setContentData (data): void {
        if (data.config) {
            this.isVertical = (data.config.direction === 'vertical');
            this.dragulaService.find('bag-1').options.direction = data.config.direction;
        }
        this.questionObject = data;
        this.instruction = this.questionObject.instruction;
        this.wording = this.questionObject.wording;
        this.options = this.questionObject.activity_content.answers;
        if (this.options.length) {
            const options = this.options;
            this.options = [];
            options.forEach((elem, index) => {
                elem.orderno = index + 1;
                elem.errorstat = 0;
                this.options[index] = elem;
            });
        } else {
            this.options = [];
        }
        const answers = this.questionObject.activity_content.answers;
        if (answers.length) {
            answers.forEach((elem, index) => {
                this.answers[index] = elem.id;
            });
        }
        this.orderlist = this.options;
        this.orderlist = this.derange(this.orderlist);
        this.shuffledOptions = this.orderlist.slice();
        this.userOrderList = _.cloneDeep(this.orderlist);
        this.changeOrderNo();

        this.loadUserSave();
        this.activityStepIndex = this.activityService.presentArrayElementIndex;

        if (this.lessonsService.isTrainerSeeCorrection()) {
            this.userAccessCorrection = true;
        }
    }

    private loadUserSave(): void {
        this.activityService.getUserSave(this.activityId.id, this.contextId).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(userSave => {
                if (userSave) {
                    this.userSave = userSave;
                    this.answerValidated = userSave.get('state') !== 'incomplete';
                    this.setAnswer();

                    this.activityPercentil = Math.round(this.getGrade().oldGrade * 100);
                } else if (this.lessonsService.isMyAssignment()) {
                    this.saveDefault();
                } else if (this.lessonsService.isTrainerSeeCorrection()) {
                    this.checkAnswer();
                }
            });
    }

    setAnswer(): void {
        if (this.userSave && this.userSave.get('state') !== 'incomplete') {
            const answers = this.userSave.get('userActivity').entitySave.answers;

            this.orderlist = answers.map((answer) => this.options.find((option) => option.id === answer.id));
            this.userOrderList = _.cloneDeep(this.orderlist);
            this.shuffledOptions = _.cloneDeep(this.orderlist);
            this.checkAnswer();

            this.changeOrderNo();
            this.activityService.userAnswer.next(answers);
        } else if (this.lessonsService.isTrainerSeeCorrection()) {
            this.checkAnswer();
        }
    }

    onDragChange(): void {
        const elems = this.elementRef.nativeElement.querySelectorAll('.counting-circle');
        elems.forEach((elem, index) => {
            elem.innerText = index + 1;
        });
        // re order
        const recordoptions = this.shuffledOptions;
        this.userOrderList = [];
        const optionsTemp = [];
        recordoptions.forEach(elem => {
            optionsTemp[elem.id] = elem;
        });
        const elemids = this.elementRef.nativeElement.querySelectorAll('.orderlist-id');
        elemids.forEach((elem, index) => {
            const ansid = elem.innerText.trim();
            if (optionsTemp[ansid]) {
                this.userOrderList[index] = optionsTemp[ansid];
            }
        });
        this.answerValidated = true;
        // to enabled the button Tester Ma Reposnse only when User has interacted.
        this.activityService.doesUserResponsed.next(true);
    }

    changeOrderNo(): void {
        const orderlist = _.cloneDeep(this.orderlist);
        orderlist.forEach((ele, index) => {
            ele.orderno = index + 1;
            this.orderlist[index] = ele;
        });
    }

    checkAnswer(): void {
        let correctCnt = 0;
        this.answerStatus = 2;
        // let elems = this.elementRef.nativeElement.querySelectorAll('.orderlist-id');
        this.userOrderList.forEach((elem, index) => {
            const ansid = elem.id;
            this.correctAnswer[ansid] = 3;
            if (this.answers[index] !== ansid) {
                this.correctAnswer[ansid] = 2;

            } else if (this.answers[index] === ansid) {
                this.correctAnswer[ansid] = 1;
                correctCnt++;
            }
        });
        if (this.orderlist.length === correctCnt) {
            this.answerStatus = 1;
        } else if (this.answerValidated) {
            this.answerStatus = 3;
        }

        this.recordAnswer = _.cloneDeep(this.correctAnswer);
        this.canMove = 0;
        /* state message functionality */
        if (this.lessonsService.isLessonTest() || this.lessonsService.isLessonTraining() || this.userAccessCorrection) {
            this.displayFeedback = this.answerStatus !== 1 && this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer();
            this.recordAnswerWrong = this.displayFeedback;
            this.showCorrection = true;
        }
        // for lesson answerStatus

        this.activityService.isUserAnswerStatus
           .next({status: this.answerStatus, index: this.activityStepIndex});

        if (this.userAccessCorrection) {
            this.activityService.checkAnswers.next({lessonCorrected: true});
        }

    }

    public answerState(answer: any): string {
        if (this.showCorrection || this.showSolution) {
            switch (this.correctAnswer[answer.id]) {
                case 1:
                    return 'correctAnswer';
                case 2:
                    return 'wrongAnswer';
                case 3:
                    return 'answerMissed';
            }
        } else if (this.correctAnswer[answer.id]) {
            return 'validated';
        }

        return '';
    }

    getGrade(): any {
        let oldGrade;
        let grade;
        let oldUserCorrectAnswer = 0;
        let userCorrectAnswer = 0;

        if (this.userSave) {
            this.userSave.get('userActivity').entitySave.answers.forEach((elem, index) => {
                const answerId = elem.id;
                if (this.answers[index] === answerId) {
                    oldUserCorrectAnswer += 1;
                }
            });
        }

        this.userOrderList.forEach((elem, index) => {
            const answerId = elem.id;
            if (this.answers[index] === answerId) {
                userCorrectAnswer += 1;
            }
        });

        oldGrade = oldUserCorrectAnswer / this.answers.length;
        grade = userCorrectAnswer / this.answers.length;

        return {
            newGrade: grade,
            oldGrade: oldGrade
        };
    }

    private saveDefault(): void {
        if (!this.isSaving && !this.userSave && !this.lessonsService.isLessonTest()) {
            this.isSaving = true;
            this.activityService.saveUserSave(this.activityId.id.toString(), this.contextId, [], this.answerStatus, 'qcm-save')
                .subscribe((userSave: DataEntity) => {
                    this.userSave = userSave;
                    this.isSaving = false;
                });
        }
    }

    public saveAnswer(): Observable<DataEntity> {
        this.isSaving = true;
        const grade = this.getGrade();
        this.lessonsService.setAssignGrade(grade['newGrade'], grade['oldGrade']);
        this.lessonsService.setProgress(this.userSave, this.answerStatus);
        const orderList = this.userOrderList.map((element) => element.id);

        if (this.userSave) {
            this.userSave.set('grade', +this.calculUserSavePercentil);
        }

        return this.activityService.saveUserSave(this.activityId.id.toString(), this.contextId, orderList, this.answerStatus, 'qcm-save', this.userSave,
            this.activityStepIndex);
    }

    derange(arr): Array<any> {
        const c = arr.slice();
        if (c.length < 2) {
            return c;
        }

        let result = [];
        let idx, i, iLen;
        let lastMoved = false;

        for (let i = 0, iLen = c.length - 1; i < iLen; i++) {

            if (c.length === 2 && !lastMoved) {
                result = result.concat(c.reverse().splice(0, 2));
                break;
            }

            do {
                idx = Math.random() * c.length | 0;

            } while (arr.indexOf(c[idx]) === result.length);

            result.push(c.splice(idx, 1)[0]);
            lastMoved = lastMoved || idx === c.length;
        }

        if (c.length) {
            result.push(c[0]);
        }
        return result;
    }

    seeAnswerSolution(): boolean {
        if (!this.options) {
            return;
        }
        this.options.forEach((elem, index) => {
            this.correctAnswer[elem.id] = 1;
        });
        this.orderlist = [];
        this.orderlist = this.questionObject.activity_content.answers;
        this.changeOrderNo();
        this.showSolution = true;
        this.showCorrection = true;
        this.displayFeedback = false;
        return true;
    }

    reset(resetAllSubscribe: boolean = false, type = null): void {
        if (!type) {
            //  type is only set when we need usersave for calculating grade.
            this.userSave = type;
        }
        if (resetAllSubscribe) {
            if (this.unsubscribeInTakeUntil) {
                this.unsubscribeInTakeUntil.next();
                this.unsubscribeInTakeUntil.complete();
            }
            this.unsubscribeInTakeUntil = new Subject();
        }

        this.isSaving = false;
        this.answerStatus = 2;
        this.activityService.displayActions.next(true);
        this.displayFeedback = false;
        this.recordAnswerWrong = false;
        this.orderlist = _.cloneDeep(this.shuffledOptions);
        this.userOrderList = _.cloneDeep(this.shuffledOptions);
        this.recordAnswer = [];
        this.correctAnswer = [];
        this.canMove = 1;
        if (this.lessonsService.isAtLeastTrainerAndAssignmentExist) {
            this.canMove = 0;
        }
        this.answerValidated = false;
        this.changeOrderNo();
        this.activityService.doesUserResponsed.next(false);
    }

    reviewAnswer(): void {
        this.orderlist = [];
        this.orderlist = _.cloneDeep(this.userOrderList);
        this.changeOrderNo();
        this.correctAnswer = _.cloneDeep(this.recordAnswer);
        this.answerValidated = false;
        this.showSolution = false;
        this.displayFeedback = this.recordAnswerWrong = this.answerStatus !== 1 && this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer();
    }

    public get showPercentil(): string {
        if (this.isTrainerAndAssessment) {
            return this.activityPercentil ? ' ' + this.activityPercentil + '%' : ' 0%';
        }

        return '';
    }

    public get isTrainerAndAssessment(): boolean {
        return this.lessonsService.isAtLeastTrainer() && this.lessonsService.isLessonEvaluation() || this.userAccessCorrection;
    }

    private get calculUserSavePercentil(): number {
        return Math.round(this.getGrade().newGrade * 100);
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

}
