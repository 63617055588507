import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ChaptersSelectionComponent} from 'fuse-core/components/chapters-selection/chapters-selection.component';
import {InstitutionDataEntity} from '@modules/groups-management/core/definitions';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '@modules/groups-management/core/models/user-search-data-entity';
import {MatExpansionPanel} from '@angular/material/expansion';
import {tap} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-edit-user-and-license',
  templateUrl: './edit-user-and-license.component.html',
  styleUrls: ['./edit-user-and-license.component.scss']
})
export class EditUserAndLicenseComponent implements OnInit, AfterViewInit {
    public institutionFormGroup: FormGroup;
    public controls: {
        firstname: FormControl,
        name: FormControl,
        mail: FormControl,
        institution?: FormControl,
        license: FormControl,
        endDate: FormControl,
    };
    public disableExpansionPanel = false;
    public formErrors: {
        endDate?: { required?: boolean; };
    } = {};

    public institutionFormErrors: {
        label?: { required?: boolean; };
    } = {};
    public panelOpenState = false;

    @ViewChild('panel') panel: MatExpansionPanel;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            user: User,
            userInstitution: InstitutionDataEntity,
            institutions: InstitutionDataEntity[],
            license: {
                id: string,
                startDate: number
                endDate: number,
                type: string
            },
            licenseTypes: string[],
            roles: string[],
            disableOption: (type: string) => boolean,
            disabledFields: string[],
            getEnumKey: (type: string) => string;
        },
        public dialogRef: MatDialogRef<ChaptersSelectionComponent>,
        private formBuilder: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.initialise();
    }

    ngAfterViewInit(): void {
        this.disableAndOpenExpansionPanel();
    }

    private initialise(): void {
        this.initFormControls();
        this.initFormControlSubscribes();
        this.institutionFormGroup = this.createInstitutionForm();
        this.initInstitutionFormSubscribes();
    }

    private initFormControls(): void {
        this.controls = {
            firstname: new FormControl(''),
            name: new FormControl({ value: this.data.user && this.data.user.name || null, disabled: !!this.data.user}),
            mail: new FormControl({ value: this.data.user && this.data.user.mail || null, disabled: !!this.data.user}),
            license: new FormControl( {value: this.data.license && this.data.license.type || null, disabled: this.data.disabledFields.includes('license')}, Validators.required),
            endDate: new FormControl( {value: this.data.license && this.data.license.endDate
                && new Date(this.data.license.endDate * 1000) || null, disabled: this.data.disabledFields.includes('endDate')}),
            institution: new FormControl({ value: this.data.userInstitution  || null, disabled: this.data.disabledFields.includes('institution')}),
        };
    }

    private initFormControlSubscribes(): void {
        // tslint:disable-next-line:forin
        for (const field in this.controls) {
            this.controls[field].valueChanges.pipe(
                tap((val: string | Date | InstitutionDataEntity) => {
                    if (field === 'endDate' && !val && this.controls[field].errors) {
                        this.formErrors.endDate = this.controls[field].errors;
                    }
                    if (field === 'institution') {
                        const institutionSelected: InstitutionDataEntity = val as InstitutionDataEntity;
                        if (val) {
                            if (this.panel) {
                                this.panel.close();
                                this.institutionFormGroup.get('label').setValue(null, {emitEvent: false});
                            }
                            this.controls.license.setValue(institutionSelected && institutionSelected.get('license').type ?
                                this.data.getEnumKey(institutionSelected.get('license').type) : null, {emitEvent: false});
                            this.controls.endDate.setValue(new Date(+institutionSelected.get('license').endDate * 1000), {emitEvent: false});
                        }
                        if (this.data.userInstitution && val && val['id'] !== this.data.userInstitution.id || val && !this.data.userInstitution) {
                            this.controls.license.disable({emitEvent: false});
                            this.controls.endDate.disable({emitEvent: false});
                        } else {
                            this.controls.license.enable({emitEvent: false});
                            this.controls.endDate.enable({emitEvent: false});
                        }
                    }
                    if (field === 'license') {
                        if (val && val !== 'free') {
                            this.controls.endDate.setValidators(Validators.required);
                            this.disableExpansionPanel = this.data.institutions.length === 0;
                            this.institutionFormGroup.enable({emitEvent: false});
                            if (!this.data.disabledFields.includes('institution') && !this.panelOpenState) {
                                this.controls.institution.enable({emitEvent: false});
                            }
                            if (!this.data.disabledFields.includes('endDate')) {
                                this.controls.endDate.enable({emitEvent: false});
                            }

                            if (this.data.roles.includes('trainer') && !this.controls.institution.value && this.panel) {
                                this.panel.open();
                            }
                        } else if (val && val === 'free') {
                            this.controls.endDate.setValue(null, {emitEvent: false});
                            this.controls.institution.setValue(null, {emitEvent: false});
                            this.controls.endDate.disable({emitEvent: false});
                            this.controls.institution.disable({emitEvent: false});
                            this.institutionFormGroup.disable({emitEvent: false});
                            if (this.panel) {
                                this.panel.close();
                                this.disableExpansionPanel = true;
                            }
                        } else {
                            this.disableExpansionPanel = this.data.institutions.length === 0;
                            this.controls.endDate.clearValidators();
                            this.controls.endDate.reset();
                        }

                    }
                })
                // wip ici verifier si la valeur corresponds au format que l'on attends (cf formErrors)
            ).subscribe();
        }
    }

    private initInstitutionFormSubscribes(): void {
        this.institutionFormGroup.valueChanges.subscribe((value) => {
            const controlLabel = this.institutionFormGroup.get('label');

            if (this.institutionFormErrors) {
                for (const field in this.institutionFormErrors) {
                    this.institutionFormErrors[field] = {};
                }
            }
            if (controlLabel && controlLabel.dirty && !controlLabel.valid) {
                this.institutionFormErrors['label'] = controlLabel.errors;
            }
        });
    }

    public close(): void {
        this.dialogRef.close();
    }

    public save(): void {
        const values: {user: User,  name: string, mail: string, password?: string, institution?: InstitutionDataEntity | {label: string}, license: string, endDate?: number} =
            {
                user: this.data.user,
                name: !!this.data.user ? this.controls.name.value : this.controls.name.value + ' ' + this.controls.firstname.value,
                mail: this.controls.mail.value,
                license: this.controls.license.value || null,
                endDate:  Math.round((this.controls.endDate.value ? new Date(this.controls.endDate.value).getTime() : new Date().getTime()) / 1000)
            };

        if (!this.data.user) {
            values.password = uuidv4().slice(8);
        }
        if (this.institutionFormGroup.enabled) {
            values.institution =  this.institutionFormGroup.getRawValue();
        }
        if (this.controls.institution.enabled) {
            values.institution =  this.controls.institution.value;
        }
        this.dialogRef.close(values);
    }

    get licenseTypes(): string[] {
        return this.data.licenseTypes || [];
    }

    get institutions(): InstitutionDataEntity[] {
        return this.data.institutions || [];
    }

    private createInstitutionForm(): FormGroup {
        const config = {
          label: [null, [Validators.required]],
        };
        return this.formBuilder.group(config);
    }

    public enableNewInstitution(): void {
        this.panelOpenState = true;
        if (this.controls.institution.value) {
            this.controls.license.setValue(null, {emitEvent: false});
            this.controls.endDate.setValue(null, {emitEvent: false});
            this.controls.institution.setValue(null, {emitEvent: false});
        }
        this.controls.institution.disable({emitEvent: false});
        this.institutionFormGroup.enable({emitEvent: false});
        if (this.data.roles.includes('director')) {
            this.controls.license.setValue('institution', {emitEvent: false});
            this.controls.license.disable({emitEvent: false});

            this.controls.endDate.setValue(null, {emitEvent: false});
            this.controls.endDate.setValidators(Validators.required);
            this.controls.endDate.markAsDirty();
            this.controls.endDate.markAsTouched();
            this.controls.endDate.setErrors({required: true});
        }
        if (this.data.roles.includes('trainer')) {
            if (!this.data.disabledFields.includes('license')) {
                this.controls.license.enable({emitEvent: false});
            }
            if (!this.data.disabledFields.includes('endDate')) {
                this.controls.endDate.enable({emitEvent: false});
            }
        }
    }

    public disableNewInstitution(): void {
        this.panelOpenState = false;
        this.institutionFormGroup.get('label').setValue(null);
        if (this.institutionFormErrors) {
            for (const field in this.institutionFormErrors) {
                this.institutionFormErrors[field] = {};
            }
        }
        if (!this.data.disabledFields.includes('institution')) {
            this.controls.institution.enable({emitEvent: false});
        }
        if (!this.data.disabledFields.includes('license') && !this.controls.institution.value) {
            this.controls.license.enable({emitEvent: false});
        }

        this.institutionFormGroup.disable({emitEvent: false});
        if (this.data.roles.includes('director')) {
            if (this.formErrors) {
                for (const field in this.formErrors) {
                    this.formErrors[field] = {};
                }
            }

            this.controls.endDate.reset();
            this.controls.endDate.setValue(this.data.license && this.data.license.endDate
                && new Date(this.data.license.endDate * 1000) || null, {emitEvent: false});
            this.controls.license.setValue(this.data.license && this.data.license.type || null, {emitEvent: false});
        }
    }

    public disableOption(licenseType: string): boolean {
        return this.data.disableOption(licenseType);
    }

    disableAndOpenExpansionPanel(): void {
        if (this.data.institutions.length === 0) {
            this.disableExpansionPanel = true;
            setTimeout(() => this.panel.open(), 1000);
        }
    }

    public get disableLicenseAndEndDate(): boolean {
        if (this.data && this.data.institutions.length === 0) {
            return this.institutionFormGroup.invalid;
        }
        return false;
    }

    public get isFormValid(): boolean {
        // TODO: pour l'instant le check est fait sur la presence de valeur mais par la suite, utiliser "valid" du Formcontrol
        // peut être simplifié mais je garde le format return true ou false pour plus de lisibilité
        if (!this.controls.license.value || this.controls.license.value && this.controls.license.value !== 'free'
            && (!this.controls.endDate.value || !this.controls.institution
                || (this.panelOpenState && !this.institutionFormGroup.get('label').value || this.institutionFormGroup.get('label').value === ''))) {
            return false;
        }
        return true;
    }
}
