import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../settings';

const settingsStructure: ModelSchema = new ModelSchema({
    actions: Structures.object({
        default: ['unassign']
    }),
    // True if lesson are allowed to be assigned
    assignableLesson: Structures.boolean(false),
    activateFxHide: Structures.boolean(true),
    checkDefaultFiltersInUrl: Structures.boolean(false),
    columns: Structures.object({
        default: Structures.array(['type', 'assigned_node_title', 'start_date', 'end_date', 'assigned_user', 'class', 'group', 'state', 'progress', 'score', 'buttons'])
    }),
    columnsDashboardWidget: Structures.object({
        default: Structures.array(['type', 'title', 'start_date', 'end_date', 'group', 'workgroup', 'progress'])
    }),
    completionDate: Structures.array(['assessment', 'homework']),
    completionStartDateOnly: Structures.array(),
    completionTime: Structures.array(['assessment']),
    defaultType: Structures.string(null),
    disableOpening: Structures.boolean(false),
    enableGradeType: Structures.array(),
    filters: Structures.object({
        default: Structures.array(['type', 'title', 'group', 'workgroup', 'learner'])
    }),
    followedList: Structures.object({
        formCreationLink: Structures.array([])
    }),
    followedLogBookFields: Structures.object({
        default: ['group', 'type', 'exoName', 'learner', 'beginDate', 'endDate']
    }),
    formFields: Structures.object({
        default: ['group', 'workgroup', 'learner', 'project', 'assignment_type', 'assignment_grade', 'start_date', 'due_date']
    }),
    getStateFromDate: Structures.boolean(),
    gettingStarted: Structures.object({}),
    hasType: Structures.boolean(true),
    hasCompletionDate: Structures.boolean(true),
    hasCompletionTime: Structures.boolean(true),
    hideLearnerAutoAssignments: Structures.boolean(false),
    initAssignment: Structures.boolean(false),
    isLessonCanBeAssignedOnlyOnceByUser: Structures.boolean(false),
    // If false, the trainer have to have specific rights defined on group, institutions, etc.
    doTrainersHaveRestrictiveRights: Structures.boolean(false),
    maxCommentLenght: Structures.number(280),
    noFilterforAssignmentsWidget: Structures.boolean(false),
    ratingBase: Structures.array(),
    rolesForOverrideAssignmentLaunchToMetadatas: Structures.array([]),
    showHours: Structures.boolean(true),
    showNameClass: Structures.boolean(false),
    stateWithIcon: Structures.boolean(false),
    useHideFeedbacksToLaunchAdaptativeModal: Structures.boolean(false),
    useSchoolYearFilter: Structures.boolean(false),
});

const projectSettingsStructure: ModelSchema = new ModelSchema(({
    accessProject: Structures.boolean()
}));

@Injectable({
    providedIn: 'root'
})
export class AssignationConfigurationService {
    public settings: { [p: string]: any };
    public projectSettings: { [p: string]: any };
    private privateSettings: { [p: string]: any };
    private privateProjectSettings: { [p: string]: any };

    constructor() {
        this.settings = this.privateSettings = settingsStructure.filterModel(modulesSettings.assignation);
        this.projectSettings = this.privateProjectSettings = projectSettingsStructure.filterModel(modulesSettings.projectsManagement);
    }

    /**
     * if false, nobody can assign a lesson
     */
    public isAssignationActionIsActive(): boolean {
        return this.privateSettings.assignableLesson;
    }

    /**
     * If false, the trainer have to have specific rights defined on group, institutions, etc.
     */
    public doTrainersHaveRestrictiveRights(): boolean {
        return this.privateSettings.doTrainersHaveRestrictiveRights;
    }

    /**
     * do we activate fxhide on array
     */
    public activateFxHide(): boolean {
        return this.privateSettings.activateFxHide;
    }
}
