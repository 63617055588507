import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';


@Component({
    selector: 'app-poll',
    templateUrl: './poll.component.html'
})
export class PollComponent implements OnInit, OnChanges {

    @Input('value') currentValue: any;
    @Input('min') minValue: number = 0;
    @Input('assignatedCount') assignatedCount: number = 0;

    @Output() moveSlider = new EventEmitter<number>();
    @Output() clickSlider = new EventEmitter<number>();

    public isShowingPopUp: boolean = false;
    public pollControl: FormControl = new FormControl(0);

    constructor() {
    }

    ngOnInit(): any {
        this.initialize();
    }

    ngOnChanges(): any {
        this.initialize();
    }

    private initialize(): void {
        this.pollControl.setValue(this.currentValue && this.currentValue !== '' ? +this.currentValue : 0);
    }

    /**
     * emit to the parent the position when moving cursor
     * @param evt : position du curseur sur la frise.
     */
    public sliderMove(evt: any): void {
        this.moveSlider.emit(+evt.currentTarget.value);
    }

    /**
     * emit to the parent the position after click
     * @param evt : position du curseur sur la frise.
     */
    public sliderClick(evt: any): void {
        this.clickSlider.emit(+evt.currentTarget.value);
    }

    /**
     * return the position for popup whith current value
     */
    public get positionTooltip(): string {
        let percent = 0;
        /**
         *  position can be NaN because 0/0 = NaN because this.assignatedCount can be 0, so wee initialize position to 0
         */
        const position =  +this.assignatedCount ? ((Math.abs(this.minValue) + +this.currentValue) / (Math.abs(this.minValue) + Math.abs(+this.assignatedCount))) : 0;

        const minPercent = 1.12;
        const maxPercent = 95.5;

        percent = minPercent + (maxPercent - minPercent) * position;

        return +percent + '%';
    }

    /**
     * show pop up on mouse down
     */
    public showPopUp(): void {
        this.isShowingPopUp = true;
    }

    /**
     * hide pop up on mouse upe
     */
    public hidePopUp(): void {
        this.isShowingPopUp = false;
    }

    /**
     * return answer percent value of poll
     * @returns {string}
     */
    public get  valPol(): string {
        return +this.assignatedCount ? ((+this.pollControl.value * 100) / +this.assignatedCount).toFixed(0) + '%' : 0 + '%';
    }
}
