import {Component, OnInit, Input, ElementRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ReplaySubject} from 'rxjs/index';
import * as _ from 'lodash';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit {
  @Input('config') config: any;
  @ViewChild('chart') chart: ElementRef;

    learnersData: boolean;
    lessonIdList: string[];
    assignmentIdList: string[];
    chartType = 'line';
    infoSettings: ReplaySubject<any> = new ReplaySubject<any>();
    showTooltip: boolean;
    selectedPoint: object;
    customSettingsWrapper = {};

    chartOptions = {
        onHover: (event, chartElement) => {
            event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        responsive: true,
        event: ['click'],
        legend: {
            display: false,
            position: 'bottom',
            labels: {
                fontSize: 12,
                boxWidth: 40,
                usePointStyle: false, // permet aux labels d'avoir une forme corrspondante aux style des point sur le graph
            }
        },
        title: {
            display: false,
            text: 'resultats moyenne'
        },
        scales: {
            yAxes: [{
                gridelines: {
                    drawTicks: true,
                },
                ticks: {
                    beginAtZero: true,
                    suggestedMin: 0,
                    suggestedMax: 20,
                    stepSize: 1
                }
            }],
            xAxes: [{
                offset: true,
            }]
        },
        elements: {
            line: {
                tension: 0 // disables bezier curves
            }
        },
        tooltips: {
            enabled: false,
            // Tooltip Element
            custom: (tooltipModel) => {

                // Tooltip Element
                const position = this.chart.nativeElement.getBoundingClientRect();

                const clonedModel = _.cloneDeep(tooltipModel);
                this.customWrapper = {
                    wrapperY: position.y,
                    wrapperX: position.x,
                    wrapperWidth: position.width,
                    wrapperHeight: position.height,
                    tooltipY: clonedModel.caretY + 33,
                    tooltipX: clonedModel.caretX - 140,
                    type: clonedModel.type
                };

            },
            callbacks: {
                title: (tooltipItem, data) => {
                    return {
                        title: this.titles[tooltipItem[0]['index']],
                    };
                }
            }
    },
        layout: {
            padding: {
                left: 50,
                right: 50,
                top: 25,
                bottom: 50
            }
        },
}

    chartData = [];
    chartLabels = [];
    chartColors = [
        {
            backgroundColor: 'transparent',
            // borderWidth: 5, // taille bordure
            // borderColor: '#A9A9A9', // couleur bordure ligne
            borderCapStyle: 'butt', // forme des extremité d'une ligne ( butt : carré, round: arrondi, square: carré et ajoute la taille de la largeur à la taille en hauteur.
            borderDash: [], // pour faire un style de ligne dashed :  longueur des tirets et longueurs espace entre les tirets
            borderDashOffset: 0, // l'espace avant le debut d'une ligne
            borderJoinStyle: 'miter', // permet de definir l'affichage des joints d'une ligne : bevel, round, miter => https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineJoin
            pointBorderColor: 'transparent', // couleur bordure point
            pointBackgroundColor: '#A9A9A9', // couleur bordure point
            pointBorderWidth: 1, // taille bordure point
            // pointRadius: [1, 5, 10, 20], // taille du point chaque valeur pour chaque point sur la ligne
            // pointHoverRadius: [1, 5, 10, 20], // taille du point en hover
            // pointHitRadius: [1, 5, 10, 20], // au click sur le point la taille change;
            // pointHoverBackgroundColor: 'pink', // couleur du point en hover
            // pointHoverBorderColor: 'purple', // couleur du point en hover
            // pointHoverBorderWidth: 1, // taille du point en hover
            pointStyle: 'circle', // type de point etile, cercle, carré etc..
            hoverBackgroundColor: 'transparent', // couleur d'une ligne en hover
            hoverBorderColor: 'transparent', // couleur de bordure d'une ligne en hover
            // hoverBorderWidth: 1, // couleur de bordure d'une ligne en hover
        },
        {
            backgroundColor: 'transparent',
            // borderWidth: 7, // taille bordure
            // borderColor: '006D86', // couleur bordure ligne
            borderCapStyle: 'butt', // forme des extremité d'une ligne ( butt : carré, round: arrondi, square: carré et ajoute la taille de la largeur à la taille en hauteur.
            borderDash: [], // pour faire un style de ligne dashed :  longueur des tirets et longueurs espace entre les tirets
            borderDashOffset: 0, // l'espace avant le debut d'une ligne
            borderJoinStyle: 'miter', // permet de definir l'affichage des joints d'une ligne : bevel, round, miter => https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineJoin
            pointBorderColor: 'transparent', // couleur bordure point
            pointBackgroundColor: '#006D86', // couleur bordure point
            // pointBorderWidth: 1, // taille bordure point
            // pointRadius: [1, 5, 10, 20], // taille du point chaque valeur pour chaque point sur la ligne
            // pointHoverRadius: [1, 5, 10, 20], // taille du point en hover
            // pointHitRadius: [1, 5, 10, 20], // au click sur le point la taille change;
            // pointHoverBackgroundColor: 'pink', // couleur du point en hover
            // pointHoverBorderColor: 'purple', // couleur du point en hover
            // pointHoverBorderWidth: 1, // taille du point en hover
            pointStyle: 'circle', // type de point etile, cercle, carré etc..
            hoverBackgroundColor: 'transparent', // couleur d'une ligne en hover
            hoverBorderColor: 'transparent', // couleur de bordure d'une ligne en hover
            // hoverBorderWidth: 1, // couleur de bordure d'une ligne en hover
        }
    ];
    legend = true;
    graphGenerated: boolean;
    assignmentIds: Array<any>;
    titles = [];
    types = [];

    constructor(public router: Router) { }

  ngOnInit(): any {

      this.config.subscribe((data) => {
          this.showTooltip = false;

          const dataLength = data['chartData'].length;
          const chartDataLength = this.chartData.length;
          const lengthToSplice = chartDataLength - dataLength;

          if (dataLength) {
              for (let index = 0; index < dataLength; index += 1) {
                  this.chartData[index] = data['chartData'][index];
              }

              if (lengthToSplice > 0) {
                  this.chartData.splice(dataLength, lengthToSplice);
              }

              this.chartLabels = data['chartLabels'];
              this.chartColors = data['chartColors'];
              this.titles = data['titles'];
              this.learnersData = data['learnersData'];
              this.lessonIdList = data['lessonIdList'];
              this.assignmentIdList = data['assignmentIdList'];
              this.graphGenerated = true;
              this.types = data['types'];
          } else {
              this.graphGenerated = false;
          }
      });

  }

    onChartClick(e): void {
        if (e.active.length > 0) {
            const chart = e.active[0]._chart;
            const activePoints = chart.getElementAtEvent(e.event);
            if ( activePoints.length > 0) {
                // get the internal index of slice in pie chart
                const clickedElementIndex = activePoints[0]._index;
                const clickedElementDataIndex = activePoints[0]._datasetIndex;

                if (this.selectedPoint && this.selectedPoint['index'] === clickedElementIndex && this.selectedPoint['dataIndex'] === clickedElementDataIndex) {
                    this.selectedPoint = null;
                    this.showTooltip = false;
                    return;
                } else {
                    this.selectedPoint = {
                        index: clickedElementIndex,
                        dataIndex: clickedElementDataIndex,
                    };
                }
                const pointRadius = {
                    8: 1,
                    12: 2,
                    16: 4,
                };

                const title = this.titles[clickedElementIndex];
                const type = this.types[clickedElementIndex];

                // TODO add more comment on the graph component to understand what is below this.
                this.customDataTooltip = {
                    title: title,
                    coeff: pointRadius[chart.data.datasets[clickedElementDataIndex].pointRadius[clickedElementIndex]],
                    grade: chart.data.datasets[clickedElementDataIndex].data[clickedElementIndex] + '/' + 20,
                    average: this.learnersData && this.learnersData['learnerSelected'] ? chart.data.datasets[1].data[clickedElementIndex] + '/' + 20 : chart.data.datasets[0].data[clickedElementIndex] + '/' + 20,
                    learnerSelected: chart.data.datasets[clickedElementDataIndex]['label'].indexOf('learner') !== -1,
                    type: type,
                    queryParams : {type : type, title : title},
                    lessonId : this.lessonIdList[clickedElementIndex],
                    assignmentId : this.assignmentIdList[clickedElementIndex],
                    learnerAmount:this.learnersData['learnersGrades'][clickedElementIndex].length,
                };
                this.infoSettings.next(this.settingsWrapper);
                this.showTooltip = true;
            }
        }
    }

    public get settingsWrapper(): any {
        return _.cloneDeep(this.customSettingsWrapper);
    }

    // TODO use merge function
    public set customWrapper(data){
        this.customSettingsWrapper['wrapperY'] = data.wrapperY;
        this.customSettingsWrapper['wrapperX'] = data.wrapperX;
        this.customSettingsWrapper['width'] = data.wrapperWidth;
        this.customSettingsWrapper['height'] = data.wrapperHeight;
        this.customSettingsWrapper['tooltipY'] = data.tooltipY;
        this.customSettingsWrapper['tooltipX'] = data.tooltipX;
        this.customSettingsWrapper['type'] = data.type;
    }

    // TODO use merge function
    public set customDataTooltip(data){
        this.customSettingsWrapper['title'] = data.title;
        this.customSettingsWrapper['coeff'] = data.coeff;
        this.customSettingsWrapper['grade'] = data.grade;
        this.customSettingsWrapper['average'] = data.average;
        this.customSettingsWrapper['learnerSelected'] = data.learnerSelected;
        this.customSettingsWrapper['type'] = data.type;
        this.customSettingsWrapper['queryParams'] = data.queryParams;
        this.customSettingsWrapper['lessonId'] = data.lessonId;
        this.customSettingsWrapper['assignmentId'] = data.assignmentId;
        this.customSettingsWrapper['learnerAmount'] = data.learnerAmount;
    }

}
