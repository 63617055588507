import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs/index';
import {GraphConfig} from 'fuse-core/components/graph/graph-mixed/graph.config';
import {GraphMathiaService} from '../../services/graph-mathia.service';
import {debounceTime, delay, filter, map, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {Label} from 'ng2-charts/lib/base-chart.directive';
import {CustomTooltipModalContentSettings} from 'fuse-core/components/graph/graph-details-modal/custom-tooltip.setting';
import * as _ from 'lodash';
import {Learner} from '@modules/graph-mathia/core/model/learner';
import {Router} from '@angular/router';
import {ErrorsData} from '@modules/graph-mathia/core/model/errors-data';
import {FakeStepperOptions} from '@modules/graph-mathia/core/component/fake-stepper/fake-stepper.component';

interface GraphDetail {
    index: number;
    data: {
        learner: string;
        count: number;
        link: string;
    }[];
    displayedColumns: string[];
    style: {
        [key: string]: string;
    };
    chartColumnLabel: string;
}

const Colors: string[] = [
    'rgb(0,175,236)',
    'rgb(232,17,35)',
    'rgb(0,178,148)',
    'rgb(236,0,140)',
    'rgb(252,183,49)',
    'rgb(128,139,199)'
];

// TODO move to utils
const opacity = (color: string, factor: string) => color.replace(')', `,${factor})`);

@Component({
    selector: 'app-errors-graph',
    templateUrl: './error-graph.component.html',
})
export class ErrorsGraphComponent implements OnInit, OnDestroy {
    public graphConfigObs: ReplaySubject<GraphConfig> = new ReplaySubject<GraphConfig>(1);
    public isReady = false;
    public details: GraphDetail;
    public showNoData = false;
    /**
     * Défini les steps du loader, le temps entre les stepsn, etc.
     */
    public loaderOptions: Partial<FakeStepperOptions> = {
        loop: false,
        interval: stateIndex => {
            return (this.isReady === false && stateIndex <= 2 ? 4000 : 1000);
        },
        states: ['fake_stepper.state_1', 'fake_stepper.state_2', 'fake_stepper.state_3'],
        onEnd: new ReplaySubject(1)
    };
    private unsubscribeInTakeUntil = new Subject();
    private graphData: ErrorsData;

    constructor(private graphMathiaService: GraphMathiaService,
                private router: Router) {
    }

    ngOnInit(): void {
        let hotFix = true; // Code dégueu mais j'ai pas envie de passer a 3 jour a tout refacto
        // Lors du premier call, le champs "erreurs" est setté avec des valeurs par defaut mais ca ne déclenche pas (volontairement mais pour un autre graph)
        // filterschanges, alors on triche pour tester si on est dans un de ces cas avec hotfix
        this.graphMathiaService.isReady
            .pipe(
                filter(isReady => !!isReady),
                tap(() => this.graphMathiaService.graphDataArePending.subscribe(() => this.isReady = false)),
                mergeMap(() => this.graphMathiaService.getErrorsGraphData()),
                tap(() => hotFix = true),
                tap((data) => this.graphData = data),
                mergeMap(() => this.graphMathiaService.filtersChanges),
                takeUntil(this.unsubscribeInTakeUntil),
                map(f => f.raw.errors),
                debounceTime(750)
            ).subscribe((errors: string[]) => {
                this.graphData.graphFilters.errors = hotFix ? this.graphData.graphFilters.errors : errors;
                this.showDetails(0);
                this.generateSimpleGraph();
                this.isReady = true;
                hotFix = false;
            }
        );

    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public onGraphClick(activeElement: number | null): void {
        if (activeElement !== null && activeElement !== undefined) {
            if (this.details.index !== activeElement) {
                this.showDetails(activeElement);
            }
        }
    }

    /**
     * open progress graph of learner selected
     * @param learnerId
     */
    public onTableClick(learnerId: string): void {
        this.graphMathiaService.storeLearnerSelectedInCacheFilter(learnerId);
        this.router.navigate(['graph-mathia', 'multi', 'progress']);
    }

    private generateSimpleGraph(): void {
        const chartLabels: Label[] = [];
        const dots: number[] = [];
        const dotsDetails: CustomTooltipModalContentSettings[] = [];

        this.graphData.toGraphFriendlyData(true)
            .sort((a, b) => b.data - a.data)
            .forEach(bar => {
                chartLabels.push(bar.label);
                dots.push(bar.data);
            });

        let loopedColors = _.clone(Colors);
        while (dots.length > loopedColors.length) {
            loopedColors = loopedColors.concat(loopedColors.slice());
        }

        this.showNoData = dots.length === 0;

        this.graphConfigObs.next({
            chartLabels,
            chartColors: [{
                backgroundColor: loopedColors.map((c, i) => opacity(c, '0.3')),
                hoverBackgroundColor: loopedColors.map((c, i) => opacity(c, '0.4')),
                hoverBorderColor: loopedColors
            }],
            chartConfig: {
                aspectRatio: 1.5,
                scales: {
                    yAxes: [{
                        // @ts-ignore
                        ticks: {beginAtZero: true, min: 0, precision: 0}
                    }],
                },
                legend: {display: false},
                onClick: (event?: MouseEvent, activeElements?: Array<{}>) => {
                    event.stopPropagation();
                    this.onGraphClick(_.get(activeElements, '[0]._index', null));
                },
                layout: {}
            },
            chartData: [{
                data: dots,
                type: 'bar'
            }],
            modalContent: [dotsDetails]
        });
    }

    private showDetails(index: number): void {
        const dots = this.graphData.toGraphFriendlyData(true).sort((a, b) => b.data - a.data);
        const errorLabel = dots.length > 0 ? dots[index].label : '';
        const errorStats = this.graphData.getStatsForError(errorLabel);
        const learnerIds = _.uniq(errorStats.map(s => s.learnerId));
        const orderedData = learnerIds.map((learnerId) => ({
            learner: this.getLearnerById(learnerId).nickname,
            count: errorStats.filter(s => s.learnerId === learnerId).length,
            link: learnerId
        })).sort((a, b) => b.count - a.count);

        this.details = {
            chartColumnLabel: errorLabel,
            index: index,
            data: orderedData,
            displayedColumns: ['learner', 'count', 'link'],
            style: {'background-color': Colors[index % Colors.length]},
        };
    }

    private getLearnerById(learnerId: string): Learner {
        return this.graphMathiaService.learners.find(l => +l.id === +learnerId);
    }
}
