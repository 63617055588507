import {Directive, Input, ElementRef, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[ifSet]'
})
/**
 * TODO : prototype to discuss whith Florent
 *
 * Usage exemple for an array avec tout les parametres saisi à la main dans le html ou dans une variable dans le ts :
 * <div *ifSet="{settings: this.activitesService.settings, settingKey:'filterSearchFields' , currentField: 'method'}">
 *     *ifSet="{settings: this.activitesService.settings, settingKey:'filterLessonDialogFields' , currentField: 'title'}"
 *     *ifSet="{settings: this.activitesService.settings, settingKey:'filterLessonDialogFields' , currentField: 'description'}"
 *
 * Usage exemple for an boolean avec tout les parametres saisi à la main dans le html ou dans une variable dans le ts :
 *  <div *ifSet="{settings: this.activitesService.settings, settingKey:'latexKeyboard'}">
 *
 * Alternative : mettre dans chaque component un champ settings et un setttings key et le récupérer par le template ref
 * exemple :
 * public settings: { [key: string]: any };
 * public settingKey: string = 'filterLessonDialogFields';
 * avec soit  dans le constructeur : this.settings = this.activitesService.settings;
 * ou mieux un getter du service :
 *
 * get settings(): { [key: string]: any }{
 *      return this.activitesService.settings;
 *   }
 *
 * exemple d'usage de la version simplifié :
 *
 * Cas pour un tableau de champs definit dans la variable settingKey
 * * *ifSet="{currentField: 'method'}"
 * <div mat-dialog-title *ifSet="{currentField: 'description'}">
 *
 * Cas pour une valeur simple de type boolean de champs definit dans la variable settingKey
 * *ifSet="{settingKey:'latexKeyboard'}"
 *
 */
export class SettingsDirective {
    @Input() set ifSet(val: { settings?: { [key: string]: any }, settingKey?: string, currentField?: string }) {
        let result = false;
        if (val.settings) {
            if (val.currentField) {
                // array of string
                result = val.settings[val.settingKey].filter(field => field === val.currentField).length > 0;
            } else {
                // setting one field: boolean
                result = val.settings[val.settingKey];
            }
        } else {
            //use template ref for getting settings
            const settings = (<any>this.templateRef)._parentView.component.settings;
            const settingKey = (<any>this.templateRef)._parentView.component.settingKey;
            if (val.currentField) {
                // array of string
                result = settings[settingKey].filter(field => field === val.currentField).length > 0;
            } else {
                // setting one field: boolean
                result = settings[val.settingKey];
            }
        }

        if (val && result) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {
    }
}
