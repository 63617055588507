export class SvgIcon {
    [key: string]: string
}

export const SVGICONTHEME: SvgIcon = {
    'add_box': 'aaa',
    'adaptive-begin': 'adaptive-begin',
    'agenda': 'agenda',
    'archive': 'bbb',
    'arrow_back': 'xxx',
    'arrow_left_circle_outline': 'arrow_left_circle_outline',
    'arrow_right_circle_outline': 'arrow_right_circle_outline',
    'assignment_ind': 'assignment_ind',
    'book_open': 'book_open_outline',
    'titleIconLesson': 'boullier',
    'brand': 'brand',
    'chat_basic': 'chat_basic',
    'chat_bubble_circle_outline': 'message_circle_outline',
    'chat_bubble_outline': 'message_square_outline',
    'check': 'check_outline',
    'close': 'cross',
    'delete': 'delete',
    'delete_outline': 'trash_outline',
    'fullscreen': 'fullscreen',
    'group': 'group',
    'info': 'info',
    'lessons': 'lessons',
    'medal_gold': 'medal_gold',
    'medal_silver': 'medal_silver',
    'medal_bronze': 'medal_bronze',
    'medal_platinium': 'medal_platinium',
    'medal_locked': 'medal_locked',
    'cup': 'cup',
};

