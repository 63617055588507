import {AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {ActivatedRoute} from '@angular/router';
import Keyboard from 'latex-keyboard';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {ReplaySubject, Subject} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {v4 as uuidv4} from 'uuid';

@Component({
    selector: 'app-fill-in-blanks-child',
    templateUrl: './fill-in-blanks-child.component.html'
})

export class FillInBlanksChildComponent implements OnDestroy, OnInit, AfterViewInit, AfterViewChecked {
    @Input() activity: DataEntity;
    @Input('childQuestion') question: string;
    @Input() userAnswer: any;
    @Input() disableOnChange: ReplaySubject<boolean>;
    @Input('formula') isFormula: boolean;
    // Emet sa propre instance lorsqu'il est chargé
    @Input() readiness: Subject<FillInBlanksChildComponent>;

    public options: any;
    public wording: string;
    public instruction: any;
    public questionsplit;
    public quesiontholder = '#input';
    public answers = [];
    public answerStatus = [];
    public latexKeyboard: Keyboard;

    private fieldCount = 0;
    private callbackToTriggerOnAfterViewChecked: Function;
    public randomId: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        private lessonsService: LessonsService,
        private elementRef: ElementRef
    ) {}

    ngOnDestroy(): void {
        if (this.latexKeyboard) {
            this.latexKeyboard.destroy();
        }
    }

    ngAfterViewInit(): void {
        const activityService = this.activityService;
        const inputElement = this.elementRef.nativeElement.querySelectorAll('.renderedInputContent');
        inputElement.forEach((element, index) => {
            if (this.userAnswer && this.userAnswer.length) {
                element.innerText = this.userAnswer[index];
            }
            element.addEventListener('keydown', function (event: KeyboardEvent): void {
                if (event.key.toLowerCase() === 'enter') {
                    event.preventDefault();
                }
            });
            element.addEventListener('keyup', function (event): void {
                if (this.innerText.length > 0) {
                    activityService.doesUserResponsed.next(true);
                } else {
                    activityService.doesUserResponsed.next(false);
                }
            });
            element.addEventListener('blur', function (event): void {
                if (this.innerText.length > 0) {
                    activityService.doesUserResponsed.next(true);
                } else {
                    activityService.doesUserResponsed.next(false);
                }
            });

            this.disableOnChange.subscribe((mode) => {
                this.disableField(mode);
            });
        });

        if (this.isFormula) {
            const field = [];

            for (let i = 0; i < this.fieldCount; i += 1) {
                field.push({
                    placeholder: '\\blue{[?]}',
                    welcomeText: '',
                    katexOptions: {                           /* See https://katex.org/docs/options.html */
                        throwOnError: false,
                        strict: false,
                        allowAllSymbols: true
                    },
                    renderedLatexDivId: `latex-rendering-${this.randomId}` + i         /* Id of div where rendering occurs         */
                });
            }

            this.latexKeyboard = new Keyboard(null, {
                inputFields: field,
                blacklistTabs: this.activityService.isPrimary(this.activity) ? ['123 secondaire'] : ['123 primaire'],
                keyboardDivId: `latex-keyboard-${this.randomId}`,           /* Id of div where keyboard tabs are        */
                tabDivContainerGridClass: 'keyboard-grid-container',  /* Class name of each div grid tab          */
                tabDivContainerItemClass: 'keyboard-grid-item',       /* Class name of each div item tab          */
                tabDivMenuEntryClass: 'keyboard-tab-title',       /* Class name of tab titles (123, abc, ..)  */
                tabSwitchClassListener: 'toggleTab',                /* Class name where tab switch has to occur */
                hideKeyboardButtonClass: 'hideKeyboardButton',      /* Class name of close '✖' button containers */
                moveLeftButtonClass: 'moveLeft',                 /* Class name of Move Left button(s) */
                moveRightButtonClass: 'moveRight',                /* Class name of Move Right button(s) */
                backspaceButtonClass: 'backspace'                 /* Class name of Move Backspace button(s) */
            });

            if (this.userAnswer && this.userAnswer.length) {
                this.latexKeyboard.pushAllLatex(this.userAnswer);
            }

            this.latexKeyboard.addEventListener('change', (fields: string[]) => {
                const data = fields.join('').trim();

                if (data === '') {
                    activityService.doesUserResponsed.next(false);
                } else {
                    activityService.doesUserResponsed.next(true);
                }
            });

            this.latexKeyboard.addEventListener('display', (isDisplayed: boolean) => {
                this.activityService.onLatexKeyboardDisplayChange.next(isDisplayed);
                if (isDisplayed){
                    this.callbackToTriggerOnAfterViewChecked = () => {
                        const inputId = this.latexKeyboard.getInputIdFocused();
                        document.getElementById(inputId).scrollIntoView();
                    };
                }
            });
        }

        this.readiness.next(this);
    }

    ngOnInit(): void {
        this.randomId = uuidv4();
        this.question = this.formatString(this.question);
        this.addInputField();
    }

    disableField(mode): void {
        const el = this.elementRef.nativeElement.querySelectorAll('.latex');

        for (const item of el) {

            if (item && mode) {
                item.classList.add('disabled');
            }

            if (item && !mode) {
                item.classList.remove('disabled');
            }
        }
    }

    addInputField(): void {
        this.questionsplit = this.question.split('##-');
        this.fieldCount = 0;
        let questionTextTemp = '';
        this.questionsplit.forEach((item, index) => {
            if (item === this.quesiontholder) {
                if (this.isFormula) {
                    questionTextTemp += '<span class="latex-wrapper">' +
                        '<span id="latex-rendering-' + this.randomId + this.fieldCount + '" class="renderedInputContent editable-default latex"></span>' +
                        '<span class="msg-icon hide"></span>' +
                        '</span>';
                } else {
                    questionTextTemp += '<span class="renderedInputContent editable-default" placeholder="reponse ici" name="answers[' + this.fieldCount + ']" contenteditable="' + (!this.lessonsService.isAtLeastTrainer() || this.lessonsService.isLessonTest()) + '"> </span>';
                    questionTextTemp += '<span class="msg-icon hide"><mat-icon class="mat-icon material-icons check" >check</mat-icon><mat-icon class="mat-icon material-icons close" >close</mat-icon><mat-icon class="mat-icon material-icons info" >info_outline</mat-icon><span class="ans-txt"></span></span>';
                }

                this.fieldCount += 1;
            } else {
                questionTextTemp += item;
            }

        });
        this.question = questionTextTemp;
    }

    formatString(answer_text: string): string {
        return answer_text;
    }

    showError(isAnswerCorrect, answers, missingAnswers?: any, missingAll?: boolean, showCorrection?: boolean): void {
        const elcolor = this.elementRef.nativeElement.querySelectorAll('.renderedInputContent');
        const msgicon = this.elementRef.nativeElement.querySelectorAll('.msg-icon');
        const anstxt = this.elementRef.nativeElement.querySelectorAll('.ans-txt');
        const wrappers = this.elementRef.nativeElement.querySelectorAll('.latex-wrapper');

        elcolor.forEach((elem, index) => {
            const errorElem = msgicon[index];
            const errorColor = isAnswerCorrect[index] === 1 ? 'right' : 'wrong';
            if (errorElem) {
                errorElem.classList.remove('missingResponse');
                errorElem.classList.remove('right');
                errorElem.classList.remove('wrong');
                errorElem.classList.remove('validated');
            }

            if (showCorrection) {
                if (answers[index] && answers[index].trim() !== '') {
                    errorElem.classList.add(errorColor);
                    errorElem.classList.remove('hide');

                    if (!this.isFormula) {
                        elem.classList.remove('hide');
                        anstxt[index].innerText = elem.innerText;
                        anstxt[index].classList.remove('hide');
                    }
                } else {
                    errorElem.classList.add('missingResponse');
                    errorElem.classList.remove('hide');

                    if (!this.isFormula) {
                        anstxt[index].innerText = missingAnswers.answer[index];
                        anstxt[index].classList.add('hide');
                    }
                }
                if (missingAll) {
                    errorElem.classList.remove('right');
                    errorElem.classList.remove('wrong');
                    errorElem.classList.add('missingResponse');

                    if (!this.isFormula) {
                        anstxt[index].innerText = missingAnswers.answer[index];
                        anstxt[index].classList.add('hide');
                    }
                }
            } else {
                errorElem.classList.add('validated');
                errorElem.classList.remove('hide');

                if (!this.isFormula) {
                    elem.classList.remove('hide');
                    anstxt[index].innerText = elem.innerText;
                    anstxt[index].classList.remove('hide');
                }
            }

            if (this.isFormula) {
                wrappers[index].classList.add('correction');
            } else {
                elem.classList.add('hide');
            }
        });
    }

    public ngAfterViewChecked(): void {
        if (this.callbackToTriggerOnAfterViewChecked) {
            this.callbackToTriggerOnAfterViewChecked();
            this.callbackToTriggerOnAfterViewChecked = null;
        }
    }
}
