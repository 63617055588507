import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MultimediaPage} from '@modules/activities/core/lessons/editor/models/multimedia-page.class';
import {MultimediaMedia} from '@modules/activities/core/lessons/editor/models/multimedia-media.class';

@Component({
    selector: 'app-multi-line-child',
    templateUrl: './multi-line-child.component.html',
})
export class MultiLineChildComponent {
    @Input() canJumpBelow = false;
    @Input() page: MultimediaPage;
    @Output() jumpBelow = new EventEmitter<MultimediaPage>();
    @Output() delete = new EventEmitter<MultimediaPage>();
    @Output() edit = new EventEmitter<MultimediaPage>();
    @Output() play = new EventEmitter<MultimediaPage>();

    constructor() {
    }

    public sefPlay(): void {
        return this.play.emit(this.page);
    }

    public selfDelete(): void {
        return this.delete.emit(this.page);
    }

    public getType(multimediaMedia: MultimediaMedia): string {
        if (multimediaMedia.type === 'videoUrl') {
            return 'video';
        }
        return multimediaMedia.type;
    }

    public selfJumpBelow(): void {
        if (this.canJumpBelow) {
            this.jumpBelow.emit(this.page);
        }
    }

    public selfEdit(): void {
        this.edit.emit(this.page);
    }
}
