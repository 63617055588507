
import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {DatacardService} from '../../../../../../shared/datacard.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {LessonMetadataDialogComponent} from '@modules/activities/core/lessons/lessons-list/lesson-metadata-dialog/lesson-metadata-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-lessons-consulted',
    templateUrl: './lessons-consulted.component.html'
})
export class LessonsConsultedComponent implements OnInit, OnDestroy {
    public dataCards: any[] = [];

    private type = 'currentUser';
    private unsubscribeInTakeUntil = new Subject();

    constructor(
        public lessonsService: LessonsService,
        private activitiesService: ActivitiesService,
        private datacardService: DatacardService,
        private dialog: MatDialog,
        private octopusConnect: OctopusConnectService,
    ) {
    }

    ngOnInit(): void {
        const callbacks = {
            'isEditableAndErasable': (resource) => this.datacardService.isEditableAndErasable(resource, this.type),
            'openAssign': (resource) => this.openAssign(resource),
            'openDialog': (resource) => this.lessonsService.openDeleteDialog(resource),
            'openDuplicate': (id, action, type) => this.lessonsService.launchEditor(id, 'copy', 'lesson'),
            'openEditor': (id, action, type) => this.lessonsService.launchEditor(id, 'edit', 'lesson'),
            'openMetaDialog': (event, metadata) => this.openMetaDialog(event, metadata),
            'play': (resource) => this.lessonsService.navigateToLesson(resource)
        };

        this.lessonsService.loadConsultedLessons().pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(resources => {
                this.dataCards = this.datacardService.processResources(resources.slice(0, 6), callbacks, this.type);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private openMetaDialog(e, metadatas): void {
        e.stopPropagation();
        this.dialog.open(LessonMetadataDialogComponent, {
            data: {
                metadatas,
                settings: {lessonMetadataDialogFields: this.lessonsService.getLessonMetadataDialogFieldsForCurrentRole()}
            }
        });
    }

    private openAssign(lesson): void {
        if (this.activitiesService.assignmentView) {
            const dialogRef = this.dialog.open(this.activitiesService.assignmentView, {
                data: {
                    nodeId: lesson.id
                }
            });

            dialogRef.afterClosed().subscribe((data) => {
                if (data && (!lesson.get('locked') || lesson.get('locked') === '0')) {
                    const entity = new DataEntity('granule-lesson', lesson.attributes, this.octopusConnect, lesson.id);
                    entity.set('locked', true);
                    entity.save()
                        .subscribe(success => {
                            if (success) {
                                this.lessonsService.userLessonsPaginated.paginator.reload();
                            }
                        });
                }
            });
        }
    }
}
