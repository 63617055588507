
import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {DashWidgetConf} from '@modules/projects-management/core/project.model';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {Subject, ReplaySubject} from 'rxjs';
import {localizedDate} from '../../../../shared/utils';
import {ResearchSheetService} from '@modules/research-sheet';
import {DataEntity} from 'octopus-connect';

@Component({
  selector: 'app-projects-dashboard',
  templateUrl: './projects-dashboard.component.html',
  styleUrls: ['./projects-dashboard.component.scss']
})
export class ProjectsDashboardComponent implements OnInit, OnDestroy {
    public DashWidgetConfResearchSheet: DashWidgetConf;
    public DashWidgetConfSectionDraft: DashWidgetConf;
    public DashWidgetConfForms: DashWidgetConf;


    public DashWidgetConfFormsLearnerAssigned: DashWidgetConf;
    public DashWidgetConfFormsLearnerClosed: DashWidgetConf;

    private unsubscribeInTakeUntil = new Subject();

    projectSubscription: Subject<any> = new Subject();
    researSheetSubscription: Subject<any> = new Subject();
    formsSubscription: Subject<any> = new Subject();

    formsLearnerSubscription: Subject<any> = new Subject();
    formsListLearnerAssignedSubscription: ReplaySubject<any> = new ReplaySubject<any>();
    formsListLearnerClosedSubscription: ReplaySubject<any> = new ReplaySubject<any>();

    formsListSubscription: ReplaySubject<any> = new ReplaySubject<any>();
    sectionsSubscription: ReplaySubject<any> = new ReplaySubject<any>();
    sectionDraftSubscription: ReplaySubject<any> = new ReplaySubject<any>();
    corpusResourceSubscription: ReplaySubject<any> = new ReplaySubject<any>();
    corpusResearchSpaceSubscription: ReplaySubject<any> = new ReplaySubject<any>();
    corpusSummarySubscription: ReplaySubject<any> = new ReplaySubject<any>();

    resources: Array<any> = [];
    resourceResearchSpace: Array<any> = [];
    corpusSummary: Array<any> = [];
    corpus: Array<any> = [];
    corpusType: string;
    sections: Array<any> = [];
    sectionsDraft: Array<any> = [];
    forms: Array<any> = [];
    formsAssigned: Array<any> = [];
    formsClosed: Array<any> = [];

    projectLoaded: boolean;
    project: any;

    researchSpace: any;
    lessonSpace: any;


  constructor(
    public authService:  AuthenticationService,
    private router: Router,
    private communicationCenter: CommunicationCenterService,
    private researchSheetService: ResearchSheetService
  ) {
      this.projectSubscription = this.communicationCenter
          .getRoom('project-management')
          .getSubject('selectedProject');
      this.projectSubscription.pipe(
          takeUntil(this.unsubscribeInTakeUntil))
          .subscribe((proj) => {
          this.projectLoaded = true;
          this.project = proj;

         if (proj && proj.corpus.length) {
             this.corpus = [];
             this.corpusSummary = [];
             for (const corpus of proj.corpus){
                 this.corpus.push(corpus);
                 if (this.authService.isAtLeastTrainer()) {
                     this.corpusSummary.push({
                         id: corpus.corpusEntity.id,
                         data: {
                             name: corpus.corpusEntity.get('name'),
                             count: corpus.corpusEntity.get('count')
                         }
                     });
                     this.corpusSummarySubscription.next(this.corpusSummary);
                 } else {
                     this.corpusType = corpus.corpusEntity.get('name');
                     this.setCorpusData(corpus.corpusEntity.get('name'), corpus);
                 }

             }

             // TODO Récuperer directement les sections draft via le communication center...
             this.researchSheetService.loadResearchDrafts(this.project.id)
                 .subscribe((collection) => {

                     this.sectionsDraft = collection.entities.map((entity) => {

                         const definition = this.researchSheetService.templatesSectionsCollection[+entity.get('sectionDefinitions')];

                         const draft = {
                             id: entity.id,
                             definitionId: +entity.get('sectionDefinitions'),
                             definitionName: definition ? definition.get('name') : '',
                             name : entity.get('name'),
                             updated : ' ' + localizedDate(entity.get('updated')),
                             owner: ' ' + entity.get('owner').name,
                         };

                         return draft;
                     });
                     if (!this.researchSheetService.templatesList || this.researchSheetService.templatesList  && !this.researchSheetService.templatesList.length) {
                         this.setDefinition();
                     }

                     this.sectionDraftSubscription.next(this.sectionsDraft);

                 });
         }

      });

      this.researSheetSubscription = this.communicationCenter
          .getRoom('research-sheet')
          .getSubject('sheetsFiltered');

      this.formsSubscription = this.communicationCenter
          .getRoom('assignment')
          .getSubject('assignmentList');

      this.researSheetSubscription.subscribe((data) => {
          this.sections = [];
          this.sections.push(...data);
          this.sectionsSubscription.next(this.sections);
      });

      if (this.authService.isAtLeastTrainer()) {
          this.formsSubscription.subscribe((data) => {
              this.forms = [];
              this.forms.push(...data);
              this.formsListSubscription.next(this.forms);
          });
      } else {
          this.formsSubscription.subscribe((data) => {
              this.formsAssigned = data[0].entities;
              this.formsClosed = data[1].entities;

              this.formsListLearnerAssignedSubscription.next(this.formsAssigned);
              this.formsListLearnerClosedSubscription.next(this.formsClosed);
          });
      }

      this.DashWidgetConfResearchSheet = {
          headerTitle: this.authService.isAtLeastTrainer() ? 'project-management.section-pending' : 'project-management.section-closed',
          headerIcon: 'search',
          headerLink: ['..', 'tools', 'research-sheet', 'list'],
          dashWidgetClassName: 'dash-widget-research-sheet'
      };

      this.DashWidgetConfSectionDraft = {
          headerTitle: 'project-management.draft_last_update',
          headerIcon: 'search',
          headerLink: ['..', 'tools', 'section-draft', 'list'],
          dashWidgetClassName: 'dash-widget-section-draft'
      };
      this.DashWidgetConfForms = {
          headerTitle: 'project-management.followed-forms-penging',
          headerIcon: 'assignment',
          headerLink: ['..', 'tools', 'followed', 'list'],
          dashWidgetClassName: 'dash-widget-forms'
      };

      this.DashWidgetConfFormsLearnerAssigned = {
          headerTitle: 'project-management.followed-forms-assigned',
          headerIcon: 'assignment',
          headerLink: ['..', 'tools', 'followed', 'list'],
          dashWidgetClassName: 'dash-widget-forms'
      };

      this.DashWidgetConfFormsLearnerClosed = {
          headerTitle: 'project-management.followed-forms-closed',
          headerIcon: 'assignment',
          headerLink: ['..', 'tools', 'followed', 'list'],
          dashWidgetClassName: 'dash-widget-forms'
      };

  }

  ngOnInit(): any {}

    getDashWidgetConfCorpus(type: any): object {
      let corp: any;
      let id: number;
      let name: string;

        if (type === 'corpus.summary') {
            corp =  this.corpus[0];

            if (corp) {
                name =  corp.corpusEntity.get('name');
                id =  +corp.corpusEntity.id;
            }
            return {
                'id': id,
                'headerTitle': 'generic.resources',
                'headerIcon': 'corpus',
                'headerLink': ['..', 'tools', 'corpus', id],
                'dashWidgetClassName': 'dash-widget-corpus'
            };
        }
        corp =  this.corpus.find((corpus) => corpus.corpusEntity.get('name') === type);

        if (corp) {
            name =  corp.corpusEntity.get('name');
            id =  +corp.corpusEntity.id;
        }
        return {
            'id': id,
            'headerTitle': name,
            'headerIcon': 'corpus',
            'headerLink': ['..', 'tools', 'corpus', id],
            'dashWidgetClassName': 'dash-widget-corpus'
        };
    }

    setCorpusData(type, corpus): void {

        if (type === 'projects-management.research_space') {
            this.researchSpace = corpus.getCorpusResources();
            this.researchSpace
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe((resources) => {
                    this.resourceResearchSpace = [];
                    this.resourceResearchSpace.push(...resources);
                    this.corpusResearchSpaceSubscription.next({id: this.getDashWidgetConfCorpus(type)['id'], data: this.resourceResearchSpace});
                });
        } else {
            this.lessonSpace = corpus.getCorpusResources();
            this.lessonSpace
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe((resources) => {
                    this.resources = [];
                    this.resources.push(...resources);
                    this.corpusResourceSubscription.next({id: this.getDashWidgetConfCorpus(type)['id'], data: this.resources});
                });
        }
  }

    setDefinition(): void {
        for (const draft of this.sectionsDraft) {
            this.researchSheetService.loadDefinition(draft.definitionId).subscribe((definitionEntity: DataEntity) => {
                draft.definitionName = definitionEntity.get('name');
            });
        }
    }

    public get empty(): boolean {
      if (this.authService.isAtLeastTrainer()) {
         return !this.corpusSummary.length  && !this.sections.length;
      }
      return !this.resources.length &&
          !this.resourceResearchSpace.length  &&
          !this.sections.length  &&
          !this.sectionsDraft.length;
    }

    public goToHelpPage(): void {
      this.router.navigate(['page', 'aide-aux-ressources']);
    }

  ngOnDestroy(): any {
      this.unsubscribeInTakeUntil.next();
      this.unsubscribeInTakeUntil.complete();
  }
}
