import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Subject} from 'rxjs';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {CommunicationCenterService} from '@modules/communication-center';
import {TranslateService} from '@ngx-translate/core';
import {LRSTracking} from '../../../../../settings';
import {TraceService} from '../../../../../shared/trace.service';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html'
})
export class FeedbackComponent implements OnInit, OnDestroy {

    private unsubscribeInTakeUntil = new Subject();
    public messages: DataEntity[] = [];
    private LRSTracking = LRSTracking;

    @ViewChild('messagesListContainer', { static: true }) messagesListContainer: ElementRef;

    constructor(
        private octopusConnect: OctopusConnectService,
        private dialog: MatDialog,
        private communicationCenter: CommunicationCenterService,
        private translate: TranslateService,
        private traceService: TraceService
    ) {
    }

    ngOnInit(): void {
        this.octopusConnect.listen('feedbackMessage').pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe((message: DataEntity) => {
            if (message.get('type') !== 'information') {
                this.messages.push(message);

                if (message.get('type') === 'earning') {
                    this.openDetail(message, 'next');
                }

                this.scrollDown();
            } else {
                this.openDetail(message, null);
            }
        });

        this.communicationCenter.getRoom('feedback').getSubject('reset').subscribe(reset => {
            if (reset) {
                this.messages = this.messages.filter(mess => mess.get('type') === 'earning');
            }
        });
    }

    scrollDown(): void {
        setTimeout(() => {
            if (this.messagesListContainer) {
                this.messagesListContainer.nativeElement.scrollTop = this.messagesListContainer.nativeElement.scrollHeight;
            }
        }, 100);
    }

    /**
     * Open message detail in modal and change activity according to action param
     **/
    public openDetail(message: DataEntity, action: string): void {
        if (message.get('detail')) {
            if (this.LRSTracking === true) {
                this.sendTrace('feedback', 'opened', message.get('body'));
            }
            const config = {
                titleDialog: '',
                bodyDialog: message.get('detail'),
                labelTrueDialog: 'OK',
                labelOtherDialog: ''
            };

            if (message.get('type') !== 'information' && message.get('glossary')) {
                this.translate.get('generic.glossary').subscribe((translation: string) => {
                    config.labelOtherDialog = translation;
                });
            }

            if (message.get('title')) {
                this.translate.get(message.get('title')).subscribe((translation: string) => {
                    config.titleDialog = translation;
                });
            }

            if (message.get('type') === 'earning' || message.get('type') === 'information') {
                if (message.get('type') === 'information') {
                    this.translate.get('generic.start').subscribe((translation: string) => {
                        config.labelTrueDialog = translation;
                    });
                }
                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                    panelClass: 'feedback-earning-dialog',
                    data: config
                });


                if (action === 'next') {
                    dialogRef.afterClosed().subscribe(result => {
                        this.communicationCenter.getRoom('feedback').next('nextActivity', true);
                        this.sendTrace('feedback', 'closed', message.get('body'));
                    });
                } else {
                    dialogRef.afterClosed().subscribe(result => {
                        if (this.LRSTracking === true) {
                            this.sendTrace('feedback', 'closed', message.get('body'));
                        }
                    });
                }
            } else {
                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                    panelClass: 'feedback-details-dialog',
                    data: config
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (this.LRSTracking === true) {
                        this.sendTrace('feedback', 'closed', message.get('body'));
                    }

                    if (result === 'otherButtonDialogAction') {
                        // previous action is feedback so we open glossary
                        this.openGlossary(message, action);
                    }
                });
            }
        }
    }

    /**
     * check if we have a pop up with more info that can be open
     */
    public isMoreDetailToOpen(message: DataEntity): boolean {
        try {
            return message.get('type') === 'motivational' && message.get('detail') !== '';
        } catch (ex) {
            console.error('error there is no field type or no field detail but we need it ');
            return false;
        }
    }

    /**
     * open glosary of the currrent message
     * @param message current message open wo contain the glossary
     * @param action current action open
     */
    public openGlossary(message: DataEntity, action: string): void {
        const glossary = message.get('glossary');
        if (glossary) {
            if (this.LRSTracking === true) {
                this.sendTrace('glossary', 'opened', glossary);
            }

            this.translate.get('generic.glossary').subscribe((translation: string) => {
                const config = {
                    titleDialog: translation,
                    bodyDialog: glossary,
                    labelTrueDialog: 'OK',
                    labelOtherDialog: ''
                };

                this.translate.get('generic.back').subscribe((translate: string) => {
                    config.labelOtherDialog = translate;
                });

                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                    panelClass: 'feedback-glossary-dialog',
                    data: config
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (this.LRSTracking === true) {
                        this.sendTrace('glossary', 'closed', glossary);
                    }

                    if (result === 'otherButtonDialogAction') {
                        // previous action is openGlossary so we reopen previous info
                        this.openDetail(message, action);
                    }
                });
            });
        }
    }

    public openHelp (): void {
            this.communicationCenter.getRoom('feedback').next('needHelp', true);
    }

    private sendTrace(type, state, detail): void {
        this.communicationCenter
            .getRoom('traces')
            .getSubject('infos')
            .subscribe((traceInfos) => {
                let body = '';
                if (type === 'feedback') {
                    const div = document.createElement('div');
                    div.innerHTML = detail;
                    const firstImage = div.getElementsByTagName('img')[0];
                    body = firstImage ? firstImage.src : '';
                } else {
                    body = detail;
                }

                const data = {
                    destination: type + '/' + state + '?id_learner=' + traceInfos.uid + '&id_task=' + traceInfos.idTask,
                    body: body
                };
                console.log('data', data);
                this.traceService.sendPostRequest(data.destination, data.body);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

}
