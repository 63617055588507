import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AuthenticationService} from './authentication.service';

import {FuseSharedModule} from '@fuse/shared.module';

import {FuseForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {FuseResetPasswordComponent} from './reset-password/reset-password.component';
import {FuseLoginComponent} from './login/login.component';
import {FusePasswordResetEmailComponent} from '@modules/authentication/core/password-reset-email/password-reset-email.component';
import {SharedModule} from '../../../shared/shared.module';
import {IsUserAnonymous} from '../../../guards/is-user-anonymous.class';
import { UpdateMailDialogComponent } from './update-mail-dialog/update-mail-dialog.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

const routes = [
    {
        path: 'login',
        component: FuseLoginComponent,
        canActivate: [IsUserAnonymous]
    },
    {
        path: 'forgot-password',
        component: FuseForgotPasswordComponent
    },
    {
        path: 'user/reset/:token',
        component: FuseResetPasswordComponent
    },
    {
        path: 'password-reset-email',
        component: FusePasswordResetEmailComponent
    }
];

@NgModule({
    imports: [
        FuseSharedModule,
        SharedModule,
        RouterModule.forChild(routes),
        MatProgressSpinnerModule
    ],
    declarations: [
        FuseLoginComponent,
        FuseForgotPasswordComponent,
        FuseResetPasswordComponent,
        FusePasswordResetEmailComponent,
        UpdateMailDialogComponent
    ],
    providers: [],
})
export class AuthenticationModule {

    static forRoot(): ModuleWithProviders<AuthenticationModule> {

        return {
            ngModule: AuthenticationModule,
            providers: [
                AuthenticationService
            ]
        };
    }
}
