import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ResearchSheetService} from '../../research-sheet.service';
import {ResearchSectionTemplate, ResearchTemplate} from '../../definitions';
import {ResearchTemplateService} from '../research-template.service';
import {DataEntity} from 'octopus-connect';

@Component({
    selector: 'app-edited-template-section-choice',
    templateUrl: './edited-template-section-choice.component.html',
    styleUrls: ['./edited-template-section-choice.component.scss']
})
export class EditedTemplateSectionChoiceComponent implements OnInit {

    editedTemplate: ResearchTemplate;
    templatesList: ResearchTemplate[];


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private sheetService: ResearchSheetService,
        private templateService: ResearchTemplateService
    ) { }

    ngOnInit(): void {

        this.sheetService.onTemplatesChanged.subscribe((templates: ResearchTemplate[]) => {
            this.templatesList = templates;

            this.route.params.subscribe((params: Params) => {
                this.editedTemplate = this.templatesList.find(template => template.id === +params['sheetid']);


                console.log('edit template section choice', this.editedTemplate);
            });
        });


    }


    get templateEntity(): DataEntity {
        if (this.editedTemplate){
            return this.sheetService.templatesCollection[this.editedTemplate.id];
        }

        return null;
    }

    isMine(): boolean {
        if (this.templateEntity) {
            return this.sheetService.isMine(+this.templateEntity.get('uid'));
        }

        return null;
    }
}
