export const cards = {
    cardDisplay: {
        showButtonThemeLesson: true,
        showButtonBookmarks: {
            themes: true,
            lesson: true,
            model: true,
            community: true,
            image: true,
            video: true,
            videoUrl: true,
            audio: true,
            document: true,
            url: true,
        },
        isTextButton: true,
    },
    cardFields: {
        default: {
            theme: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty', 'target-age'],
            lesson: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty', 'target-age', 'author', 'share-lesson'],
            model: ['title', 'titleIcon', 'menu', 'image', 'theme', 'skills', 'difficulty', 'target-age', 'author', 'share-lesson'],
            community: ['title', 'titleIcon', 'menu', 'image', 'theme', 'skills', 'difficulty', 'target-age', 'author', 'share-lesson'],
            image: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            video: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            videoUrl: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            audio: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            document: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            url: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
        }
    },
    cardDefaultHeader: '/assets/lamorim/images/backgrounds/img_default_lesson.svg',
    cardPlayOpenMenu: false,
    menuLinks: {
        lesson: {
            administrator: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'assign', 'duplicate'],
            default: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'assign', 'duplicate']
        },
        model: {
            administrator: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'duplicate'],
            manager: ['duplicate'],
            default: ['duplicate']
        }
    }
};
