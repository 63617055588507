import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {mergeMap, map, tap} from 'rxjs/operators';
import {DataEntity} from 'octopus-connect';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import * as _ from 'lodash';
import {GenericPluginsService, PluginType} from '@modules/activities/core/services/generic-plugins.service';
import {brand} from '../../../../../settings';

@Component({
    selector: 'app-tool',
    templateUrl: './tool.component.html'
})
export class ToolComponent implements OnInit {
    public renderIsReady = false;
    private activityGranule: DataEntity;
    private activityInterface: DataEntity;
    public brand = brand;

    constructor(
        private activatedRoute: ActivatedRoute,
        private activitiesService: ActivitiesService,
        private genericPluginsService: GenericPluginsService,
        private lessonsService: LessonsService
    ) {
    }

    public get instruction(): string {
        return _.get(this.activityInterface, 'attributes.instruction');
    }

    /**
     * Return the svg identifier of the current activity tool image.
     * This image should be set while creating the tool activity.
     * If it's not, the default tool image `tools-circle` will be showed
     */
    public get iconName(): string {
        const toolId = _.get(this.activityInterface, 'attributes.config.tool');

        const toolSetting = this.genericPluginsService.getPluginsByType(PluginType.lessonTool).find(
            pluginSetting => pluginSetting.toolIdentifier === toolId
        );

        return _.get(toolSetting, 'setting.iconIdentifier');
    }

    ngOnInit(): void {
        this.renderIsReady = false;
        this.activatedRoute.params.pipe(
            map(params => params['activityId']),
            mergeMap(activityId => this.activitiesService.loadActivitiesFromId(activityId)),
            tap(activityGranule => this.activityGranule = activityGranule),
            mergeMap(activityGranule => this.activitiesService.loadActivityInterface(activityGranule.get('reference'))),
            tap(activityInterface => {
                this.activityInterface = activityInterface;
                this.renderIsReady = true;
            })
        ).subscribe();
    }
}
