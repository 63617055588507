export enum SyncRules {
    DeleteInstitution = 'group-management.delete-institution',
    CreateInstitution = 'group-management.create-institution',
    BeAttachedToInstitution = 'group-management.be-attached-to-institution',
    AccessInstitutionPanel = 'group-management.access-institution-panel',
    ActivateInstitutionsCode = 'group-management.activate-institutions-code',

    // Modifier les metadata de la classe mais aussi la liste des membres
    EditGroup = 'group-management.edit-group',
    DeleteGroup = 'group-management.delete-group',
    CreateGroup = 'group-management.create-group',
    ArchiveGroup = 'group-management.archive-group',
    ActiveMetacognitionOnGroup = 'group-management.active-metacognition-on-group',
    BeAttachedToGroup = 'group-management.be-attached-to-group',
    SeeOwnGroup = 'group-management.see-own-group',
    SeeAllGroup = 'group-management.see-all-group',
    ActivateGroupsCode = 'group-management.activate-groups-code',

    // Modifier les metadata de la classe mais aussi la liste des membres
    EditWorkgroup = 'group-management.edit-workgroup',
    DeleteWorkgroup = 'group-management.delete-workgroup',
    CreateWorkgroup = 'group-management.create-workgroup',
    ArchiveWorkgroup = 'group-management.archive-workgroup',
    BeAttachedToWorkgroup = 'group-management.be-attached-to-workgroup',
    SeeOwnWorkgroup = 'group-management.see-own-workgroup',
    SeeAllWorkgroup = 'group-management.see-all-workgroup',

    EditTrainer = 'group-management.edit-trainer',
    DeleteTrainer = 'group-management.delete-trainer',
    CreateTrainer = 'group-management.create-trainer',
    EditTrainerInstitutionManagerTrainerRights = 'group-management.edit-trainer-institution-manager-trainer-rights',

    EditLearner = 'group-management.edit-learner',
    DeleteLearner = 'group-management.delete-learner',
    CreateLearner = 'group-management.create-learner',

    AccessTrainersAndDirectorsManagementPanel = 'group-management.access-trainers-and-directors-management-panel',
}
