import {Observable} from 'rxjs';
import {DataEntity, PaginatedCollection} from 'octopus-connect';
import {ModelPaginatedCollectionDto} from '@fuse/adapters/model-paginated-collection-dto';
import {IConvert} from '@fuse/adapters/iconvert';
import {Paginated} from '@fuse/adapters/paginated';

export class ThemeDto extends Paginated implements IConvert{
    private _modelPaginatedCollectionDto: ModelPaginatedCollectionDto;
    /**
     * we inheritance to add less similar code as possible
     * ModelPaginatedCollectionDto take the data and the current class type to know how to convert data
     * @param data : PaginatedCollection
     */
    constructor(data: PaginatedCollection) {
        super();
        // this represent the current instanciated object
        this._modelPaginatedCollectionDto = new ModelPaginatedCollectionDto(data, this);
        super.setPaginated(this._modelPaginatedCollectionDto);
    }

    /**
     * method is call by the adapter class because adapter is common to all object
     * but convert method is specific to each one
     * @param data dataentities
     */
    convert(data: DataEntity[]): IThemeDto[] {
        return data.map(d => ({
            id: d.id,
            type: d.type,
            attributes: {
                bookmarks: d.attributes.bookmarks,
                document: d.attributes.document,
                label: d.attributes.label,
                image: d.attributes.image,
                metadatas: d.attributes.metadatas,
                weight: d.attributes.weight,
                reference: d.attributes.reference
            },
            get: d.get,
            set: d.set
        }));
    }

    /**
     * get the final object to use in front typed and formated
     */
    getObjectDto(): Observable<IThemeDto[]> {
        return this._modelPaginatedCollectionDto.objectDto();
    }
}

export interface IThemeDto {
    attributes: IAttributes;
    id: number | string;
    type: string;

    get(key: string): any; // needed to maintain compatibility with old code using code
    set(key: string, value: any): void; // needed to maintain compatibility with old code using set
}

export interface IAttributes {
    bookmarks: boolean;
    document: string;
    label: string;
    image: IImage;
    metadatas: IMetadatas;
    // reference: not seems to be used?
    weight: string;
}

export interface IMetadatas {
    id: number | string;
    language: string;
    title: string;
    description: string;
    skills: ISkill[];
    difficulty: IDifficulty;
    educationalLevel: IEducationalLevel;
    created: string;
    changed: string;
    author: string;
}

export interface IImage {
    id: string;
    uri: string;
}

export interface ISkill {
    id: number | string;
    label: string;
}

export interface IDifficulty {
    id: number | string;
    label: string;
}

export interface IEducationalLevel {
    id: number | string;
    label: string;
}
