import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CollectionOptionsInterface} from 'octopus-connect';
import {debounceTime, take, takeUntil, tap} from 'rxjs/operators';
import {DataEntity, OrderCriteria} from 'octopus-connect';
import {CollectionPaginator} from 'octopus-connect';
import {RecommendationService} from '@modules/activities/core/lessons/lessons-list/recommendation/recommendation.service';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {FormControl} from '@angular/forms';
import {IBasicListDto} from 'fuse-core/adapters/commonModelDto/basic-list-dto';
import {OrderDirection} from 'octopus-connect';
import * as _ from 'lodash';

class RecommendationComponentOptions {
    limit: number;
    redirectUrl: string;
}

@Component({
    selector: 'app-recommendation',
    templateUrl: './recommendation.component.html',
    styleUrls: ['./recommendation.component.scss']
})
export class RecommendationComponent implements OnInit, OnDestroy {

    public checkboxes: { [key: string]: FormControl } = {};
    public recommendations: { [key: string]: DataEntity } = {};
    public allEntitiesSelectedControl: FormControl = new FormControl(false);
    public dataSource = new MatTableDataSource([]);
    public typeControl: FormControl = new FormControl();
    public learnersControl: FormControl = new FormControl();
    public lessonsControl: FormControl = new FormControl();
    public displayedColumns: string[];
    public filters: string[] = [];
    public sortSelected: string;
    public loadingPage = true;

    private recommendationsToHide = [];
    private optionsInterface: CollectionOptionsInterface = {filter: {}, range: 10, page: 1, orderOptions: []};
    private unsubscribeInTakeUntil = new Subject();
    private defaultOptions: Partial<RecommendationComponentOptions> = {
        limit: -1
    };
    private recommendationsTotal: number;

    constructor(private recommendationService: RecommendationService) {
    }

    private _options: Partial<RecommendationComponentOptions> = _.clone(this.defaultOptions);

    @Input()
    public set options(options: Partial<RecommendationComponentOptions>) {
        this._options = _.merge({}, this.defaultOptions, options);
    }

    public get redirectToRecommendationsUrl(): string {
        return this._options.redirectUrl;
    }

    /**
     * count of recommendations selected
     */
    public get countEntitiesSelected(): number {
        return Object.keys(this.checkboxes).filter((key: string) => this.checkboxes[key].value === true).length;
    }

    /**
     * get list of type of assignment
     */
    public get types(): IBasicListDto[] {
        return this.recommendationService.types;
    }

    /**
     * get learners list
     */
    public get learners(): {id: string | number, username: string}[] {
        return this.recommendationService.learners;
    }

    /**
     * get lessons list
     */
    public get lessons(): DataEntity[] {
        return this.recommendationService.lessons;
    }

    /**
     * get loading status for spinner in html
     */
    public get loading(): boolean {
        return this.recommendationService.loading;
    }

    ngOnInit(): void {
        this.recommendationService.loading = true;

        this.recommendationService.loadLessons().pipe(
            take(1),
            tap(() => this.refreshList())
        ).subscribe();


        this.allEntitiesSelectedControl.valueChanges.pipe(
            tap((val: boolean) => {
                for (const entityId in this.checkboxes) {
                    this.checkboxes[entityId].setValue(val);
                }
            })
        ).subscribe();

        this.typeControl.valueChanges.pipe(
            tap((val: number) => {
                if (!val) {
                    delete this.optionsInterface.filter.assignationType;
                } else {
                    this.optionsInterface.filter.assignationType = val;
                }
                this.refreshList();
            })
        ).subscribe();

        this.learnersControl.valueChanges.pipe(
            debounceTime(2000),
            tap((learners: {id: string | number, username: string}[]) => {
                if (!learners || !learners.length || learners.length === this.learners.length) {
                    delete this.optionsInterface.filter.learners;
                } else {
                    this.optionsInterface.filter.learners = learners.map((learner) => learner.id);
                }
                this.refreshList();
            })
        ).subscribe();

        this.lessonsControl.valueChanges.pipe(
            debounceTime(2000),
            tap((val: number[] | string) => {
                if (!val) {
                    delete this.optionsInterface.filter.lessons;
                } else {
                    this.optionsInterface.filter.lessons = val;
                }
                this.refreshList();
            })
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    /**
     * load or reload recommendations entities
     * @param pageChanged know if we reset result's page to 1
     */
    public refreshList(pageChanged?: boolean): void {
        if (!pageChanged) {
            this.optionsInterface.page = 1;
        }
        this.loadingPage = true;
        this.recommendationService.loading = true;
        this.recommendationService.loadRecommendations(this.optionsInterface)
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((activitiesAndPaginator: { entities: DataEntity[], paginator: CollectionPaginator }) => {
            activitiesAndPaginator.entities.slice().forEach((entity: DataEntity, key: number) => {
                if (!this.checkboxes[entity.id]) {
                    this.checkboxes[entity.id] = this.allEntitiesSelectedControl.value ? new FormControl(true) : new FormControl(false);
                }
                if (!this.recommendations[entity.id]) {
                    this.recommendations[entity.id] = entity;
                }
            });
            this.displayedColumns = this.recommendationService.settings.recommendationDisplayedColumns;
            const filteredData = this.recommendationsToHide.length ?
                activitiesAndPaginator.entities.filter((recommendation: DataEntity) => !this.recommendationsToHide.includes(recommendation.id)) : activitiesAndPaginator.entities;
            this.recommendationsTotal = filteredData.length;
            this.dataSource.data = this._options.limit === -1 ? filteredData : filteredData.slice(0, this._options.limit);
            this.recommendationService.loading = false;
            this.loadingPage = false;
        });
    }

    /**
     * assign unique or multiple lesson from recommendations
     * @param recommendation
     */
    public assignLesson(recommendation?: DataEntity): void {
        if (!!recommendation) {
            this.recommendationService.assignRecommendedLessonSelected([recommendation]).pipe(
                tap(() => this.updateRecommendationsInDataSource([recommendation]))
            ).subscribe();
        } else {
            const recommendations = Object.keys(this.checkboxes)
                .filter((recommendationsId: string) => !!this.checkboxes[recommendationsId].value)
                .map((recommendationsId) => this.recommendations[recommendationsId]);

            this.recommendationService.assignRecommendedLessonSelected(recommendations).pipe(
                tap(() => this.updateRecommendationsInDataSource(recommendations))
            ).subscribe(res => {
            }, error => {
                console.error('recom:203 error');
            });
        }
    }

    /**
     * edit lesson from recommendation
     * @param recommendation
     */
    public editLesson(recommendation: DataEntity): void {
        this.recommendationService.launchCopyLessonEditor(recommendation).pipe(
            tap(() => this.updateRecommendationsInDataSource([recommendation]))
        ).subscribe();
    }

    /**
     * delete lesson from recommendation
     * @param recommendation
     */
    public deleteLesson(recommendation: DataEntity): void {
        this.recommendationService.createEntityByAction('ignore', recommendation).pipe(
            tap(() => this.updateRecommendationsInDataSource([recommendation]))
        ).subscribe();
    }

    /**
     * translate type of assignments for filter
     * @param type
     */
    public localizedType(type: string): string {
        return `assignment.type.${type}`;
    }

    /**
     * sort recommendations by learner name or lesson title or confidence
     * @param field
     */
    public sortBy(field): void {
        this.sortSelected = field;
        const sortByLabel: OrderCriteria = this.optionsInterface.orderOptions.find((orderCriteria: OrderCriteria) => orderCriteria.field === field);
        if (sortByLabel) {
            if (field === 'confidence') {
                if (sortByLabel.direction === OrderDirection.DESC) {
                    sortByLabel.direction = OrderDirection.ASC;
                } else {
                    sortByLabel.direction = OrderDirection.DESC;
                    this.sortSelected = null;
                }
            } else {
                this.optionsInterface.orderOptions = [];
                this.sortSelected = null;
            }
        } else {
            this.optionsInterface.orderOptions = [{field, direction: OrderDirection.ASC}];
        }
        this.refreshList();
    }

    /**
     * after action from user (modify/assign/ignore), hide in front entities.
     * @param recommendations : dataEntity array
     */
    public updateRecommendationsInDataSource(recommendations: DataEntity[]): void {
        recommendations.forEach(rec => {
            if (!this.recommendationsToHide.includes(rec.id)) {
                this.recommendationsToHide.push(rec.id);
            }
        });

        this.refreshList();
    }

    /**
     * return if redirect to recommendation button should be displayed.
     * True if there are an url, if data length is limited and more data than the limit
     */
    public shouldDisplaySeeMoreLink(): boolean {
        // on pourrait se limiter à this.dataSource.data.length < this.recommendationsTotal mais ceinture & bretelles
        return this._options.redirectUrl !== undefined && this._options.limit !== -1 && this.dataSource.data.length < this.recommendationsTotal;
    }
}
