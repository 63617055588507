
import {take, mergeMap, map, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../settings';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable, Subscription, BehaviorSubject, combineLatest, ReplaySubject, Subject} from 'rxjs';
import {
    ResearchDraftQuestion,
    ResearchSection,
    ResearchSectionTemplate,
    ResearchSheet,
    ResearchTemplate
} from './definitions';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {Draft} from '@modules/research-sheet/core/section-draft-display/draft.model';
import {Project} from '@modules/projects-management/core/project.model';
import {AuthenticationService} from '@modules/authentication';
import {Group, Workgroup} from '@modules/groups-management/core/definitions';

const settingsStructure: ModelSchema = new ModelSchema({
    gettingStarted: Structures.object({}),
    section: Structures.object()
});

const projectSettingsStructure: ModelSchema = new ModelSchema(({
    accessProject: Structures.boolean()
}));

@Injectable()
export class ResearchSheetService {
    private sheetsCollection: {[key: number]: DataEntity} = {};
    public sheetsList: ResearchSheet[] = [];
    public sectionsCollection: {[key: number]: DataEntity} = {};
    private sectionsList: ResearchSection[] = [];
    public templatesCollection: {[key: number]: DataEntity} = {};
    public templatesList: ResearchTemplate[] = [];
    public templatesSectionsCollection: {[key: number]: DataEntity} = {};
    private templatesSectionsList: ResearchSectionTemplate[] = [];
    private researchSubscription: Subscription = null;
    public loadingSheet: string = '';
    private loadingSection: string = '';
    private claimingSection = false;

    private workgroupList: any[];
    currentProjectId: string = '';

    accessTrainer: string[] = ['trainer'];
    accessLearner: string[] = ['learner'];

    public onSheetsChanged: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public onSheetsFiltered: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public onTemplatesChanged: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public currentSheet: ResearchSheet = null;
    public currentSheetEntity: DataEntity = null;
    public currentSection: ResearchSection = null;
    public currentSectionEntity: DataEntity = null;

    public currentDraft: Draft;
    public settings: {[key:string]:any};
    public projectSettings: {[key: string]: any};

    projects: Project[];

    classList: Group[];

    public mainObs: Observable<any>;

    currentUser: DataEntity;

    public completedObs: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    public showDetailsSubject: Subject<any> = new Subject<any>();

    selectedProject: any;


    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthenticationService
    ) {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd),
            map(() => this.route),
            map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter(route => route.outlet === 'primary'),
            mergeMap(route => {
                if (route.params) {
                    return route.params;
                } else {
                    return null;
                }
            }),)
            .subscribe((params: Params) => {
                this.currentProjectId = params['projectId'];
            });

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.currentUser = data;
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });



        this.settings = settingsStructure.filterModel(modulesSettings.researchSheet);
        this.projectSettings = projectSettingsStructure.filterModel(modulesSettings.projectsManagement);
    }

    public getDraftListLink(): string {
        return '/projects/' + this.currentProjectId + '/tools/section-draft/list';
    }

    public getDraftEditLink(draftId: number): string {
        return '/projects/' + this.currentProjectId + '/tools/section-draft/edit/' + draftId;
    }

    public getDraftViewLink(draftId: number): string {
        return '/projects/' + this.currentProjectId + '/tools/section-draft/view/' + draftId;
    }

    public getSectionLink(sectionId: number): string {
        return 'section/edit/' + sectionId;
    }

    public getSheetLink(): string {
        if (this.currentSheet){
            return '/projects/' + this.currentProjectId + '/tools/research-sheet/' + this.currentSheet.id;
        } else {
            return '/projects/' + this.currentProjectId + '/tools/research-sheet/list';
        }
    }

    private postLogout(): void{
        this.researchSubscription.unsubscribe();
        this.researchSubscription = null;
    }

    private postAuthentication(): void {

        if (!this.researchSubscription) {
            this.communicationCenter
                .getRoom('groups-management')
                .getSubject('groupsList')
                .subscribe(glist => {
                    this.classList = glist.filter((group) => !group.archived);
                });

            const subjectWorkgroupsList = this.communicationCenter
                .getRoom('groups-management')
                .getSubject('workgroupsList');

            subjectWorkgroupsList.subscribe(g => {
                if (this.selectedProject) {
                    this.workgroupList = g.filter((workgroup) => !workgroup.archived && workgroup.projects.find((project) => +project === +this.selectedProject.id));
                } else {
                    this.workgroupList = g.filter((workgroup) => !workgroup.archived);
                }
            });

            if (this.projectSettings && this.projectSettings.accessProject) {
                this.communicationCenter
                    .getRoom('project-management')
                    .getSubject('selectedProject')
                    .subscribe((data) => {
                    this.selectedProject = data;
                        if (this.selectedProject) {
                            this.currentProjectId = this.selectedProject.id.toString();
                            if (this.workgroupList) {
                                this.workgroupList = this.workgroupList
                                    .filter((workgroup) => workgroup.projects
                                        .find((project) => +project === +this.selectedProject .id));
                            }
                            this.communicationCenter
                                .getRoom('research-sheet')
                                .getSubject('sheetsFiltered')
                                .next(this.filterSheetList(this.sheetsList));
                        }


                    });
            }

            this.communicationCenter
                .getRoom('projects-management')
                .getSubject('projectsList').subscribe((projects: Project[]) => this.projects = projects);


            this.mainObs = combineLatest(
                this.loadResearchSheets(),
                this.loadResearchTemplates(),
                subjectWorkgroupsList,
                this.loadResearchSheetsSections()); // TODO - find a way to not wait for sections to display anything but still have sections data loaded when we need it
            this.researchSubscription = this.mainObs.subscribe((data: [DataCollection, DataCollection, any[], DataCollection]) => {
                this.sheetsList = [];
                this.sectionsList = [];
                this.templatesList = [];

                data[1].entities.forEach((template: DataEntity) => {
                    const sections: ResearchSectionTemplate[] = (<DataEntity[]>template.getEmbed('sections'))
                        .map((section: DataEntity) => {
                            const draftQuestions: ResearchDraftQuestion[] = section.get('draftQuestions');

                            this.templatesSectionsCollection[section.id] = section;

                            return {
                                id: parseInt(section.id.toString()),
                                name: section.get('name'),
                                consigne: section.get('consigne'),
                                readingGrid: section.get('readingGrids') ? section.get('readingGrids').text : '',
                                followingSections: section.get('followingSections'),
                                draftQuestions: draftQuestions
                            };
                        });

                    this.templatesSectionsList.push(...sections);
                    this.templatesList.push({
                        id: parseInt(template.id.toString()),
                        name: template.get('name'),
                        sections: sections,
                        sectionsDefinitions: template.get('sections')
                    });

                    this.templatesCollection[template.id] = template;
                });

                data[3].entities.forEach((section: DataEntity) => {
                    this.sectionsList.push({
                        id: parseInt(section.id.toString()),
                        name: section.get('name'),
                        text: section.get('text'),
                        template: this.getSectionTemplate(section.get('sectionDefinitions')),
                        state: section.get('state'),
                        comments: section.get('comments') ? section.get('comments') : [],
                        created: section.get('created'),
                        updated: section.get('updated'),
                        parent: section.get('parent') ? section.get('parent') : '',
                        researchSheet: +section.get('researchSheet'),
                        childCount: 0,
                        medias: section.get('medias') || []
                    });

                    this.sectionsCollection[section.id] = section;
                });

                data[0].entities.forEach((sheet: DataEntity) => {
                    const sections: ResearchSection[] = this.sectionsList
                        .filter((section: ResearchSection) => section.researchSheet === parseInt(sheet.id.toString()));
                    sections.sort((sectionA: ResearchSection, sectionB: ResearchSection) => sectionA.created - sectionB.created);

                    let columns = 0;

                    function getParent(sectionId: string): ResearchSection {
                        let parent = null;

                        if (sectionId) {
                            sections.some((section: ResearchSection) => {
                                if (section.id.toString() === sectionId) {
                                    parent = section;
                                    return true;
                                }
                            });
                        }

                        return parent;
                    }

                    sections.forEach((section: ResearchSection, index: number) => {
                        const parent = getParent(section.parent);
                        if (parent) {
                            parent.childCount += 1;

                            if (parent.childCount > 1) {
                                columns += 1;
                                section.x = columns;
                            } else {
                                section.x = parent.x;
                            }
                        } else {
                            section.x = columns;
                        }

                        section.y = index;
                    });

                    this.sheetsList.push({
                        id: parseInt(sheet.id.toString()),
                        name: sheet.get('name'),
                        group: this.getGroup(sheet.get('group')),
                        project: sheet.get('project'),
                        template: this.getTemplate(sheet.get('sectionDefinitionsSet')),
                        sections: sections,
                        created: sheet.get('created'),
                        updated: sheet.get('updated')
                    });

                    this.sheetsCollection[sheet.id] = sheet;
                });

                this.communicationCenter
                    .getRoom('research-sheet')
                    .next('sheetsList', this.sheetsList);
                this.communicationCenter
                    .getRoom('research-sheet')
                    .next('sectionsList', this.sectionsList);
                this.communicationCenter
                    .getRoom('research-sheet')
                    .next('templatesList', this.templatesList);
                this.onSheetsChanged.next(this.sheetsList);
                if (this.selectedProject) {
                    this.onSheetsFiltered.next(this.filterSheetList(this.sheetsList));
                    this.communicationCenter
                        .getRoom('research-sheet')
                        .getSubject('sheetsFiltered')
                        .next(this.filterSheetList(this.sheetsList));
                }

                this.onTemplatesChanged.next(this.templatesList);

                this.communicationCenter
                    .getRoom('research-sheet')
                    .getSubject('sheetsTemplate')
                    .next({templates: this.templatesList, templateCollection: this.templatesCollection});

                if (this.loadingSheet) {
                    this.loadResearchSheet(this.loadingSheet);
                    this.loadingSheet = '';
                }
                if (this.loadingSection) {
                    this.loadResearchSection(this.loadingSection);
                    this.loadingSection = '';
                }

                this.completedObs.next(true);
            });
        }


    }

    filterSheetList(sheetList: ResearchSheet[]): any{
        const sheetFiltered = [];
        const sheetFilteredByProject = sheetList.filter((sheet) => {
            return this.selectedProject && +this.selectedProject.id && +sheet.project === +this.selectedProject.id;
        });
        for (const researchSheet of sheetFilteredByProject){

            sheetFiltered.push(...researchSheet.sections.map((sect) => {
                const obj = {};
                obj['researchSheetId'] = researchSheet.id;
                obj['researchSheetName'] = researchSheet.name;
                obj['sectionId'] = sect.id;
                obj['sectionName'] = sect.name;
                obj['sectionState'] = sect.state;
                obj['sectionCreated'] = sect.created;
                obj['sectionUpdated'] = sect.updated;
                obj['sectionComments'] = this.getCommentsFromSection(sect.comments).join(', ');
                return obj;
            }));

        }

        if (this.authService.hasLevel(['trainer'])) {
            return sheetFiltered.filter((sheet) => sheet.sectionState === 'pending' || sheet.sectionState === 'updated').slice(-3);
        }

        if (this.authService.hasLevel(['learner'])) {
            return sheetFiltered.filter((sheet) => sheet.sectionState === 'correct' || sheet.sectionState === 'validated' ).slice(-3);
        }

        return sheetFiltered.slice(-3);
    }

    getCommentsFromSection(data): string[] {
        return data.map((item) => item.comment);
    }

    public loadResearchSheets(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('research-sheets');
    }

    public loadResearchSheetsSections(): Observable<DataCollection> {
        console.log('section load function');
        return this.octopusConnect.loadCollection('sections');
    }

    public loadResearchSheetsSectionsById(sheetId: number): Observable<Object> {
        return this.octopusConnect.loadCollection('sections', {
            researchSheet: String(sheetId)
        }).pipe(map((coll: DataCollection) => {

            return coll.entities.map((section: DataEntity) => {

                return {
                    id: parseInt(section.id.toString()),
                    name: section.get('name'),
                    text: section.get('text'),
                    template: this.getSectionTemplate(section.get('sectionDefinitions')),
                    state: section.get('state'),
                    comments: section.get('comments'),
                    created: section.get('created'),
                    updated: section.get('updated'),
                    parent: section.get('parent') ? section.get('parent') : '',
                    researchSheet: parseInt(section.get('researchSheet')),
                    childCount: 0,
                    medias: section.get('medias') || []
                };

            });

        }));
    }

    public loadResearchSheet(sheetId: string) {
        this.currentSheet = null;
        this.currentSheetEntity = null;

        this.sheetsList.some((sheet: ResearchSheet) => {
            if (sheet.id.toString() === sheetId) {
                this.currentSheet = sheet;
                this.currentSheetEntity = this.sheetsCollection[sheet.id];
                return true;
            }
        });

        if (!this.currentSheet) {
            this.loadingSheet = sheetId;
        }
    }

    public loadResearchSection(sectionId: string) {
        this.currentSection = null;
        this.currentSectionEntity = null;

        this.sectionsList.some((section: ResearchSection) => {
            if (section.id.toString() === sectionId) {
                this.currentSection = section;
                this.currentSectionEntity = this.sectionsCollection[section.id];
                return true;
            }
        });

        if (!this.currentSection) {
            this.loadingSection = sectionId;
        }
    }

    public loadResearchDrafts(projectId = null): Observable<DataCollection> {
        if (projectId) {
            return this.octopusConnect.loadCollection('draft-sections', {
                parent: projectId
            });
        }

        return this.octopusConnect.loadCollection('draft-sections', {
            parent: this.currentProjectId
        });
    }

    public loadDraft(draftId: number): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('draft-sections', draftId);
    }

    createResearchDraft(draft: Draft): Observable<DataEntity> {
        const obs: Observable<DataEntity> = this.octopusConnect.createEntity('draft-sections', {
            name: draft.name,
            sectionDefinitions: draft.section,
            parent: this.currentProjectId
        }).pipe(take(1));

        obs.subscribe(entity => {
            this.sendCreationNotification(entity);
        });

        return obs;
    }
    private sendCreationNotification(entity: DataEntity): void {

        let project: Project;
        let notifType: string;

        if (entity.type === 'research-sheets') {
            notifType = 'RESEARCH_SHEET_CREATED';
            project = this.projects.find(mp => +mp.id === +entity.get('project'));
        } else {
            notifType = 'RESEARCH_SHEET_DRAFT_CREATED';
            project = this.projects.find(mp => +mp.id === +entity.get('parent'));
        }

        const userIds: number[] = [];

        if (entity.type === 'research-sheets') {
            const group: Workgroup = this.workgroupList.find(mg => +mg.id === +entity.get('group'));
            userIds.push(...group.learnersIds);
        } else {
            const group: Workgroup = this.workgroupList.find(mg => this.currentUser.get('groups').indexOf(mg.id.toString() > -1));
            userIds.push(...group.learnersIds);
        }


        userIds.forEach((id) => {
            this.communicationCenter
                .getRoom('notifications')
                .next('sendNotification', {
                    recipient: id,
                    type: notifType,
                    content: {
                        author: this.currentUser ? this.currentUser.get('label') : '',
                        name: entity.get('name'),
                        rsId: entity.id,
                        projectId: entity.get('parent'),
                        projectName: project.name
                    }
                });
        });
    }

    private loadResearchTemplates(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('section-definitions-set');
    }

    public loadDefinition(id): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('section-definitions', id);
    }

    private templateNameToId(templateName: string): string {
        let templateId = '';

        this.templatesList.some((template) => {
            if (template.name === templateName) {
                templateId = template.id.toString();
                return true;
            }
        });

        return templateId;
    }

    private templateIdToName(templateId: string): string {
        let templateName = '';

        this.templatesList.some((template) => {
            if (template.id.toString() === templateId) {
                templateName = template.name;
                return true;
            }
        });

        return templateName;
    }

    private getTemplate(templateId: string): ResearchTemplate {
        let templateFound: ResearchTemplate = null;

        this.templatesList.some((template) => {
            if (template.id.toString() === templateId) {
                templateFound = template;
                return true;
            }
        });

        return templateFound;
    }

    private getSectionTemplate(sectionId: string): ResearchSectionTemplate {
        let sectionFound: ResearchSectionTemplate = null;

        this.templatesSectionsList.some((section) => {
            if (section.id.toString() === sectionId) {
                sectionFound = section;
                return true;
            }
        });

        return sectionFound;
    }

    private getGroup(groupId: string): any {
        let groupFound: any = null;

        this.workgroupList.some((group) => {
            if (group.id.toString() === groupId) {
                groupFound = group;
                return true;
            }
        });

        return groupFound;
    }

    public getGroups(): any[] {
        return this.workgroupList.slice();
    }

    public getTemplates(): ResearchTemplate[] {
        return this.templatesList.slice();
    }

    public getSheet(sheetId: string): ResearchSheet {
        let sheetFound: ResearchSheet = null;

        this.sheetsList.some((sheet) => {
            if (sheet.id.toString() === sheetId) {
                sheetFound = sheet;
                return true;
            }
        });

        return sheetFound;
    }

    public getSection(sectionId: string): ResearchSection {
        let sectionFound: ResearchSection = null;

        this.sectionsList.some((section) => {
            if (section.id.toString() === sectionId) {
                sectionFound = section;
                return true;
            }
        });

        return sectionFound;
    }

    public addResearchSheet(sheet: ResearchSheet) {
        this.octopusConnect
            .createEntity('research-sheets', {
                name: sheet.name,
                sectionDefinitionsSet: sheet.template.id.toString(),
                group: sheet.group.id.toString(),
                project: this.currentProjectId
            }).pipe(
            take(1))
            .subscribe((sheet: DataEntity) => {
                this.sendCreationNotification(sheet);
            });
    }

    public addSection(section: ResearchSection) {
        const data = {
            name: section.name,
            text: section.text,
            medias: section.medias,
            comments: section.comments ? section.comments : [],
            sectionDefinitions: section.template.id.toString(),
            researchSheet: this.currentSheet.id.toString(),
            state: section.state ? section.state : 'pending'
        };

        if (section.parent && section.parent !== ''){
            data['parent'] = section.parent;
        }

        this.octopusConnect
            .createEntity('sections', data).pipe(
            take(1))
            .subscribe((sect: DataEntity) => {
                console.log('Section added', sect.attributes);
                this.backToSectionList();

                this.sendResearchSheetValidationNotification(+sect.id);
            });
    }

    public addResearchTemplate(template: ResearchTemplate) {
        this.octopusConnect
            .createEntity('section-definitions-set', {
                name: template.name
            }).pipe(
            take(1))
            .subscribe((tmpl: DataEntity) => {
                console.log('Research Template added', tmpl.attributes);
            });
    }

    public saveResearchSheet(sheet: ResearchSheet) {
        const sheetEntity = this.sheetsCollection[sheet.id];

        if (sheetEntity) {
            sheetEntity.set('name', sheet.name);

            if (sheet.template) {
                sheetEntity.set('sectionDefinitionsSet', sheet.template.id.toString());
            }

            if (sheet.group.id) sheetEntity.set('group', sheet.group.id.toString());

            sheetEntity.save();
        }
    }

    checkAccessTrainer(){
        return this.authService.hasLevel(this.accessTrainer);
    }

    public claimActive(sectionEntity: DataEntity): void {
        if (!this.claimingSection) {
            this.claimingSection = true;
            sectionEntity.set('activeUser', null);
            sectionEntity.save().pipe(take(1)).subscribe((entity: DataEntity) => {
                this.claimingSection = false;
            });
        }
    }

    public saveSection(section: ResearchSection ) {
        const sectionEntity = this.sectionsCollection[section.id];

        if (sectionEntity) {
            sectionEntity.set('text', section.text);
            sectionEntity.set('medias', section.medias);
            sectionEntity.set('state', section.state);
            if (this.authService.hasLevel(['learner'])){
                sectionEntity.set('state', 'pending');
            }
            sectionEntity.set('comments', section.comments);

            sectionEntity.set('activeUser', null);

            sectionEntity.save().pipe(take(1)).subscribe(entity => {
                this.sendResearchSheetValidationNotification(+entity.id);
                this.backToSectionList();
            });
        }


    }


    sendResearchSheetTeacherCommentNotification(section) {

        if (this.authService.isAdministrator() || this.authService.isTrainer()) {
            const sheet: ResearchSheet = this.sheetsList.find(csheet => +csheet.id === +section.researchSheet);
            const project: Project = this.projects.find(mproject => +mproject.id === +sheet.project);


            this.communicationCenter
                .getRoom('notifications')
                .next('sendNotification', {
                    recipient : sheet.group.learnersIds,
                    type: 'SECTION_VALIDATED_BY_TEACHER',
                    content: {
                        author: this.currentUser ? this.currentUser.get('label') : '',
                        groupName: sheet.group.workgroupname,
                        sheetName: sheet.name,
                        sheetId: sheet.id,
                        sectionId: section.id,
                        projectId: sheet.project,
                        sectionName: section.template.name
                    }
                });

        }
    }

    sendResearchSheetValidationNotification(sectionId: number) {


        if (this.authService.isLearner()) {
            const project: Project = this.projects.find(mproject => +mproject.id === +this.currentSheet.project);

            this.communicationCenter
                .getRoom('notifications')
                .next('sendNotification', {
                    recipient : +project.originalEntity.get('uid'),
                    type: 'WAITING_FOR_VALIDATION',
                    content: {
                        author: this.currentUser ? this.currentUser.get('label') : '',
                        groupName: this.currentSheet.group.workgroupname,
                        sheetName: this.currentSheet.name,
                        sheetId: this.currentSheet.id,
                        sectionId: sectionId,
                        projectId: this.currentSheet.project
                    }
                });
        }

    }

    backToSectionList(){
        const url = this.getSheetLink();
        this.router.navigate([url]);

    }

    public saveResearchTemplate(template: ResearchTemplate) {
        const templateEntity = this.templatesCollection[template.id];

        if (templateEntity) {
            templateEntity.set('name', template.name);
            template.sections.forEach((section: ResearchSectionTemplate) => this.saveResearchSectionTemplate(section));

            templateEntity.save();
        }
    }

    public saveResearchSectionTemplate(section: ResearchSectionTemplate) {
        const sectionEntity = this.templatesSectionsCollection[section.id];

        if (sectionEntity) {
            sectionEntity.set('name', section.name);
            sectionEntity.set('consigne', section.consigne);
            sectionEntity.set('followingSections', section.followingSections);
            // TODO - Save readingGrids
            sectionEntity.set('draftQuestions', section.draftQuestions);

            sectionEntity.save();
        }
    }

    public deleteResearchSheet(sheet: ResearchSheet) {
        const sheetEntity = this.sheetsCollection[sheet.id];

        if (sheetEntity) {
            sheetEntity.remove();
        }
    }

    public deleteResearchSection(section: ResearchSection) {
        const sectionEntity = this.sectionsCollection[section.id];

        if (sectionEntity) {
            sectionEntity.remove();
        }
    }

    public deleteResearchTemplate(template: ResearchTemplate): void {
        const templateEntity = this.templatesCollection[template.id];

        if (templateEntity) {
            templateEntity.remove();
        }
    }

    duplicateTemplace(template: ResearchTemplate): Observable<DataEntity> {

        const templateEntity: DataEntity = this.templatesCollection[template.id];

        const sections: Observable<DataEntity>[] = [];

        templateEntity.get('sections').forEach((section: Object) => {
            sections.push(this.duplicateSection(section));
        });

        const combinedSections: Observable<DataEntity[]> = combineLatest(...sections);

        return combinedSections.pipe(take(1),mergeMap(entities => {

            return this.octopusConnect.createEntity('section-definitions-set', {
                name: templateEntity.get('name'),
                sections: entities.map(entity => entity.id)
            });
        }),);
    }

    duplicateSection(section: Object): Observable<DataEntity> {

        const gridObservable: Observable<DataEntity> = this.duplicateGrid(section['readingGrids']);

        return gridObservable.pipe(take(1),mergeMap(gridEntity => {

            return this.octopusConnect.createEntity('section-definitions', {
                name: section['name'],
                consigne: section['consigne'],
                logicalLinkSet: section['logicalLinkSet'] ? section['logicalLinkSet']['id'] : null,
                draftQuestions: section['draftQuestions'].map(question => question['id']),
                readingGrids: gridEntity.id
            });
        }),);
    }

    /*duplicateQuestion(question: Object): Observable<DataEntity> {
        return this.octopusConnect.createEntity('draft-questions', {
            name: question['name'],
            feedbackYes: question['feedbackYes'],
            feedbackNo: question['feedbackNo']
        });
    }*/

    duplicateGrid(grid: Object): Observable<DataEntity> {
        return this.octopusConnect.createEntity('reading-grids', {
            name: grid['name'],
            text: grid['text'],
        });
    }

    public duplicateDraft(draft: DataEntity, draftList: DataEntity[]): Observable<DataEntity> {

        const originalName: string = draft.get('name').replace(/ \([0-9]*\)$/, '');
        const countRexExp: RegExp = new RegExp(originalName + ' (?:\\(([0-9]*)\\))+$');

        const maxArr: number[] = [];

        draftList.forEach((drft: DataEntity) => {
            const res: RegExpExecArray = countRexExp.exec(drft.get('name'));

            if (res && res[1]) {
                maxArr.push(+res[1]);
            }
        });

        const count: number = maxArr.length > 0 ? (Math.max(...maxArr) + 1) : 2;

        const newName: string = originalName + ' (' + count + ')';

        const obs: Observable<DataEntity> = this.octopusConnect.createEntity('draft-sections', {
            medias: draft.get('medias'),
            name: newName,
            sectionDefinitions: draft.get('sectionDefinitions'),
            text: draft.get('text'),
            parent: String(draft.get('parent'))
        });

        obs.pipe(take(1)).subscribe(entity => this.sendCreationNotification(entity));

        return obs;
    }

    getSectionPrefix(name: string): string {
        let prefix = '';
        prefix += name.charAt(0).toUpperCase();

        if (name.length > 1) {
            prefix += name.charAt(1).toLowerCase();
        }

        return prefix;
    }

    isMine(ownerId: number): boolean {
        return ownerId === +this.currentUser.id;
    }

    public isLastSection(id): boolean {
       const listIds = this.sectionsList.filter((sect) => +sect.researchSheet === +this.currentSheet.id).map((section) => +section.id);
       return  Math.max(...listIds) === +id;
    }

    public isFirstSection(id): boolean {
       const listIds = this.sectionsList.filter((sect) => +sect.researchSheet === +this.currentSheet.id).map((section) => +section.id);
       return  Math.min(...listIds) === +id;
    }

    public valueInferior(id): any[] {
        const listIds = this.sectionsList.filter((sect) => +sect.researchSheet === +this.currentSheet.id).map((section) => +section.id);
        return listIds.filter((item) => item < +id);
    }

    public linkValidForChangeSize(id, parent): boolean {
            return this.valueInferior(id).find((item) => +item === +parent);
    }
}
