import {of as observableOf, Subscription, Observable, Subject} from 'rxjs';

import {takeUntil, filter, mergeMap, take, tap} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {fuseAnimations} from 'fuse-core/animations';
import {ActivatedRoute, Router} from '@angular/router';

import {AssignationService} from '@modules/assignation/core/services/assignation.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {CollectionOptionsInterface} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {FlagService} from 'app/shared/flag.service';
import {displayHeader} from 'app/settings';
import * as _ from 'lodash';
import {AssignmentWarningModalComponent} from '@modules/assignation/core/components/assignment-warning-modal/assignment-warning-modal.component';
import {WinsSummaryComponent} from 'fuse-core/components/wins-summary/wins-summary/wins-summary.component';
import {AssignmentAdaptativePopinComponent} from '@modules/assignation/core/components/assignment-adaptative-popin/assignment-adaptative-popin.component';

@Component({
    selector: 'app-followed-list',
    templateUrl: './followed-list.component.html',
    styleUrls: ['./followed-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class FollowedListComponent implements OnInit, OnDestroy {

    corpusId: string;
    resourcesSubscription: Subscription;
    resources;
    selectAll = false;
    isChecked = false;
    files: any;
    dataSource = new MatTableDataSource();
    selected;
    items: any[];
    types: string[];

    // https://github.com/angular/material2/issues/10205
    private unsubscribeInTakeUntil = new Subject();
    private dialogTitle: string;
    private tooltipDeassign: string;
    private dialogBody: string;
    private dialogYes: string;
    private dialogNo: string;
    private optionsInterface: CollectionOptionsInterface;

    public displayedFilters: string[] = [];
    public displayedColumns: string[] = [];
    public settings: { [key: string]: any };
    public allTypes = [];
    public allStates = [];
    public displayHeader = false;
    public currentUser: DataEntity;

    countEntities = 50;
    pageIndex = 0;
    pageRange = 10;
    pageRangeOptions = [10];

    selectedResources;
    checkboxes: {};

    learnersList = [];

    public educationalLevels: DataEntity[] = [];
    public initAssignments: DataEntity[] = [];

    constructor(
        public assignationService: AssignationService,
        private route: ActivatedRoute,
        private router: Router,
        public authService: AuthenticationService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private flagService: FlagService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.currentUser = data;
                }
            });

        this.displayHeader = displayHeader;

        this.translate.get('assignment.unassign').subscribe((translation: string) => this.dialogTitle = translation);
        this.translate.get('assignment.unassign.tooltip').subscribe((translation: string) => this.tooltipDeassign = translation);
        this.translate.get('assignment.confirm_unassign').subscribe((translation: string) => this.dialogBody = translation);
        this.translate.get('generic.yes').subscribe((translation: string) => this.dialogYes = translation);
        this.translate.get('generic.cancel').subscribe((translation: string) => this.dialogNo = translation);

        this.assignationService.onFilesChanged.subscribe(files => {
            this.files = files;

            this.checkboxes = {};
        });

        this.assignationService.onSelectedResourcesChanged.subscribe(resources => {
            for (const id in this.checkboxes) {
                if (!this.checkboxes.hasOwnProperty(id)) {
                    continue;
                }

                this.checkboxes[id] = resources.includes(id);
            }
            this.selectedResources = this.resources;
        });

        this.assignationService.onFileSelected.subscribe(selected => {
            this.selected = selected;
        });

        this.assignationService.loadAssignationsTypes().subscribe(types => {
            this.allTypes = types;
        });
    }

    ngOnInit(): void {
        this.settings = this.assignationService.settings;
        this.allStates = this.assignationService.states;
        if (this.settings.filters[this.authService.accessLevel]) {
            this.displayedFilters = this.settings.filters[this.authService.accessLevel];
        } else {
            this.displayedFilters = this.settings.filters['default'];
        }

        if (this.settings.columns[this.authService.accessLevel]) {
            this.displayedColumns = this.settings.columns[this.authService.accessLevel];
        } else {
            this.displayedColumns = this.settings.columns['default'];
        }

        this.communicationCenter.getRoom('licenses')
            .getSubject('methods').pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((entities: Array<DataEntity>) => {
                this.assignationService.learnerMethods = entities.map((entity) => {
                    return _.get(entity, 'attributes.access.id');
                });
            });

        this.assignationService.getEducationalLevels().subscribe((educationalLevels) => {
            this.educationalLevels = educationalLevels;
        });

        if (this.displayFilters('schoolyear')) {
            this.setSchoolYears();
        }

        this.learnersList = this.assignationService.learnersList;

        if (this.shouldDisplayFilters() === false) {
            return this.fixNoDataRequest();
        }
    }

    public get stateWithIcon(): boolean {
        return this.settings.stateWithIcon;
    }

    public getTooltipDeassignLabel(assignation): string {
        return this.cantBeDeassign(assignation) ? this.tooltipDeassign : null;
    }

    launchSearch(optionInterface?: CollectionOptionsInterface): void {
        if (this.resourcesSubscription) {
            this.resourcesSubscription.unsubscribe();
        }
        if (optionInterface) {
            this.optionsInterface = optionInterface;
        }

        this.resourcesSubscription = this.refreshList();
    }

    refreshList(): Subscription {
        return this.resourcesSubscription = this.assignationService.loadPaginatedAssignments(this.optionsInterface).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(resources => {

                this.resources = resources;
                this.assignationService.assignations = this.resources;
                if (!this.resources) {
                    return;
                }
                this.dataSource.data = this.resources;
                this.setPaginator();
            });
    }

    setPaginator(): void {
        if (this.assignationService.assignmentsPaginated.paginator) {
            this.countEntities = this.assignationService.assignmentsPaginated.paginator.count;
            this.pageIndex = this.assignationService.assignmentsPaginated.paginator.page - 1;
            this.pageRange = this.assignationService.assignmentsPaginated.paginator.range;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    onPaginateChange(event): void {
        this.assignationService.assignmentsPaginated.paginator.page = event.pageIndex + 1;
    }

    isSelected(row): boolean {
        return this.selected && (this.selected.id === row.id);
    }

    updateCheck(): void {
        this.isChecked = !this.isChecked;
    }

    /*
     * if we have hideFeedbacks to false  that mean that before opening assignment we have to open a modal
     * to explain learner he will do a special lesson to evaluated him. it's only for adaptative lesson when IA doesn't
     * know learner before this flag is also use to hide feedback of IA
     * @param assignment
     */
    openAssignment(assignment: DataEntity): void {
        if (this.assignationService.settings.disableOpening) {
            return null;
        }
        const hideFeedbacks = assignment.get('hideFeedbacks');
        // hideFeedbacks = false = hide feedback because we are in adaptive lesson with diagnostic and so we doesn't open modal
        // to say learner will do a special lesson
        // tslint:disable-next-line:max-line-length
        if (!this.assignationService.settings.useHideFeedbacksToLaunchAdaptativeModal || hideFeedbacks === null || hideFeedbacks === undefined || hideFeedbacks === 0 || hideFeedbacks === false) {
            this.launchAssignement(assignment);
        } else {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
                svg: 'adaptive-begin',
                body: 'generic.adaptive.begin',
                button: 'generic.start'
            };
            dialogConfig.panelClass = 'adaptative-popin';
            dialogConfig.disableClose = true;

            const dialogRef = this.dialog.open(AssignmentAdaptativePopinComponent, dialogConfig);

            dialogRef.afterClosed().subscribe(() => {
                this.launchAssignement(assignment);
            });
        }
    }

    /**
     * launch the selected assignmznt
     * @param assignment Dataentity
     * @private
     */
    private launchAssignement(assignment: DataEntity): void {
        if (this.haveToOverrideAssignmentLaunchToMetadatas()) {
            if (assignment.get('type_term') && assignment.get('type_term').label === 'init') {
                this.communicationCenter.getRoom('lessons').next('openLessonMetadataDialog', {lessonId: assignment.get('assignated_nodes').id});
            } else {
                this.communicationCenter.getRoom('lessons').next('openLessonMetadataDialog', {lessonId: assignment.get('assignated_node').id});
            }
        } else if (!this.assignmentDisabled(assignment)) {
            // mono assignement
            const assignated_node = assignment.get('assignated_node');
            // multi assignement
            const assignated_nodes = assignment.get('assignated_nodes');
            let type = '';
            let idLesson = '';
            if (assignated_node === null && assignated_nodes.length > 0) {
                // in case of multi assignation we always take the info of the first assignation to launch
                // in a future us we will take the assignation where user stop before if he begin and stop before end
                type = assignated_nodes[0].type;
                idLesson = assignated_nodes[0].id;
            } else if (assignated_node !== null) {
                type = assignated_node.type;
                idLesson = assignated_node.id;
            } else {
                console.error('fol-334 : error because assignated_node is null and assignated_nodes.length <=0');
                return;
            }

            const route = ['..', 'assignment'];
            switch (type) {
                case 'form':
                    route.push('forms');
                    break;
                case 'lesson':
                    route.push('lessons');
                    break;
            }

            if (route.length) {
                route.push(idLesson);
            }

            route.push('player');

            const valid = this.dateIsValid(assignment.get('state'), assignment.get('type_term'), assignment.get('dates'));
            if (valid && valid.available) {
                this.assignationService.launchAssignment(assignment, true).pipe(
                    filter(isAllowed => !!isAllowed),
                    tap(() => {
                        let flaggingId;
                        // add value to flaggingId to patch only if current user = flag user
                        if (assignment.get('consulted').consulted_bool && assignment.get('consulted').uid === this.currentUser.id) {
                            flaggingId = assignment.get('consulted').flagging_id;
                        }
                        // create or edit flag only if current user is assignated user
                        if (assignment.get('assignated_user').uid === this.currentUser.id) {
                            this.flagService.updateFlagEntity(assignment, 'assignations', 'consulted_assignation', flaggingId);
                        }

                        this.router.navigate(route, {relativeTo: this.route});
                    }),
                    take(1)
                ).pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe();
            }
        }
    }

    /**
     * set the title of assignment :
     * simple assignement : use title in assignment if only one lesson to launch,
     * multi lesson  : one assignment with multiple lesson : use main title or first title of array assignment_nodes
     * @param assignment current assignement for the row
     */
    public getTitleAssignment(assignment: DataEntity): string {
        let title = '';
        if (assignment.get('assignated_node')) {
            title = assignment.get('assignated_node').title;
        } else {
            if (assignment.get('title')) {
                title = assignment.get('title');
            } else {
                title = assignment.get('assignated_nodes')[0].title;
            }
        }
        return title;
    }

    public getConsultedAssignment(assignment: DataEntity): string {
        return assignment.attributes.consulted.consulted_bool;
    }

    unassign(assignment: DataEntity): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: this.dialogTitle,
            bodyDialog: this.dialogBody,
            labelTrueDialog: this.dialogYes,
            labelFalseDialog: this.dialogNo
        };

        this.dialog.open(FuseConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                filter(confirm => confirm),
                mergeMap(() => this.removeAssignment(assignment))
            ).subscribe();
    }

    public displayFilters(name: string): boolean {
        return this.displayedFilters.includes(name);
    }

    /* public localizedType(type: string): string {
         return `assignment.type.${type}`;
     }*/

    public localizedState(state: string): string {
        return `assignment.state.${state}`;
    }

    get groups(): Array<any> {
        if (this.assignationService.groupsList) {
            return this.assignationService.groupsList;
        }

        return [];
    }

    get workgroups(): Array<any> {
        if (this.assignationService.workgroupsList) {
            return this.assignationService.workgroupsList;
        }

        return [];
    }

    get learners(): Array<any> {
        if (this.assignationService.learnersList) {
            return this.assignationService.learnersList;
        }

        return [];
    }

    isAtLeastTrainer(): boolean {
        return this.authService.isAtLeastTrainer();
    }

    private dateIsValid(state, type, date): any {
        const startDate = date ? date.value : null;
        const endDate = date ? date.value2 : null;
        const dateNow = Math.floor(Date.now() / 1000);

        if (type && this.assignationService.hasCompletionDate(type.label)) {
            if ((startDate && startDate < dateNow) && (endDate && endDate > dateNow)) {
                if (state === 'closed') {
                    return {
                        state: 'valid',
                        available: true,
                    };
                }
            } else if ((startDate && startDate > dateNow) && (endDate && endDate > dateNow)) {
                return {
                    state: 'assigned',
                    available: false,
                };
            } else if (endDate && endDate < dateNow) {
                return {
                    state: 'correct',
                    available: true,
                };
            }
        }

        if (startDate && startDate > dateNow && type.label === 'training') {
            return {
                state: 'assigned',
                available: false,
            };
        }

        return {
            state: 'available',
            available: true,
        };
    }


    public get stateFromDate(): boolean {
        return this.assignationService.settings.getStateFromDate;
    }

    public checkState(assignment): string {
        const state = (assignment.get('state_term') && assignment.get('state_term').label) || assignment.get('state');

        if (!this.stateFromDate) {
            return state;
        } else {
            if (assignment) {
                const valid = this.dateIsValid(state, assignment.get('type_term'), assignment.get('dates'));
                if (valid) {
                    return valid.state;
                }

            }

            return 'assigned';
        }
    }

    public getIconState(assignment): string {
        const state = this.checkState(assignment);
        switch (state) {
            case 'assigned':
                return 'tap_check';
            case 'pending':
                return 'hourglass';
            case 'closed':
                return 'chat_close';
        }
    }

    public checkAccess(user: string[]): boolean {
        if (user) {
            return this.authService.hasLevel(user);
        }
        return false;
    }

    getGrade(assignation): string {
        if (assignation) {
            if (this.checkState(assignation) === 'correct') {
                if (+assignation.get('progress') === 0) {
                    return '-';
                } else {
                    const newGrade = Math.min(20, Math.round(assignation.get('grade') * 10) / 10);

                    return newGrade.toString() + '/20';
                }
            }
        }
        return '';
    }

    public getProgress(assignation): string {
        if (assignation) {
            return (Math.round(+assignation.get('progress')) || '0') + '%';
        }

        return '';
    }

    public cantBeDeassign(assignation): boolean {
        return +assignation.get('progress') > 0;
    }

    public getEducationalLevels(educationalLevels: any): string {
        return educationalLevels ? educationalLevels.map(e => e.label).join(', ') : '';
    }

    public displayButton(buttonIdentifier: string): boolean {
        return this.assignationService.getAllowedActions().includes(buttonIdentifier);
    }

    /**
     * edit lesson of the current asignment
     * @param row
     */
    public edit(row: DataEntity): void {
        this.communicationCenter
            .getRoom('lessons')
            .next('openEditor', {id: row.attributes.assignated_node.id});
    }

    /**
     * edit comment of current assignment
     * @param row
     */
    public editAssignment(assignment: DataEntity): void {
        let titleDialog = '';
        let bodyDialog = '';
        let labelConfirmDialog = '';
        let labelOtherDialog = '';
        let labelConfirmDialogPlaceHolder = '';
        let inputErrorLengthMessage = '';

        this.translate.get('assignment.edit_comment.title').subscribe((translation: string) => titleDialog = translation);
        this.translate.get('assignment.edit_comment.body').subscribe((translation: string) => bodyDialog = translation);
        this.translate.get('assignment.edit_comment.confirm').subscribe((translation: string) => labelConfirmDialog = translation);
        this.translate.get('assignment.edit_comment.other').subscribe((translation: string) => labelOtherDialog = translation);
        this.translate.get('assignment.edit_comment.placeholder').subscribe((translation: string) => labelConfirmDialogPlaceHolder = translation);
        this.translate.get('assignment.edit_comment.error').subscribe((translation: string) => inputErrorLengthMessage = translation);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: titleDialog,
            bodyDialog: bodyDialog,
            labelConfirmDialog: labelConfirmDialog,
            labelOtherDialog: labelOtherDialog,
            inputValue: assignment.get('comment'),
            labelConfirmDialogPlaceHolder: labelConfirmDialogPlaceHolder,
            autofocus: true,
            maxInputSize: 280,
            inputErrorLengthMessage: inputErrorLengthMessage
        };

        dialogConfig.panelClass = 'edit_assignment_comment_modal';

        this.dialog.open(FuseConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe(result => {
                // if comment was changed we save it
                if (assignment.get('comment') !== result && result !== 'otherButtonDialogAction') {
                    assignment.set('comment', result);
                    this.assignationService.saveAssignment(assignment);
                }
            });
    }

    /**
     * Get list of difficulties label as an only one string
     * @param assignment
     */
    public getDifficulties(assignment: DataEntity): string {
        return _.get(assignment, 'attributes.assignated_node.difficulty', []).map(difficulty => difficulty.label).join();
    }

    /**
     * Get list of chapters label as an only one string
     * @param assignment
     */
    public getChapters(assignment: DataEntity): string {
        return _.get(assignment, 'attributes.assignated_node.chapters', []).map(chapter => chapter.label).join();
    }

    public assignmentDisabled(assignment): boolean {
        return !this.initAssignments.find((assignmentEntity: DataEntity) => +assignmentEntity.id === +assignment.id)
            && this.authService.isLearner()
            && this.settings.initAssignment
            && (this.initAssignments.length && !this.assignmentInitIsFinished());
    }

    private assignmentInitIsFinished(): boolean {
        return !!this.initAssignments.find((assignmentEntity: DataEntity) => assignmentEntity
            && (this.checkState(assignmentEntity) === 'closed' || this.getProgress(assignmentEntity) === '100%'));
    }

    /**
     * set the shoolYearsList begin in 2017 and set current year to default filter
     */
    private setSchoolYears(): void {
        this.assignationService.loadSchoolyears().subscribe(entities => {
            this.assignationService.schoolYearsList = entities;
            // default sort to current year
            // not use anymore this.optionsInterface.filter['schoolYearDates'] = this.assignationService.currentSchoolYearBegin;
        });
    }

    public delete(assignment: DataEntity): void {
        const obs = this.cantBeDeassign(assignment) === true ?
            this.dialog.open(AssignmentWarningModalComponent).afterClosed()
            : observableOf(true);

        obs.pipe(
            filter(isConfirm => isConfirm),
            mergeMap(() => this.removeAssignment(assignment))
        ).subscribe();
    }

    private removeAssignment(assignment: DataEntity): Observable<boolean> {
        return this.assignationService.deleteAssignment(assignment).pipe(
            filter(success => success),
            tap(() => this.assignationService.assignmentsPaginated.paginator.reload())
        );
    }

    private haveToOverrideAssignmentLaunchToMetadatas(): boolean {
        const rolesToOverride: string[] = this.settings.rolesForOverrideAssignmentLaunchToMetadatas;

        return rolesToOverride.length > 0
            && (
                rolesToOverride.includes(this.authService.accessLevel)
                || rolesToOverride.includes('default')
            );

    }

    /**
     * open the modal of gratifications when clic on cup
     * @param $event event to stop propagation
     * @param row current dataentity selected
     */
    public openGratification($event: any, row: DataEntity): void {
        $event.stopPropagation();
        // fake data to replace with real one when will exist
        if (row.get('gratification')) {
            const dialogRef = this.dialog.open(WinsSummaryComponent, {
                panelClass: 'feedback-earning-dialog',
                data: row.get('gratification')
            });
        }
    }

    public shouldDisplayFilters(): boolean {
        return this.displayedFilters.length > 0;
    }

    /**
     * Send a default request.
     * This method will must not exist we wait a search-filters refacto.
     *
     * Why we need it ?
     * If there's no filters, the filter component is not displayed
     * But it's the one who launch the request (this should be a parent responsability)
     * @private
     */
    private fixNoDataRequest(): void {
        this.launchSearch({
            filter: this.authService.accessLevel === 'learner' ?
                {'assignated_user': this.authService.userData.id} :
                {'assignator': this.authService.userData.id},
            page: 1,
            range: 10
        });
    }
}

