
import {take} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Draft} from '@modules/research-sheet/core/section-draft-display/draft.model';
import {ResearchSheetService} from '../../research-sheet.service';
import {ResearchSection, ResearchSectionTemplate, ResearchTemplate} from '@modules/research-sheet/core/definitions';
import {Router} from '@angular/router';
import {DataEntity} from 'octopus-connect';

@Component({
    selector: 'app-section-draft-creation-modal',
    templateUrl: './section-draft-creation-modal.component.html',
    styleUrls: ['./section-draft-creation-modal.component.scss']
})
export class SectionDraftCreationModalComponent implements OnInit {

    projectForm: FormGroup;
    draft: Draft;
    templatesList: ResearchTemplate[];
    definitions: ResearchSectionTemplate[];
    selectedDefinition: ResearchSectionTemplate;

    constructor(
        public dialogRef: MatDialogRef<SectionDraftCreationModalComponent>,
        private formBuilder: FormBuilder,
        private sheetService: ResearchSheetService,
        private router: Router
    ) { }

    ngOnInit(): any {
        this.draft = new Draft();

        this.templatesList = this.sheetService.templatesList;
        console.log(this.templatesList);

        this.projectForm = this.formBuilder.group({
            name: [this.draft.name],
            template: [this.draft.template]
        });
    }

    createDraft(): void {
        this.sheetService.currentDraft = this.draft;
        this.dialogRef.close();

        this.sheetService.createResearchDraft(this.sheetService.currentDraft).pipe(take(1)).subscribe(entity => {
            this.router.navigate([this.sheetService.getDraftEditLink(+entity.id)]);
        });
    }

    setTemplate(change: MatSelectChange): void {
        this.selectedDefinition = null;
        this.draft.template = change.value;

        const template: DataEntity = this.sheetService.templatesCollection[change.value];

        const selectedTemplate: ResearchTemplate = this.sheetService.templatesList.find(elem => elem.id === change.value);
        this.definitions = selectedTemplate.sectionsDefinitions;

    }

    getSectionPrefix(definition: ResearchSectionTemplate): string {
        return this.sheetService.getSectionPrefix(definition.name);
    }

    selectType(definition: ResearchSectionTemplate): void {
        this.draft.section = definition.id;
        this.selectedDefinition = definition;
    }
}
