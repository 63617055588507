import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {DataSource} from '@angular/cdk/collections';
import {WorkgroupService} from '@modules/groups-management/core/workgroup/workgroup.service';
import {AuthenticationService} from '@modules/authentication';
import {Workgroup} from '@modules/groups-management/core/definitions';
import {GroupsManagementService} from '@modules/groups-management/core/services/groups-management.service';
import {DataEntity} from 'octopus-connect';
import * as _ from 'lodash';
import {FuseGroupsFormDialogData} from '@modules/groups-management/core/groups-listing/groups-form/groups-form.component';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '@modules/groups-management/core/models/rules';
import {filterType, GroupManagementConfigurationService, roleList} from '@modules/groups-management/core/services/group-management-configuration.service';

@Component({
    selector: 'app-workgroup',
    templateUrl: './workgroup.component.html',
    styleUrls: ['./workgroup.component.scss']
})
export class WorkgroupComponent implements OnInit, OnDestroy {
    public displayedFilters: string[] = []; // filter to show
    public displayedColumns = ['workgroupname'];
    public dataSource: WorkgroupDataSource | null;
    public newWorkgroup: FuseGroupsFormDialogData = {
        data: {
            action: 'new',
            title: 'add_workgroup',
            fields: ['workgroupname'],
            selects: {
                workgroups: () => this.workgroupService.getWorkgroupService(),
                schoolYears: () => this.groupsManagementService.getSchoolYearEntities(),
            }
        },
        callback: (response) => this.newWorkgroupCallback(response),
        isAuthorized: () => this.authorizationService.currentUserCan(SyncRules.CreateWorkgroup)
    };
    public archiveWorkgroup: FuseGroupsFormDialogData = {
        data: {
            titleDialog: 'generic.archive',
            bodyDialog: 'groups-management.sure_archive_group',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (workgroup) => this.archiveWorkgroupCallback(workgroup),
        callbackWithMultiple: (list) => this.archiveListCallback(list),
        isAuthorized: (workgroup) => this.authorizationService.currentUserCan(SyncRules.ArchiveWorkgroup, workgroup)
    };
    public deArchiveWorkgroup: FuseGroupsFormDialogData = {
        data: {
            titleDialog: 'generic.archive',
            bodyDialog: 'groups-management.sure_dearchive_group',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (workgroup) => this.deArchiveWorkgroupCallback(workgroup),
        callbackWithMultiple: (list) => this.dearchiveListCallback(list),
        isAuthorized: (workgroup) => this.authorizationService.currentUserCan(SyncRules.ArchiveWorkgroup, workgroup)
    };
    public deleteWorkgroup: FuseGroupsFormDialogData = {
        data: {
            titleDialog: 'groups-management.title_remove',
            bodyDialog: 'groups-management.sure_remove_group',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (workgroup) => this.deleteWorkgroupCallback(workgroup),
        callbackWithMultiple: (list) => this.deleteListCallback(list),
        isAuthorized: (workgroup) => this.authorizationService.currentUserCan(SyncRules.DeleteWorkgroup, workgroup)

    };
    public editWorkgroup: FuseGroupsFormDialogData = {
        data: {
            action: 'edit',
            title: 'edit_workgroup',
            fields: ['id', 'workgroupname'],
            selects: {
                workgroups: () => this.workgroupService.getWorkgroupService(),
                schoolYears: () => this.groupsManagementService.getSchoolYearEntities(),
            }
        },
        callback: (response) => this.editWorkgroupCallback(response),
        isAuthorized: (workgroup) => this.authorizationService.currentUserCan(SyncRules.EditWorkgroup, workgroup)
    };

    constructor(
        private workgroupService: WorkgroupService,
        private authService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private groupsManagementService: GroupsManagementService,
        private groupManagementConfigurationService: GroupManagementConfigurationService
    ) {
        try {
            this.displayedFilters = this.groupManagementConfigurationService.displayFilterByOriginAndRole(filterType.workGroups, roleList[this.authService.accessLevel]);
        } catch (ex) {
            console.error('error getting settings worgroup component : 96');
        }

        if (this.authService.isAtLeastTrainer()) {
            try {
                this.displayedColumns = this.groupManagementConfigurationService.workGroupColumns(roleList[this.authService.accessLevel]);
            } catch (ex) {
                console.error('error getting settings workgroup component : 103');
            }
            const displayedColumnsByRole = this.groupsManagementService.settings.group.columns[this.authService.accessLevel] ?
                this.groupsManagementService.settings.group.columns[this.authService.accessLevel] : this.groupsManagementService.settings.group.columns['default'];
            // if we add school year on setting for my role we add it
            if (displayedColumnsByRole.includes('schoolyear')) {
                this.newWorkgroup.data.fields.push('schoolyear_term');
                this.editWorkgroup.data.fields.push('schoolyear_term');
                // add schoolyear column before buttons columns
                this.displayedColumns.splice(this.displayedColumns.length - 1, 0, 'schoolyear');
            }
        }
    }

    ngOnInit(): void {
        this.dataSource = new WorkgroupDataSource(this.workgroupService);
        this.groupsManagementService.setHeaderTitle('groups-management.workgroups');
        this.groupsManagementService.gettingStarted = _.get(this.groupsManagementService.settings, 'gettingStarted.workgroups');
    }

    ngOnDestroy(): void {
        this.groupsManagementService.gettingStarted = '';
    }

    public newWorkgroupCallback(response: FormGroup): Observable<DataEntity> {
        if (response) {
            return this.workgroupService.addWorkgroup(response.getRawValue());
        }
    }

    public archiveWorkgroupCallback(workgroup: any): Observable<DataEntity> {
        workgroup.archived = true;
        return this.workgroupService.saveWorkgroup(workgroup);
    }

    public deArchiveWorkgroupCallback(workgroup: any): Observable<DataEntity> {
        workgroup.archived = false;
        return this.workgroupService.saveWorkgroup(workgroup);
    }


    public editWorkgroupCallback(response: FormGroup): Observable<DataEntity> {
        if (response) {
            return this.workgroupService.saveWorkgroup(response.getRawValue());
        }
    }

    public deleteWorkgroupCallback(workgroup: any): Observable<boolean> {
        return this.workgroupService.deleteWorkgroup(workgroup);
    }


    public archiveListCallback(workgroups: any): void {
        for (const workgroup of workgroups) {
            workgroup.archived = true;
            this.workgroupService.saveWorkgroup(workgroup);
        }
    }

    public dearchiveListCallback(workgroups: any): void {
        for (const workgroup of workgroups) {
            workgroup.archived = false;
            this.workgroupService.saveWorkgroup(workgroup);
        }
    }

    public deleteListCallback(workgroups: any): void {
        for (const workgroup of workgroups) {
            this.workgroupService.deleteWorkgroup(workgroup);
        }
    }
}

export class WorkgroupDataSource extends DataSource<any> {
    data: BehaviorSubject<Workgroup[]>;

    constructor(public workGroupService: WorkgroupService) {
        super();
        this.data = new BehaviorSubject<Workgroup[]>(this.workGroupService.groups);

        this.workGroupService.onWorkgroupsChanged.subscribe((data: Workgroup[]) => {
            this.data.next(data);
        });
    }

    get groupService(): WorkgroupService {
        return this.workGroupService;
    }

    connect(): Observable<any[]> {
        return this.groupService.onWorkgroupsChanged;
    }

    disconnect(): void {
    }
}
