import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ModelSchema, Structures } from "octopus-model";
import {
  brand,
  brandLogoSvg,
  defaultLoginRoute,
  defaultURL,
  modulesSettings,
} from "../../../../settings";

import { AuthenticationService } from "../authentication.service";
import { DataEntity } from "octopus-connect";
import { FuseConfigService } from "fuse-core/services/config.service";
import { FuseConfirmDialogComponent } from "fuse-core/components/confirm-dialog/confirm-dialog.component";
import { ModalPageComponent } from "fuse-core/components/basic-page/modal-page/modal-page.component";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { UserDataEntity } from "@modules/authentication/core/models/user-data-entity.type";
import { currentTimestamp } from "../../../../shared/utils";
import { fuseAnimations } from "fuse-core/animations";
import { takeUntil } from "rxjs/operators";

const settingsAuthStructure: ModelSchema = new ModelSchema({
  askForHelp: Structures.boolean(false),
  enableGAR: Structures.boolean(false),
  enableSSO: Structures.boolean(false),
  urlSSO: Structures.object(),
  displayLoginLogo: Structures.boolean(false),
  activeChangePasswordStrategy: Structures.boolean(false),
  validateEmailStrategyActivated: Structures.boolean(false),
});

@Component({
  selector: "fuse-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class FuseLoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loginFormErrors: any;
  hide = true;
  hideNewPassword = true;
  hideConfirmPassword = true;
  public brand = brand;
  public brandLogoSvg = brandLogoSvg;
  public renewPassword = false;
  message: any;
  private unsubscribeInTakeUntil = new Subject();
  public modeSSO = false;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  settings: { [key: string]: any };
  settingsAuth: { [key: string]: boolean };
  public emailNotValidatedError = false;
  public newLinkSendSuccess = false;
  public newLinkSendError = false;
  public isRegisterLinkTop = false;

  constructor(
    private fuseConfig: FuseConfigService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.loginFormErrors = {
      login: {},
      password: {},
      newPassword: {},
      confirmPassword: {},
      emailValidation: {},
    };

    this.settings = this.authenticationService.settings;
    this.settingsAuth = settingsAuthStructure.filterModel(
      modulesSettings.authentication
    );
    this.isRegisterLinkTop = this.settings.isRegisterLinkTop;
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams.code) {
      this.modeSSO = true;
      this.authenticationService.loginSSO(this.route.snapshot.queryParams.code);
    }

    const loginValidators = this.authenticationService.onlyLoginWithMail()
      ? [Validators.required, Validators.email]
      : [Validators.required];

    this.loginForm = this.formBuilder.group(
      {
        login: ["", loginValidators],
        password: ["", Validators.required],
        newPassword: ["", Validators.required],
        confirmPassword: ["", Validators.required],
      },
      {
        validator: this.mustMatchAndBeNewPassword(
          "password",
          "newPassword",
          "confirmPassword"
        ),
      }
    );

    this.loginForm.valueChanges.subscribe(() => {
      this.onLoginFormValuesChanged();
    });

    this.authenticationService.errorHttpAuthentication
      .pipe(takeUntil(this.unsubscribeInTakeUntil))
      .subscribe((errorHttp) => {
        if (errorHttp.code === 401) {
          const controlLogin = this.loginForm.get("login");
          const controlpassword = this.loginForm.get("password");
          controlLogin.setErrors({ invalid: true });
          controlpassword.setErrors({ invalid: true });
          this.loginFormErrors["login"] = controlLogin.errors;
          this.loginFormErrors["password"] = controlpassword.errors;
        } else if (errorHttp.code === 403) {
          // email was not validated by email link
          const controlLogin = this.loginForm.get("login");
          controlLogin.setErrors({ invalid: true });
          this.loginFormErrors["emailValidation"] = controlLogin.errors;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeInTakeUntil.next();
    this.unsubscribeInTakeUntil.complete();
  }

  /**
   * custom validator to check that two fields match and are différent of original password
   *
   */
  private mustMatchAndBeNewPassword(
    passwordControlName: string,
    newPasswordcontrolName: string,
    confirmPasswordControlName: string
  ): any {
    return (formGroup: FormGroup) => {
      const controlOriginalPasword = formGroup.controls[passwordControlName];
      const controlNewPassword = formGroup.controls[newPasswordcontrolName];
      const controlConfirmPassword =
        formGroup.controls[confirmPasswordControlName];

      if (
        controlConfirmPassword.errors &&
        !controlConfirmPassword.errors.notMatch
      ) {
        return;
      }

      if (controlOriginalPasword.value === controlNewPassword.value) {
        controlNewPassword.setErrors({ samePasswordAsOrigine: true });
      } else {
        controlNewPassword.setErrors(null);
      }

      if (controlNewPassword.value !== controlConfirmPassword.value) {
        controlConfirmPassword.setErrors({ notMatch: true });
      } else {
        controlConfirmPassword.setErrors(null);
      }
    };
  }

  /**
   * login user : with optionnal change password possibility force by rules
   */
  public login(): void {
    this.resetEmailSendMessages();
    if (this.loginForm.value["login"] && this.loginForm.value["password"]) {
      this.authenticationService
        .authenticateIn(
          "http",
          this.loginForm.value["login"],
          this.loginForm.value["password"]
        )
        .pipe(takeUntil(this.unsubscribeInTakeUntil))
        .subscribe((user: UserDataEntity) => {
          if (
            this.settingsAuth.validateEmailStrategyActivated &&
            !user.get("email_status")
          ) {
            this.authenticationService.logoutFrom("http");
            return;
          }

          // set value is need for pass form on valid state
          this.loginForm.controls.login.setValue(this.loginForm.value["login"]);

          if (this.isNewPasswordToSet()) {
            this.updatePassword();
            return;
          }
          // must be after condition this.isNewPasswordToSet()
          if (this.isExpirePassword(user)) {
            this.renewPassword = true;
            this.authenticationService.logoutFrom("http");
            return;
          }

          const isTrainer = !!(
            user.get("role").findIndex((role) => role === 5) >= 0
          ); // tester autrement qu'avec un numero (voir authService)
          // it's fisrts user trainer connexion and help is active
          if (
            user.get("access") &&
            user.get("first_access") === true &&
            this.settingsAuth.askForHelp &&
            isTrainer
          ) {
            this.help();
          } else {
            this.navigate();
          }
        });
    }
  }

  /**
   * reset messages success and error
   * @private
   */
  private resetEmailSendMessages(): void {
    this.newLinkSendSuccess = false;
    this.newLinkSendError = false;
  }

  /**
   * open modal asking if user need help at first connexion
   */
  private help(): void {
    const data = {
      titleDialog: "generic.ask.help",
      bodyDialog: "generic.ask.help.content",
      labelTrueDialog: "generic.yes",
      labelFalseDialog: "generic.no",
    };

    this.translate
      .get(data.titleDialog)
      .subscribe((translation: string) => (data.titleDialog = translation));
    this.translate
      .get(data.bodyDialog)
      .subscribe((translation: string) => (data.bodyDialog = translation));
    this.translate
      .get(data.labelTrueDialog)
      .subscribe((translation: string) => (data.labelTrueDialog = translation));
    this.translate
      .get(data.labelFalseDialog)
      .subscribe(
        (translation: string) => (data.labelFalseDialog = translation)
      );

    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      data: data,
    });

    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // help page
        this.router.navigate(["home"]);
      } else {
        // normal navigate
        this.navigate();
      }
      this.confirmDialogRef = null;
    });
  }

  /**
   * update password and go to program if no error
   */
  private updatePassword(): void {
    this.authenticationService.loggedUser.set(
      "password",
      this.loginForm.value["newPassword"]
    );
    this.authenticationService.loggedUser.save().subscribe(
      (userUpdate: DataEntity) => {
        this.renewPassword = false;
        this.navigate();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * is user expiration password date is passed
   * @param user: DataEntity
   */
  private isExpirePassword(user: DataEntity): boolean {
    return (
      this.settingsAuth.activeChangePasswordStrategy &&
      user.get("expirePassword") &&
      currentTimestamp() >= user.get("expirePassword") &&
      user.get("expirePassword") !== null &&
      user.get("expirePassword") !== undefined
    );
  }

  /**
   * is the new password need to be set
   */
  private isNewPasswordToSet(): boolean {
    return (
      this.settingsAuth.activeChangePasswordStrategy &&
      this.loginForm.value["newPassword"] &&
      this.loginForm.value["newPassword"] !== null &&
      this.loginForm.value["newPassword"] !== undefined &&
      this.loginForm.value["newPassword"] !== ""
    );
  }

  /**
   * navigate on the good route after being logged
   */
  private navigate(): void {
    let url = defaultLoginRoute;

    const returnParam: string = this.route.snapshot.queryParams["return"];
    const userRole = this.authenticationService.accessLevel;
    // Si la route est définie par l'actuelle url, c'est la route prioritaire
    if (returnParam) {
      url = returnParam;
    } else if (this.authenticationService.isFirstConnexion()) {
      // Sinon si c'est la premiere connexion, on vérifie qu'on a pas une route particulière de bienvenue
      const redirectRules = this.settings.firstConnexionRedirection;
      const forceRedirection = redirectRules.hasOwnProperty(userRole)
        ? redirectRules[userRole]
        : redirectRules.default;
      if (forceRedirection !== undefined) {
        url = forceRedirection;
      }
    }

    if (
      this.settings.overrideDefaultRouteByRole &&
      this.settings.overrideDefaultRouteByRole[userRole]
    ) {
      url = this.settings.overrideDefaultRouteByRole[userRole];
    }
    this.router.navigateByUrl(url);
  }

  loginSSO(): void {
    if (this.settingsAuth.enableSSO) {
      window.location.href =
        this.settingsAuth.urlSSO[this.translate.currentLang] +
        defaultURL +
        "login";
    }
  }

  onLoginFormValuesChanged(): void {
    this.resetEmailSendMessages();

    for (const field in this.loginFormErrors) {
      if (!this.loginFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.loginFormErrors[field] = {};

      // Get the control
      const control = this.loginForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.loginFormErrors[field] = control.errors;
      }
    }
  }

  /**
   * user haven't valdiate is email send a new link to register email
   */
  sendLinkToValidateEmail(): void {
    this.authenticationService
      .sendNewLinkEmailValidation(this.loginForm.value["login"])
      .subscribe(
        (email) => {
          this.newLinkSendSuccess = true;
          const controlLogin = this.loginForm.get("login");
          controlLogin.setErrors({});
          this.loginFormErrors["emailValidation"] = controlLogin.errors;
          setTimeout(() => {
            this.newLinkSendSuccess = false;
          }, 3000);
        },
        (error: Object) => {
          this.newLinkSendError = true;
          console.error(error);
        }
      );
  }

  get selfSignup(): boolean {
    return this.settings.selfSignup;
  }

  get signup(): boolean {
    return this.settings.signup;
  }

  /**
   * open modal to confirm exit
   * if confirm then exit else stay here
   */
  private displayENTHelp(): void {
    this.translate.get("cookie.dismiss").subscribe((translation: string) => {
      this.dialog.open(ModalPageComponent, {
        panelClass: "entity-form-dialog",
        data: { alias: "me-connecter-ent", labelConfirmDialog: translation },
      });
    });
  }
}
