import {Injectable} from '@angular/core';

export enum SyncRules {
}

@Injectable({
    providedIn: 'root'
})
export class LessonAuthorizationService {

    constructor() {
    }

    public activeRulesOnStartup(): void {

    }
}
