export const assignation = {
    assignableLesson: true,
    columns: {
        default: ['assigned_node_title', 'start_date', 'assigned_user', 'progress', 'gratification', 'difficulty', 'buttons'],
        learner: ['assigned_node_title', 'start_date', 'assigned_user', 'progress', 'gratification', 'buttons']
    },
    columnsDashboardWidget: {
        default: ['type', 'assigned_node_title', 'start_date', 'end_date', 'group', 'workgroup', 'progress']
    },
    completionStartDateOnly: ['*'],
    filters: {
        learner: ['title'],
        default: ['title', 'group', 'workgroup', 'learner']
    },
    formFields: {
        default: ['group', 'workgroup', 'learner', 'start_date']
    },
    getStateFromDate: true,
    hasCompletionDate: true,
    hasCompletionTime: false,
    hasType: false,
    initAssignment: true,
    ratingBase: [1, 2, 4],
    rolesForOverrideAssignmentLaunchToMetadatas: ['trainer'],
    stateWithIcon: false,
    useHideFeedbacksToLaunchAdaptativeModal: true, // use flag hideFeedBack to launch an info modal before lesson
    isLessonCanBeAssignedOnlyOnceByUser: true, // in mindmath lesson must be assigned only once for a user
};
