import {Component, Input, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {displayHelp} from '../../../app/settings';
import {ModalPageComponent} from 'fuse-core/components/basic-page/modal-page/modal-page.component';

@Component({
    selector: 'app-getting-started',
    templateUrl: './getting-started.component.html',
    styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent implements OnInit {
    public displayHelp: boolean;
    private dialogRef: any;
    @Input('alias') helpAlias: string;

    constructor(
        protected dialog: MatDialog
    ) {
        this.displayHelp = displayHelp;
    }

    ngOnInit() {
    }

    public showHelp(): void {
        this.dialogRef = this.dialog.open(ModalPageComponent, {
            panelClass: 'entity-form-dialog',
            data: {alias: this.helpAlias}
        });
    }
}
