import {Component, Input, OnInit} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector   : 'fuse-mail-confirm',
    templateUrl: './mail-confirm.component.html',
    styleUrls  : ['./mail-confirm.component.scss'],
    animations : fuseAnimations
})
export class FuseMailConfirmComponent implements OnInit
{
  formTitle: string;
  contentText: string[];
  formRedirection: string;
  formRedirectionLink: string;

  @Input('data') formData: any;


    constructor()
    {
    }

    ngOnInit(){
      this.formTitle = this.formData.title;
      this.contentText = this.formData.content;
      this.formRedirection = this.formData.redirection;
      this.formRedirectionLink = this.formData.redirectionLink;
    }
}
