import {Injectable} from '@angular/core';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '@modules/graph-mathia/core/model/rules';

@Injectable({
    providedIn: 'root'
})
export class GraphMathiaAuthorizationService {

    constructor(private authorizationService: AuthorizationService) {
    }

    activeRulesOnStartup(): void {
        this.authorizationService.addRoleRule(SyncRules.seeGraphsLinksInMenu, ['learner', 'trainer']);
    }
}
