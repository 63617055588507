import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

/**
 * Generic component to filter on learner with or without filter group and workgroup
 * it take in input the list of group workgroup and leanrner
 * And senfd in output an array of id of learners selected
 */
@Component({
    selector: 'app-members-filter',
    templateUrl: './members-filter.component.html',
    styleUrls: ['./members-filter.component.scss']
})
export class MembersFilterComponent implements OnInit {
    public customFilter = {
        groupControl: new FormControl(''),
        workgroupControl: new FormControl(''),
        learnerControl: new FormControl([''])
    };

    private filterWorkgroupActive: string;
    private filterGroupActive: string;
    public learnersList: { id: (string | number), username: string, groups: string[], workgroups: string[] }[] = [];
    private isAllLearnersFlag: boolean = false;

    // if group or workgroup are equal to [] empty fields are not showed but we can also filter on learner field
    @Input('groups') groups?: { id: number | string, groupname: string, learnersIds: number | string[] }[] = []; // classroom
    @Input('workgroups') workgroups?: { id: number | string, workgroupname: string, learnersIds: number | string[] }[] = []; // group;
    @Input('learners') learners?: { id: string | number, username: string, groups: string[], workgroups: string[] }[] = [];
    @Input('learnerToSelect') learnerToSelect?: string [] = [];

    @Output('selectedLearners') selectedLearners = new EventEmitter<(string | number)[]>();
    @Input('readonly') readonly: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
        // load the learner list with all learner without filter
        this.learnersList = this.learners;
        // in edit mode and read only mode we have a list of learner previously selected
        this.customFilter.learnerControl.setValue(this.learnerToSelect.map(l => +l));
    }

    /**
     * get the current value of group and apply filter to show learner of this group filter take care of workgroup
     * @param event : value of current group(classroom)
     */
    public selectGroups(event: MatSelectChange): void {
        this.filterGroupActive = event.value;
        this.applyFilter();
        this.selectAllLearnerAllowed();
    }

    /**
     * get the current value of workgroup and apply filter to show learner of group and workgroup selected
     * @param event : value of current workgroup
     */
    public selectWorkGroups(event: MatSelectChange): void {
        this.filterWorkgroupActive = event.value;
        this.applyFilter();
        this.selectAllLearnerAllowed();
    }

    /**
     * change the list of learner to show in regard of group and workgroup use
     */
    private applyFilter(): void {
        this.learnersList = this.learners;

        if (this.filterGroupActive && this.filterGroupActive !== 'all' && this.filterGroupActive !== '') {
            this.learnersList = this.learnersList.filter((learner) => learner.groups.indexOf(this.filterGroupActive) !== -1);
        }
        if (this.filterWorkgroupActive && this.filterWorkgroupActive !== 'all' && this.filterWorkgroupActive !== '') {
            this.learnersList = this.learnersList.filter((learner) => learner.workgroups.indexOf(this.filterWorkgroupActive) !== -1);
        }
    }

    /**
     * emit the list of selected learners in case of all send all learners list active
     * @param event array of number : it's the id of learners selected
     */
    public selectLearners(event: MatSelectChange): void {
        if (event.value[0] === 'all') {
            this.selectAllLearnerAllowed();
        } else {
            if (this.isAllLearnersFlag === true) {
                this.unselectAllLeraners();
            } else {
                // send the selected learners
                this.selectedLearners.emit(event.value);
            }

        }
    }

    /**
     * select all learner in the current learner showed list
     */
    private selectAllLearnerAllowed(): void {
        this.isAllLearnersFlag = true;
        // select all in list and all learner alloawed are checked
        let allSelectedLearners: (string | number)[] = [];
        allSelectedLearners.push('all');
        this.customFilter.learnerControl.setValue(allSelectedLearners.concat(this.learnersList.map(l => +l.id)));
        // send all learner filter by group and workgroup
        this.selectedLearners.emit(this.learnersList.map(l => l.id.toString()));
    }

    /**
     * unselect all learners previously selected with all flag
     */
    private unselectAllLeraners(): void {
        this.isAllLearnersFlag = false;
        this.customFilter.learnerControl.setValue('');
        this.selectedLearners.emit([]);
    }
}
