import {Observable} from 'rxjs';
import {AdapterDataEntity} from '@fuse/adapters/adapter-data-entity';
import {DataCollection} from 'octopus-connect';
import {IConvert} from '@fuse/adapters/iconvert';
import {map} from 'rxjs/operators';

/**
 * this class return data formated and typed like we will use it
 * this class is to use for all basic list where we only use id for identifier and label for data we will shown
 * to create a new one more elaborate class just copy paste and change name of class and interface and add data mapping
 * in commonModelDto only push class that are realy basic and use most and most with the same interface in all code
 * For other case create a folder modelsDto and push the class inside
 * example of use case : getting skills with a loadCollection:
 * public getSkills(): Observable<IBasicListDto[]> {
        return new BasicListDto(this.octopusConnect.loadCollection('skills')).getObjectDto();
    }
 */
export class ModelDataCollectionDto {
    // final observable of result
    private _objectDto: Observable<any[]>;

    /**
     * we pass data, we call the adpaterDto Class to formated data
     * next we type them with current object type
     * @param data : Observable of DataCollection, we transform it in a data entity array in first time
     */
    constructor(data: Observable<DataCollection>, classDto: IConvert) {
        try {
            const dataEntity$ = data.pipe(map((d: DataCollection) => d.entities));
            // the adapter return a formated object not typed we typed it after getting it and store it
            this._objectDto = <Observable<any[]>>(new AdapterDataEntity(dataEntity$, classDto).getDataFormated());
        } catch (ex) {
            console.log(ex);
        }
    }

    /**
     * return the object to subscribe
     */
    public objectDto(): Observable<any[]> {
        return this._objectDto;
    }
}



