export const LessonActivityRoutes = {
    'APP': 'appaire',
    'Audio': 'audio',
    'CRT': 'shortanswer',
    'EXT': 'external',
    'GCM': 'gcm', // grid choice multiple
    'IMG': 'image',
    'IMGI': 'interactiveimage',
    'MULTI': 'medias',
    'ORD': 'ordon',
    'QCM': 'qcm',
    'QCMU': 'qcu',
    'RB': 'fillinblanks',
    'Tool': 'tool',
    'VF': 'truefalse',
    'summary': 'summary',
    'video': 'video',
};

export const ActivitiesFullNames = {
    'QCM': 'Questions Chois Multiple',
    'QCMU': 'Questions Chois Unique',
    'VF': 'Vrai Ou Faux',
    'ORD': 'Ordanancement',
    'CRT': 'Réponse courte',
    'RB': 'Remplir Les Blancs',
    'media': 'Media',
    'APP': 'Appariement'
};

export interface IFormatIdentifier {
    label: string;
    id?: string;
}

/**
 * Options used to load a lesson by url with some temporary settings (one-time settings no needed every times)
 */
export interface NavigateToLessonOptions {
    exitLessonUrl?: string; // used to overwrite the default lesson exit route.
    startOnStepIndex?: number; // Used to overwrite the default activity to load by another on the lesson list by this index
}
