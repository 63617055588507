import {EventFiltersComponent} from './event-filters.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {AuthenticationService} from '@modules/authentication';
import {AgendaService} from '../agenda.service';
import {MembersSelectionModule} from '@modules/agenda/core/members-selection/members-selection.module';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../../../shared/shared.module';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {MembersFilterModule} from 'fuse-core/components/members-filter/members-filter.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MembersSelectionModule,
        TranslateModule,
        SharedModule,
        FuseSharedModule,
        MembersFilterModule
    ],
  declarations: [
    EventFiltersComponent
  ],
  entryComponents: [
    EventFiltersComponent
  ],
  exports: [
    EventFiltersComponent
  ],
  providers: [
    AuthenticationService,
    AgendaService
  ]
}) export class EventFiltersModule {}
