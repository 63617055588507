import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {DataCollection, OctopusConnectService} from 'octopus-connect';
import {map, mergeMap, take} from 'rxjs/operators';
import {Observable} from 'rxjs/index';
import {CommunicationCenterService} from '@modules/communication-center';
import {ReplaySubject} from 'rxjs';

@Component({
    selector: 'app-join-school',
    templateUrl: './join-school.component.html'
})
/**
 * permit to join a school
 * good to know : school code is in reality store as a group
 * but not send with groups or workgroups list but with institution.
 * to store ans save it we change the group list of the user and back dispatch in regard of if
 * id is a group an institution or a workgroup
 */
export class JoinSchoolComponent implements OnInit {
    public hasJoinASchool = false;
    public userSchool: IUserSchool = {id: '', label: '', code: ''};
    public errorMessage = '';
    public loading = false;
    @Output('schoolJoin') schoolJoin: EventEmitter<string> = new EventEmitter<string>();

    constructor(private profileService: ProfileService,
                private communicationCenter: CommunicationCenterService,
                private octopusConnect: OctopusConnectService) {
        this.getUserSchoolCode();
    }

    ngOnInit(): void {
    }

    /**
     * prepare data to store it in a friendly format (code class id and label) for the different class of user
     * use data already existing be carefull join and leave a group need to force a refresh
     * because this data are loaded only when app is launched
     * @private
     */
    private getUserSchoolCode(): void {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionList')
            .pipe(take(1))
            .subscribe(inst => {
                if (inst && inst.length > 0) {
                    this.userSchool.id = inst[0].id;
                    this.userSchool.label = inst[0].institutiongroupname;
                    this.userSchool.code = inst[0].code;
                    this.hasJoinASchool = true;
                    this.schoolJoin.emit(this.userSchool.label);
                }
            });
    }

    public joinSchool(): void {
        this.loading = true;
        this.errorMessage = '';
        // load group id and label and code class corresponding to code class use
        this.loadGroupsByCode(this.userSchool.code)
            .pipe(take(1))
            .subscribe((groupInfo: any) => {
                if (groupInfo.length > 0) {
                    // if not use [... change is already made without set directly in original list(because of copy by ref) and request is not send.
                    let newList = [...<string[]>this.profileService.userInformation.get('groups')];
                    newList.push(groupInfo[0].id.toString());
                    this.profileService.userInformation.set('groups', newList);
                    this.profileService.userInformation.save()
                        .pipe(take(1))
                        .subscribe(res => {
                            // on success show school name
                            this.hasJoinASchool = true;
                            this.userSchool.code = groupInfo[0].code;
                            this.userSchool.label = groupInfo[0].label;
                            this.userSchool.id = groupInfo[0].id;
                            this.schoolJoin.emit(this.userSchool.label);
                            this.loading = false;
                            this.refreshData();

                        }, error => {
                            this.errorMessage = 'account-management.join-school-error';
                            this.loading = false;
                        });
                } else {
                    this.errorMessage = 'account-management.join-school-error-code-not-exist';
                    this.loading = false;
                }
            }, error => {
                this.errorMessage = 'account-management.join-school-error';
                this.loading = false;
            });
    }

    /**
     * load the groups by code data are formated and contain id label and code
     * @param code id of group in string format
     * @private
     */
    private loadGroupsByCode(code: string): Observable<IUserSchool[]> {
        const filter = {id: code};
        const subject = new ReplaySubject<Observable<IUserSchool[]>>(1);
        this.communicationCenter.getRoom('groups-management')
            .next('institutions$', {
                filter: filter,
                callback: (data: Observable<DataCollection>): void => {
                    subject.next(this.formatGroupdata(data));
                }
            });
        // for reduce to only a simple observable
        return subject.pipe(mergeMap(obs => obs));
    }

    private formatGroupdata(data: Observable<DataCollection>): Observable<IUserSchool[]> {
        return data.pipe(
            map(g => g.entities
                .map((group: any) => {
                    return {
                        id: group.id,
                        label: group.get('label'),
                        code: group.get('code')
                    };
                }))
        );
    }

    public leaveSchool(): void {
        this.loading = true;
        this.errorMessage = '';
        const idsGroup = <any>this.profileService.userInformation.get('groups');
        const newList = [...idsGroup.filter(g => g !== this.userSchool.code)];
        // update user with new list of class if success show error if update failed
        this.profileService.userInformation.set('groups', newList);
        this.profileService.userInformation.save()
            .pipe(take(1))
            .subscribe(res => {
                // update list after success of remove
                this.hasJoinASchool = false;
                this.userSchool.id = '';
                this.userSchool.code = '';
                this.userSchool.label = '';
                this.schoolJoin.emit('');
                this.loading = false;
                this.refreshData();
            }, error => {
                this.errorMessage = 'account-management.remove-school-error';
                this.loading = false;
            });
    }

    /**
     * force refresh data of list of institution (school)
     * because this data are loaded only when app is launched and only at this time
     * except if we force to reload them
     * @private
     */
    private refreshData(): void {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('refresh-institutionList')
            .next();
    }
}

export interface IUserSchool {
    id: string | number;
    code: string;
    label: string;
}
