import {Injectable} from '@angular/core';
import {GroupService} from '@modules/groups-management/core/services/group.service';
import {ProjectsService} from '@modules/projects-management/core/projects.service';
import {CorpusService} from '@modules/corpus';
import {InstitutionGroupService, LearnerService, TrainerService, WorkgroupService} from '@modules/groups-management';
import {ResearchSheetService} from '@modules/research-sheet';
import {AssignationService, AssignmentComponentsService} from '@modules/assignation';
// import { AuthenticationService } from '@modules/authentication-module';
import {SvgIconList} from './themes/mindmath/svgIconList';
import {LessonsService} from '@modules/activities';
import {LicensingService} from '@modules/licensing/core/licensing.service';
import {GamificationService} from '@modules/gamification/core/gamification.service';
import {MessagingService} from '@modules/messaging';
import {ConfigurationService} from '@modules/configuration';
import {IdeasWallService} from '@modules/ideas-wall/core/ideas-wall.service';
import {NotepadService} from '@modules/notepad';
import {MindmapService} from '@modules/mindmap';
import {TimelineService} from '@modules/timeline';
import {LessonEditionService} from '@modules/activities/core/lessons/editor/services/lesson-edition.service';
import {NewsService} from '@modules/dashboard/core/services/news.service';
import {GroupsManagementNewsService} from '@modules/groups-management/core/services/groups-management-news.service';
import {AssignmentNewsService} from '@modules/assignation/core/services/assignment-news.service';
import {SnackbarNewsService} from '@modules/dashboard/core/services/snackbar-news.service';
import {GraphMathiaService} from '@modules/graph-mathia';
import {GraphDysappService} from '@modules/graph-dysapp';
import {GraphUbolinoService} from '@modules/graph-ubolino';
import {LicensesService} from '@modules/groups-management/core/services/licenses.service';


@Injectable()
export class ServiceInstantiator {

    constructor(
        // private authenticationService: AuthenticationService,
        private groupService: GroupService,
        private workgroupService: WorkgroupService,
        private institutionGroupService: InstitutionGroupService,
        private learners: LearnerService,
        private projectsService: ProjectsService,
        private corpusService: CorpusService,
        private trainerService: TrainerService,
        private researchSheetService: ResearchSheetService,
        private assignationService: AssignationService,
        private assignmentComponentsService: AssignmentComponentsService,
        private SvgIconList: SvgIconList,
        private lessonsService: LessonsService,
        private licensingService: LicensingService,
        private gamificationService: GamificationService,
        private messaginService: MessagingService,
        private configurationService: ConfigurationService,
        private ideasWallService: IdeasWallService,
        private mindmapService: MindmapService,
        private notepadService: NotepadService,
        private timelineService: TimelineService,
        private lesson_editor: LessonEditionService,
        private newsService: NewsService,
        private groupsManagementNewsService: GroupsManagementNewsService,
        private assignmentNewsService: AssignmentNewsService,
        private snackbarNewsService: SnackbarNewsService,
        private graphMathiaService: GraphMathiaService,
        private graphDysappService: GraphDysappService,
        private graphUbolinoService: GraphUbolinoService,
        private licensesService: LicensesService,
    ) {
    }

}
