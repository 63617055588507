import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import {MessagingService} from '../../messaging.service';
import {ENTER, COMMA} from '@angular/cdk/keycodes';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import {isArray, isNumber, isString} from 'util';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {DOMAIN_MESSAGING, EVENT_CORE_LABELS} from '../../messaging.events';

@Component({
  selector: 'app-messaging-add-edit',
  templateUrl: './messaging-add-edit.component.html',
  styleUrls: ['./messaging-add-edit.component.scss']
})
export class MessagingAddEditComponent implements OnInit, AfterViewInit {

    groups: any[];
    wgroups: any[];
    learners: any[];
    audienceFormTouched = false;

    disableFormWhileSaving = false;
    textAreaMessageHeight = '100px';
    allLabel: string;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    addEditThreadForm: FormGroup;

    groupsCtrl = new FormControl();
    wgroupsCtrl = new FormControl();
    learnersCtrl = new FormControl();

    filteredLearners: Observable<Array<any>>;
    filteredGroups: Observable<Array<any>>;
    filteredWgroups: Observable<Array<any>>;

    formData = {
        subject: undefined,
        message: undefined,
        groups : [],
        wgroups : [],
        learners : []
    };

    @ViewChild('groupInput') groupInput: ElementRef<HTMLInputElement>;
    @ViewChild('groupAuto') groupAutoAutocomplete: MatAutocomplete;
    @ViewChild('wgroupInput') wgroupInput: ElementRef<HTMLInputElement>;
    @ViewChild('wgroupAuto') wgroupAutocomplete: MatAutocomplete;
    @ViewChild('learnersInput') learnerInput: ElementRef<HTMLInputElement>;
    @ViewChild('learnersAuto') learnerAutocomplete: MatAutocomplete;
    @ViewChild('textAreaMessage') textAreaMessage: MatFormField;
    @ViewChild('subjectInput', { static: true }) subjectInput: MatInput;

    constructor(
        private sanitizer: DomSanitizer,
        public messagingService: MessagingService,
        public dialogRef: MatDialogRef<MessagingAddEditComponent>,
        @Inject(MAT_DIALOG_DATA) private modalData: Object,
        private formBuilder: FormBuilder,
    ) {
        this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_CORE_LABELS, (data) => {
            this.allLabel = data['generic.all'];
        });
        this.messagingService.consumeEvent('groups-management', 'groupsList', (data) => this.groups = data);
        this.messagingService.consumeEvent('groups-management', 'workgroupsList', (data) => this.wgroups = data);
        this.messagingService.consumeEvent('groups-management', 'learnerList', (data) => this.learners = data);
        this.buildFormValidationObject(formBuilder);
        if (this.isEditThreadAction && this.messagingService.isTeacher) {
            this.recoverAudienceSelectionOnEdit();
        }
    }
    /**
     * 
     */
    showSaveButton(): boolean {
        return this.canEditSubject();
    }
    /**
     * 
     */
    disableSaveButton(): boolean {
        return this.addEditThreadForm.invalid || !this.addEditThreadForm.dirty;
    }
    /**
     * 
     */
    canEditSubject(): boolean {
        return this.isAddThreadAction || 
            (
                this.modalData['thread'] && this.modalData['thread'].archived === false &&
                (this.messagingService.isTeacher || 
                this.isAuthor)
            );
    }
    /**
     * 
     */
    ngAfterViewInit(): void {
        if (this.textAreaMessage) {
            const coeff = this.messagingService.isTeacher ? 0.6 : 0.8 ;
            this.textAreaMessageHeight = parseInt(String(this.textAreaMessage._elementRef.nativeElement.offsetHeight * coeff), 10) + 'px';
        }
    }
    /**
     * 
     * @param formBuilder 
     */
    buildFormValidationObject(formBuilder: FormBuilder): void {
        if (this.isAddThreadAction) {
            if (this.messagingService.isTeacher) {
                 this.addEditThreadForm = formBuilder.group({
                    threadSubject: ['', Validators.required],
                    threadMessage: ['', Validators.required],
                    threadAudience: [''],
                 }, {
                    validator: ValidAudience('threadAudience', this.formData)
                 });
            } else {
                this.addEditThreadForm = formBuilder.group({
                    threadSubject: ['', Validators.required],
                    threadMessage: ['', Validators.required],
                 });
            }
        } else {
            if (this.messagingService.isTeacher) {
                this.addEditThreadForm = formBuilder.group({
                    threadSubject: [{value: null, disabled: !this.canEditSubject()}, Validators.required],
                    threadAudience: [''],
                 }, {
                    validator: ValidAudience('threadAudience', this.formData)
                 });
            } else {
                this.addEditThreadForm = formBuilder.group({
                    threadSubject: [{value: null, disabled: !this.canEditSubject()}, Validators.required],
                 });
            }
        }
    }
    /** 
     * 
    */
    calcTextAreaMessageHeight(): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(`${this.textAreaMessageHeight}`);
    }
    /**
     * 
     */
    ngOnInit(): void {
        if (this.messagingService.isTeacher) {
            this.filteredGroups = this.groupsCtrl.valueChanges.pipe(
                startWith(null),
                map((grp: any | null) => grp ? this._filterChoosed(this._filter(grp, 'group'), 'group') : this._filterChoosed(this.groups.slice(), 'group'))
            );
            this.filteredWgroups = this.wgroupsCtrl.valueChanges.pipe(
                startWith(null),
                map((wgrp: any | null) => wgrp ? this._filterChoosed(this._filter(wgrp, 'wgroup'), 'wgroup') : this._filterChoosed(this.wgroups.slice(), 'wgroup'))
            );
            this.filteredLearners = this.learnersCtrl.valueChanges.pipe(
                startWith(null),
                map((lea: any | null) => lea ?
                    this._filterChoosed(this._filter(lea, 'learners'), 'learners') : 
                    this._filterChoosed(this._filterLearnersByGroupAndWGroups(this.learners, this.formData.groups[0], this.formData.wgroups), 'learners'))
            );
        } 
        if (this.isEditThreadAction) {
            this.formData.subject = this.modalData['thread']['label'];
        }
    }
    /**
     * 
     */
    computeParticipantsNames(): string {
        if (this.isEditThreadAction && !this.canEditAudience() && isArray(this.modalData['thread'].membersDetails)) {
            return this.modalData['thread'].membersDetails.map(memdet => memdet.label).join(', ');
        } else {
            return '';
        }
    }
    /**
     * 
     */
    get modalTitleKey(): string {
        if (this.isAddThreadAction) {
            return 'messaging.add_new_thread';
        } else if ((!this.messagingService.isTeacher && !this.isAuthor) || this.modalData['thread'].archived === true) {
            return 'messaging.about_thread';
        } else {
            return 'messaging.edit_thread';
        }
    }
    /**
     * 
     */
    get participants(): string {
        const label = this.learners.map(lea => {
            if (this.modalData['thread'] && this.modalData['thread'].members.includes(String(lea.id))) {
                return lea.username;
            }
        }).join(', ');
        return (isString(label) && label.length > 0) ? ', ' + label : '';
    }
    /**
     * 
     */
    get isAuthor(): boolean {
        return this.isAddThreadAction ? true : this.messagingService.isAuthor(this.modalData['thread']);
    }
    /**
     * 
     */
    recoverAudienceSelectionOnEdit(): void {
        if (!this.modalData['thread'] || !isArray(this.modalData['thread'].members)) {
            return;
        }
        const members: string[] = this.modalData['thread'].members.filter(mem => mem !== this.messagingService.user.id);
        this.learners.forEach(lea => {
            if (members.includes(String(lea.id))) {
                this.formData.learners.push(lea);
            }
        });
    }
    /**
     * 
     */
    get isEditThreadAction(): boolean {
        return this.modalData['action_type'] && this.modalData['action_type'] === 'edit';
    }
    /**
     * 
     */
    isAudienceValid(): boolean {
        return !this.audienceFormTouched || this.addEditThreadForm.controls['threadAudience'] && 
            this.addEditThreadForm.controls['threadAudience'].valid === true;
    }
    /**
     * return true if is add action
     */
    get isAddThreadAction(): boolean {
        return this.modalData['action_type'] && this.modalData['action_type'] === 'add';
    }
    /**
     * 
     */
    cancel(): void {
        this.dialogRef.close('cancel');
    }
    /**
     * 
     */
    send(): void {
        let partcipants;

        if (this.messagingService.isTeacher) {
            partcipants = this.computeLearnersList();
            if (partcipants.length === 0) {
                // TODO show message audience combination too restrictive
                return;
            }
        } else if (this.isEditThreadAction) {
            partcipants = this.modalData['thread'].members;
        }
        
        if (this.addEditThreadForm.valid) {
            this.disableFormWhileSaving = true;
            if (this.isAddThreadAction) {
                this.messagingService.newThread(
                    {
                        label: this.formData.subject,
                        members: partcipants,
                        groups: this.formData.groups.length > 0 ? this.formData.groups[0].id : null
                    },
                    {
                        message: this.formData.message
                    }
                ).subscribe(
                    data => this.cancel(),
                    error => {
                        // display error message 
                        this.disableFormWhileSaving = false;
                    }
                );
            } else {
                this.messagingService.updateThread(this.modalData['thread'].id,
                    {
                        label: this.formData.subject,
                        members: partcipants
                    }
                ).subscribe(
                    data => this.cancel(),
                    error => {
                        // display error message 
                        this.disableFormWhileSaving = false;
                    }
                );
            }
        }
    }
    /**
     * 
     */
    computeLearnersList(): string[] {
        const _learners = [];
        const isAllLearners = this.formData.learners.length === 1 && this.formData.learners[0].username === this.allLabel;
        this._filterLearnersByGroupAndWGroups(
            this.learners,
            this.formData.groups[0],
            this.formData.wgroups).forEach(l => {
            if (isAllLearners || this.formData.learners.find(item => item.username === l.username)) {
                _learners.push(isNumber(l.id) ? l.id : parseInt(l.id, 10));
            }
        });
        return _learners;
    }
    /**
     * 
     */
    handleSelectionChange($event: any): void {
        this.addEditThreadForm.controls['threadAudience'].updateValueAndValidity();
        this.addEditThreadForm.controls['threadAudience'].markAsTouched();
        this.addEditThreadForm.controls['threadAudience'].markAsDirty();
    }
    /**
     * 
     */
    removeAudience(value: any, field: string): void {
        switch (field) {
            case 'group':
                this.manageAudience(value, undefined, 'group', 'remove');
                break;
            case 'wgroup':
                this.manageAudience(value, undefined, 'wgroup', 'remove');
                break;
            case 'learners':
                this.manageAudience(value, undefined, 'learners', 'remove');
                break;
            default:
                break;
        }
        this.addEditThreadForm.controls['threadAudience'].updateValueAndValidity();
        this.setDefaultListChips(field);
    }
    /**
     * 
     * @param newObjectChip
     * @param newLabelChip
     * @param field 
     */
    manageAudience(newObjectChip: string, newLabelChip: string, field: string, operation?: string): void {
        switch (field) {
            case 'group':
                if (!operation || operation  === 'add') {
                    this.formData.groups = [newObjectChip];
                    if (newLabelChip !== this.allLabel) {
                        this.formData.learners = [{id: null, 'username' : this.allLabel}];
                    }
                } else {
                    this.formData.groups = this.formData.groups.filter(e => e !== newObjectChip);
                }
                this.refreshLearners();
                break;
            case 'wgroup':
                if (!operation || operation  === 'add') {
                    if (newLabelChip === this.allLabel) {
                        this.formData.wgroups = [this.allLabel];
                    } else {
                        if (!this.alreadyExisting(newLabelChip, 'wgroups')) {
                            this.formData.wgroups.push(newObjectChip);
                            this.formData.wgroups = this.formData.wgroups.filter(e => e !== this.allLabel);
                            this.formData.learners = [{id: null, 'username': this.allLabel}];
                        }
                    }
                } else {
                    this.formData.wgroups = this.formData.wgroups.filter(e => e !== newObjectChip);
                }
                this.refreshLearners();
                break;
            case 'learners':
                if (!operation || operation  === 'add') {
                    if (!this.alreadyExisting(newLabelChip, 'learners')) {
                        if (newLabelChip === this.allLabel) {
                            this.formData.learners = [{id: null, 'username' : this.allLabel}];
                        } else {
                            this.formData.learners.push(newObjectChip);
                            this.formData.learners = this.formData.learners.filter(e => e['username'] !== this.allLabel);
                        }
                    }

                } else {
                    this.formData.learners = this.formData.learners.filter(e => e.username !== newObjectChip);
                }
                this.refreshLearners();
                break;
            default:
                break;
        }
    }
    /**
     * 
     * @param event 
     * @param field 
     */
    addAudience(event: MatAutocompleteSelectedEvent, field: string): void {
        switch (field) {
            case 'group':
                this.groupInput.nativeElement.value = '';
                this.groupsCtrl.setValue(null);
                this.manageAudience(event.option.value, event.option.viewValue, 'group');
                break;
            case 'wgroup':
                this.wgroupInput.nativeElement.value = '';
                this.wgroupsCtrl.setValue(null);
                this.manageAudience(event.option.value, event.option.viewValue, 'wgroup');
                break;
            case 'learners':
                this.learnerInput.nativeElement.value = '';
                this.learnersCtrl.setValue(null);
                this.manageAudience(event.option.value, event.option.viewValue, 'learners');
                break;
            default:
                break;
        }

        this.setDefaultListChips(field);
    }

    /**
     * set list of data for "select" for group and workgroup
     */
    setDefaultListChips(type): void {
        if (type === 'group') {
            this.filteredGroups = this.groupsCtrl.valueChanges.pipe(
                startWith(null),
                map((grp: any | null) => grp ? this._filterChoosed(this._filter(grp, 'group'), 'group') : this._filterChoosed(this.groups.slice(), 'group'))
            );
        }
        if (type === 'wgroup') {
            this.filteredWgroups = this.wgroupsCtrl.valueChanges.pipe(
                startWith(null),
                map((wgrp: any | null) => wgrp ? this._filterChoosed(this._filter(wgrp, 'wgroup'), 'wgroup') : this._filterChoosed(this.wgroups.slice(), 'wgroup'))

            );
        }

        this.blurAllChipsList();
    }

    /**
     * blur field after select chip
     */
    blurAllChipsList(): void {
        this.groupInput.nativeElement.blur();
        this.wgroupInput.nativeElement.blur();
        this.learnerInput.nativeElement.blur();

        this.subjectInput.focus();
    }

    /**
     * can know if the chip selected is already previously selected.
     */
    alreadyExisting(name: string, type): boolean {
        switch (type) {
            case 'learners':
                return !!this.formData[type].find((data) => data['username'] === name);
                break;
            case 'wgroups':
                return !!this.formData[type].find((data) => data['workgroupname'] === name);
                break;
            default:
                return null;
            break;
        }
    }

    /**
     * 
     */
    refreshLearners(): void {
        this.learnersCtrl.setValue(null);
    }
    /**
     * 
     */
    showParticipantsLabelsList(): boolean {
        return this.isEditThreadAction &&
               (!this.messagingService.isTeacher || this.modalData['thread'].archived === true);
    }
    /**
     * 
     */
    canEditAudience(): boolean {
        return this.messagingService.isTeacher &&
               (this.isAddThreadAction || this.modalData['thread'].archived === false);
    }
    /**
     * 
     * @param $event 
     */
    doTouchAudienceForm($event): void {
        this.audienceFormTouched = true;
    }
    /**
     * 
     */
    private _filter(value: any, field: string): any[] {
        switch (field) {
            case 'group':
                const filterValue1 = value['groupname'].toLowerCase();
                return this.groups
                    .filter(group => group['groupname'].toLowerCase().indexOf(filterValue1) === 0);
            case 'wgroup':
                const filterValue2 = value['workgroupname'].toLowerCase();
                return this.wgroups
                    .filter(wgroup => wgroup['workgroupname'].toLowerCase().indexOf(filterValue2) === 0);
            case 'learners':
                const filterValue3 = value['username'] ? value['username'].toLowerCase() : '';
                return this._filterLearnersByGroupAndWGroups(
                        this.learners,
                        this.formData.groups[0],
                        this.formData.wgroups)
                        .filter(lea => lea['username'].toLowerCase().indexOf(filterValue3) === 0);
            default:
                break;
        }
    }
    /**
     * 
     * @param items 
     * @param field 
     */
    private _filterChoosed(items: any[], field: string): any[] {
        switch (field) {
            case 'group':
                return items.filter(item => !this.formData.groups.includes(item));
            case 'wgroup':
                return items.filter(item => !this.formData.wgroups.includes(item));
            case 'learners':
                return items.filter(item => !this.formData.learners.includes(item));
            default:
                break;
        }
    }
    /**
     * 
     * @param learners 
     * @param group 
     * @param wgroup 
     */
    private _filterLearnersByGroupAndWGroups(learners: any[], group: any, wgroup: any[]): any[] {
        if (Array.isArray(learners)) {
            let filtredList = learners;
            if (typeof group !== 'undefined') {
                filtredList = filtredList.filter(item => group.groupname === this.allLabel || item.groups.includes(group.groupname));
            }
            if (typeof wgroup !== 'undefined' && wgroup.length > 0) {
                filtredList = filtredList.filter(item => (wgroup && wgroup.length === 1 && wgroup[0].workgroupname === this.allLabel)
                        || item.workgroups.filter(value => wgroup.includes(value)).length > 0 );
            }
            return filtredList;
        } else {
            return [];
        }
    }
}

export function ValidAudience(controlName: string, formData: any): any {

    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        if (control.errors && !control.errors.validAudience) {
            return;
        }
        if (
            (!isArray(formData.groups) || formData.groups.length === 0)
            &&
            (!isArray(formData.wgroup) || formData.wgroup.length === 0)
            &&
            (!isArray(formData.learners) || formData.learners.length === 0)
        ) {
            control.setErrors({ validAudience: true });
        } else {
            control.setErrors(null);
        }
    };
}
