import {Injectable} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {Observable, BehaviorSubject} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {CommunicationCenterService} from '../../communication-center/index';
import {ActivitiesService} from '@modules/activities/core/activities.service';

@Injectable()
export class ActivityResolver implements Resolve<any> {

    constructor(private communicationCenter: CommunicationCenterService,
                private activitiesService: ActivitiesService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            resolve();
        });
    }
}
