
import {take} from 'rxjs/operators';
import {Component, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, ReplaySubject} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {defaultRoute} from '../settings';

@Injectable()
export class IsUserAnonymous implements CanActivate {

    userInformation: DataEntity;

    constructor(
        private connector: OctopusConnectService,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const subject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

        const obs: Observable<DataEntity> = this.connector.authenticated('http');

        obs.pipe(take(1)).subscribe((userData: DataEntity) => {
            subject.next(false);
            this.router.navigate([defaultRoute]);
        }, () => {
            subject.next(true);
        });

        return subject;
    }
}
