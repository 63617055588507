import {Component, OnInit, ViewEncapsulation, AfterViewInit, ComponentFactoryResolver, Injector, ViewChild} from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import frLocale from '@fullcalendar/core/locales/fr';
import nlLocale from '@fullcalendar/core/locales/nl';
import {MatDialog} from '@angular/material/dialog';
import {EventControlsComponent} from '../event-controls/event-controls.component';
import {CalendarOptions, DatesSetArg, EventApi, FullCalendarComponent} from '@fullcalendar/angular';
import {AgendaService} from '../agenda.service';
import {DialogConfirmDeleteComponent} from '../dialog-confirm-delete/dialog-confirm-delete.component';
import {AgendaEventUI, EventFilters} from '../definitions';
import {DialogEventData, DialogEventComponent} from '../dialog-event/dialog-event.component';
import {AuthenticationService} from '@modules/authentication';
import {Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {EventInput} from '@fullcalendar/common';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
    dayGridPlugin,
]);

@Component({
    selector: 'app-agenda',
    templateUrl: './agenda.component.html',
    styleUrls: ['./agenda.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AgendaComponent implements AfterViewInit, OnInit {
    private eventFilter: EventFilters;
    assignationsSub: Subscription;
    eventsSub: Subscription;
    locale = nlLocale;
    private subscriptionFilter: Subscription;
    private _allEvents = new Array<AgendaEventUI>();
    assignations = new Array<AgendaEventUI>();
    headerConfig = {
        left: '',
        center: 'prev,title,next',
        right: ''
    };
    userRole: 'manager' | 'trainer' | 'learner';
    filters: EventFilters = {};

    public get allEvents(): Array<AgendaEventUI> {
        return this._allEvents;
    }

    public calendarOptions: CalendarOptions = {};


    @ViewChild('calendar', {static: false}) calendarComponent: FullCalendarComponent;

    constructor(
        public dialog: MatDialog,
        private resolver: ComponentFactoryResolver,
        private injector: Injector,
        private agendaService: AgendaService,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
    ) {
    }

    ngOnInit(): void {
        this.setCalendarLocale();

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.setCalendarLocale(event.lang);
        });

        this.userRole = (this.authenticationService.accessLevel as 'manager' | 'trainer' | 'learner');
        const intervalId = setInterval(() => {

            this.calendarOptions = {
                plugins: [dayGridPlugin],
                events: <EventInput[]> this.allEvents,
                locale: this.locale,
                dayHeaderFormat: { weekday: 'long' },
                showNonCurrentDates: false,
                fixedWeekCount: false,
                eventClick: this.eventClick.bind(this),
                eventDidMount: this.eventRender.bind(this),
                datesSet: this.datesRender.bind(this),
                displayEventTime: false,
                headerToolbar: this.headerConfig,
            };

            if (!this.calendarComponent) {
                return;
            }
            const todayTd = ((this.calendarComponent as any).element.nativeElement as HTMLElement).getElementsByClassName('fc-today');
            if (!todayTd[0]) {
                return;
            }
            const weekRowDiv = todayTd[0].closest('.fc-row') as HTMLElement;
            if (!weekRowDiv) {
                return;
            }
            weekRowDiv.style.zIndex = '999';

            clearInterval(intervalId);
        }, 1000);
    }

    /**
     * set language of calendar
     */
    private setCalendarLocale(locale?: string): void {
        let lang = this.translate.currentLang;
        if (locale) {
            lang = locale;
        }
        switch (lang) {
            case 'fr':
                this.locale = frLocale;
                break;
            case 'nl':
                this.locale = nlLocale;
                break;
            default:
                this.locale = frLocale;
                break;
        }
    }

    ngAfterViewInit(): void {
        this.userRole = (this.authenticationService.accessLevel as 'manager' | 'trainer' | 'learner');
    }

    eventClick(info): void {
        this.openDialog(info.event, false);
    }

    eventRender(event: { event: EventApi, el: HTMLElement }): void {
        if (event.event.extendedProps.role === this.userRole && event.event.extendedProps.type === 'event') {

            const factory = this.resolver.resolveComponentFactory(EventControlsComponent);
            const component = factory.create(this.injector);
            component.instance.event = event.event as any;
            component.instance.edit = (ev: EventApi) => {
                this.openDialog(ev);
            };
            component.instance.delete = (ev: AgendaEventUI) => {
                this.openConfirmDeleteDialog(ev);
            };
            component.changeDetectorRef.detectChanges();

            event.el.appendChild(component.location.nativeElement);

        }
        (event.el as HTMLElement).setAttribute('aria-label', event.event.title);
        (event.el as HTMLElement).setAttribute('data-balloon-pos', 'up');
        (event.el as HTMLElement).setAttribute('data-balloon-length', 'fit');
    }

    openDialog(event: EventApi, canEdit = true): void {
        let retrievedEvent;
        if (event) {
            retrievedEvent = this.allEvents.find(e => e.id === event.id && e.extendedProps.type === event.extendedProps.type);
        }

        let windowRole;

        if (event) {
            windowRole = event.extendedProps.role;
        } else {
            windowRole = this.userRole;
        }
        const data: DialogEventData = {
            event: retrievedEvent,
            userRole: this.userRole,
            canEdit,
            agendaService: this.agendaService,
            windowRole
        };

        const dialogRef = this.dialog.open<any, any, AgendaEventUI>(DialogEventComponent, {
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.id == null) {
                    this.agendaService.addEvent(result).subscribe(res => {
                    }, err => {
                        console.log('err', err);
                    });
                } else {
                    this.agendaService.updateEvent(result).subscribe(res => {
                    }, err => {
                        console.log('err', err);
                    });
                }
            }
        });
    }


    openConfirmDeleteDialog(event: AgendaEventUI): void {
        const dialogRef = this.dialog.open(DialogConfirmDeleteComponent);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.agendaService.deleteEvent(event).subscribe(deletionSucceded => {
                    if (deletionSucceded) {
                        console.log('DELETED');
                    }
                });
            }
        });
    }

    // TODO remove all use real list of user
    public search(filters: EventFilters): void {
        // if filter is all pas de filtre we show all event
        if (filters && filters.members && filters.members.length === 0) {
            filters = null;
        }
        // if date is set but not in local filter set it
        if (!filters.date_from && this.eventFilter.date_from) {
            filters.date_from = this.eventFilter.date_from;
        }
        // if date is set but not in local filter set it
        if (!filters.date_to && this.eventFilter.date_to) {
            filters.date_to = this.eventFilter.date_to;
        }


        if (this.subscriptionFilter) {
            // warning do not replace with a take(1) it wouldn't work because of two possible call one from dateRender and one from html
            this.subscriptionFilter.unsubscribe();
        }

        this.subscriptionFilter = this.agendaService.searchAllEvents(filters)
            .subscribe((events: AgendaEventUI[]) => {
                this._allEvents = events;
            });
    }

    datesRender(event: DatesSetArg): void {
        this.eventFilter = this.agendaService.getFiltersFromView(event.view);
        this.search(this.eventFilter);
    }

}
