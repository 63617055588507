export const accountManagement = {
    allowedMaxSize: 1,
    canEditAvatar: ['learner', 'trainer', 'manager', 'administrator'],
    canSelfDelete: ['trainer'],
    displayDialogMinorAdult: false,
    fields: {
        manager: ['email|required', 'password|required', 'passwordConfirm|required', 'picture'],
        default: ['label|required', 'email|required', 'password|required', 'passwordConfirm|required', 'institution', 'you_are|required', 'find_us|required', 'picture']
    },
    selfSignup: true,
    youAreValues: ['teacher', 'district', 'other']
};
