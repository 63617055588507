import {Injectable} from '@angular/core';
import {AssignationComponent} from '@modules/assignation/core/components/assignation/assignation.component';
import {CommunicationCenterService} from '@modules/communication-center';

@Injectable()
export class AssignmentComponentsService {

    constructor(
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('assignment')
            .next('view', AssignationComponent);
    }
}
