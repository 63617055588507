
import {take, map,  mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import { modulesSettings, defaultApiURL } from '../../../settings';
import { DataEntity, DataCollection, OctopusConnectService } from 'octopus-connect';
import { CommunicationCenterService } from '@modules/communication-center';
import { MatDialog } from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { CreatureCollection, Creature, BadgeType } from './definitions';
import { HttpClient } from '@angular/common/http';
import { AccountManagementProviderService } from '@modules/account-management';
import {TranslateService} from '@ngx-translate/core';

const settingsStructure: ModelSchema = new ModelSchema({
    showRewards: Structures.boolean(false)
});

@Injectable({
    providedIn: 'root'
})
export class GamificationService {

    private badgesSubscription: Subscription;
    private badgesTypes: Array<DataEntity>;
    public settings: { [key: string]: any };
    public userPoints: number;
    public isShowPopup: boolean;
    public activeTab: 'accessories' | 'universes' = 'accessories';
    public originalAccessoriesBeforeBuyingAnotherOne = new Array<DataEntity>(); // list of accessories before buying one permit to return back if new one is not save
    /**
     * Cache of accessories used to remember which accessory are currently bought
     */
    public accessoriesCache: {[p: string]: DataEntity} = {};
    private _buyPopupInfo: {
        hidden: boolean,
        badge: DataEntity,
        buyCallback: (data: DataEntity) => void
    } = {
        hidden: true,
        badge: null,
        buyCallback: null
    };

    public badges: CreatureCollection;
    private _urlFileUpload: string = defaultApiURL + 'api/file-upload';
    private _userProfile: DataEntity;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private connector: OctopusConnectService,
        private http: HttpClient,
        private accountManagementProvider: AccountManagementProviderService,
        private dialog: MatDialog,
        private translate: TranslateService,
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.gamification);

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this._userProfile = data;
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

    }

    private postLogout(): void {
    }

    private postAuthentication(): void {
        if (this.settings.showRewards) {
            this.connector.listen('reward')
                .subscribe((reward: DataEntity) => {
                    this.translate.get(reward.get('body')).subscribe((translation) => {
                        const body =  translation.replace('{{params}}', reward.get('params'));
                        this.openRewardDialog({
                            titleDialog: '',
                            bodyDialog: body,
                            labelTrueDialog: 'OK'
                        });
                    });
                    this.loadBadges();
                });
        }
    }

    public loadBadges(force?): void {
        this.getUserPoints();
        this.badges = new CreatureCollection(this);
        this.translate.onLangChange.subscribe(() => {
            this.badges.loadCreatures(true);
            this.badges.loadUniverses(true);
        });
        this.badges.loadCreatures(force);
        this.badges.loadUniverses(force);
    }

    private openRewardDialog(config: object): void {
        this.dialog.open(FuseConfirmDialogComponent, {data: config});
    }

    openBuyPopup(badge: DataEntity, buyCallback: (data: DataEntity) => void): void {
        if (!badge) {
            return;
        }
        this._buyPopupInfo = {
            badge: badge,
            hidden: false,
            buyCallback
        };
    }

    closeBuyPopup(): void {
        this._buyPopupInfo = {
            hidden: true,
            badge: null,
            buyCallback: null
        };
    }

    buyBadge(): Promise<DataEntity> {
        return new Promise(async (resolve, reject) => {
            if (!this._buyPopupInfo.badge) {
                reject('No badge provided');
            }
            const checkedBadge = await this.getBadge(this._buyPopupInfo.badge.id);
            await this.getUserPoints();
            if (this.userPoints < checkedBadge.attributes.price) {
                reject('Too expensive');
            }
            else {
                checkedBadge.attributes.unLocked = true;
                this._buyPopupInfo.badge.attributes.unLocked = true;
                this.userPoints -= checkedBadge.attributes.price;
                checkedBadge.save(true).subscribe(res => {
                    this.badges.updateBadge(res, true, true);
                    this.getUserPoints();
                    this._buyPopupInfo.buyCallback(res);
                    resolve(res);
                }, err => {
                    reject(err);
                });
            }
        });
    }

    async setSelectedCreatureOrAccessory(collection: Array<DataEntity>, badge: DataEntity, valueToSet: boolean): Promise<DataEntity> {
        if (badge.attributes.unLocked === false) {
            throw new Error('Badge locked');
        }

        if (valueToSet === true) {
            const previous = collection.filter(
                b => b.attributes.selected === true &&
                    (!b.attributes.stuffType && !badge.attributes.stuffType || b.attributes.stuffType.id === badge.attributes.stuffType.id)
            ).map(b => {
                b.attributes.selected = false;
                return new Promise<DataEntity>((resolve, reject) => {
                    b.save().subscribe(res => {
                        this.badges.updateBadge(res);
                        resolve(res);
                    }, err => {
                        reject(err);
                    });
                });
            });
            await Promise.all(previous);
        }
        badge.attributes.selected = valueToSet;
        return new Promise<DataEntity>((resolve, reject) => {
            badge.save().subscribe(res => {
                this.badges.updateBadge(res);
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    setName(badge: DataEntity, name: string): Promise<DataEntity> {
        return new Promise((resolve, reject) => {
            if (!name) {
                reject('Empty name');
            }
            badge.attributes.label = name;
            badge.save().subscribe(res => {
                this.badges.updateBadge(res);
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    get buyPopupInfo(): {
        hidden: boolean;
        badge: DataEntity;
    } {
        return this._buyPopupInfo;
    }

    getBadges(name?: BadgeType, parent?: string): Observable<DataCollection> {
        if (this.badgesSubscription) {
            this.badgesSubscription.unsubscribe();
        }
        if (name) {
            if (this.badgesTypes) {
                const badgeTypeId = this.badgesTypes.find(bt => bt.attributes.name === name).id;
                if (!badgeTypeId) {
                    return;
                }
                const filter = {
                    type: badgeTypeId,
                    parent: parent
                };
                if (!parent) {
                    delete filter.parent;
                }
                return this.connector.loadCollection('badges', filter);
            } else {
                return this.getBadgeTypeId(name).pipe(
                    mergeMap(badgeTypeId => {
                        if (!badgeTypeId) {
                            return;
                        }
                        const filter = {
                            type: badgeTypeId,
                            parent: parent
                        };
                        if (!parent) {
                            delete filter.parent;
                        }
                        return this.connector.loadCollection('badges', filter);
                    })
                );
            }
        } else {
            return this.connector.loadCollection('badges');
        }
    }

    public saveBadge(badge:  DataEntity): Observable<DataEntity> {
        return badge.save(true);
    }

    getBadge(id: string | number): Promise<DataEntity> {
        return new Promise<DataEntity>((resolve, reject) => {
            this.connector.loadEntity('badges', this._buyPopupInfo.badge.id).subscribe(badge => {
                resolve(badge);
            }, err => {
                reject(err);
            });
        });
    }

    getBadgeTypeId(name: string): Observable<any> {
        return this.connector.loadCollection('badges-type').pipe(map(res => {
            this.badgesTypes = res.entities;
            return res.entities.find(e => e.attributes.name === name).id;
        }));
    }

    getUserPoints(): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            this.connector.loadCollection('user-points').subscribe(res => {
                this.userPoints = res.entities[0].attributes.points;
                resolve(res.entities[0].attributes.points);
            }, err => {
                reject(err);
            });
        });
    }

    uploadImage(creature: Creature, imageData: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {

            if (!this._userProfile) {
                reject('No profile data');
            }

            const f = await fetch(imageData);
            const blob = await f.blob();

            const formData = new FormData();
            formData.append('file', blob, 'avatar.png');

            this.http
                .post<any>(this._urlFileUpload, formData, { headers: { 'access-token': this.accountManagementProvider.userAccessToken } })
                .subscribe((fileUploadRes) => {
                    if (fileUploadRes && fileUploadRes.data && fileUploadRes.data[0] && fileUploadRes.data[0][0] && fileUploadRes.data[0][0].id) {
                        const newId = fileUploadRes.data[0][0].id;
                        creature.creature.attributes.fid = newId;
                        creature.creature.save().subscribe(async creatureUpdateRes => {
                            creature.creature.attributes.userImage = creatureUpdateRes.attributes.userImage;
                            creature.creature.attributes.userImageFid = creatureUpdateRes.attributes.userImageFid;
                            if (creature.creature.attributes.selected === true) {
                                const newCreatureAdterAvatar = await this.setAvatar(creature);
                                resolve(newCreatureAdterAvatar);
                            } else {
                                resolve(creatureUpdateRes);
                            }
                        }, err => {
                            reject(err);
                        });
                    } else {
                        reject('Empty uploaded file data');
                    }
                }, err => {
                    reject(err);
                });
        });
    }

    setAvatar(creature: Creature): Promise<Creature> {
        return new Promise<Creature>(async (resolve, reject) => {

            if (!this._userProfile) {
                reject('No profile data');
            }

            this._userProfile.set('_picture', creature.creature.get('userImageFid'));
            this._userProfile.save().subscribe(async userProfileUpdateRes => {
                creature.creature = await this.setSelectedCreatureOrAccessory(this.badges.creatures.map(cr => cr.creature), creature.creature, true);
                resolve(creature);

                this.communicationCenter
                    .getRoom('account-management')
                    .next('refreshUser', true);
            }, err => {
                reject(err);
            });
        });
    }

    startPersonalisation(): void {
        this.connector.loadEntity('badges', 'start').pipe(take(1));
    }
}
