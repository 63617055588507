import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfigurationService} from '../../configuration.service';
import {DataEntity} from 'octopus-connect';
import {FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Subject} from 'rxjs/index';
import {AuthenticationService} from '@modules/authentication';
import {ChaptersService} from '@fuse/services/chapters.service';

@Component({
    selector: 'app-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit, OnDestroy {

    public analyticsListing: DataEntity[];
    public startControls = {};
    public endControls = {};
    public methodControl: FormControl = new FormControl();
    public institutionControl: FormControl = new FormControl();
    public licensingMethods: { id: number | string; label: string }[];
    private unsubscribeInTakeUntil = new Subject();

    constructor(
        private configService: ConfigurationService,
        private router: Router,
        private authService: AuthenticationService,
        private chaptersService: ChaptersService
    ) {
    }

    ngOnInit(): any {
        if (this.configService.settings
            && this.authService.hasLevel(
                this.configService.settings.role
                    ? this.configService.settings.role
                    : [])
            && this.configService.settings.show) {
            this.chaptersService.getParentChapters().pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((entities) => {
                    this.licensingMethods = entities.map((entity) => {
                        return {
                            id: entity.id,
                            label: entity.get('name')
                        };
                    });
                });

            this.methodControl = new FormControl('', [Validators.required]);
            this.configService.loadAnalyticsListing().pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((data: DataEntity[]) => {
                    if (data && data.length) {
                        this.analyticsListing = data[0].get('link').slice();
                        for (let i = 0; i < this.analyticsListing.length; i++) {
                            this.startControls[i] = new FormControl();
                            this.endControls[i] = new FormControl();
                        }
                    }
                });
        } else {
            this.router.navigate(['..', 'dashboard']);
        }

    }

    /**
     * we generate an url from the list of analytics to download a csv file
     * startControls and endControls are array of formControl
     * @param analytic
     * @param index
     */
    public generate(analytic, index): void {
        if (analytic) {
            let url = this.configService.apiUrl + analytic['link_relative_url'];

            if (this.startControls[index] && this.startControls[index].value && this.startControls[index].value !== '') {
                url += '&start=' + this.startControls[index].value.format('X');
            }
            if (this.endControls[index] && this.endControls[index].value && this.endControls[index].value !== '') {
                url += '&end=' + this.endControls[index].value.format('X');
            }
            if (analytic['link_relative_url'] === 'csv-export?type=assignations-count') {
                if (this.methodControl && this.methodControl.value && this.methodControl.value !== '' && this.methodControl.value !== 'all') {
                    url += '&chapters=' + this.methodControl.value.id;
                } else {
                    url += '&method=true';
                }
            }
            if (analytic['link_relative_url'] === 'csv-export?type=active-users'
                && this.institutionControl
                && this.institutionControl.value
                && this.institutionControl.value !== '') {
                url += '&institutions=' + this.institutionControl.value;
            }
            this.configService.getFileFromUrl(url);
        }
    }

    ngOnDestroy(): any {
        if (this.unsubscribeInTakeUntil) {
            this.unsubscribeInTakeUntil.next();
            this.unsubscribeInTakeUntil.complete();
        }
    }

}
