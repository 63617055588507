
import {map} from 'rxjs/operators';
import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {NotificationsService} from '@modules/notification/core/notifications.service';
import { MatTableDataSource } from '@angular/material/table';
import {NotificationInterface} from '@modules/notification/core/notification.interface';
import {DataEntity} from 'octopus-connect';
import {fuseAnimations} from '@fuse/animations';
import {FuseMatSidenavHelperService} from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.service';
import {NotificationDefinition} from '@modules/notification';
import {FuseSidebarService} from '../../../../@fuse/components/sidebar/sidebar.service';
import {FuseSidebarComponent} from '../../../../@fuse/components/sidebar/sidebar.component';


@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations : fuseAnimations
})
export class FuseQuickPanelComponent implements OnInit
{
    @Input('fuseMatSidenavHelper') id: string;

    notifs: any;
    dataSource: MatTableDataSource<any>;
    displayedColumns;
    selected: any;
    options = { weekday: undefined, year: 'numeric', month: 'numeric', day: 'numeric' };

    iconsType = {
        Groupes: 'contacts',
        Corpus: 'corpus',
        researchSheet: 'search'
    };

    constructor(
        public notificationsService: NotificationsService,
        private communicationCenter: CommunicationCenterService,
        private router: Router,
        private fuseMatSidenavService: FuseMatSidenavHelperService,
        private _fuseSidebarService: FuseSidebarService
    ) {
        this.notificationsService.onFilesChanged.subscribe(notifs => {
            this.notifs = notifs;
        });
        this.notificationsService.onFileSelected.subscribe(selected => {
            this.selected = selected;
        });

        this.communicationCenter.getRoom('notifications').getSubject('myNotifications').pipe(
            map((entities: DataEntity[]) => {
                return entities.filter(entity => {
                    return this.notificationsService.isNotificationTypeRegistered(entity);
                }).map(entity => {
                    const definition: NotificationDefinition = this.notificationsService.getDefinition(entity.get('type'));

                    if (definition) {
                        return <NotificationInterface>{
                            notificationType: entity.get('type'),
                            type: definition.icon,
                            read: entity.get('read'),
                            label: this.notificationsService.getTranslatedText(entity.get('type'), JSON.parse(entity.get('data'))),
                            data: JSON.parse(entity.get('data')),
                            date: entity.get('created'),
                            id: entity.id,
                            originalEntity: entity,
                            deletable: definition.deletable !== false,
                            formattedDate: this.notificationsService.localeDate(entity.get('created')),
                            formattedTime: this.notificationsService.localeTime(entity.get('created'))
                        };
                    }
                });
            }))
            .subscribe((notifs: NotificationInterface[]) => {
                this.displayedColumns = ['data'];
                this.dataSource = new MatTableDataSource<any>(notifs.sort((a, b) => +b.id - +a.id));
            });

    }

    ngOnInit(): any {
    }

    getTypeName(type: string): string {
        return this.notificationsService.getTypeName(type);
    }

    get navBar(): FuseSidebarComponent {
        return this._fuseSidebarService.getSidebar('quickPanel');
    }



    deleteAll(): void {
        this.notificationsService.deleteAllNotifications();
    }

    deleteNotification(row: NotificationInterface, evt: MouseEvent): void {
        row.originalEntity.remove();
        evt.stopPropagation();
        evt.preventDefault();

    }

    onSelect(selected: NotificationInterface): void {
        this.navBar.toggleOpen();

        selected.originalEntity.set('read', true);
        selected.originalEntity.save();
        this.notificationsService.doRouting(selected.originalEntity);
        this.notificationsService.onFileSelected.next(selected);
    }

    goTo(url): void{
        this.closeSideBar();
        this.router.navigate([url]);
    }

    getIcon(type): string {
        const selected = this.getTypeName(type);
        if (!this.iconsType[selected]){
            return this.iconsType['researchSheet'];
        }
        return this.iconsType[selected];
    }

    closeSideBar(): void{
        this._fuseSidebarService.getSidebar('quickPanel').close();
    }
}
