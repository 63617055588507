import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {Subject} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-audio',
    templateUrl: './audio.component.html'
})
export class AudioComponent implements OnInit, OnDestroy {
    @Input() public activityId: any;
    @Input() public contextId: string;
    @Input() questionTypeName: string;

    public questionObject: any;
    public options: any;
    public instruction: string;
    public wording: string;
    public audioUrl: string;
    public imageUrl: string;

    private unsubscribeInTakeUntil = new Subject();

    constructor(
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        private lessonsService: LessonsService
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.activityId) {
                this.activityId = {};
            }

            if (params) {
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activityId[key] = params[key];
                    }
                }
            }
        });
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(() => {
            this.initialize();
        });
    }

    private initialize(): void {
        this.lessonsService.initDebugGrade();

        if (this.activityId && this.activityId['type'] && this.activityId['type'] === 'granule') {
            /*
                todo need to refacto to have only one format of data : DataEntity
            */
            const activity: DataEntity = <DataEntity>this.activityId;
            this.setContentData(activity.get('reference'));
        } else {
            this.activityService.launchActivity((this.activityId))
                .pipe(take(1))
                .subscribe(data => {
                    this.setContentData(data.reference);
                });
        }
    }

    private setContentData(data): void{
        this.questionObject = data;
        this.instruction = this.questionObject.instruction;
        this.wording = this.questionObject.wording;
        this.imageUrl = this.questionObject.activity_content[0].image;
        this.audioUrl = this.questionObject.activity_content[0].media;
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

}
