import {PaginatedCollection} from 'octopus-connect';
import {ModelPaginatedCollectionDto} from '@fuse/adapters/model-paginated-collection-dto';

/**
 * class to extend to use getPaginated method without having to create the method in every classDto
 * it's use with ClassDto that use ModelPaginatedCollectionDto for paginated data
 * to use it extend this class call super() in constructor and set the paginator
 * example in ThemeDto :
 * super()
 * this._modelPaginatedCollectionDto = new ModelPaginatedCollectionDto(data, this);
 * super.setPaginated(this._modelPaginatedCollectionDto);
 */
export class Paginated {
    private modelPaginatedCollectionDto: ModelPaginatedCollectionDto = null;

    /**
     * set the current paginated
     * @param modelPaginatedCollectionDto
     */
    setPaginated(modelPaginatedCollectionDto: ModelPaginatedCollectionDto): void {
        this.modelPaginatedCollectionDto = modelPaginatedCollectionDto;
    }

    /**
     * get the paginated state
     */
    getPaginated(): PaginatedCollection {
        return this.modelPaginatedCollectionDto.getPaginated();
    }
}
