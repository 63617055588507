import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {GenericModalData} from "../generic-modal-data.interface";
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'generic-modal',
    templateUrl: './generic-modal.component.html',
    styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent implements OnInit {

    text: string;
    buttons: {[key: string]: string};

    translations: Object;

    constructor(
        private ref: MatDialogRef<GenericModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: GenericModalData,
        private translation: TranslateService
    ) {}

    ngOnInit() {
        this.text = this.data.text;
        this.buttons = this.data.buttons;

        // translations
        const locKeys: string[] = this.buttonTexts;
        locKeys.push(this.text);

        this.translation.get(locKeys).subscribe((res: Object) => this.translations = res);
    }

    select(id: string) {
        this.ref.close(id);
    }
    
    get ids(): string[] {
        return Object.keys(this.buttons);
    }

    get buttonTexts(): string[] {
        return this.ids.map(key => this.buttons[key]);
    }
}
