import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject, combineLatest, of} from 'rxjs';
import {CollectionOptionsInterface} from 'octopus-connect/lib/models/collection-options.interface';
import {debounceTime, filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {CollectionPaginator, DataEntity} from 'octopus-connect';
import {
    LICENSE_TYPES_KEYS,
    LicenseManagementService,
    LicenseTypes
} from '@modules/groups-management/core/services/license-management-service/license-management.service';
import {User, UserSearchDataEntity} from '@modules/groups-management/core/models/user-search-data-entity';
import {fuseAnimations} from 'fuse-core/animations';
import * as _ from 'lodash';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InstitutionDataEntity} from '@modules/groups-management/core/definitions';
import {EditUserAndLicenseComponent} from '@modules/groups-management/core/license-management/edit-user-and-license/edit-user-and-license.component';
import {Observable} from 'rxjs/index';
import {localizedDate} from '../../../../../shared/utils';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-license-management',
    templateUrl: './license-management.component.html',
    styleUrls: ['./license-management.component.scss'],
    animations: fuseAnimations
})
export class LicenseManagementComponent implements OnInit, OnDestroy {
    public displayedColumns: string[] = []; // colonne à afficher
    public countEntities = 0; // nombre d'utilisateurs recuperé
    public pageIndex = 1; // index de la page en cours
    public pageRange = 100; // nombre d'utilisateurs par page récuperé
    public pageRangeOptions: number[] = [100]; // affichage du nombre d'utilisateurs par page récuperé
    public pending: boolean; // gère le spinner pour l'attente
    public usersInTable: User[]; // liste des utilisateurs
    public usersEntities: DataEntity[] = []; // liste des utilisateurs sous forme de  d'entités
    public filterControls: { // les form-controls des filtres
        name: FormControl;
        licenseType: FormControl;
        startDate: FormControl;
        endDate: FormControl;
    };
    public optionsFilter: CollectionOptionsInterface = { // le filtre qui sert pour la requête (recupérer les utilisateurs)
        filter: {},
        page: 1,
        range: 10
    };
    private paginator: CollectionPaginator; // le paginator d'octopus-connect
    private unsubscribeTakeUntil: Subject<any>; // se désabonné lorsque au ngOnDestroy
    private confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    constructor(private licenseManagementService: LicenseManagementService,
                private dialog: MatDialog,
                private translate: TranslateService,
                @Inject(MAT_DIALOG_DATA) private data: any) {
    }

    ngOnInit(): void {
        this.unsubscribeTakeUntil = new Subject();
        this.initialise();
    }

    ngOnDestroy(): void {
        this.unsubscribeTakeUntil.next();
        this.unsubscribeTakeUntil.complete();
    }

    /**
     * initialise le tableau des utilisateurs et les form controls. Recupere les utilisateurs.
     * @private
     */
    private initialise(): void {
        this.displayedColumns = ['name', 'role', 'license', 'institution', 'endDate', 'buttons'];
        this.loadUsers();
        this.filterControls = {
            name: new FormControl(null),
            licenseType: new FormControl(null),
            startDate: new FormControl(null),
            endDate: new FormControl(null)
        };
        this.filterControls.name.valueChanges.pipe(
            debounceTime(400),
            tap((val) => {
                if (val !== null) {
                    this.optionsFilter.filter.name = val;
                } else {
                    delete this.optionsFilter.filter.name;
                }
            })
        ).subscribe();

        this.filterControls.licenseType.valueChanges.pipe(
            debounceTime(400),
            tap((val) => {
                if (val !== null) {
                    if (val === 'free') {
                        delete this.optionsFilter.filter.licenseType;
                    } else {
                        this.optionsFilter.filter.licenseType = LicenseTypes[val];
                    }
                } else {
                    delete this.optionsFilter.filter.licenseType;
                }
            })
        ).subscribe();

        this.filterControls.startDate.valueChanges.pipe(
            debounceTime(400),
            tap((val) => {
                if (val !== null) {
                    this.optionsFilter.filter.startDate = Math.round(+val.toDate().getTime() / 1000);
                } else {
                    delete this.optionsFilter.filter.startDate;
                }
            })
        ).subscribe();

        this.filterControls.endDate.valueChanges.pipe(
            debounceTime(400),
            tap((val) => {
                if (val !== null) {
                    this.optionsFilter.filter.endDate = Math.round(+val.toDate().getTime() / 1000);
                } else {
                    delete this.optionsFilter.filter.endDate;
                }
            })
        ).subscribe();
    }

    /**
     * recupere la liste des utilisateurs ainsi que les data associés (institutions, etc..)
     * @private
     */
    private loadUsers(): void {
        this.pending = true;
        this.licenseManagementService.getUsersAndPaginator(this.optionsFilter)
            .pipe(
                takeUntil(this.unsubscribeTakeUntil),
                tap((data: { entities: UserSearchDataEntity[], paginator: CollectionPaginator }) => this.paginator = data.paginator),
                tap((data: { entities: UserSearchDataEntity[], paginator: CollectionPaginator }) => this.usersEntities = data.entities.slice()),
                mergeMap(() => this.licenseManagementService.loadAllInstitutions()),
                tap((institutions: InstitutionDataEntity[]) => this.licenseManagementService.institutions = institutions),
                tap(() => this.usersInTable = this.licenseManagementService.associateUserEntitiesWithUserInterface(this.licenseManagementService.usersEntities)),
                tap(() => this.setPaginator()),
                tap(() => this.pending = false)
            )
            .subscribe();
    }

    /**
     * initialise les infos de la pagination
     * @private
     */
    private setPaginator(): void {
        if (this.paginator) {
            this.countEntities = this.paginator.count;
            this.pageIndex = this.paginator.page - 1;
            this.pageRange = this.paginator.range;
        }
    }

    /**
     * ouvre la pop up pour l'edition d'un ustilisateurs
     * @param user
     */
    public edit(user: User): void {
        const refDialog: MatDialogRef<EditUserAndLicenseComponent> = this.dialog.open(EditUserAndLicenseComponent, {
            panelClass: 'edit-user-Component-form-dialog',
            width: '60vw',
            data: {
                user, // utilisateur à editer
                userInstitution: this.getUserInstitution(user), // institution de l'utilisateur
                institutions: this.associateRoleWithRoleMapping(user.roles).includes('director') ?
                    this.licenseManagementService.institutions.filter(i => i.get('license') && i.get('license').type === LicenseTypes.institution)
                    : this.licenseManagementService.institutions, // liste des institutions disponibles
                license: this.getLicense(user), // license actuel de l'utilisateur
                licenseTypes: this.licenseManagementService.licenseTypes, // liste tous les types de licenses
                roles: this.associateRoleWithRoleMapping(user.roles), // les roles de l'utilisateur
                disableOption: (type) => this.disableOption(type, user), // desactive certaines options d'un select selon un certaines conditions.
                disabledFields: this.disabledFields(user), // desactive certains champs du formulaire d'edition selon un certaines conditions.
                getEnumKey: (type) => this.getEnumKey(type) // recupere la key d'un enum (ici sert pour les types de license)
            }
        });
        refDialog.afterClosed().pipe(
            filter((response) => !!response),
            tap(() => this.pending = true),
            mergeMap((response) => this.saveUserData(response)),
            tap(() => this.loadUsers())
        ).subscribe();
    }

    /**
     * sauvegarde de l'utilisateur, on a plusieurs cas differents selon le role, la license de l'utilisateur.
     * @param data
     */
    saveUserData(data: { user: User, name: string, mail: string, password: string, institution?: InstitutionDataEntity | { label: string }, license: string, endDate: number }): Observable<DataEntity> {
        // creation d'un utilisateur
        if (data && !data.user) {
            return this.licenseManagementService.createUser(data);
        }
        // si le type ou la date de péremption de la license à changé au sein de la même institution
        if (((data.license && this.getLicense(data.user) && data.license !== this.licenseType(data.user))
            || (this.getLicense(data.user) && +data.endDate !== this.getLicense(data.user).endDate))
            && this.getUserInstitution(data.user) && data.institution
            && this.getUserInstitution(data.user).id === data.institution['id']) {
            if (this.associateRoleWithRoleMapping(data.user.roles).includes('trainer') && data.license === 'institution') {
                return this.licenseManagementService.editUser(data.user, {role: this.licenseManagementService.roles.director})
                    .pipe(
                        mergeMap(() => this.licenseManagementService
                            .patchLicense(this.getLicense(data.user), {type: data.license, endDate: data.endDate}))
                    );
            }
            return this.licenseManagementService.patchLicense(this.getLicense(data.user), {type: data.license, endDate: data.endDate});
        }

        // si une institution a été crée, on doit créer une license et potentiellement changer le role du client
        if (data.license && data.institution && !data.institution['id']) {
            return combineLatest([
                this.licenseManagementService.createInstitution(data.user, data.institution as { label: string }),
                this.licenseManagementService.createLicense({type: data.license, endDate: data.endDate, userId: data.user.id.toString()})
            ]).pipe(mergeMap(([institution, license]) => {
                const dataToSave: { institution: InstitutionDataEntity, role?: number } = {institution: institution};
                if (this.associateRoleWithRoleMapping(data.user.roles).includes('trainer') && data.license === 'institution') {
                    dataToSave.role = this.licenseManagementService.roles.director;
                }
                return this.licenseManagementService.editUser(data.user, dataToSave);
            }));
        }

        // si l'institution du client à changé pour une autre institution existante (forcement un prof)
        if (data.institution['id']) {
            return this.licenseManagementService.editUser(data.user, {institution: data.institution as InstitutionDataEntity});
        }
        return of(null);
    }

    /**
     * recharge les données en fontion de l'index de page souhaité
     * @param event
     */
    public onPaginateChange(event): void {
        this.paginator.page = event.pageIndex + 1;
    }

    /**
     * associe les id de role passé en  prop avec la map des roles recuperé de l'authentication service
     * permet de recuperer par exemple pour le role "7", le terme "directeur"
     * @param roles
     */
    public associateRoleWithRoleMapping(roles: { [key: string]: string }): string[] {
        const rolesAssociated = [];
        for (const key in roles) {
            const currentRole = _.findKey(this.licenseManagementService.roles, (role) => +role === +key);
            if (currentRole) {
                rolesAssociated.push(currentRole);
            }
        }
        return rolesAssociated;
    }

    /**
     * recupere l'institution d'un l'utilisateur
     * @param user
     */
    public getUserInstitution(user): InstitutionDataEntity {
        const userEntity = this.usersEntities.find((u) => +u.id === +user.id);
        const institutions = userEntity.get('og_user_node') && userEntity.get('og_user_node').filter((item) => item.type === 'Institution');
        if (institutions.length) {
            return this.licenseManagementService.institutions.find((i) => +i.id === +institutions[0].id);
        }
        return null;
    }

    /**
     * recupère le type de license d'un utilisateur
     * @param user
     */
    public licenseType(user: User): string {
        return this.getLicense(user) && this.getLicense(user).type || null;
    }

    /**
     *  recupère la date de fin de la license d'un utilisateur
     * @param user
     */
    public licenseEndDate(user): number | string {
        return this.getLicense(user) && this.getLicense(user).endDate && localizedDate(this.getLicense(user).endDate) || null;
    }

    /**
     *  recupère la license complete d'un utilisateur
     * @param user
     * @private
     */
    private getLicense(user): {
        id: string,
        startDate: number
        endDate: number,
        type: string
    } {
        let license = null;
        if (this.getUserInstitution(user)) {
            const institution = this.licenseManagementService.institutions.find((i) => +i.id === +this.getUserInstitution(user).id);
            if (institution && institution.get('license') && !_.isArray(institution.get('license'))) {
                license = {
                    id: institution.get('license').id,
                    startDate: institution.get('license').startDate,
                    endDate: institution.get('license').endDate,
                    type: LICENSE_TYPES_KEYS.get(institution.get('license').type as LicenseTypes),
                };
            }
        }
        return license;
    }

    /**
     * desactive certaines options d'un select selon un certaines conditions.
     * @param licenseType
     * @param user
     */
    public disableOption(licenseType: string, user: User): boolean {
        // todo use authorisation service to define if we disable option for specific role
        if (user) {
            if (this.associateRoleWithRoleMapping(user.roles).includes('director')) {
                return LicenseTypes[licenseType] !== LicenseTypes.institution;
            }
            return LicenseTypes[licenseType] === LicenseTypes.free
                && this.associateRoleWithRoleMapping(user.roles).includes('trainer')
                && this.licenseType(user) && this.licenseType(user) === 'class';
        }
        return false;
    }

    /**
     * desactive certains champs du formulaire d'edition selon un certaines conditions.
     * @param user
     */
    public disabledFields(user): string[] {
        if (user && this.associateRoleWithRoleMapping(user.roles).includes('director')
            && this.getLicense(user) && this.getLicense(user).type === 'institution') {
            return ['license', 'institution', 'label'];
        }
        // Todo: voir ce que l'on fait avec le role prof pour desactiver certains champs du formulaire
        // si prof avec license class
        /*if (this.associateRoleWithRoleMapping(user.roles).includes('trainer')
            && this.getLicense(user) && this.getUserInstitution(user)) {
            return [];
        }
        // si prof avec license gratuite (pas de license)
        if (this.associateRoleWithRoleMapping(user.roles).includes('trainer')
            && !this.getLicense(user) && !this.getUserInstitution(user)) {
            return [];
        }*/
        return [];
    }

    /**
     * recupere la key d'un enum (ici sert pour les types de license)
     * @param value
     */
    public getEnumKey(value): string {
        return LICENSE_TYPES_KEYS.get(value as LicenseTypes);
    }

    /**
     * recupere une liste tous les types de licenses
     */
    get licenseTypes(): string[] {
        return this.licenseManagementService.licenseTypes || [];
    }


    public createUser(): void {
        const refDialog: MatDialogRef<EditUserAndLicenseComponent> = this.dialog.open(EditUserAndLicenseComponent, {
            panelClass: 'edit-user-Component-form-dialog',
            width: '60vw',
            data: {
                user: null, // creation d'un utilisateur donc pas de d'utilisateur selectionné
                userInstitution: null, // institution de l'utilisateur
                institutions: this.institutionsByRole(), // liste des institutions disponibles
                license: null, // license actuel de l'utilisateur
                licenseTypes: this.licenseManagementService.licenseTypes, // liste tous les types de licenses
                roles: [], // les roles de l'utilisateur
                disableOption: (type) => this.disableOption(type, null), // desactive certaines options d'un select selon un certaines conditions.
                disabledFields: this.disabledFields(null), // desactive certains champs du formulaire d'edition selon un certaines conditions.
                getEnumKey: (type) => this.getEnumKey(type) // recupere la key d'un enum (ici sert pour les types de license)
            }
        });
        refDialog.afterClosed().pipe(
            filter((response) => !!response),
            tap((response) => console.log('license-management.component', response)),
            mergeMap((response) => this.saveUserData(response))
        ).subscribe();
    }

    public institutionsByRole(user?): InstitutionDataEntity[] {
        if (user && this.associateRoleWithRoleMapping(user.roles).includes('director')) {
            return this.licenseManagementService.institutions.filter(i => i.get('license') && i.get('license').type === LicenseTypes.institution);
        } else {
            return this.licenseManagementService.institutions;
        }
    }


    /**
     * open a modal and if confirm locked the user he cannot connect anymore
     * @param userToLock
     */
    public lockUserAccess(userToLock: any): void {
        const data = {
            titleDialog: userToLock.status === '1' ? 'groups-management.insitution.lock.confirm.title' : 'groups-management.insitution.unlock.confirm.title',
            bodyDialog: userToLock.status === '1' ? 'groups-management.institution.lock.confirm.body' : 'groups-management.institution.unlock.confirm.body',
            labelTrueDialog: userToLock.status === '1' ? 'groups-management.institution.lock.confirm.yes' : 'groups-management.institution.unlock.confirm.yes',
            labelFalseDialog: userToLock.status === '1' ? 'groups-management.institution.lock.confirm.no' : 'groups-management.institution.unlock.confirm.no'
        };

        this.translate.get(data.titleDialog).subscribe((translation: string) => data.titleDialog = translation);
        this.translate.get(data.bodyDialog).subscribe((translation: string) => data.bodyDialog = translation);
        this.translate.get(data.labelTrueDialog).subscribe((translation: string) => data.labelTrueDialog = translation);
        this.translate.get(data.labelFalseDialog).subscribe((translation: string) => data.labelFalseDialog = translation);

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {panelClass: 'lock-user-confirm', data});

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.licenseManagementService.getUserById(userToLock.id)
                    .pipe(take(1))
                    .subscribe((user: DataEntity[]) => {
                        if (user) {
                            // active = "1" user is locked = "0"
                            if (user[0].get('status') === '1') {
                                user[0].set('status', '0');
                                userToLock.status = '0';
                            } else {
                                user[0].set('status', '1');
                                userToLock.status = '1';
                            }
                            user[0].save();
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }
}
