import * as _ from 'lodash';

export class ProgressEndpointFilters {
    constructor(
        public date_from: number,
        public date_to: number,
        public id?: number | number[],
        public users?: number | number[]
    ) {
    }

    static isSame = (a: ProgressEndpointFilters, b: ProgressEndpointFilters): boolean => {
        try {
            return a.date_from === b.date_from && a.date_to === b.date_to && _.isEqual(a.id, b.id) && a.users === b.users;
        } catch (e) {
            console.warn(e);
            return false;
        }
    }

    isSame(b: ProgressEndpointFilters): boolean {
        return ProgressEndpointFilters.isSame(this, b);
    }
}
