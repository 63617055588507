import {Observable} from 'rxjs';
import {AdapterDataEntity} from '@fuse/adapters/adapter-data-entity';
import {PaginatedCollection} from 'octopus-connect';
import {IConvert} from '@fuse/adapters/iconvert';
import {map} from 'rxjs/operators';

/**
 * final object class to derive to return data formated and typed from an ocotpus connect PaginatedCollection
 * example to use in theme service where ThemeDto extend ModelPaginatedCollectionDto
 * public getThemes(filterOptions: CollectionOptionsInterface = {filter: {}, page: 1, range: 12}): Observable<IThemeDto[]> {
       return new ThemeDto(this.octopusConnect.paginatedLoadCollection('theme_search', filterOptions)).getObjectDto();
       Not use for moment because of mistake of get('') use in code
 */
export class ModelPaginatedCollectionDto {
    // subscribe on objectDto who is data formated and typed
    private _objectDto: Observable<any[]>;
    private paginatedData: PaginatedCollection;

    /**
     * we pass data, we call the adpaterDto Class to formated data
     * next we type them with current object type
     * @param data : Observable of DataCollection we transform it in data entity array in first time
     */
    constructor(data: PaginatedCollection, classDto: IConvert) {
        try {
            this.paginatedData = data;
            const dataEntity$ = data.collectionObservable.pipe(map(collection => collection.entities));
            // the adapter return a formated object not typed we typed it after getting it and store it
            this._objectDto = <Observable<Object[]>>(new AdapterDataEntity(dataEntity$, classDto).getDataFormated());
        } catch (ex) {
            console.log(ex);
        }
    }

    /**
     * return the object to subscribe
     */
    public objectDto(): Observable<any[]> {
        return this._objectDto;
    }

    /**
     * get the paginated state
     */
    public getPaginated(): PaginatedCollection {
        return this.paginatedData;
    }
}
