import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {MembersSelectionComponent} from './members-selection.component';
import {TranslateModule} from '@ngx-translate/core';
import {AgendaService} from '../agenda.service';
import {SharedModule} from '../../../../shared/shared.module';
import {FuseSharedModule} from 'fuse-core/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    FuseSharedModule
  ],
  declarations: [
    MembersSelectionComponent
  ],
  entryComponents: [
    MembersSelectionComponent
  ],
  exports: [
    MembersSelectionComponent
  ],
  providers: [
    AgendaService
  ]
}) export class MembersSelectionModule {}
