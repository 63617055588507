import {Injectable} from '@angular/core';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '../models/rules';
import {GroupManagementConfigurationService} from './group-management-configuration.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {InstitutionGroup, Learner} from '../definitions';
import {LearnerService} from './learner.service';
import {InstitutionGroupService} from './institution-group.service';
import {GroupService} from '@modules/groups-management/core/services/group.service';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {LicenseDataEntity, LicensesService} from '@modules/groups-management/core/services/licenses.service';

@Injectable({
    providedIn: 'root'
})
export class GroupManagementAuthorizationService {

    private reverseRoleMapping: { [roleNumber: number]: string } = {};

    private aboutInstitutionRules = [
        SyncRules.CreateInstitution,
        SyncRules.DeleteInstitution,
        SyncRules.AccessInstitutionPanel,
        SyncRules.ActivateInstitutionsCode,
        SyncRules.ActivateGroupsCode,
    ];

    private aboutTrainerRules = [
        SyncRules.EditTrainer,
        SyncRules.DeleteTrainer,
        SyncRules.CreateTrainer,
        SyncRules.EditTrainerInstitutionManagerTrainerRights,
    ];


    private aboutGroupRules = [
        SyncRules.CreateGroup,
        SyncRules.EditGroup,
        SyncRules.DeleteGroup,
        SyncRules.ArchiveGroup,
        SyncRules.ActiveMetacognitionOnGroup,
        SyncRules.BeAttachedToGroup,
        SyncRules.SeeOwnGroup,
        SyncRules.SeeAllGroup,
    ];
    private aboutWorkgroupRules = [
        SyncRules.CreateWorkgroup,
        SyncRules.EditWorkgroup,
        SyncRules.DeleteWorkgroup,
        SyncRules.ArchiveWorkgroup,
        SyncRules.BeAttachedToWorkgroup,
        SyncRules.SeeOwnWorkgroup,
        SyncRules.SeeAllWorkgroup,
    ];
    private aboutLearnerRules = [
        SyncRules.CreateLearner,
        SyncRules.EditLearner,
        SyncRules.DeleteLearner
    ];

    private userInstitutions: InstitutionGroup[] = [];

    /**
     * Un prof avec le droit Admin peut :
     * - Créer une nouvelle classe au sein de l'établissement
     * - Créer un nouveau groupe au sein de l'établissement
     * - Créer un nouvel ENSEIGNANT au sein de l'établissement
     * - Créer un nouvel ADMINISTRATEUR au sein de l'établissement // Si t'as le droit d'editer les droits admin, t'as le droit de creer un admin
     * - Créer un nouvel ÉLÈVE au sein de l'établissement
     * - Créer un nouvel ÉLÈVE au sein d'un classe // Le besoin de creer un élève mais pas dans un classe n'existe pas, donc meme droit qu'au dessus
     * - Créer un nouvel ÉLÈVE au sein d'un groupe // idem
     * - Attacher un ÉLÈVE de l'établissement à une classe // Edit group
     * - Attacher un ÉLÈVE de l'établissement à un groupe // Edit WorkGroup
     * - Voir toutes les classes de l'établissement
     * - Voir tous les groupes de l'établissement
     * - Détacher un ÉLÈVE d'une classe // Edit group
     * - Détacher un ÉLÈVE d'un groupe // Edit WorkGroup
     * - Détacher un ÉLÈVE d'un établissement // Edit Institution
     * - Voir la page ÉTABLISSEMENT
     * - Modifier un ENSEIGNANT au sein de l'établissement
     * - Modifier un ADMINISTRATEUR au sein de l'établissement // Le droit de modifier un prof non admin n'existe pas, donc ce droit et le meme que modifier Enseignant
     * - Détacher un ENSEIGNANT au sein de l'établissement // Edit Institution
     * - Détacher un ADMINISTRATEUR au sein de l'établissement // Voir les deux dessus
     * - Activer ou désactiver le code établissement
     * - Activer ou désactiver les codes classe
     */
    private allowedForDirectorsRules = [
        SyncRules.AccessInstitutionPanel,
        SyncRules.ActivateInstitutionsCode,
        SyncRules.ActivateGroupsCode,
        SyncRules.SeeAllGroup,
        SyncRules.CreateGroup,
        SyncRules.EditGroup,
        SyncRules.DeleteGroup,
        SyncRules.CreateWorkgroup,
        SyncRules.EditWorkgroup,
        SyncRules.DeleteWorkgroup,
        SyncRules.SeeAllWorkgroup,
        SyncRules.CreateTrainer,
        SyncRules.EditTrainer,
        SyncRules.EditTrainerInstitutionManagerTrainerRights,
        SyncRules.CreateLearner,
        SyncRules.EditLearner,
        SyncRules.DeleteLearner,
    ];

    private currentUserLicences: LicenseDataEntity[] = [];

    constructor(
        private authorizationService: AuthorizationService,
        private config: GroupManagementConfigurationService,
        private institutionService: InstitutionGroupService,
        private groupService: GroupService,
        private learnerService: LearnerService,
        private communicationCenter: CommunicationCenterService,
        private licensesService: LicensesService
    ) {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionList')
            .subscribe((institutionList: InstitutionGroup[]) => {
                this.userInstitutions = institutionList || [];
            });

        this.communicationCenter.getRoom('authentication')
            .getSubject('roles')
            .subscribe((roleMapping: { [roleIdentifier: string]: number }) => {
                this.reverseRoleMapping = Object.keys(roleMapping).reduce((obj, roleName) => {
                    const roleId = roleMapping[roleName];
                    obj[roleId] = roleName;
                    return obj;
                }, {});
            });

        this.licensesService.currentUserLicenses$.subscribe(licenses => this.currentUserLicences = licenses);
    }

    private atLeastManagerRoles = ['manager', 'administrator'];
    private atLeastDirectorRoles = ['director', ...this.atLeastManagerRoles];
    private atLeastTrainerRoles = ['trainer', ...this.atLeastDirectorRoles];

    activeRulesOnStartup(): void {
        this.aboutTrainerRules.forEach(r => this.authorizationService.addRoleRule(r, this.atLeastDirectorRoles));

        this.authorizationService.addRoleRule(SyncRules.AccessTrainersAndDirectorsManagementPanel, this.atLeastManagerRoles);
        this.authorizationService.addRoleRule(SyncRules.BeAttachedToInstitution, ['trainer', 'learner']);

        if (this.config.doTrainersHaveRestrictiveRights()) {
            this.activeInstitutionGivenRightsTrainersRulesWithLicenses();
        } else {
            this.activeSimplyTrainersRules();
        }

    }

    private activeSimplyTrainersRules(): void {
        [
            ...this.aboutGroupRules,
            ...this.aboutWorkgroupRules,
            ...this.aboutLearnerRules,
            ...this.aboutInstitutionRules
        ].forEach(r => this.authorizationService.addRoleRule(r, this.atLeastTrainerRoles));

    }

    private activeInstitutionGivenRightsTrainersRulesWithLicenses(): void {

        [
            ...this.aboutGroupRules,
            ...this.aboutWorkgroupRules,
            ...this.aboutLearnerRules,
            ...this.aboutInstitutionRules
        ].forEach((rule) => {
            this.authorizationService.addRule(rule, (user, options: any[]) => {
                if (this.userIsDirector(user)) {
                    return this.allowedForDirectorsRules.includes(rule);
                }
                const isAllowedByAnyLicense = this.isRuleAllowedByLicense(rule);
                const isAllowedByAnyRole = this.IsAllowedByManagerOrEduTrainer(rule, user, options);
                return isAllowedByAnyLicense && isAllowedByAnyRole;
            });
        });
    }

    /**
     * Check if the rule il allowed by the specific trainer role in the institution
     * the role need to be applied to the user if the rule is applied to the role
     * In this case return true, else false
     * @private
     */
    private IsAllowedByManagerOrEduTrainer(rule: SyncRules, user: UserDataEntity, options: any[]): boolean {
        /** Un prof avec droit EDU peut :
         - Créer une nouvelle classe au sein de l'établissement
         - Créer un nouveau groupe au sein de l'établissement
         - Être attaché à une classe existante au sein de l'établissement
         - Être attaché à un groupe existant au sein de l'établissement
         - Voir les classes qu'il a crée
         - Voir les groupes qu'il a crée
         - Voir les classes auxquelles il est attaché
         - Voir les groupes auxquels il est attaché
         - Détacher des élèves de ses classes // Compris dans le EditGroup
         - Détacher des élèves de ses groupes // Compris dans le EditWorkGroup
         - Ajouter des élèves de l'établissement à ses classes // Compris dans le EditGroup
         - Ajouter des élèves de l'établissement à ses groupes // Compris dans le EditWorkGroup
         - TODO De plus, si l'autorisation 'code classe', il pourra voir son code classe pour le communiquer à ses élèves
         */
        const allowedForEducatorRules = [
            SyncRules.CreateGroup,
            SyncRules.CreateWorkgroup,
            SyncRules.BeAttachedToGroup,
            SyncRules.BeAttachedToWorkgroup,
            SyncRules.SeeOwnGroup,
            SyncRules.SeeOwnWorkgroup,
            SyncRules.EditGroup,
            SyncRules.EditLearner,
            SyncRules.EditWorkgroup,
        ];

        let asEducatorIamAllowed = false;
        if (this.institutionService.isUserHasEducatorRightsInHisInstitutions(user)) {
            if (rule === SyncRules.EditLearner) {
                if (!options || options.length === 0) {
                    throw new Error('Learner must be pass for testing this rule : ' + SyncRules.EditLearner);
                }
                asEducatorIamAllowed = this.isLearnerInMyGroupOrWorkgroup(user, options[0] as Learner);
            } else {
                asEducatorIamAllowed = allowedForEducatorRules.includes(rule);
            }
        }
        const asInstitutionManagerIamAllowed = this.allowedForDirectorsRules.includes(rule) && this.institutionService.isUserHasManagerRightsInHisInstitutions(user);
        // Ici tu as le droit à partir du moment où l'un de tes roles de donne ce droit. Il n'y a pas de role excluant un droit.
        return asEducatorIamAllowed || asInstitutionManagerIamAllowed;
    }

    private isLearnerInMyGroupOrWorkgroup(educator: UserDataEntity, learner: Learner): boolean {
        const educatorGroups: string[] = educator.get('groups');
        const learnerGroups = learner.groupsWithData;
        const learnerWorkgroups = learner.workgroupsWithData;

        return [...learnerGroups, ...learnerWorkgroups].some(group => educatorGroups.includes(group.id.toString()));
    }

    /**
     * Check if a rule is allowed by the class licence
     * The licence is set on the institution
     * Every user are influenced by the licence (if there is a licence)
     * if there is no class licence, return true
     * @private
     */
    private isRuleAllowedByClassLicense(rule: SyncRules): boolean {
        /** Un prof avec une license classe est bridé * 1 classe * 35 élèves */
        const isUserLimitedByClassLicenseRules = [
            // Pour limiter les classes
            {rule: SyncRules.CreateGroup, resolve: () => this.getUserGroupsLength() < 1},
            // Pour limiter les élèves
            {rule: SyncRules.CreateLearner, resolve: () => this.getUserLearnersLength() < 35},
        ];

        const classLicenceRule = isUserLimitedByClassLicenseRules.find(r => r.rule === rule);
        if (!!classLicenceRule) {
            return classLicenceRule.resolve();
        }
        // Si la règle n'est pas dans les règles de licence, alors la license l'autorise
        return true;
    }

    /**
     * Check if a rule is allowed by the Institution licence
     * The licence is set on the institution
     * Every user are influenced by the licence (if there is a licence)
     * if there is no institution licence, return true
     * @private
     */
    private isRuleAllowedByInstitutionLicense(rule: SyncRules): boolean {
        /** Un prof avec une licence établissement est bridé * 5 admins * 100 profs * 2000 élèves => (donc 2000 classes)*/
        const isUserLimitedByInstitutionsLicenseRules = [
            // Pour limiter les profs de type admins
            {rule: SyncRules.EditTrainerInstitutionManagerTrainerRights, resolve: () => this.getInstitutionAdminTrainersLength() < 5},
            // Pour limiter les profs non admins
            {rule: SyncRules.CreateTrainer, resolve: () => this.getInstitutionNotAdminTrainersLength() < 100},
            // Pour limiter les classes
            {rule: SyncRules.CreateGroup, resolve: () => this.getInstitutionGroupsLength() < 2000},
            // Pour limiter les élèves
            {rule: SyncRules.CreateLearner, resolve: () => this.getInstitutionLearnersLength() < 2000},
        ];

        const institutionLicenceRule = isUserLimitedByInstitutionsLicenseRules.find(r => r.rule === rule);
        // On teste d'abord si la licence bride cette règle
        if (!!institutionLicenceRule) {
            return institutionLicenceRule.resolve();
        }

        // Si la règle n'est pas dans les règles de licence, alors la license l'autorise
        return true;
    }

    private isRuleAllowedByFreeLicense(rule: SyncRules): boolean {
        /** Un prof avec une licence gratuite ne peux pas créer, editer ou supprimer d'eleve ou de group, il est limité ce qu'il a déjà */
        const isUserLimitedByFreeLicenseRules = [
            {rule: SyncRules.CreateLearner, resolve: () => false},
            {rule: SyncRules.EditLearner, resolve: () => false},
            {rule: SyncRules.DeleteLearner, resolve: () => false},
            {rule: SyncRules.CreateGroup, resolve: () => false},
            {rule: SyncRules.EditGroup, resolve: () => false},
            {rule: SyncRules.DeleteGroup, resolve: () => false},
        ];

        const freeLicenceRule = isUserLimitedByFreeLicenseRules.find(r => r.rule === rule);
        // On teste d'abord si la licence bride cette règle
        if (!!freeLicenceRule) {
            return freeLicenceRule.resolve();
        }

        // Si la règle n'est pas dans les règles de licence, alors la license l'autorise
        return true;
    }

    /**
     * Check for each implemented licence if the licence allow the rule.
     * the licence is checked on the current institution
     * if there are no licence, return true
     * if there are a licence but not implemented, return false
     * @private
     */
    private isRuleAllowedByLicense(rule: SyncRules): boolean {

        const hasClassLicense = this.currentUserLicences.some(l => l.get('type') && l.get('type').label === 'Class');
        const hasInstitutionLicense = this.currentUserLicences.some(l => l.get('type') && l.get('type').label === 'Institution');
        const hasFreeLicense = (hasClassLicense || hasInstitutionLicense) === false;

        if (hasClassLicense) {
            return this.isRuleAllowedByClassLicense(rule);
        } else if (hasInstitutionLicense) {
            return this.isRuleAllowedByInstitutionLicense(rule);
        } else if (hasFreeLicense) {
            return this.isRuleAllowedByFreeLicense(rule);
        }

        throw new Error('Should never append');
    }

    private getInstitutionAdminTrainersLength(): number {
        return this.userInstitutions
            .map(i => i.admins.length)
            .reduce((globalSum, institutionSum) => globalSum + institutionSum, 0);
    }

    private getInstitutionNotAdminTrainersLength(): number {
        return this.userInstitutions
            .map(i => i.userscounts.trainers)
            .reduce((globalSum, institutionSum) => globalSum + institutionSum, 0) - this.getInstitutionAdminTrainersLength();
    }

    private getInstitutionLearnersLength(): number {
        return this.userInstitutions
            .map(i => i.userscounts.learners)
            .reduce((globalSum, institutionSum) => globalSum + institutionSum, 0) - this.getInstitutionAdminTrainersLength();
    }

    private getUserGroupsLength(): number {
        return this.groupService.getGroups().length;
    }

    private getUserLearnersLength(): number {
        return (this.learnerService.getLearnerList() || []).length;
    }

    private getInstitutionGroupsLength(): number {
        return (this.learnerService.getLearnerList() || []).length;
    }

    private userIsDirector(user: UserDataEntity): boolean {
        const userRoleNames = user.get('role').map(id => this.reverseRoleMapping[id]).filter(str => !!str).map(str => str.toLowerCase());
        return userRoleNames.includes('director');
    }

}
