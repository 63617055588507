import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CollectionOptionsInterface} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {MatInput} from '@angular/material/input';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

interface Learner {
    username: string;
    id: number;
}

@Component({
    selector: 'app-followed-filter',
    templateUrl: './followed-filter.component.html',
    styleUrls: ['./followed-filter.component.scss']
})
export class FollowedFilterComponent implements OnInit, AfterViewInit {
    public titleFilter = new FormControl();
    public commentFilter = new FormControl();
    public typeFilter = new FormControl();
    public beginDate = new FormControl();
    public endDate = new FormControl();
    public filterWorkgroupActive: string;
    public filterGroupActive: string;
    public customFilter = {
        dueDateControl: new FormControl(''),
        educationalLevelControl: new FormControl(''),
        groupControl: new FormControl('all'),
        startDateControl: new FormControl(''),
        workgroupControl: new FormControl('all'),
        learnerControl: new FormControl(['all']),
    };
    public formControls = {
        title: this.titleFilter,
        type: this.typeFilter,
        comment: this.commentFilter
    };
    @Input() public displayedFilters: string[] = [];
    @Input() public educationalLevels: any[] = [];
    @Input() public allStates: any[] = [];
    @Input() public learnersList: Learner[] = [];
    @Input() public workgroupsList: {
        learnersIds: number[];
        workgroupname: string;
    }[] = [];
    @Input() public groupsList: {
        groupname: string;
        learnersIds: number[];
    }[] = [];
    @Input() public allTypes: any[] = [];
    @Input() public schoolYearsList: any[] = [];
    @Input() public currentSchoolYearBegin: any[] = [];
    @Input() public schoolYearDates: string;
    @Input() public countEntities = 50;
    @Input() public checkDefaultFiltersInUrl = false;
    @Output() public launchSearch = new EventEmitter<any>();
    @ViewChild('typeSelect') public typeSelect: MatSelect;
    @ViewChild('stateSelect') public stateSelect: MatSelect;
    @ViewChild('groupSelect') public groupSelect: MatSelect;
    @ViewChild('workgroupSelect') public workgroupSelect: MatSelect;
    @ViewChild('learnerSelect') public learnerSelect: MatSelect;
    @ViewChild('titleInput') public titleInput: MatInput;
    @ViewChild('commentInput') public commentInput: MatInput;
    public selectableLearners: Learner[];
    private optionsInterface: CollectionOptionsInterface;

    constructor(private authService: AuthenticationService,
                private route: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        this.optionsInterface = {
            filter: this.authService.accessLevel === 'learner' ? {'assignated_user': this.authService.userData.id} : {'assignator': this.authService.userData.id},
            page: 1,
            range: 10
        };

        if (this.learnersList){
            this.selectableLearners = this.learnersList.slice();
        }

        this.titleFilter.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.applyFilters({value: query}, 'assignated_node_title');
            });

        this.commentFilter.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.applyFilters({value: query}, 'comment');
            });

        this.beginDate.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.convertDate(query, 'begin_date');
            });

        this.endDate.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.convertDate(query, 'end_date');
            });

        if (this.displayFilters('schoolyear') && this.schoolYearDates !== '') {
            // default sort to current year
            this.optionsInterface.filter['schoolYearDates'] = this.schoolYearDates;
        }

        let lastValue: ('all' | number)[] = this.customFilter.learnerControl.value;
        this.customFilter.learnerControl.valueChanges.pipe(
            distinctUntilChanged()
        ).subscribe((newValue: ('all' | number)[]) => {
            if (newValue.includes('all')) {
                if (lastValue.includes('all')) {
                    newValue = newValue.filter(v => v !== 'all');
                } else {
                    newValue = ['all'];
                }
            } else {
                if (lastValue.includes('all')) {
                    newValue = [];
                } else {
                    // do nothing
                }
            }

            lastValue = newValue;
            this.customFilter.learnerControl.setValue(lastValue, {emitEvent: false});
        });
    }

    ngAfterViewInit(): void {
        if (this.checkDefaultFiltersInUrl) {
            this.route.queryParams.subscribe(params => {
                const filterLabel = {
                    'title': 'assignated_node_title',
                    'type': 'assignments_type'
                };

                Object.keys(params)
                    .filter(key => Object.keys(this.formControls).includes(key))
                    .forEach((key) => {
                        let value = params[key];
                        // Cas particulier, le "type" est un label, il nous faut l'id
                        if (key === 'type') {
                            value = this.allTypes.find(t => t.label === params[key])['id'];
                        }

                        // Les filtres visibles dans l'ihm (FI) ne sont pas les filtres que l'ont envoie au server (FS).
                        // les FS sont dynamiquement généré dans les FI change
                        // Donc quand on les initialise au démarrage :
                        // Il faut changer initialValues pour les FI
                        this.formControls[key].setValue(value);
                        // Mais aussi optionsInterface.filter pour les FS
                        this.optionsInterface.filter[filterLabel[key]] = value;
                    });
                this.launchSearch.emit(this.optionsInterface);
            });
        } else {
            this.launchSearch.emit(this.optionsInterface);
        }
    }

    public displayFilters(name: string): boolean {
        return this.displayedFilters.includes(name);
    }

    public selectFilter(event, field): void {
        if (event.value === 'all') {
            delete this.optionsInterface.filter[field];
        } else {
            this.optionsInterface.filter[field] = event.value;
        }

        this.blurElementRef(field);
    }

    public selectGroup(event: MatSelectChange): void {
        this.filterSelectableLearners();
    }

    public selectWorkGroup(event: MatSelectChange): void {
        this.filterSelectableLearners();
    }

    public localizedType(type: string): string {
        return `assignment.type.${type}`;
    }

    public localizedState(state: string): string {
        return `assignment.state.${state}`;
    }

    public displaySchoolYear(schoolyear: string): string {
        const nextSchoolyear = +schoolyear + 1;
        return schoolyear + '-' + nextSchoolyear;
    }

    public onLaunchSearchClick(): void {
        this.getFilteredLearnerValues();
        this.applyFilters({value: this.getFilteredLearnerValues()}, 'assignated_user');
        this.launchSearch.emit(this.optionsInterface);
    }

    public isSearchDisabled(): boolean {
        return this.getFilteredLearnerValues().length === 0;
    }

    private convertDate(data: any, type: string): void {
        if (data) {
            // date will be the day just before the choosen one in the picker at 23:00
            this.applyFilters({value: Date.parse(data) / 1000}, type);
        } else {
            this.applyFilters({value: null}, type);
        }
    }

    private blurElementRef(type?: string): void {
        if (this.typeSelect) {
            this.typeSelect.close();
        }
        if (this.stateSelect) {
            this.stateSelect.close();
        }
        if (this.groupSelect) {
            this.groupSelect.close();
        }
        if (this.workgroupSelect) {
            this.workgroupSelect.close();
        }
        if (this.learnerSelect) {
            this.learnerSelect.close();
        }

        if (type === 'comment') {
            this.commentInput.focus();
        } else {
            this.titleInput.focus();
        }
    }

    private applyFilters(event, type): void {
        this.blurElementRef(type);
        if (event.value === 'all') {
            delete this.optionsInterface.filter[type];
        } else {
            this.optionsInterface.filter[type] = event.value;
        }
    }

    private filterSelectableLearners(): void {
        const groupIdentifier = this.customFilter.groupControl.value;
        const selectedGroupLearners = [];
        if (groupIdentifier === 'all') {
            selectedGroupLearners.push(...this.learnersList.map(l => l.id));
        } else {
            const group = this.groupsList.find(g => g.groupname === groupIdentifier);
            selectedGroupLearners.push(...group.learnersIds);
        }

        const workgroupIdentifier = this.customFilter.workgroupControl.value;
        const selectedWorkgroupLearners = [];
        if (workgroupIdentifier === 'all') {
            selectedWorkgroupLearners.push(...this.learnersList.map(l => l.id));
        } else {
            const workgroup = this.workgroupsList.find(g => g.workgroupname === workgroupIdentifier);
            selectedWorkgroupLearners.push(...workgroup.learnersIds);
        }

        this.selectableLearners = this.learnersList.filter(l =>
            selectedWorkgroupLearners.includes(l.id)
            && selectedGroupLearners.includes(l.id)
        );

        this.customFilter.learnerControl.setValue(['all'], {emitEvent: false});
    }

    private getFilteredLearnerValues(): Learner[] {
        const selectLeanerControlValue: ('all' | number)[] = this.customFilter.learnerControl.value;
        const learnerFilterValues = [];
        if (selectLeanerControlValue.includes('all')) {
            learnerFilterValues.push(...this.selectableLearners.map(l => l.id));
        } else {
            learnerFilterValues.push(...selectLeanerControlValue);
        }

        return learnerFilterValues;
    }
}
