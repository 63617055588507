import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NotepadService} from '@modules/notepad/core/notepad.service';
import {DataCollection, DataEntity} from 'octopus-connect';
import {filter, mergeMap, take, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CollectionPaginator} from 'octopus-connect';
import {CollectionOptionsInterface} from 'octopus-connect';
import * as _ from 'lodash';
import {ModalPageComponent} from 'fuse-core/components/basic-page/modal-page/modal-page.component';
import { MatDialog } from '@angular/material/dialog';

/**
 * list of filters. Could be a setting
 */
const filters = ['metadatas_title', 'lessons'];
/**
 * Max items per page
 */
const notepadListRange = 12;

@Component({
    selector: 'app-notepad-list',
    templateUrl: './notepad-list.component.html',
})
export class NotepadListComponent implements OnInit {

    /**
     * Define if component is ready or not
     */
    public isLoadingDone = false;
    /**
     * List of notepad given by BasicSearch endpoint it's not activities/granule but seach/granule entities
     */
    public notepads: DataEntity[] = [];
    /**
     * Should be {@link listBlock} if there are note or {@link emptyBlock}.
     * It's used for dynamically load more than 2 templates (there are here 3 templates: loading, empty and list)
     * Inspired by the angular documentation.
     */
    public thenBlock: TemplateRef<any> | null = null;
    /**
     * Obtain the current page data relative to the pagination (count, page index, etc.)
     */
    public paginator: CollectionPaginator;
    /**
     * list of filters for {@link SearchFiltersComponent}
     */
    public filters: string[] = filters;
    /**
     * List of already associated lessons for the {@link SearchFiltersComponent} already formated and no need to add `all` by default
     * Used to filter current notepads by an associated lesson.
     */
    public alreadyAssociatedLessonsFilterList: { id: string | number, label: string }[] = [];
    /**
     * Notepad listing
     */
    @ViewChild('listBlock', { static: true })
    private listBlock: TemplateRef<any> | null = null;
    /**
     * Empty message for help user to create a notepad
     */
    @ViewChild('emptyBlock', { static: true })
    private emptyBlock: TemplateRef<any> | null = null;
    /**
     * Max number of notepad per page
     */
    public notepadListRange = notepadListRange;

    constructor(private notepadSvc: NotepadService,
                private dialog: MatDialog) {
    }

    /**
     * Go to the notepad creation interface by calling the service and refresh the notepad after it
     * @param $event
     */
    public createNote($event: MouseEvent): void {
        this.notepadSvc.goToNotepadDataCreation()
            .afterClosed()
            .pipe(
                filter(resource => !!resource),
                mergeMap(() => this.refreshNotes())
            )
            .subscribe();
    }

    /**
     * Triggered when a notepad has changed, used to refresh the notepad data.
     *
     * @param _notepad BasicSearch granule of notepad
     */
    public onNotepadChanged(_notepad: DataEntity): void {
        this.refreshNotes().subscribe();
    }

    /**
     * Triggered when user change page index from paginator
     * @param event
     */
    public onPaginateChange(event): void {
        this.paginator.page = event.pageIndex + 1;
        this.refreshNotes().subscribe();
    }

    ngOnInit(): void {
        this.notepadSvc.getAssociatedLessons().pipe(
            tap(lessons => {
                this.alreadyAssociatedLessonsFilterList = lessons.map(entity => ({id: entity.id, label: <string>entity.get('metadatas').title}));
            })
        ).subscribe();
        this.refreshNotes().subscribe();
    }

    /**
     * Set the component in loading and get/load the notes
     * By default, only the authenticated user's note are loaded by the service
     *
     */
    refreshNotes(optionsInterface: CollectionOptionsInterface = {}): Observable<DataCollection> {
        const mergedOptionsInterface = _.merge({
            page: this.paginator ? this.paginator.page : 1,
            range: notepadListRange
        }, optionsInterface);

        this.isLoadingDone = false;
        return this.notepadSvc.getCurrentUserPaginatedNotepads(mergedOptionsInterface).pipe(
            tap(paginatedCollection => this.paginator = paginatedCollection.paginator),
            mergeMap(paginatedCollection => paginatedCollection.collectionObservable),
            tap(dataCollection => {
                this.notepads = [...dataCollection.entities];
                this.thenBlock = this.notepads.length > 0 ? this.listBlock : this.emptyBlock;
                this.isLoadingDone = true;
            }),
            take(1)
        );
    }

    public displayHelpModalPage(): void {
        this.dialog.open(ModalPageComponent, {panelClass: ['help-notepad-dialog', 'wide-dialog'], data: {alias: 'help_notepad'}, });
    }
}
