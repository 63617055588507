import {Injectable} from '@angular/core';
import {CollectionPaginator, DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable, combineLatest} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {filter, map, take, tap} from 'rxjs/operators';
import {Group, Workgroup} from '@modules/groups-management/core/definitions';
import {CollectionOptionsInterface} from 'octopus-connect/lib/models/collection-options.interface';
import {MatDialog} from '@angular/material/dialog';
import {OgUserNodeInterface, User} from '@modules/groups-management/core/models/user-search-data-entity';

@Injectable({
    providedIn: 'root'
})
export class InstitutionUsersService {
    public institution: DataEntity;
    public classes: Group[] = [];
    public workgroups: Workgroup[] = [];
    public roles: { [key: string]: number };

    constructor(private octopusConnect: OctopusConnectService, private communicationCenter: CommunicationCenterService, private dialog: MatDialog) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('roles')
            .pipe(tap((roles: { [key: string]: number }) => this.roles = roles))
            .subscribe();
    }

    public getUsersAndPaginator(filterOptions: CollectionOptionsInterface): Observable<{ entities: DataEntity[], paginator: CollectionPaginator }> {
        const filterOptionByDefault = {
            filter: {
                roles: this.roles.trainer,
                groups: this.institution && this.institution.id || null
            },
            page: 1,
            range: 10

        };

        const activitiesPaginated = this.octopusConnect.paginatedLoadCollection('user_search', filterOptions || filterOptionByDefault);
        const activitiesPaginatedObs = activitiesPaginated.collectionObservable;
        return activitiesPaginatedObs.pipe(
            map(collection => collection.entities),
            map((entities: DataEntity[]) => ({entities, paginator: activitiesPaginated.paginator}))
        );
    }

    public reloadClasses(force): void {

    }

    public saveUserRoleInInstitution(data: { [key: string]: any }, userEntity): Observable<DataEntity> {
        let usersWithRoles: { [key: string]: any }[];
        const userRolesToSave = {
            uid: userEntity.id,
            roles: data.roles
        };
        if (this.institution.get('admins').some((userRole) => +userRole.uid === +userRolesToSave.uid)) {
            usersWithRoles = this.institution.get('admins').slice().map((user) => {
                if (+user.uid === userRolesToSave.uid) {
                    return userRolesToSave;
                }
                return user;
            });
        } else {
            usersWithRoles = [...this.institution.get('admins').slice(), userRolesToSave];
        }

        this.institution.set('admins', usersWithRoles);
        return this.institution.save(true);
    }

    public saveUser(data: { [p: string]: any }, user): Observable<DataEntity> {
        if (user) {
            user.set('groups', [this.institution.id, ...data.groups.map((group) => group.id)]);
            user.type = 'users';
            return user.save(true);
        } else {
            // new trainer (educator)
            const trainerData = {
                username: data.name,
                password: data.password,
                groups: [this.institution.id, ...data.groups.map((group) => group.id.toString())],
                type: 'educator',
                email: data.mail
            };
            return this.octopusConnect.createEntity('trainers', trainerData);
        }
    }

    public loadInstitutionAndGroup(): Observable<[DataEntity[], Group[], Workgroup[]]> {
        const institutions$: Observable<DataEntity[]> = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionEntitiesList')
            .pipe(
                tap((institutions: Array<DataEntity>) => this.institution = institutions[0]),
            );
        const classes$: Observable<Group[]> = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList')
            .pipe(
                tap((classes: Group[]) => {
                    if (this.institution) {
                        this.classes = classes
                            .filter((classItem) => classItem.parent && +classItem.parent.id === +this.institution.id);
                    }
                }),
            );
        const workgroups$: Observable<Workgroup[]> = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList')
            .pipe(
                tap((workgroups: Workgroup[]) => {
                    if (this.institution) {
                        this.workgroups = workgroups
                            .filter((workgroupItem: Workgroup) => workgroupItem.parent && +workgroupItem.parent.id === +this.institution.id);
                    }

                }),
            );

        return combineLatest([institutions$, classes$, workgroups$]);
    }

    public associateUserWithRole(userEntities: DataEntity[]): User[] {
        return userEntities.map((userEntity: DataEntity) => {
            const userInInstitution = this.institution.get('admins').find((u) => +u.uid === +userEntity.id);
            return {
                id: +userEntity.id,
                name: userEntity.get('name'),
                mail: userEntity.get('mail'),
                og_user_node: userEntity.get('og_user_node')
                    .filter((group) => [...this.classes, ...this.workgroups]
                        .some((groupItem) => +groupItem.id === +group.id)),
                roles: userEntity.get('roles'),
                rolesInInstitution: userInInstitution && userInInstitution.roles || null
            };
        });
    }

    /**
     * return an array with that contain the user corresponding to the id pass
     * @param idUser
     */
    public getUserById(idUser: string): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('users', {id: idUser})
            .pipe(map(collection => collection.entities));
        // tslint:disable-next-line:no-shadowed-variable
    }
}
