
import {take, map} from 'rxjs/operators';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ResourceCreationSteps} from '@modules/corpus/core/resource-creation-steps.class';
import {CorpusMetadataInterface} from '@modules/corpus';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {CorpusRessourcesTypes} from '@modules/corpus/core/corpus-ressources-types.class';
import {Observable} from 'rxjs';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {HttpClient} from '@angular/common/http';
import {defaultApiURL} from '../../../../../settings';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {AccountManagementProviderService} from '@modules/account-management';
import {DataEntity} from 'octopus-connect';
// TODO virer ça, on appelle pas un autre module directement
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {Router} from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {FormControl, FormGroup} from '@angular/forms';


@Component({
    selector: 'app-divider-upload-modal',
    templateUrl: './divider-upload-modal.component.html',
    styleUrls: ['./divider-upload-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DividerUploadModalComponent implements OnInit, OnDestroy {

    public currentUploadPhase: ResourceCreationSteps;
    public form: FormGroup;
    public files: NgxFileDropEntry[] = [];
    public mode = 'creation';
    public param: object;
    public uploadedFileId: number;
    public uploadedFileLabel: string;
    public uploading = false;
    public editor;
    public editorConfig = {
        toolbar: [
            'bold', 'italic', 'underline',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'undo',
            'redo'
        ]
    };

    private _urlFileUpload: string = defaultApiURL + 'api/file-upload';
    private detailsValidated;
    private resource: CorpusRessource;
    public isPngTooBig: boolean = false;

    constructor(
        private ref: MatDialogRef<DividerUploadModalComponent>,
        private corpusService: CorpusService,
        private http: HttpClient,
        private accountManagementProvider: AccountManagementProviderService,
        private changeDetector: ChangeDetectorRef,
        private lessonsService: LessonsService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {
    }

    public ngOnInit(): void {
        this.form = new FormGroup({
            title:  new FormControl(''),
            description:  new FormControl('')
        });

        this.mode = this.data.mode;
        this.param = {
            format: this.corpusService.settings.formatImg,
            size: this.corpusService.settings.fileMaxSize
        };

        if (this.mode === 'edition') {
            this.resource = new CorpusRessource(this.data.divider, this.corpusService);
        }

        if (this.mode === 'edition' && this.resource) {
            this.form.get('title').setValue(this.resource.title);
            this.form.get('description').setValue(this.resource.description);
            if (this.data.divider.attributes.reference) {
                this.uploadedFileId = +this.data.divider.attributes.reference.id;
                this.uploadedFileLabel = this.data.divider.attributes.reference.filename;
            }
        }

        this.editor = ClassicEditor;
    }

    public deleteFile(fileId: number): void {
        this.http
            .delete<any>(this._urlFileUpload + '/' + fileId, {headers: {'access-token': this.accountManagementProvider.userAccessToken}})
            .subscribe(res => {
                this.uploadedFileId = undefined;
                this.changeDetector.detectChanges();
            });
    }

    public dropped(files: NgxFileDropEntry[]): void {
        this.files = files;
        for (const droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    this.uploadFile(file);
                });
            }
        }
    }

    public formValid(): boolean {
        return this.form &&
            this.form.get('title') &&
            this.form.get('title').value !== '' &&
            this.form.get('description') &&
            this.form.get('description').value !== '' || !!this.uploadedFileId;
    }

    public ngOnDestroy(): void {
        this.changeDetector.detach();
    }

    public onFileChanged(evt: Event): void {
        this.uploadFile(evt.target['files'][0]);
    }

    public reset(): void {
        this.currentUploadPhase = ResourceCreationSteps.STEP1;
        this.changeDetector.detectChanges();
    }

    public validateDetails(): void {
        if (this.mode === 'edition' && this.resource) {
            if (this.uploadedFileId) {
                const formData = {'reference': +this.uploadedFileId};
                this.http
                    .patch<any>(defaultApiURL + 'api/granule/' + this.resource.id, formData, {headers: {'access-token': this.accountManagementProvider.userAccessToken}})
                    .subscribe(res => {
                        // update Corpus ressource
                        this.resource.title = this.form.get('title').value;
                        this.resource.description = this.form.get('description').value;
                        this.resource.referenceEntity = res.reference;
                        // update Corpus ressource ressourceEntity
                        this.resource.ressourceEntity.attributes.metadatas.title = this.form.get('title').value;
                        this.resource.ressourceEntity.attributes.metadatas.description =this.form.get('description').value;
                        this.resource.ressourceEntity.attributes.reference = res.reference;
                        this.resource.save().subscribe((resource) => {
                            this.lessonsService.setActivitiesOfSelectedLesson([this.resource.ressourceEntity]);
                        });
                        /* on attache le fichier media */

                    });
            } else {
                // update Corpus ressource
                this.resource.title = this.form.get('title').value;
                this.resource.description = this.form.get('description').value;
                // update Corpus ressource ressourceEntity
                this.resource.ressourceEntity.attributes.metadatas.title = this.form.get('title').value;
                this.resource.ressourceEntity.attributes.metadatas.description = this.form.get('description').value;

                this.resource.save().subscribe((resource) => {
                    this.lessonsService.setActivitiesOfSelectedLesson([this.resource.ressourceEntity]);
                });
            }

            this.changeDetector.detectChanges();
            document.body.focus();
            this.ref.close();

        } else {
            this.detailsValidated = {
                description: this.form.get('description').value,
                language: 'fr',
                title: this.form.get('title').value,
            };
            this.onDetailsValidated(this.detailsValidated);
        }
    }

    private onDetailsValidated(metadata: CorpusMetadataInterface): void {
        this.changeDetector.detectChanges();

        let obs: Observable<DataEntity>;
        if (this.formValid()) {
            if (this.uploadedFileId) {
                obs = this.corpusService.createRessource(CorpusRessourcesTypes.DIVIDER, null, this.uploadedFileId, metadata).pipe(map(resource => resource.ressourceEntity));
            } else {
                obs = this.corpusService.createRessource(CorpusRessourcesTypes.DIVIDER, null, null, metadata).pipe(map(resource => resource.ressourceEntity));
            }
            obs.pipe(take(1)).subscribe((resource: DataEntity) => {
                this.lessonsService.setActivitiesOfSelectedLesson([resource]);
                this.changeDetector.detectChanges();
                document.body.focus();
                this.ref.close();
            }, () => {
                this.currentUploadPhase = ResourceCreationSteps.FAILED;
            });
        } else {
            console.log('required fields are missing');
        }
    }

    private uploadFile(file: File): void {
        if (this.isFileSizeTooBig(file)){
            return;
        }

        if (this.corpusService.filesWhiteList.indexOf(file.type) !== -1) {
            const formData = new FormData();
            formData.append('file', file);

            this.uploading = true;
            this.changeDetector.detectChanges();

            this.http
                .post<any>(this._urlFileUpload, formData, {headers: {'access-token': this.accountManagementProvider.userAccessToken}})
                .subscribe(res => {
                    this.uploading = false;
                    this.uploadedFileId = res['data'][0][0]['id'];
                    this.uploadedFileLabel = res['data'][0][0]['label'];
                    this.changeDetector.detectChanges();
                });
        }
    }

    private isFileSizeTooBig(file: File): boolean {
        this.isPngTooBig = file.size > +(this.corpusService.settings.fileMaxSize + '000000');
        return this.isPngTooBig;
    }
}

