import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {ActivatedRoute} from '@angular/router';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {EntityDataSet} from 'octopus-connect';
import {ReplaySubject} from 'rxjs';
import {take, tap} from 'rxjs/operators';

@Component({
  selector: 'app-multimedia',
  templateUrl: './multimedia.component.html'
})
export class MultimediaComponent implements OnInit {
    private activity = {
        id: null,
        isLoadBeforeLaunch: null
    };
    public isInitializing: boolean;
    public resources: any[];
    public title: string;
    public instruction: string;
    public wording: string;
    @Input() public activityInput?: DataEntity;
    @Input('refresh') refresh?: ReplaySubject<DataEntity>;
    constructor(
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        private changeDetector: ChangeDetectorRef,
        private lessonsService: LessonsService,
        private octopusConnect: OctopusConnectService,
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (params && !this.activityInput) {
                this.activity.id = params.id;
                this.activity.isLoadBeforeLaunch = params.isLoadBeforeLaunch;
            }
        });
    }

    ngOnInit(): any {
        if (this.activityInput) {
            this.initialize();
        } else {
            this.activatedRoute.params.subscribe(() => {
                this.initialize();
            });
        }


        /** this.refresh is a replaySubject that is "next" when we want to reset the qcu component
         * used when we start sub lesson in activities list, cf initialize()
         * */
        if (this.refresh) {
            this.refresh.subscribe((data) => {
                if (data && (data.get('ghost') && data.get('type') === 'MULTI') ||
                    (data.get('metadatas').typology && data.get('metadatas').typology.label === 'MULTI')) {
                    this.activityInput = data;
                    this.initialize();
                }
            });
        }
    }

    /**
     * Initializes the current component
     */
    private initialize(): void {
        this.reset();

        if (this.activityInput) {
            this.setActivity(this.activityInput.attributes);
        } else {
            this.activityService.launchActivity(this.activity).pipe(
                take(1),
                tap((data: EntityDataSet) => {
                    if (data) {
                        this.setActivity(data);
                    }
                })
            ).subscribe();
        }
    }

    private setActivity(data: EntityDataSet): void {
        if (this.activityInput && this.activityInput.get('ghost')) {
            // TODO get title, instruction or wording from editable activity
            this.instruction = data.instruction;
            this.wording = data.instruction;

            this.resources = data.page.map((val: {type: string, media: any}) => {
                if (val.type === 'text' && typeof val.media === 'string') {
                    const attributes = {
                        format: {
                            label: val.type
                        },
                        reference: {
                            text: val.media
                        }
                    };
                    return new DataEntity('granule', attributes, this.octopusConnect);
                } else {
                    return val.media;
                }
            });
        } else  {
            this.title = data.metadatas.title;
            this.instruction = data.reference.instruction;
            this.wording = data.reference.wording;

            this.resources = this.getGranule(data.reference.activity_content[0]);
        }
        this.isInitializing = false;
    }

    /**
     * Extract the granule's DataEntity from the given activityContent data
     * @param activityContent - field from activity
     * @returns granule from the content as a DataEntity
     */
    private getGranule(activityContent: any): DataEntity[] {
        if (activityContent && activityContent.granule && activityContent.granule.length) {
            const resources = activityContent.granule.filter((media) => !!media).map((granule) => {
                if (granule && granule.attributes) {
                    return granule;
                }
                return new DataEntity('granule', granule, this.octopusConnect, granule.id);
            });

            return resources;
        }

        return [];
    }

    /**
     * Resets the current component
     */
    private reset(): void {
        this.isInitializing = true;
        delete this.instruction;
        delete this.wording;
        this.changeDetector.detectChanges();
    }

    public videoEmbedUrl(video): any {
        const videoConfig = {
            src: video.get('reference').url,
        };
        if  (this.activityService.settings.urlVideoException.some((urlException) => video.get('reference').url.includes(urlException))) {
            videoConfig['type'] = 'video/mp4';
        }
        return {
            config: videoConfig,
        };
    }

    public get showTitle(): boolean {
        // on affiche le titre de l'activité seulement
        return this.activityService.settings.typeActivitiesToSkip && this.activityService.settings.typeActivitiesToSkip.length && !this.activityInput;
    }

}
