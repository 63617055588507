import {debounceTime, distinct, distinctUntilChanged, filter, map, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataVisualizationService} from '../../services/data-visualization.service';
import {CollectionOptionsInterface} from 'octopus-connect';
import {combineLatest, merge, Observable, Subject, Subscription} from 'rxjs';
import {Learner} from '@modules/groups-management/core/definitions';
import {OctopusConnectService} from 'octopus-connect';
import {FormControl} from '@angular/forms';
import {DateAdapter} from '@angular/material/core';
import {TranslateService} from '@ngx-translate/core';
import {TypedDataEntityInterface} from '../../../../../shared/models/octopus-connect/typed-data-entity.interface';
import {GraphConfig} from 'fuse-core/components/graph/graph-mixed/graph.config';

type AssignmentGroupType = TypedDataEntityInterface<{
    assignated_node: { title: string } | null,
    grade: number | []
}>;

@Component({
    selector: 'app-data-visualization',
    templateUrl: './data-visualization.component.html',
    styleUrls: ['./data-visualization.component.scss']
})
export class DataVisualizationComponent implements OnInit, OnDestroy {

    public displayedFilters: string[] = [];
    public graphConfig$: Subject<GraphConfig> = new Subject();
    public startDateControl = new FormControl('');
    public dueDateControl = new FormControl('');
    public learnerControl = new FormControl('');
    public minDueDate: Date;
    public maxStartDate: Date;
    private optionsInterface: CollectionOptionsInterface = {
        filter: {},
        page: 1,
    };
    private unsubscribeTakeUntil = new Subject();

    constructor(
        private graphService: DataVisualizationService,
        private octopusConnect: OctopusConnectService,
        private adapterLocalDate: DateAdapter<any>,
        private translate: TranslateService
    ) {

    }

    public get learners(): Learner[] {
        if (!!this.graphService.learnerList) {
            return this.graphService.learnerList.sort((a, b) => a.nickname.localeCompare(b.nickname));
        }

        return [];
    }

    public get isUserTrainer(): boolean {
        return this.graphService.isUserTrainer;
    }

    ngOnInit(): any {
        const onStartDateChanges$ = this.startDateControl.valueChanges.pipe(
            distinctUntilChanged(),
            tap((data) => {
                if (data) {
                    this.applyFilters(data.format('X'), 'date_from');
                    this.minDueDate = new Date(+data.format('x'));
                } else {
                    this.applyFilters(null, 'date_from');
                    this.minDueDate = null;
                }
            })
        );

        const onDueDateChanges$ = this.dueDateControl.valueChanges.pipe(
            distinctUntilChanged(),
            tap((data) => {
                if (data) {
                    const newDate = new Date(+data.format('x'));
                    newDate.setHours(23);
                    newDate.setMinutes(59);
                    this.applyFilters(newDate.getTime() / 1000, 'date_to');
                    this.maxStartDate = new Date(+data.format('x'));
                } else {
                    this.applyFilters(null, 'date_to');
                    this.maxStartDate = null;
                }
            })
        );

        const onLearnerChanges$ = this.learnerControl.valueChanges.pipe(
            distinctUntilChanged(),
            filter(learner => !!learner),
            tap((learner: Learner) => this.applyFilters(learner.id, 'assignated_user'))
        );

        const onFiltersChanges$ = [onStartDateChanges$, onDueDateChanges$];

        this.graphService.serviceIsReady$.pipe(
            takeUntil(this.unsubscribeTakeUntil),
            filter(isReady => !!isReady)
        ).subscribe(() => {
            if (this.graphService.graphSettings) {
                if (this.isUserTrainer) {
                    this.displayedFilters = this.graphService.graphSettings['default'];
                    this.optionsInterface.filter['assignator'] = this.graphService.getUserId();
                    const defaultLearner = this.learners[0];
                    this.learnerControl.setValue(defaultLearner);
                    this.optionsInterface.filter['assignated_user'] = defaultLearner.id;
                    onFiltersChanges$.push(onLearnerChanges$);
                } else {
                    this.displayedFilters = this.graphService.graphSettings['learner'];
                    this.optionsInterface.filter['assignated_user'] = this.graphService.getUserId();
                }
            }

            merge(...onFiltersChanges$).pipe(
                debounceTime(1000),
                distinctUntilChanged(),
                mergeMap(() => this.generateGraph())
            ).subscribe();

            this.generateGraph().pipe(take(1)).subscribe();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeTakeUntil.next();
        this.unsubscribeTakeUntil.complete();
    }

    generateGraph(): Observable<GraphConfig> {
        return this.graphService.loadPaginatedAssignments$(this.optionsInterface.filter).pipe(
            take(1),
            map(collection => collection.entities),
            map((assignmentGroupList: AssignmentGroupType[]) => this.getGraphConfig(assignmentGroupList)),
            tap(config => this.graphConfig$.next(config))
        );
    }

    public displayFilters(name: string): boolean {
        return this.displayedFilters.includes(name);
    }

    applyFilters(val, type): void {
        if (val === 'all' || !val) {
            delete this.optionsInterface.filter[type];
        } else {
            this.optionsInterface.filter[type] = val;
        }
    }

    getGraphConfig(assignmentGroupList: AssignmentGroupType[]): GraphConfig {
        return {

            chartLabels: assignmentGroupList
                .filter(entity => !!entity.get('assignated_node'))
                .map((entity) => entity.get('assignated_node').title).slice(0, 5),
            chartData: [
                {
                    type: 'line',
                    data: assignmentGroupList.map((entity) => entity.get('grade')).slice(0, 5),
                    label: 'Progression'
                }
            ],
            chartColors: [],
            chartConfig: {
                scales: {
                    xAxes: [{
                        stacked: true,
                        gridLines: {
                            display: false,
                        }
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            precision: 0
                        },
                        type: 'linear',
                    }]
                },
                legend: {
                    align: 'start',
                    position: 'bottom',
                    onClick: (event: MouseEvent) => event.stopPropagation()
                },
                aspectRatio: 2.5
            },
            modalContent: [
                assignmentGroupList
                    .filter(entity => !!entity.get('assignated_node'))
                    .map((entity) => ({
                        header: entity.get('assignated_node').title,
                        content: '', // JSON.stringify(entity.get('assignated_node')) En attendant de savoir
                    }))
            ],
        };
    }
}
