import {Component, ComponentFactoryResolver, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {EditableActivity} from '@modules/activities/core/lessons/editor/models/editable-activity.class';
import {OneLineActivityComponent} from '@modules/activities/core/lessons/editor/components/editable-activities/one-line-activity/one-line-activity.component';
import {ActivityComponentInterface} from '@modules/activities/core/lessons/editor/models/activity-component.interface';
import {tap} from 'rxjs/operators';
import {MultiLineActivityComponent} from '@modules/activities/core/lessons/editor/components/editable-activities/multi-line-activity/multi-line-activity.component';
import {EditableMultimediaActivity} from '@modules/activities/core/lessons/editor/models/editable-multimedia-activity.class';
import {ReplaySubject} from 'rxjs';
import {MultimediaPage} from '@modules/activities/core/lessons/editor/models/multimedia-page.class';

@Component({
    selector: 'app-editable-activities-list',
    templateUrl: './editable-activities-list.component.html',
})
/**
 * This is a factory (the design pattern) able to generate a list a component defined by the type of an {@link EditableActivity}
 */
export class EditableActivitiesListComponent implements OnChanges {

    /**
     * List of activity to display
     */
    @Input() activities: EditableActivity[] = [];
    @Input() toggle: ReplaySubject<EditableActivity>;
    @Output() delete = new EventEmitter<EditableActivity>();
    @Output() edit = new EventEmitter<EditableActivity>();
    @Output() play = new EventEmitter<EditableActivity>();
    @Output() editChild = new EventEmitter<{activity: EditableActivity, child: MultimediaPage}>();
    @Output() playChild = new EventEmitter<{activity: EditableActivity, page: MultimediaPage}>();
    @Output() addChild = new EventEmitter<EditableMultimediaActivity>();
    @Output() jumpBelow = new EventEmitter<EditableActivity>();
    /**
     * Container of the activity list
     * @private
     */
    @ViewChild('container', { read: ViewContainerRef, static: true }) private container: ViewContainerRef;
    /**
     * Mapping between an editableActivity type and an activity component
     * @private
     */
    private readonly templateMapper = {
        'default': OneLineActivityComponent,
        'MULTI': MultiLineActivityComponent
    };

    constructor(private componentFactoryResolver: ComponentFactoryResolver
    ) {
    }

    ngOnChanges(): void {
        this.generateActivitiesComponent();
    }

    /**
     * Return the Component for an activity type, if an activity type have not a specific component, it's return the default value
     * @param activityType string referenced in {@link templateMapper} if it's associated to a specific component.
     * @private
     */
    private getComponentForCardType(activityType: string): any {
        if (this.templateMapper.hasOwnProperty(activityType)) {
            return this.templateMapper[activityType];
        }

        return this.templateMapper['default'];
    }

    /**
     * Execute the activities factory for each activity
     * @private
     */
    private generateActivitiesComponent(): void {
        this.container.clear();
        this.activities.forEach(activity => this.generateActivityComponent(this.container, activity));
    }

    /**
     * Create a instance of a specific component matching the activity type
     * @param viewContainerRef Container of the activity
     * @param activity Activity to instantiate
     * @private
     */
    private generateActivityComponent(viewContainerRef: ViewContainerRef, activity: EditableActivity): void {
        // Get the class for the activity
        const componentClass = this.getComponentForCardType(activity.type);
        // Get the factory of this class
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
        // Instantiate the component defined by the factory inside a container
        const componentRef = viewContainerRef.createComponent(componentFactory);
        // Duplicate reference as an ActivityComponentInterface
        const activityComponent = (<ActivityComponentInterface>componentRef.instance);
        // Give to the instance of component the default inputs
        activityComponent.editableActivity = activity;
        activityComponent.toggle = this.toggle;
        activityComponent.canJumpBelow = this.activities.indexOf(activity) !== this.activities.length - 1;

        // Connect parent to the instance outputs
        activityComponent.jumpBelow.pipe(
            tap(toJumpBelowActivity => this.jumpBelow.next(toJumpBelowActivity))
        ).subscribe();
        activityComponent.delete.pipe(
            tap(toDeleteActivity => this.delete.next(toDeleteActivity))
        ).subscribe();
        activityComponent.edit.pipe(
            tap(toEditActivity => this.edit.next(toEditActivity))
        ).subscribe();
        activityComponent.play.pipe(
            tap(toPlayActivity => this.play.next(toPlayActivity))
        ).subscribe();

        if (activityComponent.hasOwnProperty('addChild')) {
            activityComponent.addChild.pipe(
                tap(addChildrenActivity => this.addChild.next(<EditableMultimediaActivity>addChildrenActivity))
            ).subscribe();
        }

        if (activityComponent.hasOwnProperty('editChild')) {
            activityComponent.editChild.pipe(
                tap(toEditChildActivity => this.editChild.next({activity, child: toEditChildActivity}))
            ).subscribe();
        }

        if (activityComponent.hasOwnProperty('playChild')) {
            activityComponent.playChild.pipe(
                tap(toPlayChildActivity => this.playChild.next({activity, page: <MultimediaPage>toPlayChildActivity}))
            ).subscribe();
        }
    }
}
