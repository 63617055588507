import { Component, OnInit } from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {IdeasWallService} from '../ideas-wall.service';
import {ActivatedRoute, Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {MatDialogConfig} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-ideas-walls-list',
    templateUrl: './ideas-walls-list.component.html',
    styleUrls: ['./ideas-walls-list.component.scss']
})
export class IdeasWallsListComponent implements OnInit {

    walls: DataEntity[];
    public displayHeader = true;

    public get displayCreateWallHelper(): boolean {
        return this.wallsService.settings.displayCreateWallHelper;
    }
    constructor(
        public wallsService: IdeasWallService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.displayHeader = this.wallsService.settings.displayHeader;
        this.wallsService.loadAndStoreIdeasWalls().subscribe(entities => this.walls = entities);
    }

    createWall(): void {
        this.wallsService.createIdeasWall();
    }

    goToWall(wall: DataEntity): void {
        this.router.navigate(['../' + wall.id + '/edit'], {
            relativeTo: this.route
        });
    }

    deleteWall(wall: DataEntity): void {
        const dialogConfig = new MatDialogConfig();
        let dialogYes = '';
        let dialogNo = '';
        let dialogDelete = '';

        this.translate.get('generic.yes').subscribe((translation: string) => dialogYes = translation);
        this.translate.get('generic.no').subscribe((translation: string) => dialogNo = translation);
        this.translate.get('generic.delete').subscribe((translation: string) => dialogDelete = translation);

        dialogConfig.data = {
            bodyDialog: dialogDelete,
            labelTrueDialog: dialogYes,
            labelFalseDialog: dialogNo
        };

        this.dialog.open(FuseConfirmDialogComponent, dialogConfig).afterClosed().subscribe((result) => {
            if (result) {
                wall.remove();
            }
        });
    }

    isMine(wall: DataEntity): boolean {
        return +wall.get('uid') === +this.wallsService.currentUser.id;
    }

}
