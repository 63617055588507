
import {take} from 'rxjs/operators';
import {DataEntity} from 'octopus-connect';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {Observable, combineLatest} from 'rxjs';
import {CorpusService} from '@modules/corpus';
import {CorpusRessource} from "@modules/corpus/core/corpus-ressource.class";
import {Corpus} from "@modules/corpus/core/corpus.class";

export class CorpusSet {

    public spaces: {[key: string]: DataEntity} = {};
    public corpusArray: Corpus[] = [];
    public id: number;
    public project: string;

    constructor(
        private dynamicMenu: DynamicNavigationService,
        private corpusService: CorpusService,
        private setEntity: DataEntity
    ) {
        // this.loadSpaces();
        this.id = +setEntity.id;
        this.project = setEntity.get('project');

        (<DataEntity[]>setEntity.getEmbed('corpus-list')).forEach(entity => {
            this.spaces[entity.get('name')] = entity;
            this.corpusArray.push(new Corpus(this.corpusService, entity));
        });
    }

    public getCorpusResources(corpusName: string): Observable<CorpusRessource[]> {
      return this.corpusService.loadCorpusRessources();
    }

    loadSpaces(): Observable<DataEntity[]> {
        const obsList: Observable<DataEntity>[] = [];

        this.setEntity.get('corpus-list').forEach(id => {
            const obs: Observable<DataEntity> = this.corpusService.loadCorpus(id).pipe(take(1));
            obsList.push(obs);

            obs.subscribe(entity => {
                this.spaces[entity.get('name')] = entity;
            });
        });

        return combineLatest(...obsList);
    }

    activateMenu() {
        const menuItems: Object[] = [];

        if (this.spaces) {
            for (const key in this.spaces) {
                if (this.spaces.hasOwnProperty(key)) {
                    menuItems.push({
                        'id'   : key,
                        'title': key,
                        'translate': key,
                        'type' : 'item',
                        'url'  : '/corpus/' + this.spaces[key].id
                    });
                }
            }
        }

        this.dynamicMenu.setChildren('level0', 'corpus', menuItems);
    }

    remove() {
        this.setEntity.remove();

        if (this.spaces) {
          for (const key in this.spaces) {
            if (this.spaces.hasOwnProperty(key)) {
              this.spaces[key].remove();
            }
          }
        }
    }
}
