export const graphAssignation = {
    progression: {
        charts: [
            {
                label: 'graph.advancement_title',
                chartData: {
                    color: ['#A6A6A6', '#1E726E', '#15213F'],
                    labels: ['graph.advancement_assigned', 'graph.advancement_pending', 'graph.advancement_finished']
                },
                details: {
                    headers: [
                        {id: 'learner', label: 'graph.progression_learners_list'},
                        {id: 'success', label: 'graph.advancement_title'}
                    ]
                }
            },
            {
                label: 'graph.progression_title',
                chartData: {
                    color: ['#FE5549', '#F0C24D', '#44ada8', '#5FD855'],
                    labels: ['graph.progression_not_acquired', 'graph.progression_to_do', 'graph.progression_so_close', 'graph.progression_acquired']
                },
                details: {
                    headers: [
                        {id: 'learner', label: 'graph.progression_learners_list'},
                        {id: 'progression', label: 'graph.progression_title'}
                    ]
                }
            },
            {
                label: 'graph.errors_title',
                chartData: {
                    color: ['#FE5549'],
                },
                details: {
                    headers: [
                        {id: 'learner', label: 'graph.progression_learners_list'},
                        {id: 'iteration', label: 'graph.errors_iterations'}
                    ]
                }
            }
        ],
        chartOptions: {
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#15213F',
                        fontSize: 14,
                    }
                }],
                yAxes: [{
                    gridLines: {
                        color: '#E4E4E4',
                    },
                    ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                        fontColor: '#15213F',
                        fontSize: 14,
                    }
                }]
            }
        }
    },
    // wich fields are to show in pop up info
    metadataDialogFieldsInfoToShow : {
        default: ['title', 'difficulty', 'skills', 'concepts', 'descriptions']
    },
};
