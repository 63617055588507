export const activities = {
    allowedActivityTypes: [],
    allowedRolesForModelsAssignation: ['trainer'],
    cardLayout: 'card-simple-course',
    displayFeedbacks: true, // show feedback section
    displayOptionalLessonRecapButtons: false,
    displaySpecificUserActionButton: ['save'],
    dynamicRewardMilestones: [0, 35, 60, 80], // display and explicit scale of dynamic reward in recap
    dynamicContent: true,
    // list of filter to apply on lesson by url(url contain term) id = chapter taxonomy term to use for filter
    filtertoApplyOnLessonsByUrl: [{url: 'algebra', id: 3797}, {url: 'geometry', id: 3798}],
    hideReview: true,
    hideUserActionButtons: true,
    hideUserActionButtonsByRole: ['manager', 'trainer'], // some instance need to hide lesson action button only for some roles
    // required params name for partner through iframe url
    iframeParams: ['uid', 'lessonId', 'idTask'],
    lessonDialogFields: {
        default: ['title', 'method', 'tags', 'description'],
    },
    lessonDialogRequiredFields:  {
        default: ['title', 'method'],
    },
    lessonMetadataDialogFields : {
        default: ['skills', 'concepts', 'chapters']
    },
    lessonStep: null,
    loadLessonWithSublesson: {
        typology: null,
        multi_step: 0
    },
    navigateInPreviousStep: false,
    openDialogInfoAfterDuplicateLesson: {
        default: true
    },
    searchFields: ['title', 'difficulty', 'educationnalLevel', 'launchSearch', 'countEntities'], // niveau : educationnalLevel  complexité : skills
    // required params name for partner through iframe url
    showProgressBar: true, // show a progress bar under the current lesson
    showIconTitleLesson: true, // icon to push before title lesson
    stepper: null,
    helpButton: {
        data: {
            payload: {
                trigger: 'bouton-aide',
                sensors: {},
            }
        }
    }
};
