export const mainMenu = {
    displayMenu: {
        default: {
            'level0':
                [
                    'dashboard',
                    'parameter',
                    'lessonsDeployableAlgebraGeometry', // parcours are separate on two menu algebra and geometry
                    'followed',
                    'messaging',
                    'agenda',
                    'progression'
                ]
        }
    }
};
