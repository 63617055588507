import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LicensingService} from '../licensing.service';

@Component({
    selector: 'licensing-method-add',
    templateUrl: './licensing-method-add.component.html',
    styleUrls: ['./licensing-method-add.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LicensingMethodAddComponent implements OnInit {
    public formErrors: any;
    public panelOpened: boolean;
    public form: FormGroup;
    public addMethodSuccess: boolean;
    public addMethodError: any;
    public lastMethodNameAdded: string;
    public errorKeyMessages: {main: string; details: string;};

    @Input('uid') uid?: string;
    
    constructor(private formBuilder: FormBuilder, private licensingService: LicensingService) {}

    ngOnInit() {

        this.addMethodError = null;
        this.addMethodSuccess = false;
        this.formErrors = {
            methodCode: {}
        };

        // Reactive Form
        this.form = this.formBuilder.group({
            methodCode: new FormControl('', [Validators.required])
        });
    }

    public codeInputCheck() {
        return this.form.controls['methodCode'].invalid;
    }

    public checkAndUnlock() {
        this.licensingService.addMethod(this.form.controls['methodCode'].value, this.uid).subscribe((data) => {
            this.addMethodSuccess = true;
            this.addMethodError = null;
            this.lastMethodNameAdded = data.get('access').name;
        }, (error) => {
            this.addMethodSuccess = false;
            this.addMethodError = error;
            this.errorKeyMessages = {
                main: 'licensing.method.add.error.incorrect',
                details: 'licensing.method.add.error.incorrect2'
            };
            if (error.data.response['title'] === 'license.used') {
                this.errorKeyMessages = {
                    main: 'licensing.method.add.error.used',
                    details: 'licensing.method.add.error.used2'
                };
            }
        });
    }

    public setStatePanel(value: boolean) {
        this.panelOpened = value;
        if (!value) {
            this.addMethodSuccess = false;
            this.addMethodError = null;
            this.form.controls['methodCode'].setValue('');
        }
    }
}
