import {map, takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {DashboardService} from '../services/dashboard.service';
import {AuthenticationService} from '@modules/authentication';
import {DashWidgetConf} from '@modules/dashboard/core/definitions';
import {CommunicationCenterService} from '@modules/communication-center';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {DataEntity} from 'octopus-connect';
import {FormControl} from '@angular/forms';
import {modulesSettings, displayHeaderTitle} from 'app/settings';
import {NewsService} from '@modules/dashboard/core/services/news.service';


@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, OnDestroy {
    public DashWidgetConfAgendaWeeklyManagement: DashWidgetConf;
    public DashWidgetConfAssignments: DashWidgetConf;
    public DashWidgetConfAssignmentsGroup: DashWidgetConf;
    public DashWidgetConfConsultedCorpusManagement: DashWidgetConf;
    public DashWidgetConfConsultedLessons: DashWidgetConf;
    public DashWidgetConfCreatedCorpusManagement: DashWidgetConf;
    public DashWidgetConfCreatedLessons: DashWidgetConf;
    public DashWidgetConfCreatedTheme: DashWidgetConf;
    public DashWidgetConfFavoritesCorpusManagement: DashWidgetConf;
    public DashWidgetConfFavoritesLessons: DashWidgetConf;
    public DashWidgetConfFavoritesLessonsManagement: DashWidgetConf;
    public DashWidgetConfFavoritesTheme: DashWidgetConf;
    public DashWidgetConfFormModels: DashWidgetConf;
    public DashWidgetConfGroupsManagement: DashWidgetConf;
    public DashWidgetConfMessageThreadsManagement: DashWidgetConf;
    public DashWidgetConfNews: DashWidgetConf;
    public DashWidgetConfProjectsManagement: DashWidgetConf;
    public DashWidgetConfResearchSheetTemplate: DashWidgetConf;
    public DashWidgetConfWorkgroupsManagement: DashWidgetConf;
    public DashWidgetConfAssignmentsClosed: DashWidgetConf;
    public DashWidgetConfAssignmentsWithoutAssigment: DashWidgetConf;
    public DashWidgetConfRecommendation: DashWidgetConf;
    public globalCorpus: string;
    public orderFields: boolean = false;
    fields: string[] = [];

    public get haveFilters(): boolean {
        return this.getFiltersForCurrentUser().length > 0;
    }

    groupsSubscription: ReplaySubject<any> = new ReplaySubject<any>();
    workgroupsSubscription: ReplaySubject<any> = new ReplaySubject<any>();
    projectsSubscription: ReplaySubject<any> = new ReplaySubject<any>();
    assignmentsGroupsSubscription: ReplaySubject<any> = new ReplaySubject<any>();
    messageThreadsSubscription: ReplaySubject<any> = new ReplaySubject<any>();
    groups: Array<any> = [];
    workgroups: Array<any> = [];
    projects: Array<any> = [];
    assignmentsGroup: Array<any> = [];
    assignments: Array<any> = [];
    messageThreads: Array<any> = [];
    analyticsDetails: Array<any> = [];

    sheetTemplateSubscription: ReplaySubject<any> = new ReplaySubject<any>();
    formSubscription: ReplaySubject<any> = new ReplaySubject<any>();
    sheetTemplate: Array<any> = [];
    sheetTemplateCollection: { [key: number]: DataEntity } = {};

    formModels: Array<any> = [];

    groupControl = new FormControl('');
    loadAssignmentGroup: any;
    loadMessagingThreads: Function;

    modulesSettings = modulesSettings;

    private unsubscribeInTakeUntil = new Subject();
    public displayHeaderTitle: boolean;
    // we get only closed assignment and we get in return the number of assignment by a callback we put it inside DashWidgetConfAssignments.countAssignment who update the value
    public assignmentsClosedParams = {closedOnly: true, countCallBack: (count) => this.DashWidgetConfAssignmentsClosed.countAssignment = count};
    public assignmentsWithoutParams = {countCallBack: (count) => this.DashWidgetConfAssignmentsWithoutAssigment.countAssignment = count};


    constructor(
        private dashboardService: DashboardService,
        public authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private router: Router,
        private newsService: NewsService
    ) {
        this.displayHeaderTitle = displayHeaderTitle;
        this.manageAutoOrderFields();

        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data: any[]) => {
                this.groups = [];
                this.groups.push(...data.filter((group) => !group.archived));
                this.groupsSubscription.next(this.groups);
            });

        this.communicationCenter
            .getRoom('corpus')
            .getSubject('corpusSettings')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data: any) => {
                this.globalCorpus = data.globalCorpus;
            });

        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data: any[]) => {
                this.workgroups = [];
                this.workgroups.push(...data.filter((workgroup) => !workgroup.archived));
                this.workgroupsSubscription.next(this.workgroups);
            });

        this.communicationCenter
            .getRoom('messaging')
            .getSubject('thread.list')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((response) => {
                this.loadMessagingThreads = response.callback;
                this.messageThreads = [];
                this.messageThreads.push(...response.data);
                this.messageThreadsSubscription.next(response.data);
            });

        this.communicationCenter
            .getRoom('projects-management')
            .getSubject('projectsList')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data: any[]) => {
                data = data.filter((project) => !project.archived);
                this.projects = [];
                this.projects.push(...data.reverse());
                this.projectsSubscription.next(this.projects);
            });

        this.communicationCenter
            .getRoom('research-sheet')
            .getSubject('sheetsTemplate')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data) => {
                this.sheetTemplate = [];
                this.sheetTemplateCollection = {};
                if (data) {
                    this.sheetTemplate.push(...data.templates);
                    this.sheetTemplateCollection = data.templateCollection;
                }
                this.sheetTemplateSubscription.next({templates: this.sheetTemplate, templateCollection: this.sheetTemplateCollection});
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('form-list')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data) => {
                this.formModels = [];
                if (data) {
                    this.formModels.push(...data);
                }
                this.formSubscription.next(this.formModels);
            });

        this.communicationCenter
            .getRoom('assignation')
            .getSubject('assignmentsGroup')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data: any) => {
                this.assignmentsGroup = [];
                if (data) {
                    this.assignmentsGroup.push(...data.assignmentsGroup);
                    this.loadAssignmentGroup = data.callback;
                    this.assignmentsGroupsSubscription.next(this.assignmentsGroup);
                }
            });

        this.communicationCenter
            .getRoom('configuration')
            .getSubject('analytics-details')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data: any) => {
                this.analyticsDetails = [];
                // data can be an DateEntity with empty attributes
                if (data && data.get('link') && Array.isArray(data.get('link'))) {
                    this.analyticsDetails.push(...data.get('link'));
                }
            });

        this.communicationCenter
            .getRoom('configuration')
            .getSubject('refresh-analytics-details')
            .next();


        if (this.authService.isAtLeastTrainer()) {
            this.communicationCenter
                .getRoom('assignment')
                .next('loadAvailableGroups', true);
        }
        this.groupControl.valueChanges.subscribe((newGroupSelected) => {
            // do something filter by group or reload paginatedCollection with new filter params (group)
            const options = {
                filter: {
                    inprogress: 1
                }
            };
            if (newGroupSelected && newGroupSelected !== 'all') {
                options.filter['group'] = newGroupSelected.id;
            } else {
                delete options.filter['group'];
            }
            this.loadAssignmentGroup(options, true);
            this.loadMessagingThreads(options.filter);
            this.communicationCenter
                .getRoom('dashboard')
                .next('filter', options.filter);
        });
        const shouldDisplayHeaderLink = this.dashboardService.shouldDisplayHeaderLink();
        this.DashWidgetConfNews = {
            headerTitle: 'dashboard.news_header',
            headerIcon: 'news',
            displayHeaderLink: false,
            headerLink: ['/dashboard'],
            dashWidgetClassName: 'dash-widget-news news-list-length-0',
            displayModeAccordion: false
        };
        this.DashWidgetConfGroupsManagement = {
            headerTitle: this.authService.isAtLeastTrainer() ? 'groups-management.my_classes' : 'generic.my_classes',
            headerIcon: 'contacts',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['/groups', 'list', 'groups'],
            dashWidgetClassName: 'dash-widget-groups',
            displayModeAccordion: false
        };
        this.DashWidgetConfWorkgroupsManagement = {
            headerTitle: this.authService.isAtLeastTrainer() ? 'groups-management.my_workgroups' : 'generic.my_workgroups',
            headerIcon: 'contacts',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['/groups', 'list', 'workgroups'],
            dashWidgetClassName: 'dash-widget-groups',
            displayModeAccordion: false
        };
        this.DashWidgetConfProjectsManagement = {
            headerTitle: 'navigation.projects-management',
            headerIcon: 'projects',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['/projects'],
            dashWidgetClassName: 'dash-widget-projects',
            displayModeAccordion: false
        };
        this.DashWidgetConfMessageThreadsManagement = {
            headerTitle: 'messaging.active_thread',
            headerIcon: '',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['/messaging'],
            dashWidgetClassName: 'dash-widget-message-threads',
            displayModeAccordion: true
        };
        this.DashWidgetConfAgendaWeeklyManagement = {
            headerTitle: 'navigation.agenda_weekly',
            headerIcon: 'agenda',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['/agenda'],
            dashWidgetClassName: 'dash-widget-agenda-weekly',
            displayModeAccordion: true
        };
        this.DashWidgetConfFavoritesTheme = {
            headerTitle: 'navigation.favorites-theme',
            headerIcon: 'theme',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['/themes'],
            dashWidgetClassName: 'dash-widget-favorites-theme',
            displayModeAccordion: false
        };
        this.DashWidgetConfCreatedTheme = {
            headerTitle: 'navigation.created-theme',
            headerIcon: 'theme',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['/themes'],
            dashWidgetClassName: 'dash-widget-created-theme',
            displayModeAccordion: false
        };
        this.DashWidgetConfFavoritesLessons = {
            headerTitle: 'navigation.favorites-lessons',
            headerIcon: 'lessons',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['/lessons/list'],
            dashWidgetClassName: 'dash-widget-favorites-lessons',
            displayModeAccordion: false
        };
        this.DashWidgetConfConsultedLessons = {
            headerTitle: 'navigation.consulted-lessons',
            headerIcon: 'lessons',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['/lessons/list'],
            dashWidgetClassName: 'dash-widget-consulted-lessons',
            displayModeAccordion: false
        };
        this.DashWidgetConfFavoritesLessonsManagement = {
            headerTitle: 'navigation.favorites-lessons-management',
            headerIcon: 'lessons',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['/lessons/list'],
            dashWidgetClassName: 'dash-widget-favorites-lessons',
            displayModeAccordion: false
        };
        this.DashWidgetConfCreatedLessons = {
            headerTitle: 'navigation.created-lessons',
            headerIcon: 'lessons',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['/lessons/list'],
            dashWidgetClassName: 'dash-widget-created-lessons',
            displayModeAccordion: false
        };
        this.DashWidgetConfFavoritesCorpusManagement = {
            headerTitle: 'navigation.favorites-corpus-management',
            headerIcon: 'corpus',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['/corpus', this.globalCorpus],
            dashWidgetClassName: 'dash-widget-favorites-corpus',
            displayModeAccordion: false
        };
        this.DashWidgetConfConsultedCorpusManagement = {
            headerTitle: 'navigation.consulted-corpus-management',
            headerIcon: 'corpus',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['/corpus', this.globalCorpus],
            dashWidgetClassName: 'dash-widget-consulted-corpus',
            displayModeAccordion: false
        };
        this.DashWidgetConfCreatedCorpusManagement = {
            headerTitle: 'navigation.created-corpus-management',
            headerIcon: 'corpus',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['/corpus', this.globalCorpus],
            dashWidgetClassName: 'dash-widget-created-corpus',
            displayModeAccordion: false
        };
        this.DashWidgetConfResearchSheetTemplate = {
            headerTitle: 'research-sheet.search_sheets_model_title',
            headerIcon: 'magnify',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['..', 'research-template', 'list'],
            dashWidgetClassName: 'dash-widget-research-sheet-template',
            displayModeAccordion: false
        };
        this.DashWidgetConfFormModels = {
            headerTitle: 'activities.forms_model_title',
            headerIcon: 'assignations',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['..', 'forms', 'list'],
            dashWidgetClassName: 'dash-widget-form-models',
            displayModeAccordion: false
        };
        this.DashWidgetConfAssignmentsGroup = {
            headerTitle: 'dashboard.lessons_available',
            headerIcon: '',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['..', 'followed', 'list'],
            dashWidgetClassName: 'dash-widget-assignments-group',
            displayModeAccordion: true
        };
        this.DashWidgetConfAssignments = {
            headerTitle: 'dashboard.lessons_available',
            headerIcon: '',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['..', 'followed', 'list'],
            dashWidgetClassName: 'dash-widget-assignments',
            displayModeAccordion: true,
            headerCountTitle: 'groups-management.my_students',
            countAssignment: ''
        };
        this.DashWidgetConfAssignmentsClosed = {
            headerTitle: 'dashboard.lessons_closed',
            headerIcon: '',
            displayHeaderLink: shouldDisplayHeaderLink,
            headerLink: ['..', 'followed', 'tab'],
            dashWidgetClassName: 'dash-widget-assignments dash-widget-assignments-closed',
            displayModeAccordion: true,
            headerCountTitle: 'activities.title_lessons',
            countAssignment: ''
        };

        this.DashWidgetConfAssignmentsWithoutAssigment = {
            headerTitle: 'dashboard.students_without_active_assignment',
            headerIcon: '',
            displayHeaderLink: this.dashboardService.settings.displayHeaderLink,
            headerLink: ['..', 'lessons', 'list', 'models'],
            dashWidgetClassName: 'dash-widget-assignments',
            displayModeAccordion: true,
            headerCountTitle: 'groups-management.my_students',
            countAssignment: ''
        };

        this.DashWidgetConfRecommendation = {
            headerTitle: 'dashboard.recommendation_header',
            headerIcon: 'recommendation',
            displayHeaderLink: false,
            headerLink: ['/recommendation'],
            dashWidgetClassName: 'dash-widget-recommendation recommendation-list-length-0',
            displayModeAccordion: false
        };

        this.onNewsListLengthChange().subscribe(length => {
            this.DashWidgetConfNews.dashWidgetClassName =
                this.DashWidgetConfNews.dashWidgetClassName
                    .replace(/news-list-length-[0-9]+/, 'news-list-length-' + length);

        });
    }

    private onNewsListLengthChange(): Observable<number> {
        return this.newsService.getNews$().pipe(map(newsList => newsList.length));
    }

    /**
     * get if fields must be auto order and order to respect from settings
     */
    private manageAutoOrderFields(): void {
        const role = this.authService.accessLevel;
        this.orderFields = this.dashboardService.shouldOrderFields();
        this.fields = this.dashboardService.getDisplayedWidgetForRole(role);
    }

    ngOnInit(): any {
    }

    ngOnDestroy(): void {
        this.communicationCenter
            .getRoom('assignment')
            .next('loadAvailableGroups', false);
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public displayTabsPage(): boolean {
        return this.dashboardService.shouldDisplayTabsPage();
    }

    public displayWidget(type): boolean {
        const widgetSettings = this.dashboardService.getDisplayedWidgetForRole(this.authService.accessLevel);
        return widgetSettings.includes(type);
    }

    public displayFilter(name: string): boolean {
        return this.getFiltersForCurrentUser().includes(name);
    }

    private getFiltersForCurrentUser(): string[] {
        const role = this.authService.accessLevel;
        return this.dashboardService.getFiltersForRole(role);
    }

    /***
     * show or not dashboard
     * alwaysShowDashboard setting overide all the other rules if true => show => empty = false
     */
    public get empty(): boolean {

        if (this.dashboardService.shouldAlwaysShowDashboard()) {
            return false;
        }

        if (this.authService.isAtLeastTrainer()) {
            return !this.groups.length && !this.projects.length;
        }
        return !this.groups.length && !this.assignments.length && !this.workgroups.length;
    }

    public goToHelpPage(): void {
        this.router.navigate(['page', 'aide-aux-ressources']);
    }

    public backgroundLearner(analytic): boolean {
        return analytic.label === 'analytics.learner_login_history';
    }

    translateTerm(analytic, key, type): string {

        if (type === 'number' && analytic[key] === '') {
            return '0';
        }
        if (type === 'string' && analytic[key] === '') {
            return 'NO DATA';
        }
        return analytic[key].toString();
    }
}



