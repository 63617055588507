import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-edit-form-dialog',
    templateUrl: './edit-form-dialog.component.html'
})
export class EditFormDialogComponent implements OnInit {
    public entityForm: FormGroup;
    public action: string;
    public dialogTitle: string;
    public entity: any;

    constructor(
        public dialogRef: MatDialogRef<EditFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private formBuilder: FormBuilder
    ) {
        this.action = data.action;
        this.dialogTitle = `activities.form.dialog_${this.action}`;
        this.entity = {};

        if (data.action === 'edit') {
            this.entity = data.item.get('metadatas');
            this.entityForm = this.editEntityForm(['title', 'description']);
        } else {
            this.entityForm = this.createEntityForm(['title', 'description']);
        }
    }

    ngOnInit() {}

    createEntityForm(fields: string[]) {
        const config = {};
        fields.forEach((field: string) => {
            config[field] = [this.entity[field]];
        });

        return this.formBuilder.group(config);
    }

    editEntityForm(fields: string[]) {
        const config = {};
        fields.forEach((field: string) => {
            config[field] = [this.entity[field]];
        });

        return this.formBuilder.group(config);
    }

    public close(): any {
        const data = {form: this.entityForm, redirect: true};
        this.dialogRef.close(data);
    }
}
