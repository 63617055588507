import {Component, Input, OnInit} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {fuseAnimations} from 'fuse-core/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {ReplaySubject} from 'rxjs';

@Component({
  selector: 'app-widget-section-draft',
  templateUrl: './widget-section-draft.component.html',
  styleUrls: ['./widget-section-draft.component.scss'],
  animations: fuseAnimations
})
export class WidgetSectionDraftComponent implements OnInit {
    @Input('sectionDraftSubscription') sectionDraftSubscription: ReplaySubject<any>;

    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
    displayedColumns: string[] = ['name', 'section', 'date', 'owner'];
    resource: boolean;


  constructor(private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): any {

      this.sectionDraftSubscription
          .subscribe((data) => {
              this.dataSource.data = data.slice(0, 3);
              this.resource = !!data.length;
          });
  }


    goToDraft(draft): any{
        this.router.navigate(['..' , 'tools', 'section-draft', 'edit', draft.id], { relativeTo: this.route});
    }


    public get empty(): boolean {
        return !this.resource;
    }

    public get align(): string {
        if (!this.empty) {
            return 'start center';
        }
        return 'center center';
    }
}


