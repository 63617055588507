import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../../settings';

const settingsStructure: ModelSchema = new ModelSchema({
    actionButtonsInMultiMatrix: Structures.object({
        default: []
    }),
    activitiesWithDefinedTypology: Structures.array([]),
    allowedMediaTypeCombination: Structures.array([
        ['image', 'audio'],
        ['image', 'text'],
        ['video', 'text'],
        ['audio', 'text'],
    ]),
    // WARN : An assignment of model make the model cannot be deleted or updated
    allowedRolesForModelsAssignation: Structures.array([]),
    allowedRolesForModelsCreation: Structures.array(['manager']),
    availableAddButtons: Structures.object({
        default: ['activity', 'divider', 'resource']
    }),
    cardLayout: Structures.string('card-simple-course'),
    displayFullscreenButton: Structures.boolean(true),
    displayLearnerInfo: Structures.boolean(true),
    displayCreateLessonHelper: Structures.boolean(false),
    displayFeedbacks: Structures.boolean(false),
    displayOptionalLessonRecapButton: Structures.boolean(true),
    dynamicRewardMilestones: Structures.array([]),
    gettingStarted: Structures.object({}),
    grade: Structures.boolean(),
    hideUserActionButtonsWhenDisabled: Structures.boolean(false),
    hideUserActionButtons: Structures.boolean(false),
    hideUserActionButtonsByRole: Structures.array([]),
    initLoadLessonFilter: Structures.array(['author']),
    isOnlyModelLesson: Structures.boolean(false),
    lessonStep: Structures.object(),
    lessonMetadataDialogFields: Structures.object({default: []}),
    openDialogInfoAfterDuplicateLesson: Structures.object({default: false}),
    progress: Structures.boolean(),
    // route by default when exit lessons some instance need another one
    routeOnExitLesson: Structures.array(['lessons', 'list']),
    selectedTabIndex: Structures.number(0),
    shareableModel: Structures.number(0),
    shouldSetDefaultOptionsOnActivityList: Structures.boolean(false),
    showActivityTitle: Structures.boolean(false),
    showIconTitleLesson: Structures.boolean(false),
    showLessonTitle: Structures.boolean(true),
    showMetadataButton: Structures.boolean(false),
    stepTitlePrefixPosition: Structures.boolean(false),
    stepper: Structures.object({
        align: 'start',
        position: 'left',
        showRecap: true,
        stepValidationNeedSave: true,
    }),
    stepperLoadStatus: Structures.boolean(false),
    activityOptionsEditable: Structures.array(['titleAndInstruction'])
});

@Injectable({
    providedIn: 'root'
})
export class LessonsConfigurationService {
    /**
     * @deprecated don't call settings directly, it must be private and use a function in this service
     */
    public settings: { [p: string]: any };
    private privateSettings: { [p: string]: any };

    constructor() {
        this.privateSettings = this.settings = settingsStructure.filterModel(modulesSettings.activities);
    }
}
