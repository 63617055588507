export const accountManagement = {
    canEditAvatar: ['learner', 'trainer', 'manager', 'administrator'],
    canSelfDelete: ['trainer'],
    allowedMaxSize: 1,
    displayDialogMinorAdult: false,
    displayFilters: false,
    fields: {
        default : ['label|required', 'firstName|required', 'email|required', 'password|required'] // label = pseudo
    },
    selfSignup: true,
    passwordPolicy: {
        regex : /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    },
    emailIncludeText: '@ac-', // exist only for mathia teacher may have a info of mail doesn't contain this text
    hideEditSSO: true // disable edition for users from SSO
};
