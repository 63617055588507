import {filter, map, mergeMap} from 'rxjs/operators';
import {NgModule} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ActivatedRoute, NavigationEnd, Router, RouterModule, Routes} from '@angular/router';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {LangChangeEvent, TranslateModule, TranslateService} from '@ngx-translate/core';
import 'hammerjs';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';

import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {SampleModule} from 'app/main/sample/sample.module';

import {DataEntity, OctopusConnectModule} from 'octopus-connect';
import {IsUserLogged} from './guards/is-user-logged.class';
import {ServiceInstantiator} from './service-instantiator.class';
import {DashboardModule} from '@modules/dashboard';
import {AccountManagementModule} from '@modules/account-management';
import {GroupsManagementModule} from '@modules/groups-management';
import {AuthenticationModule} from '@modules/authentication/core/authentication.module';
import {TralaTranslationLoaderService} from './trala-translation-loader.service';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {SharedTranslateModule} from './shared/shared-translate.module';
import {BrowserTestModule} from '@modules/browser-test';
import {CommunicationCenterModule, CommunicationCenterService} from '@modules/communication-center';
import {ProjectsManagementModule} from '@modules/projects-management';

import {defaultApiURL, defaultNodeURL, defaultRoute, enableRgpdLinks} from './settings';
import {NavigationModule} from './navigation/navigation.module';
import {DynamicNavigationService} from './navigation/dynamic-navigation.service';

// Captcha
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {NgHcaptchaModule} from 'ng-hcaptcha';


import {NotificationModule} from '@modules/notification';
import {CorpusModule} from '@modules/corpus';
import {ResearchSheetModule} from '@modules/research-sheet';
import {SvgIconList} from './themes/mindmath/svgIconList';
import {ActivitiesModule, LessonsModule, ThemesModule} from '@modules/activities';
import {AssignationModule} from '@modules/assignation';
import {IdeasWallModule} from '@modules/ideas-wall';
import {LicensingModule} from '@modules/licensing';
import {GamificationModule} from '@modules/gamification';
import {GraphAssignationModule} from '@modules/graph-assignation/core/graph-assignation.module';
import {ConfigurationModule} from '@modules/configuration';
import {FullPageComponent} from 'fuse-core/components/basic-page/full-page/full-page.component';
import {MessagingModule} from '@modules/messaging';
import {IsUserAnonymous} from './guards/is-user-anonymous.class';
import {AgendaModule} from '@modules/agenda';
import {DataVisualizationModule} from '@modules/data-visualization';
import {TimelineModule} from '@modules/timeline';
import {Angulartics2Module} from 'angulartics2';
import {HomePageComponent} from 'fuse-core/components/home-page/home-page.component';
import {NotepadModule} from '@modules/notepad';
import {MindmapModule} from '@modules/mindmap';
import {AuthenticationService} from '@modules/authentication';
import {GraphMathiaModule} from '@modules/graph-mathia/core/graph-mathia.module';
import {EventService} from './shared/event.service';
import {IsUserAuthorisedByRoleGuard} from './guards/is-user-authorised-by-role.guard';
import {GraphUbolinoModule} from '@modules/graph-ubolino';
import {GraphDysappModule} from '@modules/graph-dysapp';
import {MobileRedirectionModalComponent} from 'fuse-core/components/mobile-redirection-modal/mobile-redirection-modal.component';

const classPath = '';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: defaultRoute,
        pathMatch: 'full',
    },
    {
        path: 'home',
        component: HomePageComponent,
        canActivate: [IsUserLogged]
    },
    {
        path: 'page/:alias',
        component: FullPageComponent
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent
    }
];

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        MaintenanceComponent,
        MobileRedirectionModalComponent
    ],
    imports: [
        RecaptchaV3Module,
        NgHcaptchaModule.forRoot({
            siteKey: '8858d7eb-82d8-425e-9d74-e114ceca737b',
            // languageCode: 'de' // optional, will default to browser language
        }),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        // import Angulartics2GoogleGlobalSiteTag in root ngModule
        Angulartics2Module.forRoot(),

        SharedTranslateModule,
        TranslateModule.forRoot(),

        // Material moment date module
        AuthenticationModule.forRoot(),
        NavigationModule.forRoot(),
        MatMomentDateModule,

        // Mat expansion panel
        MatExpansionModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatCardModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,

        GroupsManagementModule.forRoot(),
        DashboardModule.forRoot(),
        AccountManagementModule.forRoot(),
        ProjectsManagementModule.forRoot(),
        NotificationModule.forRoot(),
        CorpusModule.forRoot(),
        ActivitiesModule.forRoot(),
        AssignationModule.forRoot(),
        LessonsModule.forRoot(),
        ThemesModule.forRoot(),
        ResearchSheetModule.forRoot(),
        IdeasWallModule.forRoot(),
        LicensingModule.forRoot(),
        GamificationModule.forRoot(),
        ConfigurationModule.forRoot(),
        MessagingModule.forRoot(),
        AgendaModule.forRoot(),
        TimelineModule.forRoot(),
        MindmapModule.forRoot(),
        NotepadModule.forRoot(),
        BrowserTestModule.forRoot({
            desktopSize: {
                minWidth: 1024,
                minHeight: 768
            },
            browsersBlackList: [
                {
                    name: 'ie',
                    minVersion: '11'
                },
                {
                    name: 'chrome',
                    minVersion: '72'
                },
                {
                    name: 'firefox',
                    minVersion: '65'
                },
                {
                    name: 'edge',
                    minVersion: '16'
                },
                {
                    name: 'safari',
                    minVersion: '10'
                }
            ]
        }),
        CommunicationCenterModule.forRoot(),
        GraphAssignationModule.forRoot(),
        DataVisualizationModule.forRoot(),
        OctopusConnectModule.forRoot({
            defaultInterface: 'localstorage',
            maxRetry: 5,
            retryTimeout: 50000,
            liveRefreshService: 'refresh',
            configuration: {
                localstorage: {
                    prefix: 'test'
                },
                http: {
                    apiUrl: defaultApiURL,
                    useApiExtension: true,
                    headers: {
                        'Content-type': 'application/json'
                    }
                },
                nodejs: {
                    socketUrl: defaultNodeURL
                }
            },
            map: {
                'form-project': 'http',
                'feedback': 'http',
                'log-book': 'http',
                'users': 'http',
                'user_search': 'http',
                'learners': 'http',
                'trainers': 'http',
                'licenses': 'http',
                'migrations': {
                    type: 'http',
                    useLanguage: true
                },
                'licenses-import': 'http',
                'groups': 'http',
                'institutions': 'http',
                'user-registration': 'http',
                'reset-password': 'http',
                'userfiles': 'http',
                'projects': 'http',
                'contact-message': 'http',
                'notification': {
                    type: 'http',
                    refreshEnabled: true
                },
                'corpus': 'http',
                'lesson': 'http',
                'activity': 'http',
                'media': 'http',
                'qcm': 'http',
                'assignations': {
                    type: 'http',
                    refreshEnabled: true
                },
                'assignations-group': 'http',
                'event-assignation': 'http',
                'granule': {
                    type: 'http',
                    refreshEnabled: true,
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'reference'
                    },
                    useLanguage: true
                },
                'difficulty': {
                    type: 'http',
                    cached: true,
                    useLanguage: true
                },
                'schoolyears': 'http',
                'granule-activity': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'activity'
                    }
                },
                'granule-lesson': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    }
                },
                'granule-sequence': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    }
                },
                'granule-form': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    }
                },
                'granule-format': {
                    type: 'http',
                    cached: true
                },
                'regions': {
                    type: 'http',
                    cached: true
                },
                'tralabar_links': {
                    type: 'http',
                    cached: true
                },
                'educational_level': {
                    type: 'http',
                    cached: true,
                    useLanguage: true
                },
                'tags': {
                    type: 'http',
                    refreshEnabled: true
                },
                'bookmarks': 'http',
                'bookmarks-theme': 'http',
                'consulted': 'http',
                'consulted_assignation': 'http',
                'evaluation': 'http',
                'corpus-text-ressource': 'http',
                'corpus-url-ressource': 'http',
                'metadatas': 'http',
                'corpus-set': {
                    type: 'http',
                    embeddings: {
                        'corpus-list': 'corpus'
                    }
                },
                'research-sheets': {
                    type: 'http',
                    refreshEnabled: true
                },
                'sections': {
                    type: 'http',
                    refreshEnabled: true
                },
                'draft-sections': {
                    type: 'http',
                    refreshEnabled: true
                },
                'section-definitions-set': {
                    type: 'http',
                    embeddings: {
                        sections: 'section-definitions'
                    }
                },
                'section-definitions': {
                    type: 'http',
                    embeddings: {
                        readingGrids: 'reading-grid'
                    }
                },
                'refresh': 'nodejs',
                'comments': 'http',
                'user-save': {
                    type: 'http',
                    embeddings: {
                        userActivity: 'user-activity'
                    }
                },
                'user-activity': 'http',
                'answer': 'http',
                'qcm-save': 'http',
                'ord-save': 'http',
                'app-save': 'http',
                'rb-save': 'http',
                'genericsave': 'http',
                'reading-grids': 'http',
                'draft-questions': 'http',
                'logical-links-set': 'http',
                'logical-links': 'http',
                'ideas-wall': {
                    type: 'http',
                    refreshEnabled: true
                },
                'wall-idea': {
                    type: 'http',
                    refreshEnabled: true
                },
                'wall-category': {
                    type: 'http',
                    refreshEnabled: true
                },
                'ideas_wall_category': {
                    type: 'http',
                    cached: true
                },
                'corpus_search/Granule': {
                    type: 'http',
                    refreshEnabled: true,
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'reference'
                    },
                    useLanguage: true
                },
                'basic_search': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'activity'
                    },
                    useLanguage: true
                },
                'assignation_search': {
                    type: 'http',
                },
                'lesson_granule_search': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    },
                    useLanguage: true
                },
                'themes': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    },
                    useLanguage: true
                },
                'theme_search': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    },
                    useLanguage: true
                },
                'variables/instance': {
                    type: 'http',
                    useLanguage: true
                },
                'assignation_state': 'http',
                'groups_search': {
                    type: 'http'
                },
                'variables/postalCodes': {
                    type: 'http'
                },
                'chapters': {
                    type: 'http',
                    useLanguage: true
                },
                'assignation_type': {
                    type: 'http',
                    useLanguage: true
                },
                'skills': {
                    type: 'http',
                    useLanguage: true
                },
                'concepts': {
                    type: 'http',
                    useLanguage: true
                },
                'authenticated': {
                    type: 'nodejs',
                },
                'join-wall': {
                    type: 'nodejs',
                },
                'pages': {
                    type: 'http',
                    useLanguage: true
                },
                'user-points': 'http',
                'badges-type': 'http',
                'badges': {
                    type: 'http',
                    useLanguage: true
                },
                'levels': {
                    type: 'http',
                    useLanguage: true
                },
                'users-import': 'http',
                'classes-import': 'http',
                'threads': {
                    type: 'http'
                },
                'messages': {
                    type: 'http',
                    refreshEnabled: true
                },
                'error-reporting': {
                    type: 'http',
                    useLanguage: true
                },
                'events': {
                    type: 'http',
                    useLanguage: true
                },
                'analytics-listing': 'http',
                'timeline': {
                    type: 'http',
                },
                'marker_type': {
                    type: 'http',
                    cached: true,
                    useLanguage: true
                },
                'video_marker': 'http',
                'summary': 'http',
                'user-dashboard': 'http',
                'progress': 'http',
                'metrics': 'http',
                'mindmaps': 'http',
                'notepads': 'http',
                'homepage_lms': {
                    type: 'http',
                    useLanguage: true
                },
                'variables/helpVideos': 'http',
                'comments-list': {
                    type: 'http',
                    useLanguage: true
                },
                'feedbacks': 'http',
                recaptchav3: {
                    type: 'http',
                },
                'statements-analytics': 'http',
                'recommendations': 'http',
                'users-link': 'http',
                'activity_type': 'http',
                'statements-stats': 'http',
                'learners-stat': 'http',
                'user-role-update': 'http'
            }
        }),
        GraphMathiaModule.forRoot(),
        GraphUbolinoModule.forRoot(),
        GraphDysappModule.forRoot(),
        HammerModule
    ],
    providers: [
        IsUserLogged,
        IsUserAuthorisedByRoleGuard,
        IsUserAnonymous,
        ServiceInstantiator,
        TralaTranslationLoaderService,
        SvgIconList,
        CommunicationCenterService,
        EventService,
        {provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Le-rdsUAAAAADbacQJe_sNQg1jxPsCsdqNcBvb1'},
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private communicationCenter: CommunicationCenterService,
        private authService: AuthenticationService,
    ) {

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
        router.events.pipe(filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter(route => route.outlet === 'primary'),
            mergeMap(route => {
                if (route.data) {
                    return route.data;
                } else {
                    return null;
                }
            }))
            .subscribe(evt => {
                let menuId: string;

                if (evt && evt['menu']) {
                    menuId = evt['menu'];
                } else {
                    menuId = 'level0';
                }
                this.registerDefaultLinkWithoutAuthentication();
                this.dynamicNavigation.generateMenu(menuId);
                dynamicNavigation.switchClassPath(menuId);
            });
    }

    private registerDefaultLinkWithoutAuthentication(): void {
        this.dynamicNavigation.refreshModuleMenu('level0', {
            'id': 'legalnotice',
            'classes': 'legalnotice',
            'title': 'Mentions légales',
            'translate': 'navigation.legalnotice',
            'type': 'item',
            'icon': 'new_releases',
            'url': '/page/conditions-generales'
        });


        this.dynamicNavigation.refreshModuleMenu('level0', {
            'id': 'privacy',
            'classes': 'privacy',
            'title': 'Privacy',
            'translate': 'navigation.privacy',
            'type': 'link',
            'icon': 'shield',
            'url': '/page/privacy'
        });
        this.addHelpAnonymous();
        this.dynamicNavigation.refreshModuleMenu('level0', {
            'id': 'needhelp',
            'classes': 'needhelp',
            'title': 'Need help',
            'translate': 'navigation.needhelp',
            'type': 'link',
            'icon': 'help',
            'url': '/'
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            'id': 'mentions-legales',
            'title': 'mentions-legales',
            'translate': 'navigation.legalnotice',
            'type': 'item',
            'icon': 'visibility_legalnotice',
            'url': '/page/mentions-legales',
            'displayIcon': true
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            'id': 'cgurgpd',
            'classes': 'cgurgpd',
            'title': 'cgurgpd',
            'translate': 'navigation.cgurgpd',
            'type': 'item',
            'icon': 'visibility_cgurgpd',
            'url': '/page/cgurgpd',
            'displayIcon': true
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            'id': 'accessibility',
            'classes': 'accessibility',
            'title': 'accessibility',
            'translate': 'navigation.accessibility',
            'type': 'item',
            'icon': 'accessibility',
            'url': '/page/accessibilite'
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            'id': 'contact',
            'classes': 'contact',
            'title': 'contact',
            'translate': 'navigation.contact',
            'type': 'item',
            'icon': 'help',
            'url': '/feedback'
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            'id': 'politique-confidentialite',
            'classes': 'politique-confidentialite',
            'title': 'politique-confidentialite',
            'translate': 'navigation.privacy',
            'type': 'item',
            'icon': 'visibility_privacy',
            'url': '/page/politique-confidentialite',
            'displayIcon': true
        });
    }

    /**
     * Replace help link with appropriate url
     */
    private updateHelpLink(Urlsuffix = ''): void {
        this.dynamicNavigation.clearMenuItem('level0', 'needhelp');
        this.translate.get('navigation.needhelp_url' + Urlsuffix).subscribe((url: string) => {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                'id': 'needhelp',
                'title': 'Need help',
                'translate': 'navigation.needhelp',
                'type': 'link',
                'icon': 'help',
                'url': url
            });
        });
    }

    private postAuthentication(): void {
        this.translate.get('navigation.external-target-link-menu-url').subscribe((translation: string) => {
                this.dynamicNavigation.refreshModuleMenu('level0', {
                    'id': 'external-target-link-menu',
                    'classes': 'external-target-link-menu',
                    'title': 'Lien externe',
                    'translate': 'navigation.external-target-link-menu',
                    'type': 'item',
                    'icon': 'external-target-link-menu-icon',
                    'url': translation,
                    'externalUrl': true,
                    'openInNewTab': true,
                    'displayIcon': true
                });
            }
        );

        this.registerDefaultLinkWithoutAuthentication();
        if (this.authService.hasLevel(['trainer', 'manager', 'administrator'])) {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                'id': 'needhelp',
                'title': 'Need help',
                'translate': 'navigation.needhelp',
                'type': 'link',
                'icon': 'help',
                'url': '/'
            });

            this.updateHelpLink();

            this.dynamicNavigation.refreshModuleMenu('level0', {
                    'id': 'home',
                    'title': 'Home',
                    'translate': 'navigation.home',
                    'type': 'item',
                    'icon': 'home',
                    'url': '/home'
                }
            );

            this.dynamicNavigation.refreshModuleMenu('level0', {
                'id': 'funambuleMove',
                'title': 'Manoeuvre du funambule',
                'translate': 'funambule.move',
                'type': 'item',
                'icon': 'funambule',
                'url': '/page/manoeuvre-funambule'
            });

            this.dynamicNavigation.refreshModuleMenu('level0', {
                'id': 'virtualClass',
                'title': 'Classe virtuelle',
                'translate': 'virtual.class',
                'type': 'item',
                'icon': 'virtual_class',
                'url': '/page/classe-virtuelle'
            });


            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                this.updateHelpLink();
            });

        } else if (this.authService.hasLevel(['learner'])) {
            this.updateHelpLink('_learner');
            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                this.updateHelpLink('_learner');
            });
        }
        this.dynamicNavigation.clearMenuItem('level0', 'help_anonymous');
    }

    private postLogout(): void {
        this.registerDefaultLinkWithoutAuthentication();
        this.dynamicNavigation.clearMenuItem('level0', 'external-target-link-menu');
        this.dynamicNavigation.clearMenuItem('level0', 'home');
        this.dynamicNavigation.clearMenuItem('level0', 'needhelp');
        this.dynamicNavigation.clearMenuItem('level0', 'funambuleMove');
        this.dynamicNavigation.clearMenuItem('level0', 'virtualClass');
        if (!enableRgpdLinks) {
            this.dynamicNavigation.clearMenuItem('level0', 'mentions-legales');
            this.dynamicNavigation.clearMenuItem('level0', 'cgurgpd');
            this.dynamicNavigation.clearMenuItem('level0', 'politique-confidentialite');
            this.dynamicNavigation.clearMenuItem('level0', 'accessibility');
            this.addHelpAnonymous();
        }
    }


    /**
     * Add link to helper resources just for unlogged user
     * @private
     */
    private addHelpAnonymous(): void {
        this.dynamicNavigation.refreshModuleMenu('level0', {
            'id': 'help_anonymous',
            'title': 'help',
            'translate': 'navigation.general_help',
            'type': 'item',
            'icon': 'help',
            'url': '/page/aide-aux-ressources'
        });
    }
}
