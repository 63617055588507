import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {IActivityContentPoint} from '@modules/activities/core/player-components/interactive-image/models/interactive-image.models';

@Directive({
    selector: '[appPositionAndColorDot]'
})
/***
 * Manage position and color of dot in interactive picture
 */
export class PositionAndColorDotDirective implements OnInit {

    constructor(private _renderer: Renderer2, private el: ElementRef) {

    }

    @Input() point: IActivityContentPoint;

    ngOnInit(): void {
        this.el.nativeElement.style.left = this.point.x.replace(',', '.') + '%';
        this.el.nativeElement.style.top = this.point.y.replace(',', '.') + '%';
        if ( this.point.alreadyOpen === true ) {
            this._renderer.addClass(this.el.nativeElement, 'visited');
        }
    }
}

