import {Observable} from 'rxjs';
import {DataCollection, DataEntity} from 'octopus-connect';
import {ModelDataCollectionDto} from 'fuse-core/adapters/model-data-collection-dto';
import {IConvert} from 'fuse-core/adapters/iconvert';

/**
 * this class return data formated and typed like we will use it
 * this class is to use for all basic list where we only use id for identifier and label for data we will shown
 * to create a new one more elaborate class just copy paste and change name of class and interface and add data mapping
 * in commonModelDto only push class that are realy basic and use most and most with the same interface in all code
 * For other case create a folder modelsDto and push the class inside
 * example of use case : getting skills with a loadCollection:
 * public getSkills(): Observable<IBasicListDto[]> {
        return new BasicListDto(this.octopusConnect.loadCollection('skills')).getObjectDto();
    }
 */

// TODO also use another way than extend and implement iconvert  like theme if work
export class BasicListDto implements IConvert {
    private _modelDataCollectionDto: ModelDataCollectionDto;

    /**
     * we pass data, we call the adpaterDto Class to formated data
     * next we type them with current object type
     * @param data : Observable of DataCollection, we transform it in a data entity array in first time
     */
    constructor(data: Observable<DataCollection>) {
        this._modelDataCollectionDto = new ModelDataCollectionDto(data, this);
    }

    /**
     * method is call by the adapter class because adapter method is common to all object of this type
     * but convert method is specific so this method is implemented in the custom ClassDto
     * @param data : dataentities to format in regard of what we will use reprensent by an interface here IBasicListDto
     */
    convert(data: DataEntity[]): IBasicListDto[] {
        try {
            return data.map(d => ({
                type: d.type,
                id: d.id,
                label: d.attributes.label,
            }));
        } catch (ex) {
            console.log(ex);
        }
    }

    /**
     * get the final object to use in front typed and formated
     * is call by the client to subscribe on it and get values
     */
    public getObjectDto(): Observable<IBasicListDto[]> {
        return this._modelDataCollectionDto.objectDto();
    }
}

/**
 * interface used to type the final object we will return
 */
export interface IBasicListDto {
    type: string;
    id: number | string;
    label: string;
}

