import {Injectable, Inject, HostListener} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class FullscreenService {

    mode = false;

    constructor( @Inject(DOCUMENT) private document: any) {
        this.document.addEventListener('fullscreenchange', (evt) => this.setFullscreenMode(evt));
        this.document.addEventListener('webkitfullscreenchange', (evt) => this.setFullscreenMode(evt));
        this.document.addEventListener('mozfullscreenchange', (evt) => this.setFullscreenMode(evt));
        this.document.addEventListener('MSFullscreenChange', (evt) => this.setFullscreenMode(evt));
    }

    fullscreen(): void {
        if (!this.document.fullscreen) {
            this.mode = true;
            const elem = document.documentElement;
            const methodToBeInvoked = elem['requestFullscreen'] ||
                elem['webkitRequestFullScreen'] ||
                elem['mozRequestFullscreen'] ||
                elem['msRequestFullscreen'];

            if (methodToBeInvoked) { methodToBeInvoked.call(elem); }
        } else {
            this.mode = false;
            const methodToBeInvoked = document['exitFullscreen'] ||
                document['webkitExitFullscreen'] ||
                document['mozCancelFullScreen'] ||
                document['msExitFullscreen'];

            if (methodToBeInvoked) { methodToBeInvoked.call(document); }
        }
    }

    public setFullscreenMode(evt): any{
        if (this.document.fullscreen) {
            this.mode = true;
        } else {
            this.mode = false;
        }
    }

}


