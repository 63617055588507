import {GraphData} from '@modules/graph-mathia/core/model/graph-data';
import {ProgressDataEntity} from '@modules/graph-mathia/core/model/progress-data-entity';
import * as _ from 'lodash';
import {RawProgressDotDetail} from '@modules/graph-mathia/core/model/raw-progress-dot-detail';
import {LearnerProgressDataEntity} from '@modules/graph-mathia/core/model/learner-progress-data-entity';

interface LevelDataRow {
    details: RawProgressDotDetail[];
    average: number;
    count: number;
}

interface LearnerLevelDataRow {
    [p: string]: LevelDataRow;
}

interface SuccessLevel {
    includedMin: number;
    excludedMax: number;
}

export class LevelData extends GraphData {
    public static readonly successLevel: {[p: string]: SuccessLevel} = {
        notAcquired: {includedMin: 0, excludedMax: 25},
        toDo: {includedMin: 25, excludedMax: 50},
        soClose: {includedMin: 50, excludedMax: 75},
        acquired: {includedMin: 75, excludedMax: 101}
    };

    public toGraphFriendlyData(exerciseLimit: number = -1): { data: number, label: string }[] {
        const stats = this.getStatsByLearner(exerciseLimit);

        const progression_not_acquired = this.countBySuccess(stats, LevelData.successLevel.notAcquired);
        const progression_to_do = this.countBySuccess(stats, LevelData.successLevel.toDo);
        const progression_so_close = this.countBySuccess(stats, LevelData.successLevel.soClose);
        const progression_acquired = this.countBySuccess(stats, LevelData.successLevel.acquired);

        const data: { data: number, label: string }[] = [];
        // warning : respect order assigned, pending, closed when push data
        data.push({data: progression_not_acquired, label: 'graph.progression_not_acquired'});
        data.push({data: progression_to_do, label: 'graph.progression_to_do'});
        data.push({data: progression_so_close, label: 'graph.progression_so_close'});
        data.push({data: progression_acquired, label: 'graph.progression_acquired'});
        return data;
    }

    public getStatsByLearner(exerciseLimit: number = -1): LearnerLevelDataRow {
        const formattedRequestResult = this.toFriendlyFormat();
        if (exerciseLimit > -1) {
            const limitedRequestResult = _.merge(
                {},
                ...Object.keys(formattedRequestResult)
                    .map(learnerId => ({[learnerId]: formattedRequestResult[learnerId].slice(0, 5)})));
            return this.addAverageStats(limitedRequestResult);
        }
        return this.addAverageStats(formattedRequestResult);
    }

    public countBySuccess(stats: LearnerLevelDataRow, successLevel: SuccessLevel): number {
        return Object.keys(this.filterBySuccess(stats, successLevel)).length;
    }

    public filterBySuccess(collection: LearnerLevelDataRow, {includedMin, excludedMax}: SuccessLevel): LearnerLevelDataRow {
        const acceptedIds = Object.keys(collection).filter(learnerId => collection[learnerId].average < excludedMax && collection[learnerId].average >= includedMin);
        return _.pick(collection, acceptedIds);
    }

    public toFriendlyFormat(): { [learnerId: string]: RawProgressDotDetail[] } {
        const grouped = this.groupedByLeaner();

        return _.merge({}, ...this.graphFilters.learnerList.map(learnerId => ({
                [learnerId]: grouped[learnerId]
                    .filter(d => d.data.activitiesDone > 0)
                    .map(l => l.data.detail)
                    .flat()
                    .filter(dot => dot.firstAnswer + dot.secondAnswer + dot.failedFirstAttempt + dot.failedRetry !== 0)
                    .sort((a, b) => +a.date - +b.date)
            }))
        );
    }

    private addAverageStats(granularStats: { [learnerId: string]: RawProgressDotDetail[] }): LearnerLevelDataRow {
        return _.merge({}, ...Object.keys(granularStats).map(learnerId => ({
            [learnerId]: _.merge(
                {},
                {details: granularStats[learnerId]},
                {count: granularStats[learnerId].length},
                {average: (granularStats[learnerId].reduce((a, b) => a + b.totalGood, 0) / granularStats[learnerId].length) || 0},
            )
        })));
    }

    private groupedByLeaner(): { [id: string]: { learner: string, entity: ProgressDataEntity, data: LearnerProgressDataEntity }[] } {
        return _.groupBy(this.entities.map(e =>
            Object.keys(e.attributes.data).map(k => ({
                learner: k,
                entity: e, // usefull to debug
                data: e.attributes.data[k]
            }))).flat(), 'learner');
    }
}
