import { LRSTrackingUrl } from '../settings';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommunicationCenterService } from '@modules/communication-center';
import { Subscription} from 'rxjs';

@Injectable()
export class TraceService {

    baseUrl: string = LRSTrackingUrl;
    url: string;

    constructor (
        private http: HttpClient,
    ) {}

    /**
     * Creates a flag of given type for the given entity
     * @param destination to complete base url
     * @param informations to send
     */
    sendPostRequest(destination, body): Subscription {
        this.url = this.baseUrl + destination;
        console.log('url', this.url);
        const headers = new HttpHeaders()
            .set('cache-control', 'no-cache')
            .set('content-type', 'application/json');

        return this.http
            .post(this.url, body, { headers: headers })
            .subscribe(res => console.log(res));
    }
}
