import {Injectable} from '@angular/core';
import {IFormatIdentifier, LessonActivityRoutes, NavigateToLessonOptions} from '@modules/activities/core/models/lessonsActivityRoutes';
import {NavigationExtras, Router} from '@angular/router';
import * as _ from 'lodash';
import {DataEntity} from 'octopus-connect';


@Injectable({
    providedIn: 'root'
})
/**
 * service create to separate and extract navigate logic from activities service
 * !!!!!! i'haven't found how to fired lessonroutepath , navigatetorecap, loadnextlesson method
 */
export class NavigateService {

    constructor(private router: Router) {
    }

    /**
     * Directly execute routing to an url for execute the good activity player from the params
     * @param {DataEntity} data Activity to play (can be a lesson to play as a multi-activity)
     * @param loadBeforeLaunch todo
     * @param relativePath Force the relative path used to calculate new path. If no argument is passed the default value is '../..' (or '../../../..' in case of multi).
     */
    navigateHere(data: any, loadBeforeLaunch?: boolean, relativePath?: string): void {
        const urlFragments = [];
        const type = data.attributes.format.label;

        if (type === 'activity') {
            const {label} = this.getExactRoute(type, data);
            urlFragments.push(LessonActivityRoutes[label]);
        } else if (type === 'divider') {
            urlFragments.push('divider');
        } else if (type === 'lesson') {
            urlFragments.push('multi');
        } else {
            urlFragments.push('media');
        }

        urlFragments.push(data.id);

        if (!relativePath) {
            relativePath = this.router.url.indexOf('multi') > -1 ? '../../../..' : '../..';
        }

        this.router.navigate([
                relativePath,
                ...urlFragments
            ],
            this.constructNavParams(!!loadBeforeLaunch, data.id)
        );
    }

    /**
     * construct route in regard of type dans data
     * @param type : type activity or other
     * @param data : data entity
     * @private
     */
    private getExactRoute(type: string, data: DataEntity): IFormatIdentifier {
        if (type === 'activity') {
            return this.getPropertyFromNestedObject(data['attributes'], ['metadatas', 'typology']);
        } else {
            return {label: this.getPropertyFromNestedObject(data['attributes'], ['format', 'label'])};
        }
    }

    /**
     * generic function to get the exact object from the nestedObject.
     * @param mainObject : Object : object that contain all data
     * @param pathToAttribute : string[] fields to extract in another object
     */
    public getPropertyFromNestedObject(mainObject: Object, pathToAttribute: Array<string>): any {
        return pathToAttribute.reduce((obj, key) =>
            (obj && obj[key] !== 'undefined') ? obj[key] : undefined, mainObject);
    }

    /**
     * construct part of routing in regard of activity id
     * @param loadBeforeLaunch : boolean is load before launch
     * @param activityId : id of activity
     * @private
     */
    private constructNavParams(loadBeforeLaunch: boolean, activityId?: string): NavigationExtras {
        let currentRoute = this.router.routerState.root;
        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
        }

        return {
            relativeTo: currentRoute,
            queryParams: {
                isLoadBeforeLaunch: loadBeforeLaunch,
                id: activityId
            }
        };
    }

    /**
     * return the array to navigate to lesson
     * @param url  current url
     * @param id  id of lesson where to go
     */
    public lessonRoutePath(url: string, id: string): string[] {
        const urlEnd = url.split('lessons')[1];
        const countOccurence = urlEnd.split('/').length - 1; // -1 : string begin with '/' => first item is empty array
        let path = '';
        for (let i = 0; i < countOccurence; i++) {
            path = path + '../';
        }
        const route = [path];
        route.push(id);
        return route;
    }

    /**
     * construct Url And Navigate To Lesson
     * @param lessonId
     * @param assignment
     * @param forceNextLesson
     * @param options
     */
    public constructUrlAndNavigateToLesson(lessonId: number, assignment: boolean, forceNextLesson = false, options?: NavigateToLessonOptions): void {
        const queryParams: { [k: string]: string | number } = {};

        const queryParamsStepIndex = _.get(options ? options : {}, 'startOnStepIndex');
        if (queryParamsStepIndex || queryParamsStepIndex === 0) {
            queryParams['startOnStepIndex'] = queryParamsStepIndex.toString();
        }
        if (assignment) {
            //  fix: if current url is the same as the new url (new lesson to start), we have an error "outlet of null".
            if (forceNextLesson) {
                this.router.navigateByUrl('/followed/list', {skipLocationChange: false})
                    .then(() => this.router.navigate(['/followed', 'assignment', 'lessons', lessonId, 'player'], {skipLocationChange: false, queryParams}));
            } else {
                this.router.navigate(['/followed', 'assignment', 'lessons', lessonId, 'player'], {skipLocationChange: false, queryParams});
            }
        } else {
            this.router.navigate(['lessons', lessonId, 'player'], {skipLocationChange: false, queryParams});
        }
    }

    /**
     * navigate to recap
     * @param currentAssignment current assignement
     * @param currentLesson current lesson contain id
     */
    public navigateToRecap(currentAssignment: DataEntity, currentLesson: any): void {
        if (currentAssignment) {
            this.router.navigate(['/followed', 'assignment', 'lessons', currentLesson.id, 'player', 'recap'], {skipLocationChange: false});
        } else {
            this.router.navigate(['lessons', currentLesson.id, 'player', 'recap'], {skipLocationChange: false});
        }
    }

    /**
     * load the next lesson in multiassignement case
     */
    public loadNextLesson(currentAssignment: DataEntity, nextIndex: number): void {
        let currentRoute = this.router.routerState.root;
        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
        }
        const lessons = currentAssignment.get('assignated_nodes');

        if (nextIndex < lessons.length) {
            const route = this.lessonRoutePath(this.router.url, lessons[nextIndex].id);
            this.router.navigate(route, {relativeTo: currentRoute});
        }
    }
}
