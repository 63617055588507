import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MembersFilterComponent} from './members-filter.component';
import {TranslateModule} from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    exports: [
        MembersFilterComponent
    ],
    declarations: [MembersFilterComponent]
})
export class MembersFilterModule {
}
