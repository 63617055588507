import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FuseSharedModule } from '@fuse/shared.module';
import {FuseQuickPanelComponent} from "./quick-panel.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        FuseQuickPanelComponent
    ],
    imports     : [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        MatIconModule,
        MatTableModule,
        MatTooltipModule,
        MatButtonModule,
        FuseSharedModule,
        TranslateModule
    ],
    exports: [
        FuseQuickPanelComponent
    ]
})
export class QuickPanelModule
{
}
