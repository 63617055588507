import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SectionDraftCreationModalComponent} from '@modules/research-sheet/core/section-draft-display/section-draft-creation-modal/section-draft-creation-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ResearchSheetService} from '@modules/research-sheet/core/research-sheet.service';
import {Router} from '@angular/router';
import {DataEntity} from 'octopus-connect';

@Component({
    selector: 'app-rename-draft-modal',
    templateUrl: './rename-draft-modal.component.html',
    styleUrls: ['./rename-draft-modal.component.scss']
})
export class RenameDraftModalComponent implements OnInit {

    projectForm: FormGroup;
    name: string;

    constructor(
        public dialogRef: MatDialogRef<SectionDraftCreationModalComponent>,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public draft: DataEntity,
        private sheetService: ResearchSheetService,
        private router: Router
    ) { }

    ngOnInit() {
        this.name = this.draft.get('name');
        this.projectForm = this.formBuilder.group({
            name: [this.name]
        });
    }

    save() {
      this.draft.set('name', this.name);
      this.draft.save();
      this.dialogRef.close();
    }
}
