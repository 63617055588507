import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {combineLatest, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, mapTo, /*mapTo,*/ mergeMap, take, tap} from 'rxjs/operators';
import {CollectionOptionsInterface, CollectionPaginator, DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {ProgressData} from '@modules/graph-ubolino/core/model/progress-data';
import {DynamicGraphFilters} from '@modules/graph-ubolino/core/model/dynamic-graph-filters';
import {Learner} from '@modules/graph-ubolino/core/model/learner';
import * as moment from 'moment';
import * as _ from 'lodash';
import {GraphFiltersValues} from '@modules/graph-ubolino/core/model/graph-filters-values';
import {Group} from '@modules/graph-ubolino/core/model/group';
import {Workgroup} from '@modules/graph-ubolino/core/model/workgroup';
import {AttendanceData} from '@modules/graph-ubolino/core/model/attendance-data';
import {GraphFilter} from '@modules/graph-ubolino/core/model/graph-filter';
import {ProgressEndpointFilters} from '@modules/graph-ubolino/core/model/progress-endpoint-filters';
import {MultiLessonDataEntity} from '@modules/graph-ubolino/core/model/multi-lesson-data.entity';
import {DefaultAttendanceFilters, DefaultOwnProgressFilters, DefaultProgressFilters} from '@modules/graph-ubolino/core/model/default-filters';
import {Concept} from '@modules/graph-ubolino/core/model/concept';
import {ChapterEntity, ChaptersService} from 'fuse-core/services/chapters.service';
import {GraphData} from '@modules/graph-ubolino/core/model/graph-data';
import {ProgressDataEntity} from '@modules/graph-ubolino/core/model/progress-data-entity';

const SHARED_FILTERS = ['startDate', 'endDate', 'exerciseType', 'group', 'multiLesson'];

@Injectable({
    providedIn: 'root'
})
export class GraphUbolinoService {
    public filtersChanges = new ReplaySubject<{ raw: Partial<GraphFiltersValues>, optimised: Partial<GraphFiltersValues> }>(1);
    public forceFiltersValues = new Subject();
    public dynamicFilters: DynamicGraphFilters = {always: [], hidden: []};
    public learners: Learner[] = [];
    public lessons: MultiLessonDataEntity[] = [];
    public isReady = new ReplaySubject(1);
    public graphDataArePending = new ReplaySubject(1);
    public workgroups: Workgroup[] = [];
    public groups: Group[] = [];
    public currentUser: DataEntity;
    public silentlySetFilterValues = new Subject<{ field: string, value: any }>();
    public concepts: Concept[] = [];
    private cacheFilters: Partial<GraphFiltersValues> = {};
    private cache: { progressRawData: ProgressData, attendanceRawData: AttendanceData } = {
         progressRawData: null,
         attendanceRawData: null
    };
    public chapters: ChapterEntity[] = [];

    constructor(private router: Router,
                private communicationCenter: CommunicationCenterService,
                private octopusConnect: OctopusConnectService,
                private chaptersService: ChaptersService) {
        this.isReady.next(false);
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((currentUser: DataEntity) => {
                if (!!currentUser) {
                    this.postAuthentication(currentUser);
                    this.currentUser = currentUser;
                } else {
                    this.postLogout();
                    this.currentUser = undefined;
                }
            });
        this.filtersChanges.pipe(debounceTime(250)).subscribe(filters => {
            this.cacheFilters = _.merge(this.cacheFilters, filters.raw);
        });
    }

    private get flattenedDynamicFilters(): GraphFilter[] {
        return [...this.dynamicFilters.always, ...this.dynamicFilters.hidden];
    }

    /**
     * Transforme an object from something like user filters (inputs) to a format accepted by the endpoint 'Progress' ({@link loadProgressData})
     * @param filters
     */
    static toProgressEndpointFriendlyFilters(filters: Partial<GraphFiltersValues>): ProgressEndpointFilters {
        return !filters ? undefined : new ProgressEndpointFilters(
            moment(filters.startDate).unix(),
            moment(filters.endDate).unix(),
            filters.lessonList,
            filters.learnerList || +filters.learner,
        );
    }

    public getAttendanceGraphData(): Observable<AttendanceData> {
        const isValid = (a: GraphFiltersValues) => !!a.startDate && !!a.endDate;

        const isSame = (a: Partial<GraphFiltersValues>, b: Partial<GraphFiltersValues>) =>
            a.exerciseType === b.exerciseType
            && a.endDate.toString() === b.endDate.toString()
            && a.startDate.toString() === b.endDate.toString()
            && a.attendanceView === b.attendanceView
            && _.isEqual(a.learnerList, b.learnerList);

        this.resetDynamicFiltersForAttendanceGraph();

        return this.filtersChanges.pipe(
            debounceTime(1000),
            filter((graphFilters) => this.toggleActivityAttendanceView(graphFilters.raw.attendanceView === 'activity')),
            map(f => f.optimised),
            filter(isValid),
            distinctUntilChanged(isSame),
            tap(() => this.graphDataArePending.next()),
            mergeMap(graphFilters => {
                if (!graphFilters.learnerList || graphFilters.learnerList.length === 0) {
                    return of(new AttendanceData(graphFilters, []));
                }

                return this.getProgressDataIfNeeded<AttendanceData>(AttendanceData, graphFilters, this.cache.attendanceRawData).pipe(
                    tap(attendanceData => this.cache.attendanceRawData = attendanceData) // Si je le met sur la ligne d'en dessous, phpstorm est dans les choux
                );
            })
        );
    }

    /**
     * Return the data used to generate a 'progress' graph. If filters changed, the observable is refresh
     * @remarks, use a custom cache to avoid useless request.
     */
    public getProgressGraphData(): Observable<ProgressData> {
        const isSame = (a: Partial<GraphFiltersValues>, b: Partial<GraphFiltersValues>) =>
            a.learner === b.learner
            // && a.exerciseType === b.exerciseType
            // && a.view === b.view
            // && a.lessonList === b.lessonList
            && a.endDate.toString() === b.endDate.toString()
            && a.startDate.toString() === b.startDate.toString() && a.lessonList === b.lessonList;

        const isValid = (a: GraphFiltersValues) =>
            !!a.startDate
            && !!a.endDate
            // && !!(a.exerciseType || a.lessonList)
            && !!a.learner; // on a pas besoin du learner pour la requête, mais sans on pourra pas traiter les données


        this.resetDynamicFiltersForProgressGraph();

        // @ts-ignore
        return this.filtersChanges.pipe(
            debounceTime(1000),
            map(f => f.optimised),
            filter(isValid),
            distinctUntilChanged(isSame),
            tap(() => this.graphDataArePending.next()),
            mergeMap(graphFilters => this.getProgressDataIfNeeded<ProgressData>(ProgressData, graphFilters, this.cache.progressRawData).pipe(
                tap(progressData => this.cache.progressRawData = progressData)
            ))
        );
    }

    /**
     * Return the data used to generate a 'progress' graph. If filters changed, the observable is refresh
     * @remarks, use a custom cache to avoid useless request.
     */
    public getOwnProgressGraphData(): Observable<ProgressData> {
        const isSame = (a: Partial<GraphFiltersValues>, b: Partial<GraphFiltersValues>) =>
            a.endDate.toString() === b.endDate.toString()
            && a.startDate.toString() === b.startDate.toString();

        const isValid = (a: GraphFiltersValues) => !!a.startDate && !!a.endDate;

        this.resetDynamicFiltersForOwnProgressGraph();

        // @ts-ignore
        return this.filtersChanges.pipe(
            debounceTime(1000),
            map(f => f.optimised),
            filter(isValid),
            distinctUntilChanged(isSame),
            tap(() => this.graphDataArePending.next()),
            tap(graphFilter => graphFilter.learner = this.currentUser.id.toString()),
            mergeMap(graphFilters => this.getOwnProgressDataIfNeeded<ProgressData>(ProgressData, graphFilters, this.cache.progressRawData).pipe(
                 tap(progressData => { this.cache.progressRawData = progressData; })
            ))
        );
    }

    /**
     * Return the list of the learner sorted by nicknames
     */
    public getLearnersAlphabetically(): Learner[] {
        return this.learners.sort((a, b) => a.nickname.localeCompare(b.nickname, [], {numeric: false}));
    }

    /**
     * get learners from filters group and workgroup. But if both are valued, get only learners inside both group and workgroup.
     * @private
     * @param groupId
     * @param workgroupId
     */
    public getLearnerFilteredOfGroupAndWorkgroup(groupId: string | number, workgroupId: string | number): number[] {
        let learnersIds: number[] = [];
        if (!!groupId && !!workgroupId) {
            const group = this.groups.find(g => +g.id === +groupId);
            const workgroup = this.workgroups.find(g => +g.id === +workgroupId);

            if (!!group && !!workgroup) {
                const wgIds = workgroup.learnersIds.map(id => +id);
                learnersIds = group.learnersIds.filter(id => wgIds.includes(+id));
            }
        } else if (!!groupId) {
            const group = this.groups.find(g => +g.id === +groupId);
            if (!!group) {
                learnersIds.push(...group.learnersIds.map(id => +id));
            }
        } else if (workgroupId) {
            const workgroup = this.workgroups.find(g => +g.id === +workgroupId);
            if (!!workgroup) {
                learnersIds.push(...workgroup.learnersIds.map(id => +id));
            }
        }
        return learnersIds;
    }

    /**
     * store the learner selected on level graph to use it on progress graph when open it directly from level graph
     * @param learnerId : id of learner
     */
    public storeLearnerSelectedInCacheFilter(learnerId: string): void {
        const currentLearner = <any>this.learners.filter(l => l.id.toString() === learnerId)[0];
        this.cacheFilters.learner = currentLearner.nickname;
    }

    /**
     * take the learner in cache and put it inside the dynamic filter used to init filter
     * use to set filter in progress of the learner we click previously in level graph
     */
    public setLearnerDynamicFilterWithCacheFilter(): void {
        // get learner stored in cache to inject the same
        if (!!this.cacheFilters.learner) {
            const learnerFilter = this.flattenedDynamicFilters.find(f => f.label === 'learner');
            if (!!learnerFilter) {
                learnerFilter.value = this.cacheFilters.learner;
            }
        }
    }

    public graphsAreAvailable(): boolean {
        return this.learners.length > 0;
    }

    // private getExerciseIdsFromPersonalLessons(graphFilter: Partial<GraphFiltersValues>): string[] {
    //     return this.lessons.filter(l => (graphFilter.lessonList || []).includes(+l.id)).map(l => l.attributes.reference.map(subLesson => subLesson.id)).flat();
    // }

    /**
     * Return a list of Lesson's Granules with silent auto pagination.
     */
    private getLessonGranuleCollectionInChain(options?: CollectionOptionsInterface): Observable<DataEntity[]> {
        return this.communicationCenter.getRoom('activities')
            .getSubject('loadLessonGranuleCollection')
            .pipe(
                mergeMap((getLessonCallback: (options?: CollectionOptionsInterface) => Observable<{ entities: DataEntity[], paginator: CollectionPaginator }>) =>
                    getLessonCallback(_.merge({page: 1, range: 10}, options)).pipe(
                        take(1),
                        // Il y a un bug dans octopus-connect, il met à jour le paginator (le count par exemple) un poil trop tard, on a déjà reçu le résultat ici.
                        // Donc on s'endort quelques millisecondes pour lui laisser le temps de réagir au résultat avant nous.
                        debounceTime(300),
                        mergeMap(firstAttempt => {
                            const firstAttemptEntities = firstAttempt.entities.slice();
                            const total = firstAttempt.paginator.count;
                            const pages: number[] = [];
                            let currentPage = 1; // la premiere page est dans le "firstAttempt", donc on passe directement a la page suivante

                            if (total < options.range) {
                                return of(firstAttempt.entities);
                            }

                            do {
                                currentPage++;
                                pages.push(currentPage);
                            } while ((currentPage * options.range) < total);


                            return combineLatest(
                                pages.map(i => getLessonCallback(_.merge({range: 10}, options, {page: i})).pipe(debounceTime(300)))
                            ).pipe(
                                take(1),
                                map((combinedResult) => combinedResult.map(c => c.entities).flat()),
                                map(results => [...firstAttemptEntities, ...results]),
                                map( results => _.uniqBy(results, l => l.id))
                            );

                        })
                    )
                ),
            );
    }

    private postAuthentication(currentUser: DataEntity): void {
        combineLatest([
            this.initLearners(),
            this.initGroups(),
            this.initWorkgroups(),
            this.initChapters(),
            this.initLessons(currentUser),
            this.initConcepts(),
        ])
            // .pipe(take(1))
            .subscribe(() => {
                // this.resetDynamicFiltersForProgressGraph(); // Default values;
                this.isReady.next(true);
                this.communicationCenter
                    .getRoom('graphUbolino')
                    .getSubject('initSelectedLearnerFilter')
                    .pipe(
                        tap((selectedLearnerId: number) => this.storeLearnerSelectedInCacheFilter(selectedLearnerId.toString())),
                        tap(() => this.router.navigate(['graph-ubolino', 'multi', 'progress']))
                    )
                    .subscribe();
            });
    }

    private postLogout(): void {
        this.learners = [];
        this.lessons = [];
        this.chapters = [];
        this.groups = [];
        this.workgroups = [];
        this.concepts = [];
        this.isReady.next(false);
    }

    private resetDynamicFiltersForAttendanceGraph(): void {
        // Set default values
        const learnerIds: number[] = [];
        this.dynamicFilters = _.clone(DefaultAttendanceFilters);

        const flatFilters = this.flattenedDynamicFilters;
        if (this.groups.length > 0) {
            flatFilters.find(f => f.label === 'group').value = this.groups[0].groupname;
            learnerIds.push(...this.getLearnerFilteredOfGroupAndWorkgroup(this.groups[0].id, null));
        } else {
            learnerIds.push(...this.getLearnerFilteredOfGroupAndWorkgroup(null, null));
        }
        flatFilters.find(f => f.label === 'multiLearner').value = this.learners.filter(l => learnerIds.includes(+l.id)).map(l => l.nickname);
        // But erase it with cache filters
        this.setCachedFilters();
        this.forceFiltersValues.next();
    }

    private resetDynamicFiltersForProgressGraph(): void {
        // Set default values
        const learner = this.getFirstLearnerAlphabetically();
        // const exerciseType = this.getFirstExerciseTypeAlphabetically();
        this.dynamicFilters = _.clone(DefaultProgressFilters);
        const flatFilters = this.flattenedDynamicFilters;
        if (!!learner) {
            flatFilters.find(f => f.label === 'learner').value = learner.nickname;
        }
        // if (!!exerciseType) {
        //     flatFilters.find(f => f.label === 'exerciseType').value = exerciseType.attributes.name;
        // }
        // But erase it with cache filters
        this.setCachedFilters();
        this.forceFiltersValues.next();
    }

    private resetDynamicFiltersForOwnProgressGraph(): void {
        // Set default values
        this.dynamicFilters = _.clone(DefaultOwnProgressFilters);
        // But erase it with cache filters
        this.setCachedFilters();
        this.forceFiltersValues.next();
    }

    // tslint:disable-next-line:no-shadowed-variable y'a pas de variable shadowed mais l'ide rale
    private loadProgressData(filter: ProgressEndpointFilters): Observable<DataCollection> {
        if (filter.id === null || filter.id === undefined) {
            delete filter.id;
        }

        return this.octopusConnect.paginatedLoadCollection('learners-stat', {filter}).collectionObservable;
    }

    private getProgressDataIfNeeded<T extends GraphData>(type: { new(...any: any): T }, graphFilters: Partial<GraphFiltersValues>, cache: T = null): Observable<T> {
        const newFilters = GraphUbolinoService.toProgressEndpointFriendlyFilters(graphFilters);
        const oldFilters = GraphUbolinoService.toProgressEndpointFriendlyFilters(_.get(cache, 'graphFilters'));

        let obs: Observable<T>;
        // On teste que oldFilters car c'est pas la responsabilité de cette methode de le gérer avant
        // Et au premier chargement du graph, y'a pas de oldFilters
        if (!!oldFilters && newFilters.isSame(oldFilters)) {
            const cloned = _.clone(cache);
            cloned.graphFilters = graphFilters;
            obs = of(cloned);
        } else {
            obs = this.loadProgressData(newFilters).pipe(
                take(1),
                map(progressRawData => {
                    return new type(graphFilters, <ProgressDataEntity[]>progressRawData.entities);
                })
            );
        }

        return obs;
    }

    private getOwnProgressDataIfNeeded<T extends GraphData>(type: { new(...any: any): T }, graphFilters: Partial<GraphFiltersValues>, cache: T = null): Observable<T> {
        const newFilters = GraphUbolinoService.toProgressEndpointFriendlyFilters(graphFilters);
        const oldFilters = GraphUbolinoService.toProgressEndpointFriendlyFilters(_.get(cache, 'graphFilters'));

        let obs: Observable<T>;
        // On teste que oldFilters car c'est pas la responsabilité de cette methode de le gérer avant
        // Et au premier chargement du graph, y'a pas de oldFilters
        if (!!oldFilters && newFilters.isSame(oldFilters)) {
            const cloned = _.clone(cache);
            cloned.graphFilters = graphFilters;
            obs = of(cloned);
        } else {
            obs = this.loadProgressData(newFilters).pipe(
                take(1),
                map(progressRawData => new type(graphFilters, <ProgressDataEntity[]>progressRawData.entities))
            );
        }

        return obs;
    }

    private getFirstLearnerAlphabetically(): Learner {
        return this.getLearnersAlphabetically()[0];
    }

    private getFirstExerciseTypeAlphabetically(): ChapterEntity {
        return this.chapters.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name, [], {numeric: false}))[0];
    }

    // private setLearners(graphFilters: Partial<GraphFiltersValues>): Partial<GraphFiltersValues> {
    //     graphFilters.learnerList = this.getLearnerFilteredOfGroupAndWorkgroup(graphFilters.group, graphFilters.workgroup);
    //     return graphFilters;
    // }

    // private setExerciseIdsFromChapters(graphFilters: Partial<GraphFiltersValues>, cacheGraphRawData: GraphData): Observable<Partial<GraphFiltersValues>> {
    //     const toEndpointFriendlyFilters = (filters: Partial<GraphFiltersValues>) => !filters ? undefined : {chapters: filters.exerciseType};
    //     const isSame = (a, b) => a.chapters === b.chapters;
    //
    //     const newFilters = toEndpointFriendlyFilters(graphFilters);
    //     const oldFilters = toEndpointFriendlyFilters(_.get(cacheGraphRawData, 'graphFilters'));
    //
    //     if (!!oldFilters && isSame(newFilters, oldFilters)) {
    //         // Si la requete est la même que la précédente
    //         graphFilters.exerciseList = cacheGraphRawData.graphFilters.exerciseList;
    //         return of(graphFilters);
    //     } else if (newFilters.chapters === undefined || newFilters.chapters === null || newFilters.chapters === '') {
    //         // Ou s'il n'y a meme pas de quoi faire une requete (garde fou, c'est mieux qu'on appelle pas cette méthode dans ce cas)
    //         graphFilters.exerciseList = [];
    //         return of(graphFilters);
    //     }
    //
    //     return this.getLessonGranuleCollectionInChain({filter: {chapters: graphFilters.exerciseType}, range: 200})
    //         .pipe(
    //             take(1),
    //             map(lessons => lessons.map(l => l.id.toString())),
    //             tap(lessonIdList => graphFilters.exerciseList = lessonIdList),
    //             mapTo(graphFilters)
    //         );
    // }

    private initLearners(): Observable<void> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList')
            .pipe(
                filter((learners) => learners !== null),
                map(list => this.learners = list),
                tap(() => this.forceFiltersValues.next()) // ¨Fix la liste vide d'élève en rechargeant la page d'un graph.
            );
    }

    private initLessons(currentUser: DataEntity): Observable<MultiLessonDataEntity[]> {
        return combineLatest([this.getUserLessons(currentUser), this.getModelsLessons()])
            .pipe(
                map(([userLessons, modelLessons]: DataEntity[][]) => [...userLessons, ...modelLessons]),
                map((lessons: DataEntity[]) =>
                    (<MultiLessonDataEntity[]>lessons).sort((a, b) => {
                            const lastTime = (d) => +d.attributes.changed > +d.attributes.metadatas.changed ? +d.attributes.changed : +d.attributes.metadatas.changed;
                            return lastTime(a) - lastTime(b);
                        }
                    ).slice()
                ),
                map(lessons => this.lessons = lessons),
            );
    }

    private getModelsLessons(): Observable<DataEntity[]> {
        return this.communicationCenter.getRoom('activities')
            .getSubject('getAllowedRoleIdsForModelsCreationCallback')
            .pipe(
                map((callback: () => number[]) => callback()),
                mergeMap(modelsRoles => this.getLessonGranuleCollectionInChain({filter: {role: modelsRoles, multi_step: 0, typology: null}, range: 200})),
                take(1)
            );
    }

    private getUserLessons(user: DataEntity): Observable<DataEntity[]> {
        return this.getLessonGranuleCollectionInChain({filter: {author: user.id, multi_step: 0}, range: 200})
            .pipe(
                take(1),
            );
    }

    private initGroups(): Observable<void> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList')
            .pipe(
                tap(glist => this.groups = glist.filter((group) => !group.archived))
            );
    }

    private initWorkgroups(): Observable<void> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList')
            .pipe(
                tap(wglist => this.workgroups = wglist.filter((wgroup) => !wgroup.archived))
            );
    }

    private initConcepts(): Observable<Concept[]> {
        return this.octopusConnect.loadCollection('concepts').pipe(
            take(1),
            map(collection => collection.entities as Concept[]),
            tap(concepts => this.concepts = concepts)
        );
    }

    private setCachedFilters(...limitFilterList: string[]): void {
        const filters = limitFilterList.length > 0
            ? this.flattenedDynamicFilters.filter(f => limitFilterList.includes(f.label))
            : this.flattenedDynamicFilters;

        filters.forEach(graphFilter => {
            if (SHARED_FILTERS.includes(graphFilter.label) && !!this.cacheFilters[graphFilter.label]) {
                graphFilter.value = this.cacheFilters[graphFilter.label];
            }
        });
    }

    /**
     * When activity view is choose in assiduity graph, we add a filter, and remove it when another choice is done
     * @param isActivityView
     * @private
     * @return false if the filter is added or deleted, true if no operation is made
     */
    private toggleActivityAttendanceView(isActivityView: boolean): boolean {
        if (isActivityView && this.dynamicFilters.always.some(f => f.label === 'exerciseType') === false) {
            const exerciseType = this.getFirstExerciseTypeAlphabetically();
            let defaultValue = '';
            if (!!exerciseType) {
                defaultValue = exerciseType.attributes.name;
            }

            this.flattenedDynamicFilters.find(f => f.label === 'attendanceView').value = 'activity';
            this.dynamicFilters.always.push({label: 'exerciseType', value: defaultValue});
            this.setCachedFilters('exerciseType');
            this.forceFiltersValues.next();
            return false;
        } else if (isActivityView === false && this.dynamicFilters.always.some(f => f.label === 'exerciseType')) {
            this.flattenedDynamicFilters.find(f => f.label === 'attendanceView').value = 'global';
            this.dynamicFilters.always = this.dynamicFilters.always.filter(f => f.label !== 'exerciseType');
            this.forceFiltersValues.next();
            return false;
        }
        return true;
    }

    private initChapters(): Observable<void> {
        return this.chaptersService.getChapters().pipe(
            tap(entities => this.chapters = entities),
            mapTo(null)
        );
    }
}
