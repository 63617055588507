import { Injectable } from '@angular/core';
import {OctopusConnectService} from 'octopus-connect';
import {ErrorCases} from './error-cases.class';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {WaitingForResponseModalComponent} from '@modules/browser-test/core/modals/waiting-for-response-modal/waiting-for-response-modal.component';
import {GenericErrorModalComponent} from '@modules/browser-test/core/modals/generic-error-modal/generic-error-modal.component';
import {InterfaceError} from 'octopus-connect';
import {AuthenticationService} from '../../authentication';

@Injectable()
export class OctopusConnectListenerService {

    private waitingModalRef: MatDialogRef<WaitingForResponseModalComponent>;
    private exclusive = false;

    private ignoreAll = false;

    private errorsDialogRef: MatDialogRef<GenericErrorModalComponent>;

    private storedErrors: InterfaceError[] = [];

    private conf = {
        '-1': ErrorCases.OK,
        '0': ErrorCases.WAITING,
        '401': ErrorCases.REDIRECTION,
        '403': ErrorCases.MESSAGE,
        '404': ErrorCases.RELOAD_APP,
        '500': ErrorCases.RELOAD_APP,
        '503': ErrorCases.RELOAD_APP,
        '504': ErrorCases.RELOAD_PAGE
    };

    constructor(
        octopusConnect: OctopusConnectService,
        private dialog: MatDialog,
        private authService: AuthenticationService
    ) {
        octopusConnect.globalMessageSubject.subscribe((error: InterfaceError) => {
            if (error) {
                console.log('Message', error.message);
                this.checkErrorCase(error);
            }
        });

        document.addEventListener('keydown', (evt: KeyboardEvent) => {
            if (evt.ctrlKey && evt.key === 'e') {
                this.toggleErrorsModal();
                evt.preventDefault();
            }
        });
    }

    isErrorStored(error: InterfaceError): boolean {
        for (const stored of this.storedErrors) {
            if (stored.message === error.message) {
                return true;
            }
        }

        return false;
    }

    /**
     * Receive the errorName, and opens (or close) a popup according to the case
     * @param {string} error
     */
    checkErrorCase(error: InterfaceError) {


        console.log('check', error);

        // const errNum: number = +error;

        if (error !== undefined && !this.ignoreAll && this.isAdmin) {
            if (!this.isErrorStored(error)) {
                this.storedErrors.push(error);
                if (!this.errorsDialogRef) {
                    this.openErrorsModal();
                }
            }
        }

        if (this.exclusive) {
            return;
        }

        /*if (this.conf[error]) {
            const code: string = this.conf[error];

        }*/

        /*switch (errorName) {
            case ErrorCases.WAITING:
                this.exclusive = true;

                this.waitingModalRef = this.dialog.open(WaitingForResponseModalComponent, {
                    disableClose: true
                });

                break;

            case ErrorCases.OK:
                // this.exclusive = true;

                if (this.waitingModalRef) {
                    this.waitingModalRef.close();
                    this.waitingModalRef = null;
                    this.exclusive = false;
                }

                break;

            case ErrorCases.RELOAD_APP:
            case ErrorCases.RELOAD_PAGE:
                this.exclusive = true;

                this.dialog.open(FatalErrorModalComponent, {
                    disableClose: true
                });

                break;

            case ErrorCases.RELOAD_APP:
                this.exclusive = true;

                this.dialog.open(InMaintenanceModalComponent, {
                    disableClose: true
                });

                break;
        }*/
    }

    toggleErrorsModal() {
        if (this.errorsDialogRef) {
            this.errorsDialogRef.close();
        } else {
            this.openErrorsModal();
        }
    }

    openErrorsModal() {
        this.errorsDialogRef = this.dialog.open(GenericErrorModalComponent, {
            data: {
                errors: this.storedErrors
            }
        });
        this.errorsDialogRef.afterClosed().subscribe((args: Object) => {
            this.errorsDialogRef = null;

            if (args && args['ignoreAll']) {
                this.ignoreAll = true;
            }
        });
    }

    get isAdmin(): boolean {
        return this.authService.isAdministrator();
    }

}
