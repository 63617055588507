import {EventListener} from './event-listener.class';
import {Observable, ReplaySubject, Subject} from 'rxjs';

export class CommunicationRoom {

    /**
     * Listeners index
     * @type {{}}
     */
    private listeners: {[key: string]: EventListener[]} = {};

    /**
     * References dataSubjects
     * @type {{}}
     */
    private dataSubjects: {[key: string]: ReplaySubject<any>} = {};


    ////////// EVENTS //////////

    /**
     * Dispatch an event in the room
     * @param {string} eventName The dispatched event name
     * @param args Argument sent with the event
     */
    dispatchEvent(eventName: string, args: any) {
        if (this.listeners[eventName]) {
            this.listeners[eventName].forEach((listener: EventListener) => {
                listener.callback(args);
            });
        }
    }

    /**
     * Listen an event
     * @param {string} eventName The event name
     * @param {Function} callback Event callback
     * @returns {EventListener} An event listener object
     */
    addListener(eventName: string, callback: Function): EventListener {
        const listener: EventListener = new EventListener(this, eventName, callback);

        if (!this.listeners[eventName]) {
            this.listeners[eventName] = [];
        }

        this.listeners[eventName].push(listener);

        return listener;
    }

    /**
     * Remove all event listener with this name
     * @param {string} eventName The event name
     */
    removeAllListener(eventName: string) {
        delete this.listeners[eventName];
    }

    /**
     * Remove à specific listener
     * @param {string} eventName The event name
     * @param {Function} callback Listener callback
     */
    // voir à utiliser un filter au lieu de la boucle
    removeListener(eventName: string, callback: Function) {
        for (let i: number = this.listeners[eventName].length - 1; i >= 0; i--) {
            if (this.listeners[eventName][i].callback === callback) {
                this.listeners[eventName].splice(i, 1);
            }
        }
    }

    /**
     * Deletes an event listener
     * @param {EventListener} listener
     */
    deleteListenerReference(listener: EventListener) {
        const index: number = this.listeners[listener.eventName].indexOf(listener);

        if (index !== -1) {
            this.listeners[listener.eventName].splice(index, 1);
        }
    }


    ///////// OBSERVABLES ////////////

    /**
     * Get a subject, by name
     * @param {string} name The subject name
     * @returns {Subject<any>} The subject associated to the name
     */
    getSubject(name: string): Subject<any> {
        if (!this.dataSubjects[name]) {
            const subject: ReplaySubject<any> = new ReplaySubject<any>(1);
            this.dataSubjects[name] = subject;
            return subject;
        } else {
            return this.dataSubjects[name];
        }
    }

    /**
     * Remove a subject by name
     * @param {string} name The subject name
     */
    removeSubject(name: string) {
        if (this.dataSubjects[name]) {
            this.complete(name);
            delete this.dataSubjects[name];
        } else {
            console.log('No subject available width this name ' + name);
        }
    }

    /**
     * Send a new value in the subject, by name
     * @param {string} observableName The subject name
     * @param value The next value
     */
    next(observableName: string, value: any) {
        this.getSubject(observableName).next(value);
    }

    /**
     * Dispatch an error in the subject
     * @param {string} observableName The subject name
     * @param {Object} arg The error object
     */
    error(observableName: string, arg: Object) {
        this.getSubject(observableName).error(arg);
    }

    /**
     * Complete a subject, by name
     * @param {string} observableName The subject name
     */
    complete(observableName: string) {
        this.getSubject(observableName).complete();
    }
}
