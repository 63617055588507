import { catchError, map, mergeMap, take, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, of, ReplaySubject } from "rxjs";
import { DataEntity, OctopusConnectService } from "octopus-connect";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CommunicationCenterService } from "@modules/communication-center";
import { ModelSchema, Structures } from "octopus-model";
import { defaultRoute, modulesSettings } from "../../../settings";
import {
  UpdateMailDialogComponent,
  UpdateMailDialogDataInterface,
} from "@modules/authentication/core/update-mail-dialog/update-mail-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UserDataEntity } from "@modules/authentication/core/models/user-data-entity.type";
import * as _ from "lodash";

const settingsStructureAuth: ModelSchema = new ModelSchema({
  displayLoginLogo: Structures.boolean(false),
  enableGAR: Structures.boolean(false),
  enableSSO: Structures.boolean(false),
  firstConnexionRedirection: Structures.object({}),
  forceSetEmail: Structures.boolean(false),
  // Occurs in login.component
  isRegisterLinkTop: Structures.boolean(false),
  logoutUrlSSO: Structures.object(),
  onlyLoginWithMail: Structures.boolean(false),
  overrideDefaultRouteByRole: Structures.object({}),
  urlSSO: Structures.object(),
  validateEmailStrategyActivated: Structures.boolean(false),
});

const settingsStructureAccount: ModelSchema = new ModelSchema({
  selfSignup: Structures.boolean(true),
  signup: Structures.boolean(true),
});

@Injectable()
export class AuthenticationService {
  /**
   * Mapping between role name and role id.
   * It can be given by the server but in all the instance we work to always use the same name/id combination
   */
  private static readonly roleMapping = {
    administrator: 3,
    manager: 4,
    trainer: 5,
    learner: 6,
    director: 7,
  };

  loggedUser: DataEntity;
  isAuthenticated = false;
  subject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  errorHttpAuthentication: ReplaySubject<any> = new ReplaySubject<any>(1);
  public settings: { [key: string]: any };

  constructor(
    private octopusConnect: OctopusConnectService,
    private router: Router,
    private translate: TranslateService,
    private communicationCenter: CommunicationCenterService,
    private dialog: MatDialog
  ) {
    this.settings = settingsStructureAuth.filterModel(
      modulesSettings.authentication
    );
    Object.assign(
      this.settings,
      settingsStructureAccount.filterModel(modulesSettings.accountManagement)
    );
    this.octopusConnect.getUnexpectedLogoutSubject("http").subscribe(
      () => {
        this.doLogout();
      },
      (error: Object) => {
        this.errorHttpAuthentication.next(error);
      }
    );

    this.communicationCenter
      .getRoom("authentication")
      .getSubject("do-logout")
      .subscribe((callback) => {
        this.logoutFrom("http");
        callback();
      });

    this.communicationCenter
      .getRoom("authentication")
      .next("roles", AuthenticationService.roleMapping);

    this.communicationCenter
      .getRoom("authentication")
      .getSubject("goToLogin")
      .subscribe(() => this.goToLogin());

    this.communicationCenter
      .getRoom("authentication")
      .getSubject("doLogout")
      .subscribe(() => this.doLogout());

    this.communicationCenter
      .getRoom("authentication")
      .getSubject("connectAfterValidatedAccountByEmail")
      .subscribe((data) => this.connectionAfterValidatingEmail(data));
  }

  get userData(): null | DataEntity {
    if (this.loggedUser) {
      this.isAuthenticated = true;
      return this.loggedUser;
    }

    return null;
  }

  /**
   * Obtain the good setting value by use the current user's role. If the setting don't have the role has a key, the method return the value associated to the "default" key.
   *
   * @remarks if the current user's role and the "default" key is not included in the setting, an error is threw
   *
   * @param data the global settings object with `default` and roles as keys
   *
   * @example
   * const setting = {default: [], trainer: ['email']}
   * const userSetting = this.getRoleAssociatedValueOrDefault(setting);
   * // if user is trainer, userSetting is ['email'], else [].
   *
   * @todo create a global permission service to do that
   */
  public getRoleAssociatedValueOrDefault<T>(data: { [role: string]: T }): T {
    const role = this.accessLevel;

    if (_.has(data, role)) {
      return data[role];
    } else if (_.has(data, "default")) {
      return data["default"];
    }

    throw new Error(
      `no current user's role as key or "default" key for this setting`
    );
  }

  get accessLevel(): string {
    if (this.loggedUser) {
      const role = this.loggedUser.get("role") || [];

      if (role.indexOf(3) > -1) {
        return "administrator";
      }
      if (role.indexOf(4) > -1) {
        return "manager";
      }
      if (role.indexOf(7) > -1) {
        return "director";
      }
      if (role.indexOf(5) > -1) {
        return "trainer";
      }
      if (role.indexOf(6) > -1) {
        return "learner";
      }
      if (role.indexOf(2) > -1) {
        return "authenticated";
      }
    }

    return "anonymous";
  }

  loginSSO(code): void {
    this.octopusConnect
      .createEntity("user-registration", { code: code })
      .subscribe(
        (userData: DataEntity) => {
          if (userData && userData.get("token")) {
            this.router.navigate(["/user/reset/", userData.get("token")]);
          }
        },
        (error: Object) => {
          this.errorHttpAuthentication.next(error);
        }
      );
  }

  authenticateIn(
    serviceName: string,
    login: string,
    password: string
  ): Observable<UserDataEntity> {
    return this.octopusConnect
      .authenticate(
        serviceName,
        unescape(encodeURIComponent(login)),
        unescape(encodeURIComponent(password))
      )
      .pipe(
        take(1),
        mergeMap((user: UserDataEntity) => {
          let obs = of(user);

          // if validating email is need before connecting user
          if (
            this.settings.validateEmailStrategyActivated &&
            !user.get("email_status")
          ) {
            return obs;
          }

          if (
            (this.settings.forceSetEmail &&
              user.get("updateProfile") === true) ||
            user.get("updateToAcademicMail")
          ) {
            obs = obs.pipe(
              tap((subUser) =>
                this.askForMailUpdate(
                  subUser,
                  !!user.get("updateToAcademicMail")
                )
              )
            );
          }

          return obs.pipe(tap((subUser) => this.onAuthenticated(subUser)));
        }),
        catchError((error) => {
          this.errorHttpAuthentication.next(error);
          throw new Error(error);
        })
      );
  }

  onAuthenticated(data: DataEntity): void {
    this.loggedUser = data;
    this.isAuthenticated = true;
    this.communicationCenter.getRoom("authentication").next("userData", data);
  }

  public isMe(id: string | number): boolean {
    if (this.loggedUser) {
      return this.loggedUser.id.toString() === id.toString();
    }

    return false;
  }

  public isAnonymous(): boolean {
    return this.accessLevel === "anonymous";
  }

  public isAuthenticatedUser(): boolean {
    return this.loggedUser.get("role").indexOf(2) > -1;
  }

  public isSSO(): boolean {
    return this.loggedUser.get("sso");
  }

  public isGAR(): boolean {
    return this.isSSO() && this.settings.enableGAR;
  }

  public isLearner(): boolean {
    return this.accessLevel === "learner";
  }

  public isAtLeastLearner(): boolean {
    return this.hasLevel([
      "learner",
      "trainer",
      "director",
      "manager",
      "administrator",
    ]);
  }

  public isTrainer(): boolean {
    return this.accessLevel === "trainer";
  }

  public isAtLeastTrainer(): boolean {
    return this.hasLevel(["trainer", "director", "manager", "administrator"]);
  }

  public isDirector(): boolean {
    return this.accessLevel === "director";
  }

  public isAtLeastDirector(): boolean {
    return this.hasLevel(["director", "manager", "administrator"]);
  }

  public isManager(): boolean {
    return this.accessLevel === "manager";
  }

  public isAtLeastManager(): boolean {
    return this.hasLevel(["manager", "administrator"]);
  }

  public isAdministrator(): boolean {
    return this.accessLevel === "administrator";
  }

  public hasLevel(levels: string[]): boolean {
    return levels.indexOf(this.accessLevel) > -1;
  }

  logoutFrom(serviceName: string): void {
    let token;
    if (this.settings.enableSSO && this.loggedUser.get("sso")) {
      token = this.loggedUser.get("sso_token");
    }

    const data = new DataEntity(
      "authenticated",
      { myType: "authenticated" },
      this.octopusConnect,
      this.loggedUser["id"]
    );
    data.remove();

    this.octopusConnect.logout(serviceName).subscribe(() => {
      this.doLogout();
    });
  }

  forgotPassword(login: string): Observable<DataEntity> {
    return this.octopusConnect.createEntity("reset-password", {
      mail: login,
      lang: this.translate.currentLang,
    });
  }

  public goToLogin(state?): void {
    if (state) {
      this.router.navigate(["/login"], {
        queryParams: {
          return: state.url,
        },
      });
    } else {
      this.router.navigate(["/login"]);
    }
  }

  /**
   * Return true if the currently connected user is connected for the very first time.
   * @remarks If the user is not logged return false
   */
  public isFirstConnexion(): boolean {
    return !!this.userData && this.userData.get("first_access");
  }

  /**
   * fire a send of email to validate
   * @param email : email of user who hasn't validate email before
   */
  sendNewLinkEmailValidation(email: string): Observable<any> {
    return this.octopusConnect.createEntity("user-registration", {
      email: email,
      sendValidationMail: true,
    });
  }

  protected doLogout(): void {
    this.isAuthenticated = false;
    this.loggedUser = null;
    this.communicationCenter.getRoom("authentication").next("userData", null);
    this.goToLogin();
  }

  private askForMailUpdate(
    subUser: UserDataEntity,
    required = false
  ): MatDialogRef<UpdateMailDialogComponent> {
    const dialogRef = this.dialog.open<
      UpdateMailDialogComponent,
      UpdateMailDialogDataInterface
    >(UpdateMailDialogComponent, {
      data: {
        updateMail: (mail) => {
          if (!!mail) {
            subUser.set("email", mail);
            return subUser.save().pipe(map(() => null));
          }

          throw new Error("no mail given");
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== "done" && required) {
        this.logoutFrom("http");
      }
    });

    return dialogRef;
  }

  /**
   * if user create account when he validate email he s connected without
   * type password because he directly use token so we init data like if he was connected by
   * login or token in standard process
   * @param userData : DataEntity contain user data
   * @private
   */
  private connectionAfterValidatingEmail(userData: DataEntity): void {
    this.loggedUser = userData;
    this.isAuthenticated = true;
  }

  /**
   * in some case the default route is not the same for user
   * for example gestionnaire will have upload route in ubolino instance
   */
  overrideDefaultRouteInRegardOfRole(
    useDefaultRouteIfNoRulesMatch = false
  ): void {
    // manager case if setting exist will be redirect
    if (
      this.settings.overrideDefaultRouteByRole &&
      this.settings.overrideDefaultRouteByRole[this.accessLevel]
    ) {
      this.router.navigate([
        this.settings.overrideDefaultRouteByRole[this.accessLevel],
      ]);
    } else if (useDefaultRouteIfNoRulesMatch) {
      this.router.navigate([defaultRoute]);
    }
  }

  /**
   * If true, only mail could be use to authenticate user, if false it's with mail or username
   */
  public onlyLoginWithMail(): boolean {
    return this.settings.onlyLoginWithMail;
  }
}
