import { Injectable } from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../app/settings';
import {Subject} from 'rxjs';

const settingsStructure: ModelSchema = new ModelSchema({
    menuLinks: Structures.object({
        lesson: {
            administrator: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'assign', 'duplicate'],
            default: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'assign']
        },
        model: {
            administrator: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'duplicate'],
            manager: ['duplicate'],
            default: ['duplicate']
        }
    }),
    cardFields: Structures.object({
        default: ['title', 'menu', 'lessonStats', 'keywords', 'metadatas', 'assigned']
    }),
    cardDisplay: Structures.object({
        showButtonThemeLesson: Structures.boolean(false),
        showButtonBookmarks: Structures.boolean(true),
        isTextButton: Structures.boolean(false)
    }),
    cardDefaultHeader: Structures.string(),
    cardPlayOpenMenu: Structures.boolean( false),
    islaunchLessonAskClass: Structures.boolean( false),
    displayMenuWithPreviewOption: Structures.boolean(false),
    difficultyDisplaySteps: Structures.number(0)
});

@Injectable({
  providedIn: 'root'
})
export class CardsService {

  public settings: { [key: string]: any };
  public settingBookmark = new Subject();

  constructor() {
      this.settings = settingsStructure.filterModel(modulesSettings.cards);
  }

}
