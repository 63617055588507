import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../settings';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import * as _ from 'lodash';

const settingsStructure: ModelSchema = new ModelSchema({
    alwaysShowDashboard: Structures.boolean(false),
    displaySentenceBeforeHeader: Structures.boolean(false),
    displayHeaderLink: Structures.boolean(true),
    displayTabsPage: Structures.boolean(false),
    displayedWidgets: Structures.object({default: []}),
    filtersByRole: Structures.object({
        default: []
    }),
    newsLimit: Structures.number(-1),
    orderFields: Structures.boolean(false),
    // {@link IsUserAuthorisedByRoleGuard}
    forbiddenPaths: Structures.object({
        default: []
    })
});

@Injectable()
export class DashboardService {

    private components: any[] = [];
    // TODO loi de demeter les classes dépendantes de ce service n'ont pas a connaitre le format/les fonctions de settings.
    public settings: { [key: string]: any };

    constructor(
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.settings = settingsStructure.filterModel(modulesSettings.dashboard);
    }

    private postLogout(): void {

    }

    private postAuthentication(): void {

    }

    register(component: string, callback: () => any): void {
        this.components = this.components.filter((element) => element.name !== component);
        this.components.push({
            name: component,
            getData: callback
        });
    }

    get widgets(): any[] {
        const data = [];

        this.components.forEach((element: any) => {
            data.push({
                name: element.name,
                data: element.getData()
            });
        });

        return data;
    }

    public shouldAlwaysShowDashboard(): boolean {
        return this.settings.alwaysShowDashboard;
    }

    public getFiltersForRole(role: string): string[] {
        return this.settings.filtersByRole.hasOwnProperty(role) ?
            this.settings.filtersByRole[role].slice() :
            this.settings.filtersByRole['default'].slice();
    }

    public getDisplayedWidgetForRole(role: string): string[] {
        try {
            return this.settings.displayedWidgets.hasOwnProperty(role) ?
                this.settings.displayedWidgets[role].slice() :
                this.settings.displayedWidgets['default'].slice();
        } catch (e) {
            console.error(e);
        }
        return [];
    }

    public shouldDisplayHeaderLink(): boolean {
        return this.settings.displayHeaderLink;
    }

    public shouldDisplayTabsPage(): boolean {
        return this.settings.displayTabsPage;
    }

    public shouldOrderFields(): boolean {
        return this.settings.orderFields;
    }

    public getNewsLimit(): number {
        return this.settings.newsLimit;
    }

    /**
     * Return a list of unaccepted routes for roles
     */
    public getForbiddenPaths(): { [role: string]: (string | RegExp)[] } {
        return this.settings.forbiddenPaths;
    }
}
