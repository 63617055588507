import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {RouterModule, Routes} from '@angular/router';
import {ResearchSheetService} from './research-sheet.service';
import { ResearchSheetListComponent } from './research-sheet/research-sheet-list/research-sheet-list.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { ResearchSheetFormComponent } from './research-sheet/research-sheet-form/research-sheet-form.component';
import { ResearchSheetDisplayComponent } from './research-sheet/research-sheet-display/research-sheet-display.component';
import { ResearchSheetDisplaySectionComponent } from './research-sheet/research-sheet-display/research-sheet-display-section/research-sheet-display-section.component';
import { ResearchSheetDisplayLinkComponent } from './research-sheet/research-sheet-display/research-sheet-display-link/research-sheet-display-link.component';
import { SectionDisplayComponent } from './section-display/section-display.component';
import { SectionDetailComponent } from './section-display/section-detail/section-detail.component';
import { SectionGuidelineAndGridComponent } from './section-display/section-guideline-and-grid/section-guideline-and-grid.component';
import { SectionMediaLearnerComponent } from './section-display/section-media-learner/section-media-learner.component';
import { SectionCommentTrainerComponent } from './section-display/section-comment-trainer/section-comment-trainer.component';
import {ResearchSheetDisplayService} from '@modules/research-sheet/core/research-sheet/research-sheet-display/research-sheet-display.service';
import { SectionDraftListComponent } from './section-draft-display/section-draft-list/section-draft-list.component';
import { SectionDraftCreationModalComponent } from './section-draft-display/section-draft-creation-modal/section-draft-creation-modal.component';
import { SectionTypeSelectionComponent } from './section-draft-display/section-type-selection/section-type-selection.component';
import { SectionDraftEditComponent } from './section-draft-display/section-draft-edit/section-draft-edit.component';
import { DraftQuestionsModalComponent } from './section-draft-display/draft-questions-modal/draft-questions-modal.component';
import {SectionDisplayService} from '@modules/research-sheet/core/section-display/section-display.service';
import {ResearchTemplateListComponent} from '@modules/research-sheet/core/research-template/research-template-list/research-template-list.component';
import { CorpusModalComponent } from './section-draft-display/corpus-modal/corpus-modal.component';
import {CorpusModule} from '@modules/corpus';
import { ResearchSheetDisplayAddSectionComponent } from './research-sheet/research-sheet-display/research-sheet-display-add-section/research-sheet-display-add-section.component';
import { ResearchSheetListSectionStateComponent } from './research-sheet/research-sheet-list/research-sheet-list-section-state/research-sheet-list-section-state.component';
import {SharedModule} from '../../../shared/shared.module';
import { RenameDraftModalComponent } from './section-draft-display/rename-draft-modal/rename-draft-modal.component';
import {GenericModalService} from '@modules/research-sheet/core/generic-modal.service';
import {GenericModalComponent} from '@modules/research-sheet/core/generic-modal/generic-modal.component';
import { SectionDraftItemComponent } from './section-draft-item/section-draft-item.component';
import { ResearchTemplateItemComponent } from './research-template/research-template-item/research-template-item.component';
import {ResearchTemplateService} from './research-template/research-template.service';
import { EditedTemplateSectionChoiceComponent } from './research-template/edited-template-section-choice/edited-template-section-choice.component';
import { EditTemplateSectionComponent } from './research-template/edit-template-section/edit-template-section.component';
import { SectionChoiceItemComponent } from './research-template/section-choice-item/section-choice-item.component';
import { RenameTemplateModalComponent } from './research-template/rename-template-modal/rename-template-modal.component';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {NotificationsService} from '@modules/notification/core/notifications.service';
import {GettingStartedModule} from 'fuse-core/components/getting-started/getting-started.module';

const routes: Routes = [
    {
        path       : 'research-sheet',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'list',
                component: ResearchSheetListComponent
            },
            {
                path: ':sheetId',
                children: [
                  {
                    path: 'display',
                    component: ResearchSheetDisplayComponent,
                  },
                  {
                    path: 'section/edit',
                    children: [
                      {
                        path: '',
                        component: SectionDisplayComponent,
                      },
                      {
                        path: ':sectionid',
                        component: SectionDisplayComponent,
                      }
                    ]
                  },
                  {
                    path: '**',
                    pathMatch: 'full',
                    redirectTo: 'display'
                  }
                ]
            },
            {
                path: '**',
                pathMatch: 'full',
                redirectTo: 'list'
            }
        ]
    },
    {
        path       : 'research-template',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'edit/:sheetid',
                component: EditedTemplateSectionChoiceComponent
            },
            {
                path: 'edit/:sheetid/view/:sectionid',
                component: EditTemplateSectionComponent,
                data: {
                    mode: 'display'
                }
            },
            {
                path: 'edit/:sheetid/edit/:sectionid',
                component: EditTemplateSectionComponent,
                data: {
                    mode: 'edition'
                }
            },
            {
                path: 'list',
                component: ResearchTemplateListComponent
            },
            {
                path: '**',
                pathMatch: 'full',
                redirectTo: 'list'
            }
        ]
    },
    {
        path       : 'section-draft',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'list',
                component: SectionDraftListComponent
            },
            {
                path: 'section-type',
                component: SectionTypeSelectionComponent
            },
            {
                path: 'edit/:editionid',
                component: SectionDraftEditComponent,
                data: {
                    displayMode: 'edition'
                }
            },
            {
                path: 'view/:editionid',
                component: SectionDraftEditComponent,
                data: {
                    displayMode: 'view'
                }
            }
        ]
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedModule,
        CorpusModule,
        GettingStartedModule
    ],
    declarations: [
        ResearchSheetListComponent,
        ResearchSheetFormComponent,
        ResearchSheetDisplayComponent,
        ResearchSheetDisplaySectionComponent,
        ResearchSheetDisplayLinkComponent,
        SectionDisplayComponent,
        SectionDetailComponent,
        SectionGuidelineAndGridComponent,
        SectionMediaLearnerComponent,
        SectionCommentTrainerComponent,
        ResearchSheetDisplayLinkComponent,
        ResearchTemplateListComponent,
        SectionDraftListComponent,
        SectionDraftCreationModalComponent,
        SectionTypeSelectionComponent,
        SectionDraftEditComponent,
        DraftQuestionsModalComponent,
        CorpusModalComponent,
        ResearchSheetDisplayAddSectionComponent,
        ResearchSheetListSectionStateComponent,
        RenameDraftModalComponent,
        GenericModalComponent,
        SectionDraftItemComponent,
        ResearchTemplateItemComponent,
        EditedTemplateSectionChoiceComponent,
        EditTemplateSectionComponent,
        SectionChoiceItemComponent,
        RenameTemplateModalComponent
    ],
    providers: [

    ],
    entryComponents: [
        ResearchSheetFormComponent,
        SectionDraftCreationModalComponent,
        DraftQuestionsModalComponent,
        CorpusModalComponent,
        RenameDraftModalComponent,
        RenameTemplateModalComponent,
        GenericModalComponent,
        ResearchSheetDisplayAddSectionComponent
    ]
})
export class ResearchSheetModule {

    private static isMenuSet = false;

    constructor(
        notificationsService: NotificationsService,
        private dynamicNavigation: DynamicNavigationService,
        private communicationCenter: CommunicationCenterService,
        private authService: AuthenticationService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Research Sheet',
                type: 'WAITING_FOR_VALIDATION',
                icon: 'search',
                text: 'research-sheet.notification_section_waiting_validation',
                textTransform: (text: string, datas: Object) => {
                    return {
                        groupName: datas['groupName'],
                        sheetName: datas['sheetName']
                    };
                },
                action: (data: Object) => {
                    return [
                        'projects',
                        data['projectId'],
                        'tools',
                        'research-sheet',
                        data['sheetId'],
                        'display'
                    ];
                }
            });


        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Research Sheet',
                type: 'SECTION_VALIDATED_BY_TEACHER',
                icon: 'search',
                text: 'research-sheet.notification_section_corrected',
                textTransform: (text: string, datas: Object) => {
                    return {
                        sectionName: datas['sectionName'],
                        sheetName: datas['sheetName']
                    };
                },
                action: (data: Object) => {
                    return [
                        'projects',
                        data['projectId'],
                        'tools',
                        'research-sheet',
                        data['sheetId'],
                        'section',
                        'edit',
                        data['sectionId']
                    ];
                }
            });

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Research Sheet',
                type: 'RESEARCH_SHEET_DRAFT_CREATED',
                icon: 'search',
                text: 'research-sheet.notification_draft_created',
                textTransform: (text: string, datas: Object) => {
                    return {
                        userName: datas['author'],
                        name: datas['name'],
                        projectName: datas['projectName']
                    };
                },
                action: (data: Object) => {
                    console.log(data);
                    return [
                        'projects',
                        data['projectId'],
                        'tools',
                        'section-draft',
                        'edit',
                        data['rsId']
                    ];
                }
            });

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Research Sheet',
                type: 'RESEARCH_SHEET_CREATED',
                icon: 'search',
                text: 'research-sheet.notification_created',
                textTransform: (text: string, datas: Object) => {
                    return {
                        userName: datas['author'],
                        name: datas['rsName'],
                        projectName: datas['projectName']
                    };
                },
                action: (data: Object) => {
                    console.log(data);
                    return [
                        'projects',
                        data['projectId'],
                        'tools',
                        'research-sheet',
                        data['rsId'],
                        'display'
                    ];
                }
            });

    }

    private postLogout(): void{
        ResearchSheetModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'tools');
    }

    private postAuthentication(): void{
        if (!ResearchSheetModule.isMenuSet && this.authService.hasLevel(['trainer', 'manager', 'administrator'])) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'tools',
                'title': 'Outils',
                'translate': 'generic.tools',
                'type': 'collapse',
                'icon': 'outils2',
            });
            this.dynamicNavigation.addChildTo('level0', 'tools', {
                'id': 'param-child',
                'title': 'Modèles de fiches recherches',
                'translate': 'research-sheet.search_sheets_model_title',
                'type': 'item',
                'url': '/research-template/list',
                'weight': 2
            });

            ResearchSheetModule.isMenuSet = true;
        }
    }

    static forRoot(): ModuleWithProviders<ResearchSheetModule> {
        return {
            ngModule: ResearchSheetModule,
            providers: [
                ResearchSheetService,
                ResearchSheetDisplayService,
                SectionDisplayService,
                GenericModalService,
                ResearchTemplateService
            ]
        };
    }
}
