import { Injectable } from '@angular/core';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {GenericModalComponent} from "./generic-modal/generic-modal.component";
import {Observable} from "rxjs";
import {GenericModalData} from "./generic-modal-data.interface";

@Injectable()
export class GenericModalService {

    constructor(
        private dialogRef: MatDialog,
    ) { }

    openGenericDialog(data: GenericModalData, disableClose: boolean = true): Observable<string> {
        return this.dialogRef.open(GenericModalComponent, <MatDialogConfig>{
            data: data,
            width: '400px',
            disableClose: disableClose,
            autoFocus: false
        }).beforeClosed();
    }

    openYesNoDialog(text: string): Observable<string> {
        return this.openGenericDialog({
            text: text,
            buttons: {
                no: 'generic.no',
                yes: 'generic.yes'
            }
        });
    }

    openOkDialog(text: string): Observable<string> {
        return this.openGenericDialog({
            text: text,
            buttons: {
                ok: 'generic.ok'
            }
        });
    }
}
