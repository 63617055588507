import {Injectable} from '@angular/core';
import {DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {filter, mergeMap, map, mapTo, take, tap} from 'rxjs/operators';
import {CollectionOptionsInterface} from 'octopus-connect';
import * as _ from 'lodash';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MindmapDataEditorModalComponent} from '@modules/mindmap/core/mindmap-data-editor-modal/mindmap-data-editor-modal.component';
import {CommunicationCenterService} from '@modules/communication-center';
import {NavigationExtras, Router} from '@angular/router';
import {MindmapGenericAlertModalComponent} from '@modules/mindmap/core/mindmap-generic-alert-modal/mindmap-generic-alert-modal.component';
import {IMindmapFormData, MindmapRepositoryService} from '@modules/mindmap/core/mindmap-repository.service';
import {MindmapContentViewerModalComponent} from '@modules/mindmap/core/mindmap-content-viewer-modal/mindmap-content-viewer-modal.component';
import {v4 as uuidv4} from 'uuid';

// Should be a copy of LessonToolDataCommunicationCenterInterface interface in GenericPluginService
export interface MindmapDataCommunicationCenterInterface {
    lesson: DataEntity;
    onComplete: ReplaySubject<DataEntity>;
}

/**
 * List field options to apply on mindmap creation or edition
 *
 * @remarks For now, only the `associatedLessonId` field is used
 */
export interface IMindmapFormOptions {
    [fieldName: string]: { disable: boolean };
}

@Injectable({
    providedIn: 'root'
})
/**
 * Define the mindmap business rules of the application
 */
export class MindmapService {
    /**
     * Obtain the current user or null if not authenticated
     */
    public currentUser$ = new ReplaySubject<DataEntity>(1);
    /**
     * List of unique {@link ReplaySubject} used to identity when an mindmap edition is done.
     * There should never have more than one subject at times, but a subject in this array can be an old one and we need a way to identify if it's the good one.
     * For resolve this problem, we use an object has an hashMap/Key->Value array. The `k` is the unique identifier to a subject
     */
    public onMindmapDataEditionCompleteSubject: { [k: string]: ReplaySubject<DataEntity> } = {};
    /**
     * Obtain the function to load lessons. This method is the container and undefined, it's filled in the constructor by calling activities-module
     */
    private loadPaginatedLessons: (type?: string, roles?: number[], searchValue?, filterOptions?: {}) => Observable<DataEntity[]>;
    private getAllowedRoleIdsForModelsCreation;
    private shareableModel;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private mindmapRepoSvc: MindmapRepositoryService,
        private octopusConnect: OctopusConnectService,
        private router: Router,
    ) {
        this.communicationCenter
            .getRoom('activities')
            .getSubject('loadPaginatedLessonsCallback')
            .subscribe((callback: (type?: string, roles?: number[], searchValue?, filterOptions?: {}) => Observable<DataEntity[]>) => {
                this.loadPaginatedLessons = callback;
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('getAllowedRoleIdsForModelsCreationCallback')
            .subscribe((callback) => {
                this.getAllowedRoleIdsForModelsCreation = callback;
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('shareableModelCallback')
            .subscribe((callback) => {
                this.shareableModel = callback;
            });

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .pipe(
                filter(currentUser => !!currentUser),
                tap(currentUser => {
                    this.currentUser$.next(currentUser);
                })
            )
            .subscribe();

        /**
         * Used to create or edit a mindmap from everywhere out of the current module
         */
        this.communicationCenter
            .getRoom('mindmap')
            .getSubject('execute')
            .pipe(
                mergeMap((args: MindmapDataCommunicationCenterInterface) =>
                    this.createOrEditMindmapIfAlreadyExistForLesson(args.lesson.id).pipe(
                        map(mindmap => args.onComplete.next(mindmap))
                    )
                )
            )
            .subscribe();
    }

    /**
     * Obtains the paginated list of current user's notes
     * @param filterOptions
     * @return The {@link DataEntity} are `granules` and the are not of `mindmaps` but `BasicSearch` endpoint
     */
    public getCurrentUserPaginatedMindmaps(filterOptions: CollectionOptionsInterface = {}): Observable<PaginatedCollection> {
        return this.currentUser$.pipe(
            filter(currentUser => !!currentUser),
            map(currentUser => _.merge({
                filter: {
                    author: currentUser.id
                }
            }, filterOptions)),
            mergeMap(options => this.mindmapRepoSvc.getPaginatedMindmaps(options)),
            take(1)
        );
    }

    /**
     * Open and return the mindmap data editor modal.
     *
     * - It's the same way to create a note ({@link goToMindmapDataCreation}) but the save method passed to the modal is used to patch mindmap
     *
     * @param mindmap Used to defined which mindmap to edit and the default form values.
     * Should be a entity given by the `BasicSearch` endpoint or similar because the default values are not in the same path for other endpoints
     */
    public goToMindmapDataEdition(mindmap: DataEntity): MatDialogRef<MindmapDataEditorModalComponent, DataEntity | null> {
        return this.goToMindmapDataAndContentEditors({
            saveMindmap: (data) => this.mindmapRepoSvc.updateMindmap(mindmap.id, data),
            defaultValues: {
                title: _.get(mindmap.get('metadatas'), 'title'),
                associatedLessonId: _.get(mindmap.get('reference'), 'activity_content[0].associated_nodes[0].id')
            }
        });
    }

    /**
     * Open and return the mindmap data editor modal.
     *
     * - It's the same way to edit a note ({@link goToMindmapDataCreation}) but the save method passed to the modal is used to create mindmap*
     */
    public goToMindmapDataCreation(defaultValues?: IMindmapFormData, options?: IMindmapFormOptions): MatDialogRef<MindmapDataEditorModalComponent, DataEntity | null> {
        return this.goToMindmapDataAndContentEditors({
            saveMindmap: (data) => this.mindmapRepoSvc.createMindmap(data),
            defaultValues: defaultValues,
            options: options
        });
    }

    /**
     * Redirect to the list of current user's mindmap
     */
    public goToMindmapList(): Promise<boolean> {
        return this.router.navigate(['mindmap', 'list']);
    }

    /**
     * Obtain the lesson available to be associated to a mindmap
     * An lesson is available because
     * - User have access to it
     * - No current user's mindmap are already associated to it
     *
     * @remarks: the backend limit the result to 50 item, if we need more, we need to make it paginated
     */
    public getAssociableLessons(): Observable<DataEntity[]> {
        return this.currentUser$.pipe(
            filter(currentUser => !!currentUser),
            mergeMap(currentUser => {
                const allModelsWithoutMindmap$ = this.getLessons({
                    filter: {
                        'removeMindmapAttachedNode': true,
                        'role': this.getAllowedRoleIdsForModelsCreation(), // get role used for models creation
                        'model': this.shareableModel()
                    }
                });

                const allMyLessonsWithoutMindmap$ = this.getLessons({
                    filter: {
                        'author': currentUser.id,
                        'removeMindmapAttachedNode': true,
                    }
                });

                return combineLatest([
                    allModelsWithoutMindmap$,
                    allMyLessonsWithoutMindmap$
                ]);
            }),
            map(([notSharedLessons, sharedLessons])  => [...notSharedLessons, ...sharedLessons])
        );
    }

    /**
     * Obtain the lesson already associated to a mindmap o current user
     *
     * @remarks: the backend limit the result to 50 item, if we need more, we need to make it paginated
     */
    public getAssociatedLessons(): Observable<DataEntity[]> {
        return this.getLessons({
            filter: {
                'removeMindmapAttachedNode': false
            }
        });
    }

    /**
     * Obtain the all lessons
     * @param filterOptions
     */
    public getLessons(filterOptions: {}): Observable<DataEntity[]> {
        const mergedOptions = _.merge({
            filter: {
                'multi_step': 0
            }
        }, filterOptions);

        return this.loadPaginatedLessons('all', null, '', mergedOptions);
    }

    /**
     * Ask user if he confirm the mindmap deletion and, if it's ok, delete it
     * @param id of the mindmap granule
     */
    public deleteMindmap(id: number | string): Observable<boolean> {
        const modalData = {data: {contentKey: 'mindmap.ask_before_delete_alert'}};
        return this.dialog.open(MindmapGenericAlertModalComponent, modalData).afterClosed().pipe(
            filter(isConfirm => !!isConfirm),
            mergeMap(() => this.mindmapRepoSvc.destroyMindmap(id))
        );
    }

    /**
     * Display the mindmap content as a read-only modal
     * @param id of the mindmap Granule
     */
    displayMindmap(id: number | string): void {
        this.dialog.open(MindmapContentViewerModalComponent, {data: {mindmap$: this.mindmapRepoSvc.getMindmap(id)}});
    }

    /**
     * Open the mindmap editor modal and if there are a mindmap given on closed go to mindmap content editor
     * @param data The saveMindmap method receive the form data TODO give an interface of form data here
     */
    private goToMindmapDataAndContentEditors(
        data: { saveMindmap: (data) => Observable<DataEntity>; defaultValues: IMindmapFormData; options?: IMindmapFormOptions }
    ): MatDialogRef<MindmapDataEditorModalComponent, DataEntity | null> {
        const modalData = _.merge({
            availableLessons$: this.getAssociableLessons(),
            getLessonById: (id: string | number) => this.getLessonById(id)
        }, data);

        const dialogRef = this.dialog.open(MindmapDataEditorModalComponent, {
            data: modalData
        });

        dialogRef.afterClosed().pipe(
            // If is mindmapGranule empty is because the modal is closed without want to create/edit the mindmap
            filter(mindmapGranule => !!mindmapGranule),
            tap(mindmapGranule => {
                this.goToMindmapContentEditor(mindmapGranule);
            })).subscribe();

        return dialogRef;
    }

    /**
     * Redirect the user to the mindmap content editor page.
     *
     * @param mindmapGranule
     * @param navigationExtras some data to pass by the url, show {@link onMindmapDataEditionCompleteSubject} for example
     */
    private goToMindmapContentEditor(mindmapGranule: DataEntity, navigationExtras?: NavigationExtras): void {
        this.router.navigate(['mindmap', mindmapGranule.id, 'edit'], navigationExtras);
    }

    /**
     * Obtain a lesson from the activities module
     * @param id unique identifier of the lesson DataEntity
     */
    private getLessonById(id: string | number): Observable<DataEntity> {
        const lesson$ = new ReplaySubject<Observable<DataEntity>>(1);

        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId: id,
                callbackSubject: lesson$
            });

        return lesson$.pipe(
            mergeMap(obs => obs)
        );
    }

    /**
     * Generic way to open a note hypothetically already created
     * @param lessonId unique identifier of lesson granule used to identify if a mindmap is alrealdy associated to this lesson.
     *  Open the mindmap editor if true, the mindmap creator if false but with the lesson associated by default
     */
    private createOrEditMindmapIfAlreadyExistForLesson(lessonId: number | string): Observable<DataEntity> {
        const onCompleteSubject = new ReplaySubject<DataEntity>(1);
        const onCompleteSubjectId: string = uuidv4();
        this.onMindmapDataEditionCompleteSubject[onCompleteSubjectId] = onCompleteSubject;

        return this.getCurrentUserPaginatedMindmaps({filter: {associated_node: lessonId}}).pipe(
            // We load the mindmap from the lesson id associated
            mergeMap(paginatedCollection => paginatedCollection.collectionObservable.pipe(
                take(1)
            )),
            map(collection => collection.entities),
            mergeMap(entities => {
                if (entities.length > 1) {
                    throw new Error('a user must have only one note by lesson');
                } else if (entities.length === 0) {
                    // If no mindmap, we creating it
                    return this.goToMindmapDataCreation({associatedLessonId: lessonId}, {associatedLessonId: {disable: true}}).afterClosed();
                } else {
                    // Else we reload it but from another endpoint to get all datas
                    return this.mindmapRepoSvc.getMindmap(entities[0].id);
                }
            }),
            // If the user cancel the mindmap creation there are no mindmap
            filter(mindmap => !!mindmap),
            // whether it existed or not, we are modifying the content now
            tap((mindmap: DataEntity) => this.goToMindmapContentEditor(mindmap, {queryParams: {onComplete: onCompleteSubjectId}, skipLocationChange: false})),
            mergeMap(() => onCompleteSubject)
        );
    }

    public goToLesson(associatedLessonId: string | number): void {
        this.communicationCenter.getRoom('lessons').next('playLesson', {id: associatedLessonId});
    }
}
