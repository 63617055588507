import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {Subject} from 'rxjs';
import {ActivityInterface} from '@modules/activities/core/player-components/activity.interface';
import {DataEntity} from 'octopus-connect';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-divider',
    templateUrl: './divider.component.html'
})
export class DividerComponent implements OnInit, OnDestroy, ActivityInterface {
    public resourceURI: string;
    public description: string;
    public filemime: string;

    @Input('activityId') private activityId: any;
    private unsubscribeInTakeUntil = new Subject();

    constructor (
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.activityId) {
                this.activityId = {};
            }

            if (params) {
                for (let key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activityId[key] = params[key];
                    }
                }
            }
        });
    }

    ngOnInit (): void {
        this.activatedRoute.params.subscribe((params) => {
            this.initialize();
        });
    }

    initialize (): void {
        this.reset();

        if (this.activityId && this.activityId['type'] && this.activityId['type'] === 'granule') {
            /*
                todo need to refacto to have only one format of data : DataEntity
            */
            const activity: DataEntity = <DataEntity>this.activityId;
            this.description = activity.get('metadatas').description;
            this.setContentData(activity.get('reference'));
        } else {
            this.activityService.launchActivity(this.activityId)
                .pipe(takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((divider) => {
                    this.description = divider.metadatas.description;
                    this.setContentData(divider.reference);
                });
        }
    }

    private setContentData (data): void {
        this.filemime = data.filemime;
        this.resourceURI = data.uri;
        this.activityService.saveUserSave(this.activityId.id.toString(), this.activityService.currentAssignmentID, [], 1, 'divider-save');
    }


    ngOnDestroy (): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    reset (): void {
        this.activityService.displayActions.next(false);
    }
}
