export const corpus = {
    accessMatrix: { // on a 2 corpus généraux (= dans le menu principal, non liés à un projet)
        lesson: { // les ressources du prof (il ne voit que les siennes)
            view: ['trainer', 'manager', 'administrator'],
            add: ['trainer', 'manager', 'administrator'],
            edit: ['trainer', 'manager', 'administrator'],
            print: ['trainer', 'manager', 'administrator'],
            delete: ['trainer', 'manager', 'administrator'],
        },
        global: { // ressources mis à disposition par lamorim
            view: ['trainer', 'manager', 'administrator'],
            add: ['manager', 'administrator'],
            edit: ['manager', 'administrator'],
            delete: ['manager', 'administrator'],
            print: ['trainer', 'manager', 'administrator'],
            selectAll: ['learner', 'trainer', 'manager', 'administrator']
        },
        userCorpus: {
            view: ['trainer', 'manager', 'administrator'],
            add: ['trainer', 'manager', 'administrator'],
            edit: ['trainer', 'manager', 'administrator'],
            delete: ['trainer', 'manager', 'administrator'],
            print: ['trainer', 'manager', 'administrator'],
            selectAll: ['learner', 'trainer', 'manager', 'administrator']
        },
        research: {
            add: ['learner', 'trainer', 'manager', 'administrator'],
            edit: ['trainer', 'manager', 'administrator'],
            editOwn: ['learner'],
            delete: ['trainer', 'manager', 'administrator'],
            print: ['trainer', 'manager', 'administrator'],
            deleteOwn: ['learner']
        },
    },
    addToLesson: true,
    allowedExtensions: 'jpg, jpeg, png, gif, mp4, mpeg, mp3, pdf',
    assetFields: {
        default: ['name', 'scolomDate', 'source', 'description', 'tags', 'author', 'target-age', 'difficulty', 'skills']
    },
    assetFieldsAutoOrder: true,
    assetRequiredFields: ['name'],
    assetTextEditorFields: ['description'],
    detailsFields: ['pseudo', 'scolomDate', 'source-author', 'source', 'format', 'target_age', 'description', 'indexation', 'size', 'difficulty', 'skills'],
    authorRolesGeneralCorpus: ['3', '4'], // 3 => administrator, 4 => gestionnaire, 5 => formateur, 6 => apprenant
    authorRolesCommunityCorpus: ['3', '4', '5'], // 3 => administrator, 4 => gestionnaire, 5 => formateur
    cardLayout: 'card-split-media-info',
    columns: {
        default: ['icon', 'title', 'difficulty', 'skills', 'favorite', 'buttonsAction'],
        learner: ['icon', 'title', 'difficulty', 'skills', 'favorite', 'buttonsAction']
    },
    corpusNoClone: true,
    displayCreateCorpusHelper: true,
    displayFiltersIcons: true,
    globalCorpus: '11057',
    fileMaxSize: '40',
    filesWhiteList: [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/gif',
        'video/mp4',
        'video/mpeg',
        'audio/mp3',
        'audio/mpeg',
        'application/pdf'
    ],
    filters: {
        default: ['type', 'title', 'skills', 'difficulty', 'launchSearch']
    },
    filterUsePosition: true,
    mediaTypes: ['audio', 'document', 'image', 'url', 'video'], // 'audio', 'document', 'image', 'media', 'text',  'url', 'video', 'videoUrl'
    showButtonOptions: true,
    terms: {
        globalCorpus: {
            title: 'corpus.title',
            columns: {
                type: 'generic.type',
                author: 'generic.author',
                group: 'generic.group',
            }
        },
        research: {
            title: 'projects-management.research_space',
            columns: {
                type: 'generic.type',
                author: 'generic.author',
                group: 'generic.group',
            }
        },
        lesson: {
            title: 'generic.title_lesson_corpus',
            columns: {
                type: 'generic.type',
                author: 'generic.author',
                group: 'generic.group',
            }
        },
    },
    searchFields: ['type', 'title', 'skills', 'difficulty'], // list of dynamic field use to filter search in corpus
    textEditor: {
        enabled: true,
        config: {toolbar: ['bold', 'italic']},
    },
    userCorpus: true, // define if there is a corpus to list current user assets,
    shareableResourceToCommunity: true,
    displayHeader: false,
    urlVideoException: ['https://mp4.ina.fr/'] // url exception for play video url who is not know by js-video-url-parser
};
