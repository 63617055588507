
import {take} from 'rxjs/operators';
import {defaultApiURL} from '../settings';
import {Injectable} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';

@Injectable()
export class ArchiveService {

    constructor(
        public octopusConnect: OctopusConnectService,
    ) {

    }

    archive(entity: DataEntity){
        entity.set('archived', '1');
        entity.save().pipe(take(1)).subscribe(() => {
            /* if (workgroup.workgroupname !== oldGroupName) {
                this.notificationsService.sendNotificationToUsers(this.workgroupsList.find((item => item.id === workgroup.id)).learnersIds,
                    'GROUP_NAME_CHANGED', {
                        oldName: oldGroupName,
                        newName: workgroup.workgroupname
                    });
            } */
        });
    }

    unarchive(entity: DataEntity){
        entity.set('archived', '0');
        entity.save().pipe(take(1)).subscribe(() => {
            /* if (workgroup.workgroupname !== oldGroupName) {
                this.notificationsService.sendNotificationToUsers(this.workgroupsList.find((item => item.id === workgroup.id)).learnersIds,
                    'GROUP_NAME_CHANGED', {
                        oldName: oldGroupName,
                        newName: workgroup.workgroupname
                    });
            } */
        });
    }
}
