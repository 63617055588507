import {Injectable} from '@angular/core';
import {NewsService} from '@modules/dashboard/core/services/news.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {debounceTime, filter, map, mergeMap, takeUntil} from 'rxjs/operators';
import {DataEntity} from 'octopus-connect';
import {Subject} from 'rxjs/index';
import {NewsInterface} from '@modules/dashboard/core/widgets/widget-news/news.interface';
import {combineLatest, Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NavigationEnd, Router} from '@angular/router';

export interface SnackbarNewsSettings {
    active: boolean;
}

/**
 * This service listen news service to send last news in snackbar
 * - TODO change the NewsInterface to choose a channel (dashboard, snackbar or both)
 */
@Injectable({
    providedIn: 'root'
})
export class SnackbarNewsService {

    private onPostLogout = new Subject();
    private settings: SnackbarNewsSettings = {active: true};

    constructor(private communicationCenter: CommunicationCenterService,
                private newsService: NewsService,
                private snackBar: MatSnackBar,
                private router: Router) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    private postAuthentication(): void {
        const news$ = this.getNews$();
        const pageChange$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

        if (!!this.settings.active) {
            combineLatest([news$, pageChange$])
                .pipe(
                    debounceTime(1000),
                    takeUntil(this.onPostLogout),
                    map(([news, event]: [NewsInterface[], NavigationEnd]) => this.filterNewsByUrl(news, event)),
                    map((news: NewsInterface[]) => this.emitNewsInSnackbar(news))
                )
                .subscribe();
        }
    }

    private postLogout(): void {
        this.onPostLogout.next();
    }

    private getNews$(): Observable<NewsInterface[]> {
        return this.communicationCenter.getRoom('news').getSubject('isReady').pipe(
            filter(isReady => !!isReady),
            mergeMap(() => this.newsService.getNews$())
        );
    }

    private emitNewsInSnackbar(newsList: NewsInterface[]): void {
        newsList.sort((a, b) => b.weight - a.weight) // trié du plus grand au plus petit
            .slice(0, 1)
            .forEach(news => {
                const snackBarRef = this.snackBar.openFromComponent(<any>news.component, {
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    duration: 15000,
                    data: {
                        dismiss: () => {
                            console.log('snackbar-news.service::dismiss::78', snackBarRef);
                            snackBarRef.dismiss();
                        }
                    }
                });

            });

    }

    private filterNewsByUrl(news: NewsInterface[], event: NavigationEnd): NewsInterface[] {
        return news.filter(n => new RegExp(n.channel.snackbar.acceptedUrlRegex).test(event.url));
    }
}
