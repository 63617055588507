import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {DataEntity} from 'octopus-connect';
import {combineLatest} from 'rxjs';
import {UploadResourceService} from '@modules/activities/core/shared-components/upload-resource/upload-resource.service';

@Component({
    selector: 'app-upload-resource',
    templateUrl: './upload-resource.component.html'
})
export class UploadResourceComponent implements OnInit {
    /**
     * TODO:On doit mettre ce composant dans le module Corpus et gerer l'upload grace à un subject transmit via le communication center
     * On recupèrera l'url de la video grace au subject une fois que celle ci sera enregistré.
     */
    public disableField: boolean;
    public form: FormGroup;

    constructor(private uploadService: UploadResourceService,
                public dialogRef: MatDialogRef<UploadResourceComponent>) { }

    ngOnInit(): void {
        this.uploadService.loadFormats();

        this.form = this.createForm();
    }

    getFormatId(): number | void {
        for (const format of this.uploadService.formats) {
            if (format.get('label') === 'videoUrl') {
                return +format.id;
            }
        }
    }

    createForm(): FormGroup {
        const config = {
            url: [{value: '', disabled: this.disableField}, {validators: [Validators.required, (control) => this.validateUrl(control)],    updateOn: 'blur'  }],
            title: [{value: '', disabled: this.disableField}, Validators.required],
            date: [{value: '', disabled: this.disableField}],
            author: [{value: '', disabled: this.disableField}],
            source: [{value: '', disabled: this.disableField}],
            description: [{value: '', disabled: this.disableField}],
        };

        return new FormBuilder().group(config);

    }

    validate(): void {
        this.disableField = true;
        const metadatas = {
            title: this.form.get('title').value ? this.form.get('title').value : 'video',
            scolomDate: this.form.get('date').value,
            'source-author': this.form.get('author').value,
            source: this.form.get('source').value,
            description: this.form.get('description').value,
        };
        const urlObs = this.uploadService.createUrlEntity({url: this.form.get('url').value});
        const metadatasObs = this.uploadService.createRessourceMetadata(metadatas);

        combineLatest(urlObs, metadatasObs)
            .subscribe((data: any[]) => {

                const granule = {
                    format: this.getFormatId(),
                    groups: [],
                    metadatas: data[1].id,
                    parent: this.uploadService.corpusId,
                    reference: data[0].id
                };
                this.uploadService.createGranule(granule)
                    .subscribe((entity: DataEntity) => {
                        this.disableField = false;
                        this.uploadService.sendEventUploadFinished(entity);
                        this.dialogRef.close(this.form.get('url').value);
                    });
            });

    }


    validateUrl(control: AbstractControl): any {
        if (!control || !control.parent) {
            return;
        }

        const url = control.parent.get('url');

        if (!url || url.value === '') {
            return;
        }

        if (url && url.value !== '') {
            const regExp = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
            if (!url.value.match(regExp)) {
                return {urlInvalid: true};
            }
        }
    }
}
