
import {take} from 'rxjs/operators';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ResourceCreationSteps} from '@modules/corpus/core/resource-creation-steps.class';
import {CorpusMetadataInterface} from '@modules/corpus';
import { MatDialogRef } from '@angular/material/dialog';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {CorpusRessourcesTypes} from '@modules/corpus/core/corpus-ressources-types.class';
import {AuthenticationService} from '@modules/authentication';

const urlParser = require('js-video-url-parser');
const ResourceCreationStepsMax = ResourceCreationSteps.STEP4;

@Component({
    selector: 'app-resource-upload-modal',
    templateUrl: './resource-upload-modal.component.html',
    styleUrls: ['./resource-upload-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceUploadModalComponent implements OnInit, OnDestroy {

    currentUploadPhase: ResourceCreationSteps = ResourceCreationSteps.STEP1;
    uploadedFileId: number;
    uploadedFileMime: string;
    url: string;
    isLoading = false; // If is loading, hide all steps for display spinner

    private temporaryResourceMetadata: CorpusMetadataInterface;
    private resourceType: CorpusRessourcesTypes;
    private uploadedFileUrl: { url: string };

    constructor(
        private authService: AuthenticationService,
        private changeDetector: ChangeDetectorRef,
        private corpusService: CorpusService,
        private ref: MatDialogRef<ResourceUploadModalComponent>
    ) {
    }

    ngOnInit(): void {
        this.corpusService.metadataAreCreating = false;
    }

    ngOnDestroy(): void {
        this.changeDetector.detach();
    }

    goToNextStep(): void {
        if (this.currentUploadPhase === ResourceCreationStepsMax) {
            this.submitResource();
        } else {
            const previousStep = this.currentUploadPhase;
            this.currentUploadPhase = (<number>previousStep) + 1;

            if (this.corpusService.currentStepIsAllowed(this.currentUploadPhase) === false) {
                this.goToNextStep();
                return;
            }

            this.changeDetector.detectChanges();
        }
    }

    onFileUploaded(fileArray: any[]): void {
        this.uploadedFileId = fileArray['0'];
        this.uploadedFileMime = fileArray['1'];
        this.goToNextStep();
    }

    onUrlValidated(url: string): void {
        this.url = url;
        this.goToNextStep();
    }

    public onDetailsValidated(metadata: CorpusMetadataInterface): void {
        this.corpusService.metadataAreCreating = true;
        this.changeDetector.detectChanges();
        this.temporaryResourceMetadata = metadata;


        if (this.uploadedFileId) {
            switch (this.uploadedFileMime) {
                case ('image/png'):
                case ('image/jpeg'):
                case ('image/jpg'):
                case ('image/gif'):
                    this.resourceType = CorpusRessourcesTypes.IMAGE;
                    break;
                case ('audio/mp3'):
                case ('audio/mpeg'):
                    this.resourceType = CorpusRessourcesTypes.AUDIO;
                    break;
                case ('video/mpeg'):
                case ('video/mp4'):
                    this.resourceType = CorpusRessourcesTypes.VIDEO;
                    break;
                case ('application/pdf'):
                    this.resourceType = CorpusRessourcesTypes.DOCUMENT;
                    break;
                default:
                    console.log('undefined mime');
            }
        } else if (this.url) {
            const parsed: Object = urlParser.parse(this.url); // check if it is an url video
            if (parsed || this.corpusService.settings.urlVideoException.some((urlException) => this.url.includes(urlException))) {
                this.resourceType = CorpusRessourcesTypes.VIDEO_URL;
            } else {
                this.resourceType = CorpusRessourcesTypes.URL;
            }
            this.uploadedFileUrl = {
                url: this.url
            };
        }

        this.goToNextStep();
    }

    public onChaptersValidated($event): void {
        this.temporaryResourceMetadata.chapters = $event.reduce((accumulator, entity) => {
            accumulator.push(...entity.chapters.map(chapter => chapter.id));
            return accumulator;
        }, []);
        this.goToNextStep();
    }

    public onConceptsValidated($event: any): void {
        this.temporaryResourceMetadata.concepts = $event.map(concept => concept.id);
        this.goToNextStep();
    }

    private submitResource(): void {
        this.isLoading = true;
        this.corpusService
            .createRessource(
                this.resourceType.toString(),
                this.corpusService.corpusId,
                this.uploadedFileUrl ? this.uploadedFileUrl : this.uploadedFileId,
                this.temporaryResourceMetadata).pipe(
            take(1))
            .subscribe(() => {
                this.isLoading = false;
                document.body.focus();
                this.ref.close();
                this.corpusService.metadataAreCreating = false;
                this.changeDetector.detectChanges();
            }, (err) => {
                this.isLoading = false;
                console.error('ResourceUploadModalComponent:136 : ', err);
                this.currentUploadPhase = ResourceCreationSteps.FAILED;
            });
    }

    public onCanceled(): void {
        this.close();
    }

    public reset(): void {
        this.currentUploadPhase = ResourceCreationSteps.STEP1;
        this.changeDetector.detectChanges();
    }

    public onError(): void {
        this.currentUploadPhase = ResourceCreationSteps.FAILED;
        this.changeDetector.detectChanges();
    }

    private close(): void {
        this.ref.close();
    }
}
