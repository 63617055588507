import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {NotepadService} from '../notepad.service';
import {brand} from '../../../../settings';
import * as _ from 'lodash';
import {tap} from 'rxjs/operators';
import {NotepadRepositoryService} from '@modules/notepad/core/notepad-repository.service';

const notepadIconLabel = 'notepad';

@Component({
    selector: 'app-notepad-card',
    templateUrl: './notepad-card.component.html',
})
export class NotepadCardComponent implements OnInit {
    /**
     * Notepad resource used to get card data
     */
    @Input()
    public notepadBasicSearchEntity: DataEntity;
    /**
     * Emit when granule has changed from this component and need to be reloaded
     */
    @Output()
    public hasChanged = new EventEmitter();
    /**
     * Notepad creation date
     */
    public creationDate: string;
    /**
     * card image url (in the left side)
     */
    public imageUri: string;
    /**
     * Notepad last modification date
     */
    public lastUpdate: string;
    /**
     * Notepad associated lesson name
     */
    public associatedLessonTitle: string | null;
    public associatedLessonId: string | number;
    /**
     * Define if assiciated lesson name is loading from it's id
     */
    public associatedLessonIsLoading = true;
    /**
     * Define notepad title
     */
    public title: string;
    private notepadEntity: DataEntity;

    constructor(private notepadSvc: NotepadService,
                private notepadRepoSvc: NotepadRepositoryService) {
    }

    ngOnInit(): void {
        this.creationDate = new Date(this.notepadBasicSearchEntity.get('created') * 1000).toLocaleDateString();
        this.imageUri = this.getImageUri();
        this.lastUpdate = new Date(this.notepadBasicSearchEntity.get('changed') * 1000).toLocaleDateString();
        this.title = this.notepadBasicSearchEntity.get('title');

        // For get the associated lesson title, we need to load the notepad activity content.
        // We don't have the activity content here and to have it, we need to load the granule and everything (incluce the activitycontent) is loaded with it.
        this.notepadRepoSvc.getNotepad(this.notepadBasicSearchEntity.id).pipe(
            tap(notepad => this.notepadEntity = notepad),
            // There are only one activity content and one assignated lesson by notepad
            tap(notepad => {
                this.associatedLessonTitle = _.get(notepad.get('reference'), 'activity_content[0].associated_nodes[0].title');
                this.associatedLessonId = _.get(notepad.get('reference'), 'activity_content[0].associated_nodes[0].id');
            }),
            tap(() => this.associatedLessonIsLoading = false)
        ).subscribe();
    }

    /**
     * Go to notepad editor.
     * No need to know how to edit a notepad here.
     * @param $event
     */
    public edit($event: MouseEvent): void {
        $event.preventDefault();
        this.notepadSvc.goToNotepadDataEdition(this.notepadEntity);
    }

    /**
     * Delete the current Notepad
     * @param $event
     */
    public delete($event: MouseEvent): void {
        $event.preventDefault();
        this.notepadSvc.deleteNotepad(this.notepadEntity.id).pipe(
            tap(() => this.hasChanged.next())
        ).subscribe();
    }

    /**
     * Display the content of the notepad
     * @param $event
     */
    public show($event: MouseEvent): void {
        $event.preventDefault();
        this.notepadSvc.displayNotepad(this.notepadBasicSearchEntity.id);
    }

    /**
     * Extracted way to know the notepad image urls
     */
    private getImageUri(): string {
        return './assets/' + brand + '/icons/' + notepadIconLabel + '.svg';
    }

    public goToLesson(): void {
        this.notepadSvc.goToLesson(this.associatedLessonId);
    }
}
