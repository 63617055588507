import {take, takeUntil, tap} from 'rxjs/operators';
import {ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {Subject} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {CommunicationCenterService} from '@modules/communication-center';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from 'app/settings';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {LRSTracking} from '../../../../../settings';
import {TranslateService} from '@ngx-translate/core';
import {EntityDataSet} from 'octopus-connect';


const settingsStructure = new ModelSchema({
    iframeParams: Structures.array(['uid']),
    dynamicContent: Structures.boolean(false),
    helpButton: Structures.object({})
});

@Component({
    selector: 'app-external',
    templateUrl: './external.component.html'
})
export class ExternalComponent implements OnInit, OnDestroy {

    private activity = {
        id: null,
        isLoadBeforeLaunch: null
    };
    @Input() public activityId: any;
    @Input() public contextId: string;
    @Input() questionTypeName: string;
    @ViewChild('iframeViewport', { static: true }) iframeViewport: ElementRef;
    @ViewChild('iframeView') iframeView: ElementRef;

    private iframeBaseWidth = 800;
    private iframeBaseHeight = 450;
    private isFullscreen = false;


    public questionObject: any;
    public instruction: SafeResourceUrl;
    public wording: string;
    public userSave: DataEntity;
    private LRSTracking = LRSTracking;
    private url: URL;
    private previousIdAssignmentNextEvent: number | string = 0;
    private unsubscribeInTakeUntil = new Subject();
    private isInitializing: boolean;
    public currentUser: DataEntity;
    public settings: { [key: string]: any };


    constructor(
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        private changeDetector: ChangeDetectorRef,
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private dialog: MatDialog,
        private translate: TranslateService
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.activities);
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.currentUser = data;
                }
            });

        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.activityId) {
                this.activityId = {};
            }

            this.activity.id = params.id;
            this.activity.isLoadBeforeLaunch = params.isLoadBeforeLaunch;

            if (params) {
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activityId[key] = params[key];
                    }
                }
            }
        });

        this.octopusConnect.listen('externalAction').pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe((action: DataEntity) => {
            if (action.get('action') === 'next') {
                this.launchNextActivity();
            }
        });

        this.communicationCenter.getRoom('feedback').getSubject('nextActivity').subscribe(next => {
            if (next) {
                this.launchNextActivity();
            }
        });

        this.communicationCenter.getRoom('feedback').getSubject('needHelp').pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(next => {
            if (next) {
                this.openHelp();
            }
        });

        this.octopusConnect.listen('feedbackMessage').pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe((message: DataEntity) => {
            if (message.get('type') === 'disclaimer') {
                this.openDetail(message, null);
            }
        });
    }

    public openDetail(message: DataEntity, action: string): void {
        if (message.get('detail')) {
            const config = {
                titleDialog: '',
                bodyDialog: message.get('detail'),
                labelTrueDialog: 'OK',
                labelOtherDialog: ''
            };

            if (message.get('type') === 'disclaimer') {
                this.translate.get('generic.start').subscribe((translation: string) => {
                    config.labelTrueDialog = translation;
                });

                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                    panelClass: 'feedback-earning-dialog',
                    data: config
                });

                dialogRef.afterClosed().subscribe(result => {
                    this.activityService.currentAssignment.set('hideFeedbacks', false);
                    this.launchNextActivity();
                });
            }
        }
    }

    public openHelp(): void {
        if (this.settings.helpButton.data && this.url) {
            this.settings.helpButton.data.payload.sensors.idTask = this.url.searchParams.get('idTask');
            this.settings.helpButton.data.payload.sensors.idLearner = this.url.searchParams.get('uid');
            this.settings.helpButton.data.payload.uid = this.url.searchParams.get('uid');
            this.settings.helpButton.data.assId = this.activityService.currentAssignment.id;
            this.octopusConnect.createEntity('feedbacks', this.settings.helpButton.data);
        }
    }

    @HostListener('fullscreenchange', ['$event'])
    @HostListener('webkitfullscreenchange', ['$event'])
    @HostListener('mozfullscreenchange', ['$event'])
    @HostListener('MSFullscreenChange', ['$event'])
    screenChange(event): void {
        this.isFullscreen = !this.isFullscreen;
        setTimeout(() => {
            this.iframeSizeByRatio();
        }, 1000);
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.iframeSizeByRatio();
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(() => {
            this.initialize();
        });

    }

    public onIframeLoad(): void {
        this.iframeSizeByRatio();
    }

    /**
     * Initializes the current component
     */
    private initialize(): void {
        this.reset();

        if (this.activityId && this.activityId['type'] && this.activityId['type'] === 'granule') {
            /*
                todo need to refacto to have only one format of data : DataEntity
            */
            const activity: DataEntity = <DataEntity>this.activityId;
            this.setContentData(activity.get('reference'));
        } else {
            this.activityService.launchActivity(this.activity).pipe(
                take(1),
                tap((data: EntityDataSet) => {
                    if (data) {
                        this.setContentData(data.reference);
                    }
                })
            ).subscribe();
        }
    }

    private setContentData(data): void {
        this.questionObject = data;
        this.loadUserSave();
    }

    /**
     * Load userSave which store the iframe url and add params depending on instance settings or redirect to the end of the curent lesson
     */
    private loadUserSave(): void {
        this.activityService.getUserSave(this.activityId.id, this.contextId).pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(userSave => {
                // we not send another next if it was already done for the same id assignment
                if (this.activityService.currentAssignment && (this.previousIdAssignmentNextEvent === 0 || +this.previousIdAssignmentNextEvent !== +this.activityService.currentAssignment.id)) {
                    this.previousIdAssignmentNextEvent = this.activityService.currentAssignment.id;
                    this.communicationCenter
                        .getRoom('assignation')
                        .next('event', {id: this.activityService.currentAssignment.id, event: 'next'});
                }

                this.userSave = userSave;

                let tempUrl = this.questionObject.instruction;
                if (this.settings.dynamicContent && this.userSave && this.userSave.get('userActivity') && this.userSave.get('userActivity').entitySave.content[0]) {
                    tempUrl = this.userSave.get('userActivity').entitySave.content[0];
                }

                if (tempUrl === 'end') {
                    if (this.activityService.currentAssignment &&
                        this.activityService.currentAssignment.get('type_term') &&
                        this.activityService.currentAssignment.get('type_term').label === 'init') {
                        this.activityService.metacognition(true);
                    } else {
                        this.router.navigate(['../../recap'], {relativeTo: this.activatedRoute});
                    }
                }

                if (tempUrl) {
                    this.url = new URL(tempUrl);
                    if (this.settings.iframeParams.includes('uid')) {
                        this.url.searchParams.set('uid', this.currentUser.id.toString());
                    }
                    if (this.settings.iframeParams.includes('prenom')) {
                        this.url.searchParams.set('prenom', this.currentUser.get('label'));
                    }
                    if (this.settings.iframeParams.includes('codeclasse')) {
                        this.url.searchParams.set('codeclasse', this.currentUser.get('groups')[0]);
                    }
                    if (this.settings.iframeParams.includes('token')) {
                        this.url.searchParams.set('token', JSON.parse(localStorage.getItem('http_accessToken')));
                    }
                    if (this.settings.iframeParams.includes('assignationId') && this.activityService.currentAssignment) {
                        this.url.searchParams.set('assignation_id', this.activityService.currentAssignment.id.toString());
                    }
                    if (this.settings.iframeParams.includes('idLesson') && this.activityService.currentAssignment) {
                        this.url.searchParams.set('idLesson', this.activityService.currentAssignment.id.toString());
                    }
                    if (this.settings.iframeParams.includes('idTask')) {
                        this.url.searchParams.set('idTask', Date.now().toString());
                    }

                    const traceInfos = {
                        uid: this.url.searchParams.get('uid'),
                        idTask: this.url.searchParams.get('idTask')
                    };

                    if (this.LRSTracking === true) {
                        this.communicationCenter
                            .getRoom('traces')
                            .next('infos', traceInfos);
                    }

                    this.instruction = this.sanitizer.bypassSecurityTrustResourceUrl(this.url.href); // TODO chargée deux fois

                    this.wording = 'this.questionObject.wording';
                    this.isInitializing = false;
                    this.communicationCenter
                        .getRoom('assignation')
                        .next('event', {id: this.activityService.currentAssignment.id, event: 'loaded'});
                }
            });
    }

    /**
     * load next recommendation (which is stored in userSave)
     */
    public launchNextActivity(): void {
        this.loadUserSave();
        this.communicationCenter.getRoom('feedback').next('reset', true);
    }

    /**
     * Control the display of next button for this activity type
     */
    public displayUserActionButton(): boolean {
        return false;
    }

    /**
     * Resets the current component
     */
    private reset(): void {
        this.isInitializing = true;
        delete this.questionObject;
        delete this.instruction;
        delete this.wording;

        this.changeDetector.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    /**
     * resize the image according to its frame/viewport
     */
    private iframeSizeByRatio(): void {
        if (this.iframeView && !this.isFullscreen ) {
            const iframe = this.iframeView.nativeElement;
            iframe.height = '';
            iframe.width = '';
            iframe.height = +this.iframeViewport.nativeElement.offsetHeight;
            iframe.width = +this.iframeViewport.nativeElement.offsetWidth;
        }
    }
}
