import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {ChartOptions, ChartType, ChartDataSets} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import * as _ from 'lodash';
import {ReplaySubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {tap} from 'rxjs/operators';
import {ProgressionService} from '@modules/graph-assignation/core/progression/progression.service';

@Component({
    selector: 'app-graph-progression',
    templateUrl: './graph-progression.component.html'
})
export class GraphProgressionComponent implements OnInit, OnChanges {
    @Input() barChartData: ChartDataSets[] = [];
    @Input() barChartLabels: string[] = [];
    @Output() graphClick = new EventEmitter<{ event: MouseEvent, activeElement: number | null }>();
    @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

    public barChartType: ChartType = 'bar';
    public barChartLegend = false;
    public graphDetailsModal = new ReplaySubject();
    public displayTooltipIndex: number | null = null;
    private learnersLabel = 'generic.learners';
    public barChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    suggestedMax: this.getMaxTicksLimit(),
                }
            }]
        }, onClick: (event?: MouseEvent, activeElements?: Array<{}>) => {
            event.stopPropagation();
            this.graphClick.emit({event, activeElement: _.get(activeElements, '[0]._index', null)});
        }, onHover: (event?: MouseEvent, activeElements?: Array<{}>) => {
            this.displayTooltipIndex = _.get(activeElements, '[0]._index', null);
        },
        tooltips: {
            enabled: false,
            custom: (tooltipModel) => {
                if (this.displayTooltipIndex !== null) {
                    const clonedModel = _.cloneDeep(tooltipModel);
                    this.graphDetailsModal.next({
                        tooltipY: clonedModel.caretY - 55, // Value define by scss height + 16px of margin
                        tooltipX: clonedModel.caretX - 40, // Value define by the half of the scss width
                        modalContent: {
                            header: this.barChartData[0].data[this.displayTooltipIndex],
                            content: this.learnersLabel
                        }
                    });
                }
            }
        },
    };

    constructor(private translateSvc: TranslateService, private progressionSvc: ProgressionService) {
    }

    ngOnInit(): void {
        this.translateSvc.get(this.learnersLabel).pipe(
            tap(value => {
                this.learnersLabel = value;
            })
        ).subscribe();
        this.barChartOptions = _.merge(this.progressionSvc.progressionSettings.chartOptions, this.barChartOptions);
    }

    ngOnChanges(): void {
        const lastMax = this.barChartOptions.scales.yAxes[0].ticks.suggestedMax;
        const newMax = this.getMaxTicksLimit();
        if (lastMax !== newMax) { // We do this because when we click on a bar, we don't want to reload the component
            this.barChartOptions.scales.yAxes[0].ticks.suggestedMax = newMax;
            this.barChartOptions = _.cloneDeep(this.barChartOptions);
        }
    }

    private getMaxTicksLimit(): number {
        try {
            const maxValue = Math.max(..._.get(this.barChartData, '[0].data', [1]));
            return maxValue + Math.ceil(maxValue / 10);
        } catch (e) {
            console.error(e);
            return 100;
        }
    }
}

