import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {GraphUbolinoService} from './services/graph-ubolino.service';
import {ProgressGraphComponent} from './component/progress-graph/progress-graph.component';
import {AttendanceGraphComponent} from './component/attendance-graph/attendance-graph.component';
import {SharedFiltersComponent} from './component/shared-filters/shared-filters.component';
import {GraphSelectorComponent} from './component/graph-selector/graph-selector.component';
import {MultiGraphViewComponent} from './component/multi-graph-view/multi-graph-view.component';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CdkTableModule} from '@angular/cdk/table';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {FakeStepperComponent} from './component/fake-stepper/fake-stepper.component';
import {defaultRoute} from '../../../settings';
import {IsAtLeastTrainerGuard} from '../../../guards/is-at-least-trainer-guard.service';
import {GraphUbolinoRoutes} from '@modules/graph-ubolino/core/graph-ubolino.routes';
import {SingleGraphViewComponent} from '@modules/graph-ubolino/core/component/single-graph-view/single-graph-view.component';
import {OwnProgressGraphComponent} from '@modules/graph-ubolino/core/component/own-progress-graph/own-progress-graph.component';
import {IsLearnerGuard} from '../../../guards/is-learner.guard';
import { GraphUbolinoAuthorizationService } from './services/graph-ubolino-authorization.service';
import { AuthorizationService } from '@modules/authorization';
import {AsyncRules} from '@modules/graph-ubolino/core/model/rules';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

const routes: Routes = [
    {
        path: 'graph-ubolino',
        children: [
            {
                path: 'multi',
                component: MultiGraphViewComponent,
                children: [
                    {path: GraphUbolinoRoutes.Attendance, component: AttendanceGraphComponent},
                    {path: GraphUbolinoRoutes.Progress, component: ProgressGraphComponent},
                    {
                        path: '**',
                        redirectTo: GraphUbolinoRoutes.Attendance,
                        pathMatch: 'full'
                    }
                ],
                canActivate: [IsAtLeastTrainerGuard]
            },
            {
                path: 'single',
                component: SingleGraphViewComponent,
                children: [
                    {path: GraphUbolinoRoutes.OwnProgress, component: OwnProgressGraphComponent},
                ],
                canActivate: [IsLearnerGuard]
            },
            {
                path: '**',
                redirectTo: defaultRoute,
                pathMatch: 'full'
            }
        ],
        canActivate: [IsUserLogged]
    }
];

@NgModule({
    declarations: [
        AttendanceGraphComponent,
        MultiGraphViewComponent,
        GraphSelectorComponent,
        ProgressGraphComponent,
        SharedFiltersComponent,
        FakeStepperComponent,
        SingleGraphViewComponent,
        OwnProgressGraphComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forRoot(routes),
        SharedModule,
        FuseSharedModule,
        MatProgressSpinnerModule,
        CdkTableModule
    ],
    providers: [
        IsUserLogged
    ]
})
export class GraphUbolinoModule {
    private static isMenuSet = false;
    private onLogout$ = new Subject<void>();

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private graphUbolinoAuthorizationService: GraphUbolinoAuthorizationService,
        private communicationCenter: CommunicationCenterService,
        private authorization: AuthorizationService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.graphUbolinoAuthorizationService.activeRulesOnStartup();
    }


    static forRoot(): ModuleWithProviders<GraphUbolinoModule> {

        return {
            ngModule: GraphUbolinoModule,
            providers: [
                GraphUbolinoService
            ]
        };
    }

    private postLogout(): void {
        this.clearDynamicMenu();
        this.onLogout$.next();
        this.onLogout$.complete();
    }

    private postAuthentication(): void {
        this.onLogout$ = new Subject<void>();

        if (!GraphUbolinoModule.isMenuSet) {
            this.authorization.currentUserCan<Observable<boolean>>(AsyncRules.seeGraphs)
                .pipe(takeUntil(this.onLogout$))
                .subscribe((isAuthorized) => {
                    if (isAuthorized === false) {
                        this.clearDynamicMenu();
                    } else {
                        this.dynamicNavigation.registerModuleMenu('level0', {
                            'id': 'graph-ubolino',
                            'title': 'graph-ubolino',
                            'translate': 'graph_ubolino.menu_link',
                            'type': 'item',
                            'icon': 'analytics',
                            'url': '/graph-ubolino/multi',
                        });

                        Object.keys(GraphUbolinoRoutes)
                            .map(route => GraphUbolinoRoutes[route].toLowerCase())
                            .map(route => ({
                                'id': `graph-ubolino-single-${route}`,
                                'title': `graph-ubolino-single-${route}`,
                                'translate': `graph_ubolino.single_${route}_link`,
                                'type': `item`,
                                'icon': `analytics`,
                                'url': `/graph-ubolino/single/${route}`,
                            })).forEach(menuLink => {
                            this.dynamicNavigation.registerModuleMenu('level0', menuLink);
                        });
                    }

                    GraphUbolinoModule.isMenuSet = true;
                });
        }
    }

    private clearDynamicMenu(): void {
        GraphUbolinoModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'graph-ubolino');
        Object.keys(GraphUbolinoRoutes)
            .map(route => GraphUbolinoRoutes[route].toLowerCase())
            .map(route => `graph-ubolino-single-${route}`).forEach(menuLink => {
            this.dynamicNavigation.clearMenuItem('level0', menuLink);
        });
    }
}
