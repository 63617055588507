import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';

@Injectable()
export class ProjectsMenuResolve implements Resolve<any> {
  constructor(
      private communicationCenter: CommunicationCenterService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return 'project' + route.params['projectId'];
  }
}
