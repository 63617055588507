import {Component, OnInit, OnDestroy, ViewEncapsulation} from '@angular/core';
import {ResearchSheetService} from '../../research-sheet.service';
import {DataEntity} from 'octopus-connect';
import { MatDialog } from '@angular/material/dialog';
import {SectionDraftCreationModalComponent} from '@modules/research-sheet/core/section-draft-display/section-draft-creation-modal/section-draft-creation-modal.component';
import {Router} from '@angular/router';
import {combineLatest, Subscription} from 'rxjs';
import {ResearchTemplate} from '@modules/research-sheet/core/definitions';
import {RenameDraftModalComponent} from '@modules/research-sheet/core/section-draft-display/rename-draft-modal/rename-draft-modal.component';
import {GenericModalService} from '@modules/research-sheet/core/generic-modal.service';
import {brand} from '../../../../../settings';

@Component({
    selector: 'app-section-draft-list',
    templateUrl: './section-draft-list.component.html',
    styleUrls: ['./section-draft-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SectionDraftListComponent implements OnInit, OnDestroy {

    drafts: DataEntity[];
    brand: string = brand;
    draftSubscribe: Subscription;

    constructor(
        public sheetService: ResearchSheetService,
        private dialog: MatDialog,
        private router: Router,
        private genericModal: GenericModalService
    ) { }

    ngOnInit(): void {
        this.draftSubscribe = combineLatest(this.sheetService.loadResearchDrafts(), this.sheetService.completedObs).subscribe(resp => {
            this.drafts = resp[0].entities;
        });
    }

    getSectionTypeNamePrefix(draft: DataEntity): string {
        return this.sheetService.getSectionPrefix(this.sheetService.templatesSectionsCollection[+(draft.get('sectionDefinitions'))].get('name'));
    }

    getSectionTypeName(draft: DataEntity): string {
        return this.sheetService.templatesSectionsCollection[+(draft.get('sectionDefinitions'))].get('name');
    }

    getTemplateName(draft: DataEntity): string {
        const templateSectionId: number = +draft.get('sectionDefinitions');

        const template: ResearchTemplate = this.sheetService.templatesList.find(templ => {
            return templ.sections.find(section => section.id === templateSectionId) !== undefined;
        });

        return template.name;
    }

    getDraftCreationDate(draft: DataEntity): string {
        const date = new Date(draft.get('created') * 1000);
        return date.toLocaleDateString((navigator.languages && navigator.languages[0]) || navigator.language);
    }

    addDraft() {
        this.dialog.open(SectionDraftCreationModalComponent, {
            panelClass: 'research-sheet-draftCreation-dialog',
            width: '500px'
        });
    }

    editDraft(draft: DataEntity) {
        this.router.navigate([this.sheetService.getDraftEditLink(+draft.id)]);
    }

    viewDraft(draft: DataEntity) {
        this.router.navigate([this.sheetService.getDraftViewLink(+draft.id)]);
    }

    clickAction(draft: DataEntity) {
        if (this.isMine(draft)) {
            this.editDraft(draft);
        } else {
            this.viewDraft(draft);
        }
    }

    deleteDraft(draft: DataEntity) {
        this.genericModal.openYesNoDialog('Suppression ?').subscribe((resp: string) => {
            if (resp === 'yes') {
                draft.remove();
            }
        });
    }

    duplicateDraft(draft: DataEntity) {
        this.sheetService.duplicateDraft(draft, this.drafts);
    }

    renameDraft(draft: DataEntity) {
        this.dialog.open(RenameDraftModalComponent, {
            width: '500px',
            data: draft
        });
    }

    isMine(draft: DataEntity): boolean {
        return this.sheetService.isMine(+draft.get('owner')['uid']);
    }

    ngOnDestroy(){
        this.draftSubscribe.unsubscribe();
    }
}
