import {Injectable} from '@angular/core';
import {AssignationConfigurationService} from '@modules/assignation/core/services/assignation-configuration.service';
import {AuthorizationService} from '@modules/authorization';
import {AsyncRules} from '@modules/assignation/core/models/rules';
import {AuthenticationService} from '@modules/authentication';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {CommunicationCenterService} from '@modules/communication-center';
import {Observable, of} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AssignationAuthorizationService {

    constructor(private config: AssignationConfigurationService,
                private authorization: AuthorizationService,
                private authentication: AuthenticationService,
                private communicationCenter: CommunicationCenterService) {
    }

    public activeRulesOnstartUp(): void {
        if (this.config.doTrainersHaveRestrictiveRights()) {
            this.activeInstitutionGivenRightsTrainersRules();
        } else {
            this.activeSimplyTrainersRules();
        }
    }

    private isUserHasEducatorRights(user: UserDataEntity): Observable<boolean> {
        return this.communicationCenter
            .getRoom('groupsManagement')
            .getSubject('isUserHasEducatorRightsInHisInstitutionsCallback')
            .pipe(
                take(1),
                mergeMap((callBack: (UserDataEntity) => Observable<boolean>) => callBack(user))
            );
    }

    private activeInstitutionGivenRightsTrainersRules(): void {
        /**
         * - Assigner des leçons à ses élèves
         * - Assigner des leçons à ses classes
         * - Assigner des leçons à ses groupes
         * - Suivre les assignations qu'il a donné
         */
        this.authorization.addRule(AsyncRules.ReadAssignments, (user) => {
            if (this.authentication.isLearner() || this.authentication.isAtLeastDirector()) {
                return of(true);
            } else if (this.authentication.isTrainer()) {
                return this.isUserHasEducatorRights(user);
            }
            return of(false);
        });

        this.authorization.addRule(AsyncRules.AssignLessons, (user) => {
            if (this.config.isAssignationActionIsActive()) {
                if (this.authentication.isAtLeastManager()) {
                    return of(true);
                } else if (this.authentication.isTrainer()) {
                    return this.isUserHasEducatorRights(user);
                } else if (this.authentication.isDirector()) {
                    return of(true);
                }
            }
            return of(false);
        });
    }

    private activeSimplyTrainersRules(): void {
        this.authorization.addRule(AsyncRules.ReadAssignments, (user) => {
            return of(this.authentication.isAtLeastLearner());
        });

        this.authorization.addRule(AsyncRules.AssignLessons, (user) => {
            if (this.config.isAssignationActionIsActive()) {
                return of(this.authentication.isAtLeastTrainer());
            }
            return of(false);
        });
    }
}
