import {GraphData} from './graph-data';
import * as _ from 'lodash';
import {RawProgressDotDetail} from './raw-progress-dot-detail';

export interface ExerciseAttendanceGraphData {
    [learnerId: number]: {
        [exerciseTypeId: number]: {
            activitiesDone: number,
            exerciseTypeId: string,
            detail: RawProgressDotDetail[],
            label: string,
            learnerId: string,
            localized: string
        }[]
    };
}

export interface ModalityAttendanceGraphData {
    [learnerId: number]: {
        [modality: string]: RawProgressDotDetail & {
            label: string,
            learnerId: string,
            localized: string
        }[]
    };
}

export class AttendanceData extends GraphData {
    public get entitiesGroupedByLearnerAndExerciseType(): ExerciseAttendanceGraphData {

        const flattenedData = this.entities.map(entity =>
            Object.keys(entity.attributes.data).map(learnerId =>
                _.merge(
                    {learnerId},
                    _.clone(entity.attributes.data[learnerId]),
                    {
                        exerciseTypeId: entity.attributes.chapters[0],
                        label: entity.attributes.label,
                        localized: entity.attributes.localized
                    }
                )
            )
        ).flat();

        flattenedData.forEach(row => {
            row.detail = row.detail.filter(dot => dot.firstAnswer + dot.secondAnswer + dot.failedFirstAttempt + dot.failedRetry !== 0);
        });

        const groupedByLearner = _.groupBy(flattenedData, 'learnerId');
        return Object.keys(groupedByLearner).reduce((acc, learnerId) =>
            _.merge(acc, {[learnerId]: _.groupBy(groupedByLearner[learnerId], 'exerciseTypeId')}), {}
        );
    }

    public get entitiesGroupedByLearnerAndModality(): ModalityAttendanceGraphData {
        const flattenedData = this.entities.map(entity =>
            Object.keys(entity.attributes.data).map(learnerId =>
                entity.attributes.data[learnerId].detail.map(detail =>
                    _.merge(
                        {learnerId},
                        _.clone(detail),
                        {
                            label: entity.attributes.label,
                            localized: entity.attributes.localized,
                            chapters: entity.attributes.chapters
                        }
                    )
                )
            ))
            .flat()
            .flat()
            .filter(a => !!a);

        const filteredByChapters = flattenedData.filter(d => d.chapters.includes(this.graphFilters.exerciseType));
        const groupedByLearner = _.groupBy(filteredByChapters, 'learnerId');
        return Object.keys(groupedByLearner).reduce((acc, learnerId) =>
            _.merge(acc, {[learnerId]: _.groupBy(groupedByLearner[learnerId], 'modality')}), {}
        );
    }
}
