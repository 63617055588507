import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {IdeasWallDdService} from '../ideas-wall-dd.service';
import { MatDialog } from '@angular/material/dialog';
import {IdeaEditionModalComponent} from '../idea-edition-modal/idea-edition-modal.component';
import {IdeasWallService} from '../ideas-wall.service';
import {CategoryDrop} from '../category-drop.interface';
import {MatDialogConfig} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';

declare var require: any;
const Draggable = require('gsap/Draggable');
const TweenLite = require('gsap/TweenLite');

@Component({
    selector: 'category-item',
    templateUrl: './category-item.component.html',
    styleUrls: ['./category-item.component.scss']
})
export class CategoryItemComponent implements OnInit, OnDestroy {

    @Input('category') category: DataEntity;
    @Input('ideas') ideas: DataEntity[];
    @ViewChild('content', { static: true }) element: ElementRef;
    @ViewChild('mainElement', { static: true }) mainElement: ElementRef;
    @ViewChild('contentText', { static: true }) contentText: ElementRef;
    @ViewChild('topElement', { static: true }) topElement: ElementRef;
    @ViewChild('cardElement', { static: true }) cardElement: ElementRef;
    private draggable: any;
    over = false;
    overOnly = false;
    folded = false;

    dragging = false;

    beforePlaceholder = false;
    afterPlaceholder = false;

    clone: HTMLElement;
    originalHeight: number;

    constructor(
        private ddService: IdeasWallDdService,
        private wallsService: IdeasWallService,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.ddService.registerCategory(this);
        this.dragInit();
    }

    dragInit(): void {
        let startParentTop: number;

        this.draggable = Draggable.create(this.mainElement.nativeElement, {
            trigger: this.contentText.nativeElement,
            onDragStart: () => {

                // creation du ghost
                this.clone = this.cardElement.nativeElement.cloneNode(true);
                this.clone.style.position = 'fixed';
                this.clone.style.width = this.mainElement.nativeElement.clientWidth + 'px';
                this.clone.style.zIndex = '1';
                this.clone.style.opacity = '0.5';
                document.body.appendChild(this.clone);

                this.originalHeight = this.cardElement.nativeElement.getBoundingClientRect().height;

                this.dragging = true;

                setTimeout(() => {
                    startParentTop = this.mainElement.nativeElement.parentElement.getBoundingClientRect().top;
                });

                this.setClonePosition();
            },
            onDrag: () => {
                this.ddService.resetCategoriesComponents();
                this.ddService.overCategoriesContainer = false;

                const parentTop: number = this.mainElement.nativeElement.parentElement.getBoundingClientRect().top;
                const gap: number = Math.floor(parentTop - startParentTop);

                this.setClonePosition(gap);

                const category: CategoryDrop = this.ddService.checkIfOnCategoryWithPosition(this.draggable, (this.displayMode === 'mixed') ? 'vertical' : 'horizontal');

                if (category) {
                    this.ddService.overPlaceholderHeight = this.originalHeight;

                    if (category.position === 'top' || category.position === 'left') {
                        category.category.displayBeforePlaceholder();
                    } else if (category.position === 'bottom' || category.position === 'right') {
                        category.category.displayAfterPlaceholder();
                    }

                    return;
                }

                if (this.ddService.checkIfOnCategoriesContainer(this.draggable)) {
                    this.ddService.overCategoriesContainer = true;
                    this.ddService.overPlaceholderHeight = this.originalHeight;
                }

            },
            onDragEnd: () => {


                const category: CategoryDrop = this.ddService.checkIfOnCategoryWithPosition(this.draggable, (this.displayMode === 'mixed') ? 'vertical' : 'horizontal');

                if (category) {
                    this.ddService.overPlaceholderHeight = this.originalHeight;

                    if (category.position === 'top' || category.position === 'left') {
                        this.category.set(
                            'position',
                            this.wallsService.getPreviousMeanPosition(category.category.category, this.category, this.wallsService.completeCategoriesList)
                        );
                    } else if (category.position === 'bottom' || category.position === 'right') {
                        this.category.set(
                            'position',
                            this.wallsService.getNextMeanPosition(category.category.category, this.category, this.wallsService.completeCategoriesList)
                        );
                    }

                    this.category.save(true);

                    this.resetPosition();
                    this.dragging = false;
                    this.ddService.resetCategoriesComponents();
                    this.ddService.overCategoriesContainer = false;

                    document.body.removeChild(this.clone);
                    this.clone = null;
                    return;
                }

                if (this.ddService.checkIfOnCategoriesContainer(this.draggable)) {
                    this.category.set('position', this.wallsService.maxPosition(this.wallsService.completeCategoriesList) + this.wallsService.ideasInterval);
                    this.category.save(true);
                }

                this.resetPosition();
                this.dragging = false;
                this.ddService.resetCategoriesComponents();
                this.ddService.overCategoriesContainer = false;

                document.body.removeChild(this.clone);
                this.clone = null;
            }
        })[0];

        if (this.locked) {
            this.draggable.disable();
        }

        this.wallsService.lockToggle
            .subscribe((lock: any) => {
                if (lock.type === 'categories') {
                    if (lock.locked) {
                        this.draggable.disable();
                    } else {
                        this.draggable.enable();
                    }
                }
            });
    }

    resetPosition(): void {
        TweenLite.set(this.mainElement.nativeElement, {
            clearProps: 'all'
        });
    }

    setClonePosition(gap: number = 0): void {
        this.clone.style.top = (this.mainElement.nativeElement.getBoundingClientRect().top - gap) + 'px';
        this.clone.style.left = this.mainElement.nativeElement.getBoundingClientRect().left + 'px';
    }

    deleteCategory(): void {
        const dialogConfig = new MatDialogConfig();
        let dialogYes = '';
        let dialogNo = '';
        let dialogDelete = '';

        this.translate.get('generic.yes').subscribe((translation: string) => dialogYes = translation);
        this.translate.get('generic.no').subscribe((translation: string) => dialogNo = translation);
        this.translate.get('generic.delete').subscribe((translation: string) => dialogDelete = translation);

        dialogConfig.data = {
            bodyDialog: dialogDelete,
            labelTrueDialog: dialogYes,
            labelFalseDialog: dialogNo
        };

        this.dialog.open(FuseConfirmDialogComponent, dialogConfig).afterClosed().subscribe((result) => {
            if (result) {
                this.ideas.forEach(idea => {
                    idea.set('category', []);
                    idea.save();
                });

                this.category.remove();
            }
        });
    }

    toggleFolding(evt: PointerEvent): void {
        this.folded = !this.folded;
        evt.stopPropagation();
        evt.preventDefault();
    }

    get placeholderHeight(): number {
        return this.ddService.overPlaceholderHeight;
    }

    get displayMode(): string {
        return this.wallsService.currentDisplayMode;
    }

    setOver(): void {
        this.over = true;
        this.overOnly = true;
        this.cdr.detectChanges();
    }

    unsetOver(): void {
        this.over = false;
        this.overOnly = false;
        this.cdr.detectChanges();
    }

    displayBeforePlaceholder(): void {
        this.beforePlaceholder = true;
    }

    displayAfterPlaceholder(): void {
        this.afterPlaceholder = true;
    }

    hidePlaceholders(): void {
        this.beforePlaceholder = false;
        this.afterPlaceholder = false;
    }

    editCategory(): void {
        this.dialog.open(IdeaEditionModalComponent, {
            data: {
                text: this.category.get('name') || '',
                type: 'category',
                phase: 'edition'
            }
        }).beforeClosed().subscribe((text: string) => {
            if (text !== '' && text !== null && text !== undefined) {
                this.category.set('name', text);
                this.category.save();
            }
        });
    }

    ngOnDestroy(): void {
        this.ddService.unRegisterCategory(this);
    }

    get id(): number {
        return +this.category.id;
    }

    get locked(): boolean {
        return this.wallsService.lockedCategories;
    }

    mergeWith(otherCategory: DataEntity): void {
        this.wallsService.mergeCategories(this.category, otherCategory);
    }

    get isTeacher(): boolean {
        return this.wallsService.isTeacher;
    }
}
